import React,{useEffect, useState} from 'react'
import primeToggle from '../../../assets/user/images/prime-toggle.svg'
import verifyProfile from '../../../assets/user/images/verify-profile-img.svg'
import goldMemberIcon from '../../../assets/user/images/icon-gold-member.svg'
import userClient1 from '../../../assets/user/images/users-client-1.png'
import addPhotoFemale from '../../../assets/user/images/add-photo-female.png'
import addHobies from '../../../assets/user/images/add-hobbies.png'
import cardBgEducation from '../../../assets/user/images/card-bg-education.png'
import exploreAssisted from '../../../assets/user/images/icon-explore-assisted.svg'
import explorePremium from '../../../assets/user/images/icon-explore-premium.svg'
import exploreSafety from '../../../assets/user/images/icon-explore-safety.svg'
import exploreRetail from '../../../assets/user/images/icon-explore-retail.svg'
import exploreMandap from '../../../assets/user/images/icon-explore-mandap.svg'
import assistedOthers from '../../../assets/user/images/assisted_others.png'
import Bazzar from '../../../assets/user/images/Bazaar_720x300.png'
import editProfile from '../../../assets/user/images/edit-profile.svg'
import editPreference from '../../../assets/user/images/edit-preference.svg'
import menuHoroscope from '../../../assets/user/images/menu_horoscope.svg'
import menuIdentity from '../../../assets/user/images/menu_identity.svg'
import settingSvg from '../../../assets/user/images/setting.svg'
import helpSvg from '../../../assets/user/images/help.svg'
import safeMatrimony from '../../../assets/user/images/safe-matrimony.svg'
import sucessStory from '../../../assets/user/images/success-story.svg'
import maleAvatar from '../../../assets/user/images/male-avatar.svg'
import limcaRecord from "../../../assets/user/images/logo-limca-records.webp"
import femaleAvatar from "../../../assets/user/images/user-avtar.svg"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { getProfile } from '../services/user.service';
import { Link } from 'react-router-dom';
import { greet } from '../../../utils/commonfunction'

// import './owl.css';  

const Dashboard = () => {
    const [userInfo,setUserInfo]=useState(null)
    useEffect(() => {
        getProfile().then((response) => {
            if(response.success){
                setUserInfo(response.data)
            }
        }).catch((error) => {
            console.log("error",error)
        })
      }, []);
    return (
        <div className="wraper-inner">
            <section className="padding-space">
                <div className="container">

                    <div className="toogle-switch-btn mb-5">

                        <ul className="toogle-switch-list d-flex align-items-center justify-content-center">

                            <li className="active"> Regular </li>
                            <li><figure><img src={primeToggle} alt=""/></figure></li>
                        </ul>
                    </div>
                    <div className="row">

                        <div className="col-md-3">
                            <div className="profile-sidebar">
                                <div className="upload-photo">
                                    <div className="avatar-upload">
                                        <div className="avatar-edit">
                                            <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" />
                                            <label htmlFor="imageUpload" className="img-upload-icon"><i className="ti ti-camera"></i>
                                            </label>
                                        </div>
                                        <div className="avatar-preview">
                                            <div id="imagePreview" className="avatar-preview-img" style={{backgroundImage: userInfo && userInfo.gender=='female' ?`url(${femaleAvatar})`:`url(${maleAvatar})` }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-content">
                                    <h5>{greet()}</h5>
                                    <h1>{userInfo && userInfo.name}</h1>
                                    <span>{userInfo && userInfo.matrimony_id}</span>
                                    <small>Membership: Free</small>
                                    <p>Become a paid member now</p>
                                    <a className="btn-sm btn-outline m-auto mt-2" href="/">Upgrade</a>
                                </div>
                                <hr className="seprator" />
                                <ul className="profile-list">
                                    <li><Link to="/edit-profile"> <img src={editProfile} alt="" />Edit Profile</Link></li>
                                    {/* <li><a href="/"><img src={editProfile} alt="" />Edit Profile</a></li> */}
                                    <li><a href="/"><img src={editPreference} alt="" />Edit Preferencess</a></li>
                                    <li><a href="/"><img src={menuHoroscope} alt="" />Generate Horoscope</a></li>
                                    <li><a href="/"><img src={menuIdentity} alt="" />Verify Your Profile</a></li>
                                </ul>
                                <hr className="seprator" />
                                <ul className="profile-list">
                                    <li><Link to="/settings"><img src={settingSvg} alt="" />Settings</Link></li>
                                    <li><a href="/"><img src={helpSvg} alt="" />Help</a></li>
                                    <li><a href="/"><img src={safeMatrimony} alt="" />Safe Matrimony</a></li>
                                    <li><a href="/"><img src={sucessStory} alt="" />Success Stories</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-9">
                            {userInfo && userInfo.is_mobile_verified ?
                            <div className="view-profile mb-4">
                                <div className="view-profile-left">
                                    <figure><img src={verifyProfile} alt=""/></figure>
                                    <figcaption>
                                        <h4>Your profile is pending verification! Verify now to get better responses</h4>
                                    </figcaption>
                                </div>
                                <div className="view-profile-right">
                                    <a className="btn-sm" href="/"> Verify Profile </a>
                                </div>
                            </div>:''}
                            <div className="your-journey">
                                <div className="box-dash-head">
                                    <h2>Your Journey So Far</h2>
                                </div>
                                <div className="box-dash-body">
                                    <div className="row mb-4">
                                        <div className="col-md-3">
                                            <div className="your-journey-box">
                                                <div className="your-journey-box-left">
                                                    <span>3</span>
                                                    <small>Viewed you</small>
                                                </div>
                                                <div className="your-journey-box-right">
                                                    <div className="img-select">
                                                        <span><img src={maleAvatar} alt=""/></span>
                                                        <span><img src={maleAvatar} alt=""/></span>
                                                        <span><img src={maleAvatar} alt=""/></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-dash">
                                <div className="row">
                                    <div className="col-md-12 mb-5 daily-recommendations-sec">
                                        <div className="box-dash-head d-flex align-items-center justify-content-between">
                                            <div className="box-dash-head-left">
                                                <h2>Your Daily Recommendations for 28th Nov</h2>
                                            </div>
                                            <a className="link-text d-flex" href="/">See all <i className="ri-arrow-right-s-line"></i></a>
                                        </div>
                                        <div className="box-dash-body">
                                            <div className='recommendations-slider'>
                                            <div className="owl-carousel owl-theme owl-common owl-common-nav">
                                                <OwlCarousel items={4.5} className="owl-theme"loop nav margin={20} >
                                                    <div className="item gold-member-active">
                                                        <span className="gold-member"><img src={goldMemberIcon} alt="" /></span>
                                                        <figure><img src={userClient1} alt="" /></figure>
                                                        <figcaption>
                                                            <h4> Bhuvesh Garg </h4>
                                                            <span>28 Yrs, 5'0"</span>
                                                        </figcaption>
                                                    </div>
                                                    <div className="item">
                                                        <figure><img src={userClient1} alt="" /></figure>
                                                        <figcaption>
                                                            <h4> Bhuvesh Garg </h4>
                                                            <span>28 Yrs, 5'0"</span>
                                                        </figcaption>
                                                    </div>
                                                    <div className="item">
                                                        <figure><img src={userClient1} alt="" /></figure>
                                                        <figcaption>
                                                            <h4> Bhuvesh Garg </h4>
                                                            <span>28 Yrs, 5'0"</span>
                                                        </figcaption>
                                                    </div>
                                                    <div className="item">
                                                        <figure><img src={userClient1} alt="" /></figure>
                                                        <figcaption>
                                                            <h4> Bhuvesh Garg </h4>
                                                            <span>28 Yrs, 5'0"</span>
                                                        </figcaption>
                                                    </div>
                                                    <div className="item">
                                                        <figure><img src={userClient1} alt="" /></figure>
                                                        <figcaption>
                                                            <h4> Bhuvesh Garg </h4>
                                                            <span>28 Yrs, 5'0"</span>
                                                        </figcaption>
                                                    </div>
                                                    <div className="item">
                                                        <figure><img src={userClient1} alt="" /></figure>
                                                        <figcaption>
                                                            <h4> Bhuvesh Garg </h4>
                                                            <span>28 Yrs, 5'0"</span>
                                                        </figcaption>
                                                    </div>
                                                </OwlCarousel>
                                            </div>
                                            </div>
                                            <div className="time-box mt-3">Time left to view your daily matches - <span className="time-slot">07 : 25 : 34</span></div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-4 all-matches-sec">
                                        <div className="box-dash-head d-flex align-items-center justify-content-between">
                                            <div className="box-dash-head-left">
                                                <h2>All Matches (4800)</h2>
                                                <p>Members who match your partner preferences</p>
                                            </div>
                                            <a className="link-text d-flex" href="/">See all <i className="ri-arrow-right-s-line"></i></a>
                                        </div>
                                        <div className="box-dash-body">
                                            <div className='other-slider'>
                                                
                                            <div className="owl-carousel owl-theme all-matches  owl-common sec-slide owl-common-nav">
                                                
                                            <OwlCarousel items={4.5} className="owl-theme" loop nav margin={20} >
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                </OwlCarousel>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-5 complete-profile">
                                        <div className="box-dash-head">
                                            <div className="box-dash-head-left">
                                                <h2>Complete Your Profile</h2>
                                                <div className="text-with-progress d-flex align-items-center">
                                                    <p>Profile completeness score 30% </p>
                                                    <div className="progress ms-3">
                                                        <div className="progress-bar" role="progressbar" aria-label="Basic example" style={{ width: '30%' }} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-dash-body">

                                        <div className='other-slider'>
                                            <div className="owl-carousel owl-theme complete-profile owl-common-nav">
                                               
                                            <OwlCarousel items={2.2} className="owl-theme" loop nav margin={20} >
                                                <div className="item">
                                                    <figure><img src={addPhotoFemale} alt=""/></figure>
                                                    <figcaption>
                                                        <span>Your photo is the first thing that your matches look at</span>
                                                        <a className="btn-sm w-100"> Add  Photos </a>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={addHobies} alt="" /></figure>
                                                    <figcaption>
                                                        <span> Add your hobbies and interests to discover matches with similar interests </span>
                                                        <a className="btn-sm w-100"> Verify Profile With ID </a>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={addPhotoFemale} alt=""/></figure>
                                                    <figcaption>
                                                        <span>Your photo is the first thing that your matches look at</span>
                                                        <a className="btn-sm w-100"> Add More Photos </a>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={addHobies} alt="" /></figure>
                                                    <figcaption>
                                                        <span> Add your hobbies and interests to discover matches with similar interests </span>
                                                        <a className="btn-sm w-100"> Add Hobbies </a>
                                                    </figcaption>
                                                </div>
                                                </OwlCarousel>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-5 new-matches-sec">
                                        <div className="box-dash-head d-flex align-items-center justify-content-between">
                                            <div className="box-dash-head-left">
                                                <h2>New Matches (458)</h2>
                                                <p>Members who match your preferences and have joined in the last 30 days</p>
                                            </div>
                                            <a className="link-text d-flex" href="/">See all <i className="ri-arrow-right-s-line"></i></a>
                                        </div>
                                        <div className="box-dash-body">

                                        <div className='other-slider'>
                                            <div className="owl-carousel owl-theme all-matches  owl-common sec-slide owl-common-nav">
                                                
                                            <OwlCarousel items={4.5} className="owl-theme" loop nav margin={20} >
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                </OwlCarousel>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-5 enrich-profile-sec">
                                        <div className="box-dash-head">
                                            <div className="box-dash-head-left">
                                                <h2>Enrich Your Profile</h2>
                                                <p>Add below details to let prospects know more about you and get higher responses </p>
                                            </div>
                                        </div>
                                        <div className="box-dash-body">
                                        <div className='other-slider'>
                                            <div className="owl-carousel owl-theme complete-profile owl-common-nav">

                                            <OwlCarousel items={2.2} className="owl-theme" loop nav margin={20} >
                                                <div className="item">
                                                    <figure><img src={addPhotoFemale} /></figure>
                                                    <figcaption>
                                                        <span>Your photo is the first thing that your matches look at</span>
                                                        <a className="btn-sm w-100"> Add  Photos </a>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={addHobies} /></figure>
                                                    <figcaption>
                                                        <span> Add your hobbies and interests to discover matches with similar interests </span>
                                                        <a className="btn-sm w-100"> Verify Profile With ID </a>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={addPhotoFemale} /></figure>
                                                    <figcaption>
                                                        <span>Your photo is the first thing that your matches look at</span>
                                                        <a className="btn-sm w-100"> Add More Photos </a>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={addHobies} /></figure>
                                                    <figcaption>
                                                        <span> Add your hobbies and interests to discover matches with similar interests </span>
                                                        <a className="btn-sm w-100"> Add Hobbies </a>
                                                    </figcaption>
                                                </div>
                                                </OwlCarousel>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-4 matches-viewed-you-sec">
                                        <div className="box-dash-head d-flex align-items-center justify-content-between">
                                            <div className="box-dash-head-left">
                                                <h2>Matches Viewed You (3)</h2>
                                            </div>
                                            <a className="link-text d-flex">See all <i className="ri-arrow-right-s-line"></i></a>
                                        </div>
                                        <div className="box-dash-body">

                                        <div className='other-slider'>
                                            <div className="owl-carousel owl-theme matched-viewed owl-common-nav">

                                            <OwlCarousel items={2} className="owl-theme" loop nav margin={20} >
                                                <div className="item">
                                                    <div className="d-flex">
                                                        <figure><img src={userClient1} alt="" /></figure>
                                                        <figcaption>
                                                            <h4 > Yash Gupta </h4>
                                                            <span>25 Yrs, 5 ft 5 in  </span>
                                                            <span>B.Com., Business, Varanasi</span>
                                                            <p className="date-box mt-3 d-flex align-items-center"><i className="ri-calendar-2-line"></i>  28 Nov 2023 </p>
                                                        </figcaption>
                                                    </div>
                                                </div>
                                                </OwlCarousel>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-5 discover-sec">
                                        <div className="box-dash-head ">
                                            <h2>Discover Matches</h2>
                                            <p>Explore profiles matching your preferences</p>
                                        </div>
                                        <div className="box-dash-body">
                                            <div className="row">
                                                <div className="col-md-3 ">
                                                    <div className="discover-content">
                                                        <figure><img src={cardBgEducation} /></figure>
                                                        <figcaption>
                                                            <h4> Education <span>(3940)</span></h4>
                                                        </figcaption>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="discover-content">
                                                        <figure><img src={cardBgEducation} /></figure>
                                                        <figcaption>
                                                            <h4> Education <span>(3940)</span></h4>
                                                        </figcaption>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 ">
                                                    <div className="discover-content">
                                                        <figure><img src={cardBgEducation} /></figure>
                                                        <figcaption>
                                                            <h4> Education <span>(3940)</span></h4>
                                                        </figcaption>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 ">
                                                    <div className="discover-content">
                                                        <figure><img src={cardBgEducation} /></figure>
                                                        <figcaption>
                                                            <h4> Education <span>(3940)</span></h4>
                                                        </figcaption>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-5 nearby-matches-sec">
                                        <div className="box-dash-head d-flex align-items-center justify-content-between">
                                            <div className="box-dash-head-left">
                                                <h2>Nearby Matches (32)</h2>
                                                <p>Matches from your location</p>
                                            </div>
                                            <a className="link-text d-flex">See all <i className="ri-arrow-right-s-line"></i></a>
                                        </div>
                                        <div className="box-dash-body">
                                            <div className='other-slider'>
                                            <div className="owl-carousel owl-theme nearby-matches  owl-common sec-slide owl-common-nav">
                                                
                                            <OwlCarousel items={4.2} className="owl-theme" loop nav margin={20} >
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <a className="address-link d-flex align-items-center mb-1"><i className="ri-map-pin-line"></i>Adoni</a>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <a className="address-link d-flex align-items-center mb-1"><i className="ri-map-pin-line"></i>Adoni</a>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <a className="address-link d-flex align-items-center mb-1"><i className="ri-map-pin-line"></i>Adoni</a>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <a className="address-link d-flex align-items-center mb-1"><i className="ri-map-pin-line"></i>Adoni</a>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <a className="address-link d-flex align-items-center mb-1"><i className="ri-map-pin-line"></i>Adoni</a>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                <div className="item">
                                                    <figure><img src={userClient1} alt="" /></figure>
                                                    <figcaption>
                                                        <a className="address-link d-flex align-items-center mb-1"><i className="ri-map-pin-line"></i>Adoni</a>
                                                        <h4> Bhuvesh Garg </h4>
                                                        <span>28 Yrs, 5'0"</span>
                                                    </figcaption>
                                                </div>
                                                </OwlCarousel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-5 explore-bharatmatrimony-sec">
                                        <div className="box-dash-head ">
                                            <div className="box-dash-head-left">
                                                <h2>Explore Bharatmatrimony</h2>
                                            </div>
                                        </div>
                                        <div className="box-dash-body">
                                            <div className='other-slider'>
                                            <div className="owl-carousel owl-theme Explore-Bharatmatrimony  owl-common-nav">
                                            <OwlCarousel items={4.2} className="owl-theme" loop nav margin={20} >
                                            
                                                <div className="item">
                                                    <figure><img src={exploreAssisted} /></figure>
                                                    <figcaption>
                                                        Assisted <br /> Service
                                                    </figcaption>
                                                </div>
                                                <div className="item bg-lp">
                                                    <figure><img src={explorePremium} /></figure>
                                                    <figcaption>
                                                        Premium  <br />  Membership
                                                    </figcaption>
                                                </div>
                                                <div className="item bg-lb">
                                                    <figure><img src={exploreSafety} /></figure>
                                                    <figcaption>
                                                        Safety & Privacy
                                                    </figcaption>
                                                </div>
                                                <div className="item bg-lpp">
                                                    <figure><img src={exploreRetail} /></figure>
                                                    <figcaption>
                                                        Retail  Stores
                                                    </figcaption>
                                                </div>
                                                <div className="item bg-lp">
                                                    <figure><img src={exploreMandap} /></figure>
                                                    <figcaption>
                                                        Retail  Stores
                                                    </figcaption>
                                                </div>
                                                </OwlCarousel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-5 success-stories-sec">
                                        <div className="box-dash-head ">
                                            <div className="box-dash-head-left">
                                                <h2>Success Stories</h2>
                                            </div>
                                        </div>
                                        <div className="box-dash-body">
                                            <div className="limica-record d-flex align-items-center mb-4">
                                                <figure><img src={limcaRecord} /></figure>
                                                <figcaption>We organise more marriages than anyone else in the world!</figcaption>
                                            </div>
                                            <div className="box-dash-body">
                                                <div className='other-slider'>
                                                <div className="owl-carousel owl-theme success-stories owl-common-dots">
                                                <OwlCarousel items={2.2} className="owl-theme"loop nav margin={20} >
                                                    <div className="item">
                                                        <div className="d-flex">
                                                            <figure><img src={userClient1} alt="" /></figure>
                                                            <figcaption>
                                                                <h4 >Aryan Arnav & Vindhya Dhamala </h4>
                                                                <span>  Grateful to Bharat Matrimony We first came into contact through Bharat.... <a className="link-text mt-1">Read More...</a></span>
                                                            </figcaption>
                                                        </div>
                                                    </div>
                                                    <div className="item">
                                                        <div className="d-flex">
                                                            <figure><img src={userClient1} alt="" /></figure>
                                                            <figcaption>
                                                                <h4 >Aryan Arnav & Vindhya Dhamala </h4>
                                                                <span>  Grateful to Bharat Matrimony We first came into contact through Bharat.... <a className="link-text mt-1">Read More...</a></span>
                                                            </figcaption>
                                                        </div>
                                                    </div>
                                                    <div className="item">
                                                        <div className="d-flex">
                                                            <figure><img src={userClient1} alt="" /></figure>
                                                            <figcaption>
                                                                <h4 >Aryan Arnav & Vindhya Dhamala </h4>
                                                                <span>  Grateful to Bharat Matrimony We first came into contact through Bharat.... <a className="link-text mt-1">Read More...</a></span>
                                                            </figcaption>
                                                        </div>
                                                    </div>
                                                    <div className="item">
                                                        <div className="d-flex">
                                                            <figure><img src={userClient1} alt="" /></figure>
                                                            <figcaption>
                                                                <h4 >Aryan Arnav & Vindhya Dhamala </h4>
                                                                <span>  Grateful to Bharat Matrimony We first came into contact through Bharat.... <a className="link-text mt-1">Read More...</a></span>
                                                            </figcaption>
                                                        </div>
                                                    </div>
                                                    </OwlCarousel>
                                                    </div>
                                                </div>
                                                <div className="btn-group  d-flex justify-content-center mt-3">
                                                    <a className="btn-sm btn-outline"> View More Success Stories </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="need-help mt-4 mb-5">
                                        <figure><img src={assistedOthers} /></figure>
                                        <figcaption>
                                            <h3>Need help to find a life partner?</h3>
                                            <p>Get personalised service from a dedicated Relationship Manager</p>
                                            <a className="btn-sm mt-2"> Know More </a>
                                        </figcaption>
                                    </div>
                                    <div className="col-md-12 mb-5 our-wedding-sec">
                                        <div className="box-dash-head ">
                                            <div className="box-dash-head-left">
                                                <h2>Our Wedding Services</h2>
                                            </div>
                                        </div>
                                        <div className="box-dash-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="wedding-services-box">
                                                        <figure><img src={Bazzar} /></figure>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="wedding-services-box">
                                                        <figure><img src={Bazzar} /></figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Dashboard