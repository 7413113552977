import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails
} from 'amazon-cognito-identity-js';

import AWS from 'aws-sdk';
// import { Amplify } from 'aws-amplify';
// import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import Storage from '@aws-amplify/storage';
import awsMobile from '../aws-exports';
import Swal from 'sweetalert2';
import { successResponse } from './toastr';
import { getLocalKey, setLocalKey } from './commonfunction';
Amplify.configure(awsMobile);

var poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID, // Your user pool id here
  ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID, // Your client id here
};

var userPool = new CognitoUserPool(poolData);

export const cognitoSignup = (data) => {
  return new Promise((resolve, reject) => {
    let attributeList = [];
    let dataEmail = { Name: 'email', Value: data.email };
    let dataPhoneNumber = { Name: 'phone_number', Value: data.phone };
    let dataName = { Name: 'name', Value: data.name };
    let attributeEmail = new CognitoUserAttribute(dataEmail);
    let attributePhoneNumber = new CognitoUserAttribute(dataPhoneNumber);
    let attributeName = new CognitoUserAttribute(dataName);
    attributeList.push(attributeEmail);
    attributeList.push(attributePhoneNumber);
    attributeList.push(attributeName);

    userPool.signUp(data.phone, data.password, attributeList, null, (err, result) => {
      if (err) {
        reject(err);
      } else {
        var cognitoUser = result.user;

        Swal.fire({
          title: "CHECK YOUR EMAIL FOR OTP",
          text:"email@example.com",
          input: "number",
          inputAttributes: {
            autocapitalize: "off",
            class:"test"
          },
          customClass:{
            input:"form-control w-90",
            title:"swall-modal-title"
          },
          // showCancelButton: true,
          confirmButtonText: "Verify",
          confirmButtonClass:'swall-login-btn',
          // backdrop:false,
          allowOutsideClick: false,
          showLoaderOnConfirm: true,
          preConfirm: async (otp) => {
            console.log("otp",otp);
            if(otp && otp.length === 6){
              /**Success */
            }else{
              Swal.showValidationMessage(`Please enter a valid OTP`);
            }
          },
          // allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            const otp = result.value;
            cognitoUser.confirmRegistration(otp, true, function(err, result) {
              if (err) {
                console.log("err",err);
              }else{
                successResponse("Email verified successfully");
                resolve();
              }
            });
          }
        });
      }
    });
  });
};


export const cognitoVerifyUser = (data) => {
  return new Promise((resolve, reject) => {
    var userData = {
      Username: data.username,
      Pool: userPool,
    };

    var cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmRegistration(data.code, true, (err, result) => {
      if (err) {
        const errorResponse = {
          success: false,
          errors: err.message || JSON.stringify(err),
        };
        console.error(err.message);
        reject(errorResponse);
      } else {
        console.log('call result: ' + result);
        const successResponse = {
          success: true,
          data: result,
        };
        resolve(successResponse);
      }
    });
  });
};

export const cognitoResendCode = (data) => {
  return new Promise((resolve, reject) => {
    var userData = {
      Username: data.username,
      Pool: userPool,
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        const errorResponse = {
          success: false,
          errors: err.message || JSON.stringify(err),
        };
        console.error(err.message);
        reject(errorResponse);
      } else {
        const successResponse = {
          success: true,
          data: result,
        };
        resolve(successResponse);
      }
    });
  });
};


export const updateuserDetail = () => {
  var userData = {
    Username: 'user4',
    Pool: userPool,
  };

  var cognitoUser = new CognitoUser(userData);
  console.log(cognitoUser)
}


export const changePhoneNumber = (data) => {

  return new Promise((resolve, reject) => {
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider(
      {
        region: 'eu-north-1',  // replace with your AWS region
        accessKeyId: 'AKIAUXKEYGYJDNA76F75',/***AKIAUXKEYGYJDNA76F75 */
        secretAccessKey: 'pfJzDgQC66RgO9QCxIdIPbhGsfBh2XYsUVv5XsgU',
      }
    );

    const params = {
      UserAttributes: [
        {
          Name: 'phone_number',
          Value: data.phone
        },
        // Add other attributes as needed
      ],
      UserPoolId: 'eu-north-1_4akHnfvJg',
      Username: data.username // Replace with the actual username
    };
    cognitoidentityserviceprovider.adminUpdateUserAttributes(params, function (err, data) {
      if (err) {
        const errorResponse = {
          success: false,
          errors: err.message || JSON.stringify(err),
        };
        console.error(err.message);
        reject(errorResponse);
      } else {
        const successResponse = {
          success: true,
          data: data,
        };
        resolve(successResponse);
      }
    });
  });


}


export const forgetPassword = (email) => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        // successfully initiated reset password request
        console.log('CodeDeliveryData from forgotPassword: ' + data);
        resolve(data);
      },
      onFailure: function (err) {
        reject(err.message || JSON.stringify(err));
      },
    });
  });
}

export const resetPassword = (data) => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: data.userName,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(data.verificationCode, data.newPassword, {
      onSuccess() {
        console.log('Password confirmed!');
        resolve();
      },
      onFailure(err) {
        console.log('Password not confirmed!');
        reject(err.message || JSON.stringify(err));
      },
    });
  });
}


// 919549753372
export const signIn = (data) => {
  return new Promise((resolve, reject)=>{
    var userData = {
      Username: data.email,
      Pool: userPool,
    };
    var cognitoUser = new CognitoUser(userData);
    var authenticationData = {
      Username: data.email,
      Password: data.password,
    };
    var authenticationDetails = new AuthenticationDetails(
      authenticationData
    );
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        resolve({cognitoUser, result});
        // cognitoUser.getAttributeVerificationCode('phone_number', {
        //   onSuccess: function(result) {
        //     console.log('call result: ' + result);
        //   },
        //   onFailure: function(err) {
        //     alert(err.message || JSON.stringify(err));
        //   },
        //   inputVerificationCode: function() {
        //     var verificationCode = prompt('Please input verification code: ', '');
        //     cognitoUser.verifyAttribute('phone_number', verificationCode, this);
        //   },
        // });
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
}

export const getUserDetails = async (email) => {
  var userData = {
    Username: email,
    Pool: userPool,
  };

  var cognitoUser = new CognitoUser(userData);
  await cognitoUser.getUserAttributes(function (err, result) {
    if (err) {
      alert(err.message || JSON.stringify(err));
      return;
    }
    // for (let i = 0; i < result.length; i++) {
    //   console.log(
    //     'attribute ' + result[i].getName() + ' has value ' + result[i].getValue()
    //   );
    // }
    console.log("result",result)
  });
}

export const checkUserSession = () => {
  let flag = 0;
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.error(err);
      } else {
        const userToken = getLocalKey("token");
        if (session.isValid() && userToken) {
          flag = 1;
         } else if(userToken){
          cognitoUser.refreshSession(session.refreshToken, (refreshErr, refreshSession) => {
            if (refreshErr) {
              flag = 0;
            } else {
              setLocalKey("token", JSON.stringify({...refreshSession.getAccessToken()}));
              flag = 1;
            }
          });
        }else{
          flag = 0;
        }
      }
    });
  }
  return flag;
}


export const changePassword = (data) => {
  const cognitoUser = userPool.getCurrentUser();
  return new Promise((resolve, reject) => {
    cognitoUser.changePassword(data.oldPassword, data.newPassword, function (err, result) {
      if (err) {
        console.log(err.message || JSON.stringify(err));
        reject(err.message || JSON.stringify(err));
      }
      else {
        console.log('call result: ' + result);
        resolve(result);
      }
    });
  });
}

export const cognitoLogout = (email) => {
  return new Promise ((resolve, reject) => {
    let userData = {
      Username: email,
      Pool: userPool,
    };
    let cognitoUser = new CognitoUser(userData);
    cognitoUser.signOut(error => {
      if(error){
        reject(error);
      }else{
        resolve();
      }
    });
  })
}



export const getCodeToEmail = (data) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    cognitoUser.getAttributeVerificationCode('email', {
      onSuccess: function (result) {
        console.log('call result: ' + result);
        resolve(result)
      },
      onFailure: function (err) {
        alert(err.message || JSON.stringify(err));
        reject(err.message || JSON.stringify(err))
      },
    });
  });
}


export const verifyEmail = (data) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    var verificationCode = prompt('Please input verification code: ', '');
    cognitoUser.verifyAttribute('email', verificationCode, {
      onSuccess: function (result) {
        console.log('call result: ' + result);
        resolve(result)
      },
      onFailure: function (err) {
        alert(err.message || JSON.stringify(err));
        reject(err.message || JSON.stringify(err))
      }
    });
  });
}

export const loginByOTP = (data) => {
  return new Promise((resolve, reject) => {
    // const cognitoUser = userPool.getCurrentUser();
    // var verificationCode = prompt('Please input verification code: ', '');
    // cognitoUser.verifyAttribute('email', verificationCode, {
    //   onSuccess: function (result) {
    //     console.log('call result: ' + result);
    //     resolve(result)
    //   },
    //   onFailure: function (err) {
    //     alert(err.message || JSON.stringify(err));
    //     reject(err.message || JSON.stringify(err))
    //   }
    // });

      //setMessage(VERIFYNUMBER);
     
      Auth.signIn(data.phone_number)
        .then((result) => {
          //alert(JSON.stringify(result));
          let resp = {
            success: true,
            data: result,
          };
          resolve(resp);
          //setSession(result);
          //setMessage(WAITINGFOROTP);
        })
        .catch((e) => {
          reject(e);
          //alert(JSON.stringify(e.code));
          if (e.code === 'UserNotFoundException') {
            //signUp();
          } else if (e.code === 'UsernameExistsException') {
           // setMessage(WAITINGFOROTP);
            //signIn();
          } else {
            console.log(e);
            console.error(e);
          }
        });

  });
}

export const uploadFileToS3 = (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userId = user.attributes.sub; // Use the user's unique ID

      const result = await Storage.put(`${userId}/${file.name}`, file, {
        contentType: file.type,
      });

      console.log('File uploaded successfully:', result);
      let resp = {
        success: true,
        data: result,
      };
      resolve(resp);
    } catch (error) {
      console.error('Error uploading file:', error);
      let resp = {
        success: false,
        data: error,
      };
      reject(resp);
    }
  });
}


export const verifyOtp = (data) => {
  return new Promise((resolve, reject) => {
    console.log(data);
    Auth.sendCustomChallengeAnswer(data.session, data.otp)
    .then((user) => {
      let resp = {
        success: true,
        data: user,
      };
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
  });
}