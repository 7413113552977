import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function ResetPassword() {
    const { state } = useLocation();
    const [password, setPassword] = useState("");
	
    useEffect(() => {
        Promise.all([
        import('bootstrap/dist/css/bootstrap.min.css'),
        import("../../../assets/admin/css/skins.css"),
        import("../../../assets/admin/css/icon-list.css"),
        import("../../../assets/admin/plugins/select2/css/select2.min.css"),
        import("../../../assets/admin/css/style.css"),
        import("../../../assets/admin/css/dark-style.css"),
        import("../../../assets/admin/css/colors/default.css"),
        import("../../../assets/admin/plugins/multipleselect/multiple-select.css"),
        import("../../../assets/admin/css/sidemenu/sidemenu.css"),
        import("../../../assets/admin/plugins/summernote/summernote-bs4.css"),
        import("../../../assets/admin/plugins/fileuploads/css/fileupload.css"),
        import("../../../assets/admin/plugins/fancyuploder/fancy_fileupload.css")]).then(() => {
            // setPageLoaded(true);
        })
        
    }, []);

  return (
    <div className='page main-signin-wrapper'>
						<div className='innerbody'>
							<div className="row signpages text-center">
								<div className="col-md-12">
									<div className="card">
										<div className="row row-sm">
											<div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
												<div className="mt-5 pt-4 p-2 pos-absolute">
													{/* <img src={Logo} className="ht-120 mb-4" alt="logo" /> */}
													<div className="clearfix"></div>
													<h5 className="mt-4 text-white">Reset Your Password</h5>
													<span className="tx-white-6 tx-13 mb-5 mt-xl-0">Signup to create, discover and connect with the global community</span>
												</div>
											</div>
											<div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
												<div className="container-fluid">
													<div className="row row-sm">
														<div className="card-body mt-2 mb-2">
															<img src="../assets/img/brand/logo.png" className=" d-lg-none header-brand-img text-left float-left mb-4" alt="logo" />
															<div className="clearfix"></div>
															<h5 className="text-left mb-2">Reset Your Password</h5>
															<p className="mb-4 text-muted tx-13 ml-0 text-left">It's free to signup and only takes a minute.</p>
															<form>
																<div className="form-group text-left">
																	<label>New Password</label>
																	<div className="input-group">
																		<input className={"form-control"}
																			placeholder="Enter your new password"
																			type={"text"}
																			name="newPassword"
																			onChange={(e) => setPassword(e.target.value)}
																			// onBlur={handleBlur}
																			value={password}
																			autoComplete="off" />
																		{/* <span className='input-group-text' onClick={() => { handleshow("newpwd") }}>{(!newshow === true ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>)}</span> */}
																	</div>
																	{/* <span className='text-danger text-left d-blockerr-spn'>{errors.newPassword && touched.newPassword && errors.newPassword}</span> */}
																</div>
																{/* <div className="form-group text-left">
																	<label>Confirm Password</label>
																	<div className="input-group">
																		<input className={"form-control" + (errors.confirmPassword && touched.confirmPassword ? " is-invalid state-invalid" : "")}
																			placeholder="Confirm password"
																			type={!conformshow === true ? "text" : "password"}
																			name="confirmPassword"
																			onChange={handleChange}
																			onBlur={handleBlur}
																			value={values.confirmPassword}
																			autoComplete="off" />
																		<span className='input-group-text' onClick={() => { handleshow("confirepwd") }}>{(!conformshow === true ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>)}</span>
																	</div>
																	<span className='text-danger text-left d-blockerr-spn'>{errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}</span>
																</div> */}
																<button className="btn ripple btn-main-primary btn-block signbtn" type='submit'>Reset Password</button>
															</form>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
  )
}

export default ResetPassword;