import React from 'react'
// import myImage from '../../../assets/user/images/404.svg'


const Error404User = () => {
    return (
        <div className='page-404 cpt'>
            <div className='container'>
                <div className='page-404-in text-center'>
                    {/* <img className='mb-4' src={myImage} alt='not found'/> */}
                    <h3>404 Not found</h3>
                    <p className="tx-15 mt-1 mb-4">lorem</p>
                    <a className="btn btn-primary" href="/">jhk</a>
                </div>
            </div>
        </div>
    )
}

export default Error404User