import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { checkUserSession } from '../../../utils/amazon_utils';
import { getLocalKey } from '../../../utils/commonfunction';

const UserPrivateRoute = ({ component: Component }) => {
    return (
        <>
            {(function(){
                if(checkUserSession()){
                    return Component;
                }else{
                    return <Navigate to="/" />;
                }
            })()}
            {/* {(function(){
                if(isValidSession==1){
                    return Component;
                }else{
                    return <Navigate to="/" />;
                }
            })()} */}
        </>
    )
}

export default UserPrivateRoute;

