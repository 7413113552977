import axiosInstance from "../../../utils/axios";

const path = "admin/global-settings"

export const GlobalSettingAdd = async (data) => {
    return await axiosInstance().post(`${path}/add`, data);
}

export const GlobalSettingDetails = async () => {
    return await axiosInstance().get(`${path}/details`);
}