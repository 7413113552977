import axiosInstance from "../../../utils/axios";
import { getQuery } from "../../../utils/commonfunction";
const path = "web/global"


export const checkRegisteredEmail = async (values) => {
    return await axiosInstance().get(`${path}/is-email-available${getQuery(values)}`);
}

export const checkRegisteredPhone = async (values) => {
    return await axiosInstance().post(`${path}/is-phone-available`, values);
}

export const getQueries = async (values) => {
    return await axiosInstance().get(`${path}/lookups${getQuery(values)}`);
}

export const getSubCastes = async (data={}) => {
    return await axiosInstance().get(`${path}/sub-castes${getQuery(data)}`);
}

export const getCountries = async (values) => {
    return await axiosInstance().get(`${path}/countries`);
}

export const getStates = async (data={}) => {
    return await axiosInstance().get(`${path}/states${getQuery(data)}`);
}

export const getCities = async (data={}) => {
    return await axiosInstance().get(`${path}/cities${getQuery(data)}`);
}

export const getRaasi = async (data={}) => {
    return await axiosInstance().get(`${path}/raasi`);
}

export const getEducations = async (data={}) => {
    return await axiosInstance().get(`${path}/educations`);
}

export const getOccupations = async (data={}) => {
    return await axiosInstance().get(`${path}/occupations`);
}
