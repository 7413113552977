import React, { useEffect, useLayoutEffect, useState } from 'react'
import Header from './header'
import Footer from './footer'
import SideBar from './sidebar';
import { Outlet } from 'react-router-dom';
import GlobalLoader from './globalLoader';
import { globalLoader } from '../../../utils/commonfunction';


const AdminLayout = () => {

    const [globalLoaderLayout, setGlobalLoader] = useState(false);

    useLayoutEffect(() => {
       // setGlobalLoader(true);
        //Promise.all([
        import("../../../assets/admin/css/skins.css");
        import("../../../assets/admin/css/icon-list.css");
        import("../../../assets/admin/plugins/select2/css/select2.min.css");
        import("../../../assets/admin/css/style.css");
        import("../../../assets/admin/css/dark-style.css");
        import("../../../assets/admin/css/colors/default.css");
        import("../../../assets/admin/plugins/multipleselect/multiple-select.css");
        import("../../../assets/admin/css/sidemenu/sidemenu.css");
        import("../../../assets/admin/plugins/summernote/summernote-bs4.css");
        import("../../../assets/admin/plugins/fileuploads/css/fileupload.css");
        import("../../../assets/admin/plugins/fancyuploder/fancy_fileupload.css");
        // ]).then(() => {
        //         setGlobalLoader(false);
        //         globalLoader(false);
        //     })
        }, []);
    
    return (
        <>
            { globalLoaderLayout ? 
                <GlobalLoader/> 
                :
                <div className='page'>
                    <SideBar />
                    <Header />
                    <div className="main-content side-content">
                        <div className="container-fluid">
                            <div className="inner-body">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                    <a href="#top" id="back-to-top"><i className="fe fe-arrow-up"></i></a>
                    <Footer />
                </div>
            }
        </>

    )
}
export default AdminLayout;