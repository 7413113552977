import React, { useEffect, useRef, useState } from 'react'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { getCountries, getEducations, getOccupations, getQueries } from '../../services/global.service';
import { handleServerValidations, prepareLabel } from '../../../../utils/commonfunction';
import Swal from 'sweetalert2';
import {RECENTLY_ACTIVE_PROFILE, SWAL_SETTINGS, HEIGHT_OPTIONS, AGE_OPTIONS, EMPLOYED_IN_OPTIONS,ANNUAL_INCOME_OPTIONS, SHOW_PROFILE, PROFILE_CREATED_BY, MARITAL_STATUS, PHYSICAL_STATUS_OPTIONS, DOSH_VALUE, EATING_VALUE, SMOKING_VALUE, DRINKING_VALUE, FAMILY_STATUS, FAMILY_TYPES, FAMILY_VALUES } from '../../../../utils/Constants';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import * as searchService from '../../services/search.service';
import CustomError from '../../shared/CustomError';
import {useSearchParams} from 'react-router-dom';

const UserSearch = () => {

    const save_search_open = useRef(null);
    const [showDefault, setShowDefault] = useState({});
    const [religionOptions, setReligionOptions] = useState([]);
    const [casteOptions, setCasteOptions] = useState([]);
    const [motherTongueOptions, setMotherTongueOptions] = useState([]);
    const [starOptions, setStarOptions] = useState([]);
    const [ancestralOptions, setAncestralOptions] = useState([]);
    const [isAccordionVisible, setAccordionVisible] = useState(false);
    const [isLifeStyleAccordionVisible, setLifeStyleAccordionVisible] = useState(false);
    const [isLocationAccordionVisible, setLocationAccordionVisible] = useState(false);
    const [isFamilyDetailsAccordionVisible, setFamilyDetailsAccordionVisible] = useState(false);
    const [selectedMinAge, setSelectedMinAge] = useState("");
    const [selectedMaxAge, setSelectedMaxAge] = useState("");
    const [selectedMinHeight, setSelectedMinHeight] = useState("");
    const [selectedMaxHeight, setSelectedMaxHeight] = useState("");
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [selectedProfiles, setSelectedProfiles] = useState([]);
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState([]);
    const [selectedPhysicalStatus, setSelectedPhysicalStatus] = useState([]);
    const [selectedCasteStatus, setSelectedCasteStatusStatus] = useState([]);
    const [selectedDosh, setSelectedDosh] = useState([]);
    const [selectedProfileCreated, setSelectedProfileCreated] = useState([]);
    const [selectedMotherTongue, setSelectedMotherTongue] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedCitizenship, setSelectedCitizenship] = useState([]);
    const [selectedOccupation, setSelectedOccupation] = useState([]);
    const [selectedEmployment, setSelectedEmployment] = useState([]);
    const [selectedEducation, setSelectedEducation] = useState([]);
    const [selectedEatingOptions, setSelectedEatingOptions] = useState([]);
    const [selectedSmokingOptions, setSelectedSmokingOptions] = useState([]);
    const [selectedDrinkingOptions, setSelectedDrinkingOptions] = useState([]);
    const [selectedAncestralOriginOptions, setSelectedAncestralOriginOptions] = useState([]);
    const [selectedFamilyStatusOptions, setSelectedFamilyStatusOptions] = useState([]);
    const [selectedFamilyValueOptions, setSelectedFamilyValueOptions] = useState([]);
    const [selectedFamilyTypeOptions, setSelectedFamilyTypeOptions] = useState([]);
    const [selectedReligion, setSelectedReligion] = useState(null);
    const [countries, setCountries] = useState([]);
    const [educations, setEducations] = useState([]);
    const [occupations, setOccupations] = useState([]);
    const [selectedMinIncome, setSelectedMinIncome] = useState("");
    const [selectedMaxIncome, setSelectedMaxIncome] = useState("");
    const [selectedStar, setSelectedStar] = useState([]);
    const [selectedShowProfile, setSelectedShowProfile] = useState([]);
    const [showReadMore, setShowReadMore] = useState(false);
    const [profileShowReadMore, setProfileShowReadMore] = useState(false);
    const [maritalShowReadMore, setMaritalShowReadMore] = useState(false);
    const [physicalShowReadMore, setPhysicalShowReadMore] = useState(false);
    const [casteShowReadMore, setCasteShowReadMore] = useState(false);
    const [starShowReadMore, setStarShowReadMore] = useState(false);
    const [doshShowReadMore, setDoshShowReadMore] = useState(false);
    const [occupationShowReadMore, setOccupationShowReadMore] = useState(false);
    const [employmentShowReadMore, setEmploymentShowReadMore] = useState(false);
    const [educationShowReadMore, setEducationShowReadMore] = useState(false);
    const [countriesShowReadMore, setCountriesShowReadMore] = useState(false);
    const [citizenshipShowReadMore, setCitizenshipShowReadMore] = useState(false);
    const [ancestralOriginShowReadMore, setAncestralOriginShowReadMore] = useState(false);
    const [eatingShowReadMore, setEatingShowReadMore] = useState(false);
    const [smokingShowReadMore, setSmokingShowReadMore] = useState(false);
    const [drinkingShowReadMore, setDrinkingShowReadMore] = useState(false);
    const [familyStatusShowReadMore, setFamilyStatusShowReadMore] = useState(false);
    const [familyValueShowReadMore, setFamilyValueShowReadMore] = useState(false);
    const [familyTypeShowReadMore, setFamilyTypeShowReadMore] = useState(false);
    const [showProfileShowReadMore, setShowProfileShowReadMore] = useState(false);
    const [queryParams] = useSearchParams();

    console.log("selectedProfiles",selectedProfiles);


    useEffect(() => {
        /**Getting religion options */
        getQueries({ type: 'religion' }).then((response) => {
            if (response.success) {
                setReligionOptions(response.data);
            }
        }).catch((err) => {
            console.log("Server error ", err);
        });
        /**Getting caste options */
        getQueries({ type: 'caste' }).then((response) => {
            if (response.success) {
                setCasteOptions(response.data);
            }
        }).catch((err) => {
            console.log("Server error ", err);
        });

        /**Getting mother tongue options */
        getQueries({ type: 'mother-tongue' }).then((response) => {
            if (response.success) {
                setMotherTongueOptions(response.data);
            }
        }).catch((err) => {
            console.log("Server error ", err);
        });

        /**Getting star options */
        getQueries({ type: 'star' }).then((response) => {
            if (response.success) {
                setStarOptions(response.data);
            }
        }).catch((err) => {
            console.log("Server error ", err);
        });
        /**Getting ancestral origin options */
        getQueries({ type: 'ancestral-origin' }).then((response) => {
            if (response.success) {
                setAncestralOptions(response.data);
            }
        }).catch((err) => {
            console.log("Server error ", err);
        });
    }, []);

    useEffect(() => {
        getCountriesData();
        getEducationsData();
        getOccupationsData();
    }, []);

    useEffect(() => {
        setShowReadMore(selectedMotherTongue.length > 3);
        setProfileShowReadMore(selectedProfiles.length > 3);
        setMaritalShowReadMore(selectedMaritalStatus.length > 3);
        setPhysicalShowReadMore(selectedPhysicalStatus.length > 3);
        setCasteShowReadMore(selectedCasteStatus.length > 3);
        setStarShowReadMore(selectedStar.length > 3);
        setDoshShowReadMore(selectedDosh.length > 3);
        setOccupationShowReadMore(selectedOccupation.length > 3);
        setEmploymentShowReadMore(selectedEmployment.length > 3);
        setEducationShowReadMore(selectedEducation.length > 3);
        setCountriesShowReadMore(selectedCountries.length > 3);
        setCitizenshipShowReadMore(selectedCitizenship.length > 3);
        setAncestralOriginShowReadMore(selectedAncestralOriginOptions.length > 3);
        setEatingShowReadMore(selectedEatingOptions.length > 3);
        setSmokingShowReadMore(selectedSmokingOptions.length > 3);
        setDrinkingShowReadMore(selectedDrinkingOptions.length > 3);
        setFamilyStatusShowReadMore(selectedFamilyStatusOptions.length > 3);
        setFamilyValueShowReadMore(selectedFamilyValueOptions.length > 3);
        setFamilyTypeShowReadMore(selectedFamilyTypeOptions.length > 3);
        setShowProfileShowReadMore(selectedShowProfile.length > 3);

    },
        [selectedMotherTongue, selectedProfiles, selectedMaritalStatus, selectedPhysicalStatus, selectedCasteStatus, selectedStar, selectedDosh, selectedOccupation, selectedEmployment, selectedEducation, selectedCountries, selectedCitizenship, selectedAncestralOriginOptions, selectedEatingOptions, selectedSmokingOptions, selectedDrinkingOptions, selectedFamilyStatusOptions, selectedFamilyValueOptions, selectedFamilyTypeOptions, selectedShowProfile]);

    useEffect(() => {
        if(queryParams.get('search_id')){
            searchService.Details(queryParams.get('search_id')).then((response) => {
                if (response?.success) {
                    setShowDefault(response?.data);
                    setTimeout(() => {
                    }, 100)
                }
            }).catch((err) => {
                console.log("err", err)
            })
        }
    }, []);

    console.log("showDefault",showDefault.marital_status)

    useEffect(()=>{
        if(Object.keys(showDefault).length){
            setSelectedMinAge(showDefault?.min_age);
            setSelectedMaxAge(showDefault?.max_age);
            setSelectedMinHeight(showDefault?.min_height);
            setSelectedMaxHeight(showDefault?.max_height);
            setSelectedMinIncome(showDefault?.min_income);
            setSelectedMaxIncome(showDefault?.max_income);
            setSelectedProfiles(showDefault.profile_created_by? [showDefault.profile_created_by.toLowerCase()] : []);
            setSelectedMaritalStatus(showDefault.marital_status? [showDefault.marital_status.toLowerCase()] : []);
            setSelectedPhysicalStatus(showDefault.physical_status? [showDefault.physical_status.toLowerCase()] : []);
            setSelectedDosh(showDefault.dosha? [showDefault.dosha.toLowerCase()] : []);
            setSelectedEmployment(showDefault.employment_type? [showDefault.employment_type.toLowerCase()] : [])
            setSelectedEatingOptions(showDefault.eating_habits? [showDefault.eating_habits.toLowerCase()] : [])
            setSelectedSmokingOptions(showDefault.smoking_habits? [showDefault.smoking_habits.toLowerCase()] : [])
            setSelectedDrinkingOptions(showDefault.drinking_habits? [showDefault.drinking_habits.toLowerCase()] : [])
            setSelectedFamilyStatusOptions(showDefault.family_status? [showDefault.family_status.toLowerCase()] : [])
            setSelectedFamilyValueOptions(showDefault.family_value? [showDefault.family_value.toLowerCase()] : [])
            setSelectedFamilyTypeOptions(showDefault.family_type? [showDefault.family_type.toLowerCase()] : [])
            setSelectedProfileCreated(showDefault.recently_profile_active_duration? [showDefault.recently_profile_active_duration] : [])
            setSelectedShowProfile(showDefault.do_not_show_profiles? [showDefault.do_not_show_profiles.toLowerCase()] : [])
            setSelectedCountries(showDefault.country? [showDefault.country] : [])
            setSelectedCitizenship(showDefault.citizenship? [showDefault.citizenship] : [])
            setSelectedAncestralOriginOptions(showDefault.ancestral_origin? [showDefault.ancestral_origin] : [])
            setSelectedMotherTongue(showDefault.mother_tongue? [showDefault.mother_tongue] : [])
            setSelectedCasteStatusStatus(showDefault.caste? [showDefault.caste] : [])
            setSelectedStar(showDefault.star? [showDefault.star] : [])
            setSelectedOccupation(showDefault.occupation? [showDefault.occupation] : [])
            setSelectedEducation(showDefault.education? [showDefault.education] : [])

        }
    },[showDefault]);

    async function getCountriesData() {
        getCountries().then((response) => {
            if (response.success) {
                setCountries(response.data)
            }
        }).catch((err) => {
            console.log("Server error ", err);
        });
    }

    async function getEducationsData() {
        getEducations()
            .then((response) => {
                if (response.success) {
                    setEducations(response.data);
                }
            })
            .catch((err) => {
                console.log("Server error ", err);
            });
    }
    async function getOccupationsData(id = 1) {
        getOccupations({ state_id: id })
            .then((response) => {
                if (response.success) {
                    setOccupations(response.data);
                }
            })
            .catch((err) => {
                console.log("Server error ", err);
            });
    }

    const saveSearchForm = useFormik({
        initialValues: {
            title: showDefault && showDefault?.title ? showDefault.title : '',
        },
        enableReinitialize : true,
        validationSchema: Yup.object().shape({
            title: Yup.string().required('This field is required'),
        }),
        onSubmit: (values, { resetForm }) => {
            let formData = new FormData();
            formData.append("min_age", selectedMinAge);
            formData.append("max_age", selectedMaxAge);
            formData.append("min_height", selectedMinHeight);
            formData.append("max_height", selectedMaxHeight);
            formData.append("title", values?.title);
            formData.append("profile_created_by", selectedProfiles);
            formData.append("marital_status", selectedMaritalStatus);
            // formData.append("having_children", saveSearchForm?.values?.having_children);
            formData.append("having_children", saveSearchForm?.values?.having_children ? saveSearchForm?.values?.having_children : null);
            formData.append("mother_tongue", selectedMotherTongue);
            formData.append("physical_status", selectedPhysicalStatus);
            formData.append("religion", selectedReligion);
            formData.append("caste", selectedCasteStatus);
            // formData.append("subcaste", saveSearchForm?.values?.subcaste);
            formData.append("subcaste", saveSearchForm?.values?.subcaste ? saveSearchForm?.values?.subcaste : null);
            formData.append("star", selectedStar);
            formData.append("is_horoscope", saveSearchForm?.values?.is_horoscope_enabled ? saveSearchForm?.values?.is_horoscope_enabled : null);
            formData.append("dosha", selectedDosh);
            formData.append("occupation", selectedOccupation);
            formData.append("min_income", selectedMinIncome);
            formData.append("max_income", selectedMaxIncome);
            formData.append("employment_type", selectedEmployment);
            formData.append("education", selectedEducation);
            formData.append("education", saveSearchForm?.values?.institution ? saveSearchForm?.values?.institution : null);
            formData.append("education", saveSearchForm?.values?.organisation ? saveSearchForm?.values?.organisation : null);
            formData.append("is_near_by_profile", saveSearchForm?.values?.is_near_by_profile ? saveSearchForm?.values?.is_near_by_profile : null);
            formData.append("country", selectedCountries);
            formData.append("citizenship", selectedCitizenship);
            formData.append("ancestral_origin", selectedAncestralOriginOptions);
            formData.append("is_mutual_hobbies", saveSearchForm?.values?.is_mutual_hobbies ? saveSearchForm?.values?.is_mutual_hobbies : null);
            formData.append("eating_habits", selectedEatingOptions);
            formData.append("smoking_habits", selectedSmokingOptions);
            formData.append("drinking_habits", selectedDrinkingOptions);
            formData.append("family_status", selectedFamilyStatusOptions);
            formData.append("family_value", selectedFamilyValueOptions);
            formData.append("family_type", selectedFamilyTypeOptions);
            formData.append("recently_profile_active_duration", selectedProfileCreated);
            formData.append("is_profile_added", saveSearchForm?.values?.is_profile_added ? saveSearchForm?.values?.is_profile_added : null);
            formData.append("do_not_show_profiles", selectedShowProfile);
            if(queryParams.get('search_id')){
            searchService.searchEdit(queryParams.get('search_id'), formData)
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        icon: "success",
                        text: response.message,
                        ...SWAL_SETTINGS,
                    });
                    document.getElementById('close_modal_search').click();
                    resetForm();
                } else {
                    Swal.fire({
                        icon: "error",
                        text: handleServerValidations(response),
                        ...SWAL_SETTINGS,
                    });
                    document.getElementById('close_modal_search').click();
                }
            })
            .catch((error) => {
                console.log("error ====> ", error);
            });
        }
        else{
           searchService.searchSave(formData)
                .then((response) => {
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                        document.getElementById('close_modal_search').click();
                        resetForm();
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                        document.getElementById('close_modal_search').click();
                    }
                })
                .catch((error) => {
                    console.log("error ====> ", error);
                });
        }
    }
    })


    const handleProfileSelect = (value) => {
        if (selectedProfiles.includes(value)) {
            setSelectedProfiles(selectedProfiles.filter(profile => profile !== value));
        } else {
            setSelectedProfiles([...selectedProfiles, value]);
        }
    };

    const handleMaritalStatusSelect = (value) => {
        if (selectedMaritalStatus.includes(value)) {
            setSelectedMaritalStatus(selectedMaritalStatus.filter(MaritalStatus => MaritalStatus !== value));
        } else {
            setSelectedMaritalStatus([...selectedMaritalStatus, value]);
        }
    };

    const handlePhysicalStatusSelect = (value) => {
        if (selectedPhysicalStatus.includes(value)) {
            setSelectedPhysicalStatus(selectedPhysicalStatus.filter(PhysicalStatus => PhysicalStatus !== value));
        } else {
            setSelectedPhysicalStatus([...selectedPhysicalStatus, value]);
        }
    };

    const handleCasteStatusSelect = (value) => {
        if (selectedCasteStatus.includes(value)) {
            setSelectedCasteStatusStatus(selectedCasteStatus.filter(CasteStatus => CasteStatus !== value));
        } else {
            setSelectedCasteStatusStatus([...selectedCasteStatus, value]);
        }
    };

    const handleDoshSelect = (value) => {
        if (selectedDosh.includes(value)) {
            setSelectedDosh(selectedDosh.filter(Dosh => Dosh !== value));
        } else {
            setSelectedDosh([...selectedDosh,value]);
        }
    };

    // const handleMotherTongueSelect = (value) => {
    //     if (selectedMotherTongue.includes(prepareLabel(value))) {
    //         setSelectedMotherTongue(selectedMotherTongue.filter(MotherTongue => MotherTongue !== prepareLabel(value)));
    //     } else {
    //         setSelectedMotherTongue([...selectedMotherTongue, prepareLabel(value)]);
    //     }
    // };

    const handleMotherTongueSelect = (value) => {
        if (selectedMotherTongue.includes(value)) {
            setSelectedMotherTongue(selectedMotherTongue.filter(MotherTongue => MotherTongue !== value));
        } else {
            setSelectedMotherTongue([...selectedMotherTongue, value]);
        }
    };

    const handleCountriesSelect = (value) => {
        if (selectedCountries.includes(value)) {
            setSelectedCountries(selectedCountries.filter(Countries => Countries !== value));
        } else {
            setSelectedCountries([...selectedCountries, value]);
        }
    };
    const handleCitizenshipSelect = (value) => {
        if (selectedCitizenship.includes(value)) {
            setSelectedCitizenship(selectedCitizenship.filter(Citizenship => Citizenship !== value));
        } else {
            setSelectedCitizenship([...selectedCitizenship, value]);
        }
    };

    const handleOccupationSelect = (value) => {
        if (selectedOccupation.includes(value)) {
            setSelectedOccupation(selectedOccupation.filter(Occupation => Occupation !== value));
        } else {
            setSelectedOccupation([...selectedOccupation, value]);
        }
    };

    const handleEmploymentSelect = (value) => {
        if (selectedEmployment.includes(value)) {
            setSelectedEmployment(selectedEmployment.filter(Employment => Employment !== value));
        } else {
            setSelectedEmployment([...selectedEmployment, value]);
        }
    };

    const handleEducationSelect = (value) => {
        if (selectedEducation.includes(value)) {
            setSelectedEducation(selectedEducation.filter(Education => Education !== value));
        } else {
            setSelectedEducation([...selectedEducation, value]);
        }
    };

    const handleEatingOptionsSelect = (value) => {
        if (selectedEatingOptions.includes(value)) {
            setSelectedEatingOptions(selectedEatingOptions.filter(EatingOptions => EatingOptions !== value));
        } else {
            setSelectedEatingOptions([...selectedEatingOptions,value]);
        }
    };

    const handleSmokingOptionsSelect = (value) => {
        if (selectedSmokingOptions.includes(value)) {
            setSelectedSmokingOptions(selectedSmokingOptions.filter(EatingOptions => EatingOptions !== value));
        } else {
            setSelectedSmokingOptions([...selectedSmokingOptions, value]);
        }
    };
    const handleDrinkingOptionsSelect = (value) => {
        if (selectedDrinkingOptions.includes(value)) {
            setSelectedDrinkingOptions(selectedDrinkingOptions.filter(DrinkingOptions => DrinkingOptions !== value));
        } else {
            setSelectedDrinkingOptions([...selectedDrinkingOptions, value]);
        }
    };
    const handleAncestralOriginOptionsSelect = (value) => {
        if (selectedAncestralOriginOptions.includes(value)) {
            setSelectedAncestralOriginOptions(selectedAncestralOriginOptions.filter(AncestralOrigin => AncestralOrigin !== value));
        } else {
            setSelectedAncestralOriginOptions([...selectedAncestralOriginOptions, value]);
        }
    };
    const handleFamilyStatusOptionsSelect = (value) => {
        if (selectedFamilyStatusOptions.includes(value)) {
            setSelectedFamilyStatusOptions(selectedFamilyStatusOptions.filter(FamilyStatusOptions => FamilyStatusOptions !== value));
        } else {
            setSelectedFamilyStatusOptions([...selectedFamilyStatusOptions, value]);
        }
    };
    const handleFamilyValueOptionsSelect = (value) => {
        if (selectedFamilyValueOptions.includes(value)) {
            setSelectedFamilyValueOptions(selectedFamilyValueOptions.filter(FamilyValueOptions => FamilyValueOptions !== value));
        } else {
            setSelectedFamilyValueOptions([...selectedFamilyValueOptions, value]);
        }
    };
    const handleFamilyTypeOptionsSelect = (value) => {
        if (selectedFamilyTypeOptions.includes(value)) {
            setSelectedFamilyTypeOptions(selectedFamilyTypeOptions.filter(FamilyTypeOptions => FamilyTypeOptions !== value));
        } else {
            setSelectedFamilyTypeOptions([...selectedFamilyTypeOptions,value]);
        }
    };
    const handleStarSelect = (value) => {
        if (selectedStar.includes(value)) {
            setSelectedStar(selectedStar.filter(Star => Star !== value));
        } else {
            setSelectedStar([...selectedStar, value]);
        }
    };
    // const handleShowProfileSelect = (value) => {
    //     if (selectedShowProfile.includes(prepareLabel(value))) {
    //         setSelectedShowProfile(selectedShowProfile.filter(ShowProfile => ShowProfile !== prepareLabel(value)));
    //     } else {
    //         setSelectedShowProfile([...selectedShowProfile, prepareLabel(value)]);
    //     }
    // };

    const handleShowProfileSelect = (value) => {
        if (selectedShowProfile.includes(value)) {
            setSelectedShowProfile(selectedShowProfile.filter(ShowProfile => ShowProfile !== value));
        } else {
            setSelectedShowProfile([...selectedShowProfile, value]);
        }
    };

    const toggleAccordion = () => {
        setAccordionVisible(!isAccordionVisible);
    };
    const toggleLifeStyleAccordion = () => {
        setLifeStyleAccordionVisible(!isLifeStyleAccordionVisible);
    };
    const toggleLocationAccordion = () => {
        setLocationAccordionVisible(!isLocationAccordionVisible);
    };
    const toggleFamilyDetailsAccordion = () => {
        setFamilyDetailsAccordionVisible(!isFamilyDetailsAccordionVisible);
    };

    const handleReligionChange = (religionId) => {
        setSelectedReligion(religionId == selectedReligion ? null : religionId);
    };


    const searchFormValidation = Yup.object().shape({
        // religion: Yup.string().required('Religion is required'),
        // caste: Yup.string().required('Caste is required'),
        // dosha: Yup.string().required("Dosh is required"),
    });

    const searchForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            min_age: '',
            max_age: '',
            min_height: '',
            max_height: '',
            profile_created_by: '',
            marital_status: '',
            having_children: '',
            mother_tongue: '',
            physical_status: '',
            religion: '',
            caste: '',
            subcaste: '',
            star: '',
            dosha: '',
            occupation: '',
            min_income: '',
            max_income: '',
            employment_type: '',
            education: '',
            institution: '',
            organisation: '',
            is_near_by_profile: showDefault && showDefault?.is_near_by_profile && showDefault?.is_near_by_profile == 1 ? true : false,
            country: '',
            citizenship: '',
            is_mutual_hobbies: showDefault && showDefault?.is_mutual_hobbies && showDefault?.is_mutual_hobbies == 1 ? true : false,
            eating_habits: '',
            smoking_habits: '',
            drinking_habits: '',
            family_status: '',
            family_value: '',
            family_type: '',
            recently_profile_active_duration: '',
            is_profile_added: showDefault && showDefault?.is_profile_added && showDefault?.is_profile_added == 1 ? true : false,
            do_not_show_profiles: '',
            is_horoscope_enabled: false,
        },
        validationSchema: searchFormValidation,
        onSubmit: (values) => {
            save_search_open.current.click();
        }
    })

    return (
        <>
            <form onSubmit={searchForm.handleSubmit}>
                <div className="tab-content" id="myTabContent">
                    {console.log("values",searchForm?.values)}
                    <div
                        className="tab-pane fade show active"
                        id="home-tab-pane"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                        tabIndex={0}
                    >
                        <h3 className="inner-title mb-4">
                            Search profiles using the below criteria
                        </h3>
                        <div className="serch-content-box basic-detail-info mb-4">
                            <div className="bg-title mb-3">
                                <h4>Basic Details</h4>
                            </div>
                            <div className="inline-input-box mb-3">
                                <div className="form-group d-flex align-items-center">
                                    <label className="inline-label">Age</label>
                                    <div className="froup-field d-flex align-items-center">
                                        <select className="form-select" value={selectedMinAge} onChange={(e) => setSelectedMinAge(e.target.value)}>
                                            {
                                                AGE_OPTIONS.map((item, index) => {
                                                    return (<option key={index} value={item?.value}>{item?.label}</option>)
                                                })
                                            }
                                        </select>
                                        <span className="to-text ps-2 pe-2">to</span>
                                        <select className="form-select" value={selectedMaxAge} onChange={(e) => setSelectedMaxAge(e.target.value)}>
                                            {
                                                AGE_OPTIONS.map((item, index) => {
                                                    return (<option key={index} value={item?.value}>{item?.label}</option>)
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-input-box mb-3">
                                <div className="form-group d-flex align-items-center">
                                    <label className="inline-label">Height</label>
                                    <div className="froup-field d-flex align-items-center">
                                        <select className="form-select" value={selectedMinHeight} onChange={(e) => setSelectedMinHeight(e.target.value)}>
                                            {
                                                HEIGHT_OPTIONS.map((item, index) => {
                                                    return (<option key={index} value={item?.value}>{item?.label}</option>)
                                                })
                                            }
                                        </select>
                                        <span className="to-text ps-2 pe-2">to</span>
                                        <select className="form-select" value={selectedMaxHeight} onChange={(e) => setSelectedMaxHeight(e.target.value)}>
                                            {
                                                HEIGHT_OPTIONS.map((item, index) => {
                                                    return (<option key={index} value={item?.value}>{item?.label}</option>)
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-input-box mb-3">
                                <div className="form-group d-flex align-items-start">
                                    <label className="inline-label">Profile Created By</label>
                                    <div className="froup-field d-flex align-items-center">
                                        <div
                                            className={`accordion accordion-boder-in accordion-select ${isAccordionOpen ? { display: 'block' } : { display: 'none' }}`}
                                            id="accordionExample"
                                        >
                                            <div className="accordion-item select-collapse">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse01"
                                                        aria-expanded="true"
                                                        aria-controls="collapse01"
                                                        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                                                    >
                                                        {(selectedProfiles[0] ? selectedProfiles[0] + ", " : "") + (selectedProfiles[1] ? selectedProfiles[1] + ", " : "") + (selectedProfiles[2] ? selectedProfiles[2] + ", " : "") || 'Any'}
                                                        {profileShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse01"
                                                    className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <div className="search-field d-flex align-items-center mb-3">
                                                            <input
                                                                type="search"
                                                                name=""
                                                                placeholder="Search Profile created by"
                                                                className="form-control"
                                                            />
                                                            <button className="search-btn">
                                                                <i className="ri-search-line" />
                                                            </button>
                                                        </div>
                                                        <ul className="coustom-check-list">
                                                            <li>
                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                    <input
                                                                        className="form-check-input cp"
                                                                        type="checkbox"
                                                                        defaultValue=""
                                                                        id="flexCheckDefault-profile"
                                                                    />
                                                                    <label
                                                                        className="form-check-label cp"
                                                                        htmlFor="flexCheckDefault-profile"
                                                                    >
                                                                        Any
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            {
                                                                PROFILE_CREATED_BY.map((item, i) => {
                                                                    return (
                                                                        <li key={i}>
                                                                            <div className="form-check coustom-check d-flex align-items-center">
                                                                                <input
                                                                                    className="form-check-input cp"
                                                                                    type="checkbox"
                                                                                    checked={selectedProfiles.includes(item?.value)}
                                                                                    value={item?.value}
                                                                                    id={"flexCheckDefault-profile" + i}
                                                                                    onChange={() => handleProfileSelect(item?.value)}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label cp"
                                                                                    htmlFor={"flexCheckDefault-profile" + i}
                                                                                >
                                                                                    {item?.label}
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`filter-list moretext`} style={isAccordionVisible ? { display: 'block' } : { display: 'none' }}>
                                <div className={`inline-input-box mb-3`}>
                                    <div className="form-group d-flex align-items-start">
                                        <label className="inline-label">Marital Status</label>
                                        <div className="froup-field d-flex align-items-center">
                                            <div
                                                className="accordion accordion-boder-in accordion-select"
                                                id="accordionExample"
                                            >
                                                <div className="accordion-item select-collapse">
                                                    <h2 className="accordion-header">
                                                        <button
                                                            className="accordion-button"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapse002"
                                                            aria-expanded="true"
                                                            aria-controls="collapse002"
                                                        >
                                                            {(selectedMaritalStatus[0] ? selectedMaritalStatus[0] + ", " : "") + (selectedMaritalStatus[1] ? selectedMaritalStatus[1] + ", " : "") + (selectedMaritalStatus[2] ? selectedMaritalStatus[2] + ", " : "") || 'Any'}
                                                            {maritalShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapse002"
                                                        className="accordion-collapse collapse"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            <ul className="coustom-check-list">
                                                                <li>
                                                                    <div className="form-check coustom-check d-flex align-items-center">
                                                                        <input
                                                                            className="form-check-input cp"
                                                                            type="checkbox"
                                                                            defaultValue=""
                                                                            id="flexCheckDefault-martialStatus"
                                                                        />
                                                                        <label
                                                                            className="form-check-label cp"
                                                                            htmlFor="flexCheckDefault-martialStatus"
                                                                        >
                                                                            Any
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                {
                                                                    MARITAL_STATUS.map((item, i) => {
                                                                        return (
                                                                            <li key={i}>
                                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                                    <input
                                                                                        className="form-check-input cp"
                                                                                        type="checkbox"
                                                                                        // defaultValue=""
                                                                                        checked={selectedMaritalStatus.includes(item?.value)}
                                                                                        value={item?.value}
                                                                                        id={"flexCheckDefault-maritalStatus" + i}
                                                                                        onChange={() => handleMaritalStatusSelect(item?.value)}
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label cp"
                                                                                        htmlFor={"flexCheckDefault-maritalStatus" + i}
                                                                                    >
                                                                                        {/* {prepareLabel(MARITAL_STATUS[item])} */}
                                                                                        {prepareLabel(item?.label)}
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="inline-input-box mb-3">
                                    <div className="form-group d-flex align-items-start">
                                        <label className="inline-label">Mother Tongue</label>
                                        <div className="froup-field d-flex align-items-center">
                                            <div
                                                className="accordion accordion-boder-in accordion-select"
                                                id="accordionExample"
                                            >
                                                <div className="accordion-item select-collapse">
                                                    <h2 className="accordion-header">
                                                        <button
                                                            className="accordion-button"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapse003"
                                                            aria-expanded="true"
                                                            aria-controls="collapse003"
                                                        >
                                                            {(selectedMotherTongue[0] ? selectedMotherTongue[0] + ", " : "") + (selectedMotherTongue[1] ? selectedMotherTongue[1] + ", " : "") + (selectedMotherTongue[2] ? selectedMotherTongue[2] + ", " : "") || 'Any'}
                                                            {showReadMore && <a className="read-more text-link">Read more...</a>}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapse003"
                                                        className="accordion-collapse collapse"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            <ul className="coustom-check-list">
                                                                <li>
                                                                    <div className="form-check coustom-check d-flex align-items-center">
                                                                        <input
                                                                            className="form-check-input cp"
                                                                            type="checkbox"
                                                                            defaultValue=""
                                                                            id="flexCheckDefault-motherTongue"
                                                                        />
                                                                        <label
                                                                            className="form-check-label cp"
                                                                            htmlFor="flexCheckDefault-motherTongue"
                                                                        >
                                                                            Any
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                {
                                                                    motherTongueOptions.map((item, i) => {
                                                                        return (
                                                                            <li key={i}>
                                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                                    <input
                                                                                        className="form-check-input cp"
                                                                                        type="checkbox"
                                                                                        checked={selectedMaritalStatus.includes(item?.name)}
                                                                                        value={item?.id}
                                                                                        id={"flexCheckDefault-motherTongue" + i}
                                                                                        onChange={() => handleMotherTongueSelect(item?.name)}
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label cp"
                                                                                        htmlFor={"flexCheckDefault-motherTongue" + i}
                                                                                    >
                                                                                        {item?.name}
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="inline-input-box mb-3">
                                    <div className="form-group d-flex align-items-start">
                                        <label className="inline-label">Physical Status</label>
                                        <div className="froup-field d-flex align-items-center">
                                            <div
                                                className="accordion accordion-boder-in accordion-select"
                                                id="accordionExample"
                                            >
                                                <div className="accordion-item select-collapse">
                                                    <h2 className="accordion-header">
                                                        <button
                                                            className="accordion-button"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapse004"
                                                            aria-expanded="true"
                                                            aria-controls="collapse004"
                                                        >
                                                            {(selectedPhysicalStatus[0] ? selectedPhysicalStatus[0] + ", " : "") + (selectedPhysicalStatus[1] ? selectedPhysicalStatus[1] + ", " : "") + (selectedPhysicalStatus[2] ? selectedPhysicalStatus[2] + ", " : "") || "Doesn't Matter"}
                                                            {physicalShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapse004"
                                                        className="accordion-collapse collapse"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            <ul className="coustom-check-list">
                                                                <li>
                                                                    <div className="form-check coustom-check d-flex align-items-center">
                                                                        <input
                                                                            className="form-check-input cp"
                                                                            type="checkbox"
                                                                            defaultValue=""
                                                                            id="flexCheckDefault-physicalStatus"
                                                                        />
                                                                        <label
                                                                            className="form-check-label cp"
                                                                            htmlFor="flexCheckDefault-physicalStatus"
                                                                        >
                                                                            Doesn't Matter
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                {
                                                                   PHYSICAL_STATUS_OPTIONS.map((item, i) => {
                                                                        return (
                                                                            <li key={i}>
                                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                                    <input
                                                                                        className="form-check-input cp"
                                                                                        type="checkbox"
                                                                                        // defaultValue=""
                                                                                        checked={selectedPhysicalStatus.includes(item?.value)}
                                                                                        value={item?.value}
                                                                                        id={"flexCheckDefault-physicalStatus" + i}
                                                                                        onChange={() => handlePhysicalStatusSelect(item?.value)}
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label cp"
                                                                                        htmlFor={"flexCheckDefault-physicalStatus" + i}
                                                                                    >
                                                                                        {/* {prepareLabel(PHYSICAL_STATUS[item])} */}
                                                                                        {item?.label}
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="view-box">
                                <a className="link-text moreless-button" onClick={toggleAccordion}> {isAccordionVisible ? 'View Less' : 'View More'}</a>
                            </div>
                        </div>
                        <div className="serch-content-box religious-detail-info mb-4">
                            <div className="bg-title mb-3">
                                <h4>Religious Details</h4>
                            </div>
                            <div className="inline-input-box mb-3">
                                <div className="form-group d-flex align-items-start">
                                    <label className="inline-label">Religion</label>
                                    <div className="froup-field d-flex align-items-center">
                                        <div
                                            className="accordion accordion-boder-in accordion-select"
                                            id="accordionExample"
                                        >
                                            <div className="accordion-item select-collapse">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse05"
                                                        aria-expanded="true"
                                                        aria-controls="collapse05"
                                                    >
                                                        {selectedReligion ? religionOptions.find(religion => religion.id == selectedReligion)?.name : 'Any'}
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse05"
                                                    className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <div className="search-field d-flex align-items-center mb-3">
                                                            <input
                                                                type="search"
                                                                name=""
                                                                placeholder="Search Profile created by"
                                                                className="form-control"
                                                            />
                                                            <button className="search-btn">
                                                                <i className="ri-search-line" />
                                                            </button>
                                                        </div>
                                                        <ul className="coustom-check-list">
                                                            <li>
                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                    <input
                                                                        className="form-check-input cp"
                                                                        type="checkbox"
                                                                        id="flexCheckDefault-religion"
                                                                        checked={selectedReligion == null}
                                                                        onChange={() => handleReligionChange(null)}
                                                                    />
                                                                    <label
                                                                        className="form-check-label cp"
                                                                        htmlFor="flexCheckDefault-religion"
                                                                    >
                                                                        Any
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            {
                                                                religionOptions.map((religion, i) => {
                                                                    return (
                                                                        <li key={i}>
                                                                            <div className="form-check coustom-check d-flex align-items-center">
                                                                                <input
                                                                                    className="form-check-input cp"
                                                                                    type="checkbox"
                                                                                    defaultValue=""
                                                                                    value={religion?.id}
                                                                                    id={"flexCheckDefault-religion" + i}
                                                                                    checked={religion.id == selectedReligion}
                                                                                    onChange={() => handleReligionChange(religion.id)}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label cp"
                                                                                    htmlFor={"flexCheckDefault-religion" + i}
                                                                                >
                                                                                    {religion?.name}
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-input-box mb-3">
                                <div className="form-group d-flex align-items-start">
                                    <label className="inline-label">Caste</label>
                                    <div className="froup-field d-flex align-items-center">
                                        <div
                                            className="accordion accordion-boder-in accordion-select"
                                            id="accordionExample"
                                        >
                                            <div className="accordion-item select-collapse">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse006"
                                                        aria-expanded="true"
                                                        aria-controls="collapse006"
                                                    >
                                                        {(selectedCasteStatus[0] ? selectedCasteStatus[0] + ", " : "") + (selectedCasteStatus[1] ? selectedCasteStatus[1] + ", " : "") + (selectedCasteStatus[2] ? selectedCasteStatus[2] + ", " : "") || 'Any Caste'}
                                                        {casteShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse006"
                                                    className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <ul className="coustom-check-list">
                                                            <li>
                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                    <input
                                                                        className="form-check-input cp"
                                                                        type="checkbox"
                                                                        defaultValue=""
                                                                        id="flexCheckDefault-caste"
                                                                    />
                                                                    <label
                                                                        className="form-check-label cp"
                                                                        htmlFor="flexCheckDefault-caste"
                                                                    >
                                                                        Any
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            {
                                                                casteOptions.map((item, i) => {
                                                                    return (
                                                                        <li key={i}>
                                                                            <div className="form-check coustom-check d-flex align-items-center">
                                                                                <input
                                                                                    className="form-check-input cp"
                                                                                    type="checkbox"
                                                                                    checked={selectedCasteStatus.includes(item?.name)}
                                                                                    value={item?.id}
                                                                                    id={"flexCheckDefault-caste" + i}
                                                                                    onChange={() => handleCasteStatusSelect(item?.name)}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label cp"
                                                                                    htmlFor={"flexCheckDefault-caste" + i}
                                                                                >
                                                                                    {item?.name}
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="inline-input-box mb-3">
                                            <div className="form-group d-flex align-items-start">
                                                <label className="inline-label">Subcaste</label>
                                                <div className="froup-field d-flex align-items-center">
                                                    <div
                                                        className="accordion accordion-boder-in accordion-select"
                                                        id="accordionExample"
                                                    >
                                                        <div className="accordion-item select-collapse">
                                                            <h2 className="accordion-header">
                                                                <button
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapse007"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapse007"
                                                                >
                                                                    Awadhi, Bhojpuri, Brij, B...{" "}
                                                                    <a className="read-more text-link">Read more</a>
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapse007"
                                                                className="accordion-collapse collapse"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    <ul className="coustom-check-list">
                                                                        <li>
                                                                            <div className="form-check coustom-check d-flex align-items-center">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    defaultValue=""
                                                                                    id="flexCheckDefault"
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="flexCheckDefault"
                                                                                >
                                                                                    Any
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="form-check coustom-check d-flex align-items-center">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    defaultValue=""
                                                                                    id="flexCheckDefault"
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="flexCheckDefault"
                                                                                >
                                                                                    Never Married
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                            {/* <div className="inline-input-box mb-3 lock-inline-box">
                                <div className="lock-box-info pt-4 pb-4 mt-4"> */}
                                    {/* <span className="lock-icon">
                                                    <i className="ri-lock-line" />
                                                </span> */}
                                    {/* <div className="form-group d-flex align-items-center mb-3">
                                                    <label className="inline-label">Star</label>
                                                    <select className="form-select">
                                                        <option>1</option>
                                                    </select>
                                                </div> */}
                                    <div className="inline-input-box mb-3">
                                        <div className="form-group d-flex align-items-start">
                                            <label className="inline-label">Star</label>
                                            <div className="froup-field d-flex align-items-center">
                                                <div
                                                    className="accordion accordion-boder-in accordion-select"
                                                    id="accordionExample"
                                                >
                                                    <div className="accordion-item select-collapse">
                                                        <h2 className="accordion-header">
                                                            <button
                                                                className="accordion-button"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapse003"
                                                                aria-expanded="true"
                                                                aria-controls="collapse003"
                                                            >
                                                                {(selectedStar[0] ? selectedStar[0] + ", " : "") + (selectedStar[1] ? selectedStar[1] + ", " : "") + (selectedStar[2] ? selectedStar[2] + ", " : "") || 'Any'}
                                                                {starShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="collapse003"
                                                            className="accordion-collapse collapse"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body">
                                                                <ul className="coustom-check-list">
                                                                    <li>
                                                                        <div className="form-check coustom-check d-flex align-items-center">
                                                                            <input
                                                                                className="form-check-input cp"
                                                                                type="checkbox"
                                                                                defaultValue=""
                                                                                id="flexCheckDefault-star"
                                                                            />
                                                                            <label
                                                                                className="form-check-label cp"
                                                                                htmlFor="flexCheckDefault-star"
                                                                            >
                                                                                Any
                                                                            </label>
                                                                        </div>
                                                                    </li>
                                                                    {
                                                                        starOptions.map((item, i) => {
                                                                            return (
                                                                                <li key={i}>
                                                                                    <div className="form-check coustom-check d-flex align-items-center">
                                                                                        <input
                                                                                            className="form-check-input cp"
                                                                                            type="checkbox"
                                                                                            checked={selectedStar.includes(item?.name)}
                                                                                            value={item?.id}
                                                                                            id={"flexCheckDefault-star" + i}
                                                                                            onChange={() => handleStarSelect(item?.name)}
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label cp"
                                                                                            htmlFor={"flexCheckDefault-star" + i}
                                                                                        >
                                                                                            {item?.name}
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group d-flex align-items-center">
                                        <label className="inline-label">
                                            Profiles with Horoscope
                                        </label>
                                        <div className="form-check coustom-check d-flex align-items-center">
                                            <input
                                                className="form-check-input cp"
                                                type="checkbox"
                                                value={searchForm?.values?.is_horoscope_enabled}
                                                onChange={(e) => searchForm?.setFieldValue('is_horoscope_enabled', e.target.checked)}
                                                onBlur={searchForm?.handleBlur}
                                                id="flexCheckDefault-horoscope"
                                            />
                                            <label
                                                className="form-check-label cp"
                                                htmlFor="flexCheckDefault-horoscope"
                                            >
                                                Matches who have added horoscope
                                            </label>
                                        </div>
                                    </div>
                                    {/* <div className="btn-group-box d-flex align-items-center justify-content-center mt-4">
                                        <p className="me-4">To access these premium filters</p>
                                        <a className="btn-sm"> Become a paid member </a>
                                    </div> */}
                                {/* </div>
                            </div> */}
                            <div className="inline-input-box mt-3">
                                <div className="form-group d-flex align-items-start">
                                    <label className="inline-label">Dosha(m)</label>
                                    <div className="froup-field d-flex align-items-center">
                                        <div
                                            className="accordion accordion-boder-in accordion-select"
                                            id="accordionExample"
                                        >
                                            <div className="accordion-item select-collapse">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse008"
                                                        aria-expanded="true"
                                                        aria-controls="collapse008"
                                                    >
                                                        {(selectedDosh[0] ? selectedDosh[0] + ", " : "") + (selectedDosh[1] ? selectedDosh[1] + ", " : "") + (selectedDosh[2] ? selectedDosh[2] + ", " : "") || "Doesn't Matter"}
                                                        {doshShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse008"
                                                    className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <ul className="coustom-check-list">
                                                            <li>
                                                                <div className="form-check coustom-check d-flex align-items-center cp">
                                                                    <input
                                                                        className="form-check-input cp"
                                                                        type="checkbox"
                                                                        defaultValue=""
                                                                        id="flexCheckDefault-dosh"
                                                                    />
                                                                    <label
                                                                        className="form-check-label cp"
                                                                        htmlFor="flexCheckDefault-dosh"
                                                                    >
                                                                        Doesn't Matter
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            {
                                                                DOSH_VALUE.map((item, i) => {
                                                                    return (
                                                                        <li key={i}>
                                                                            <div className="form-check coustom-check d-flex align-items-center">
                                                                                <input
                                                                                    className="form-check-input cp"
                                                                                    type="checkbox"
                                                                                    // defaultValue=""
                                                                                    checked={selectedDosh.includes(item?.value)}
                                                                                    value={item?.value}
                                                                                    id={"flexCheckDefault-dosh" + i}
                                                                                    onChange={() => handleDoshSelect(item?.value)}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label cp"
                                                                                    htmlFor={"flexCheckDefault-dosh" + i}
                                                                                >
                                                                                    {/* {prepareLabel(DOSH_OPTIONS[item])} */}
                                                                                    {item?.label}
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="serch-content-box professional-detail-info mb-4">
                            <div className="bg-title mb-3">
                                <h4>Professional Details</h4>
                            </div>
                            <div className="inline-input-box mb-3">
                                <div className="form-group d-flex align-items-center">
                                    <label className="inline-label">Occupation</label>
                                    <div className="froup-field d-flex align-items-center">
                                        <div
                                            className="accordion accordion-boder-in accordion-select"
                                            id="accordionExample"
                                        >
                                            <div className="accordion-item select-collapse">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse09"
                                                        aria-expanded="true"
                                                        aria-controls="collapse09"
                                                    >
                                                        {(selectedOccupation[0] ? selectedOccupation[0] + ", " : "") + (selectedOccupation[1] ? selectedOccupation[1] + ", " : "") + (selectedOccupation[2] ? selectedOccupation[2] + ", " : "") || "Any"}
                                                        {occupationShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse09"
                                                    className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <div className="search-field d-flex align-items-center mb-3">
                                                            <input
                                                                type="search"
                                                                name=""
                                                                placeholder="Search Profile created by"
                                                                className="form-control"
                                                            />
                                                            <button className="search-btn">
                                                                <i className="ri-search-line" />
                                                            </button>
                                                        </div>
                                                        <ul className="coustom-check-list">
                                                            <li>
                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                    <input
                                                                        className="form-check-input cp"
                                                                        type="checkbox"
                                                                        defaultValue=""
                                                                        id="flexCheckDefault-occupation"
                                                                    />
                                                                    <label
                                                                        className="form-check-label cp"
                                                                        htmlFor="flexCheckDefault-occupation"
                                                                    >
                                                                        Any
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            {
                                                                occupations.map((item, i) => {
                                                                    return (
                                                                        <li key={i}>
                                                                            <div className="form-check coustom-check d-flex align-items-center">
                                                                                <input
                                                                                    className="form-check-input cp"
                                                                                    type="checkbox"
                                                                                    checked={selectedOccupation.includes(item?.name)}
                                                                                    value={item?.id}
                                                                                    id={"flexCheckDefault-occupation" + i}
                                                                                    onChange={() => handleOccupationSelect(item?.name)}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label cp"
                                                                                    htmlFor={"flexCheckDefault-occupation" + i}
                                                                                >
                                                                                    {item?.name}
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-input-box mb-3">
                                <div className="form-group d-flex align-items-center">
                                    <label className="inline-label">Annual Income</label>
                                    <div className="froup-field d-flex align-items-center">
                                        <select className="form-select cp" value={selectedMinIncome} onChange={(e) => setSelectedMinIncome(e.target.value)}>
                                            {
                                                ANNUAL_INCOME_OPTIONS.map((item, index) => {
                                                    return (<option key={index} value={item?.value}>{item?.label}</option>)
                                                })
                                            }
                                        </select>
                                        <span className="to-text ps-2 pe-2">to</span>
                                        <select className="form-select cp" value={selectedMaxIncome} onChange={(e) => setSelectedMaxIncome(e.target.value)}>
                                            {
                                                ANNUAL_INCOME_OPTIONS.map((item, index) => {
                                                    return (<option key={index} value={item?.value}>{item?.label}</option>)
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-input-box mb-3">
                                <div className="form-group d-flex align-items-start">
                                    <label className="inline-label">Employment Type</label>
                                    <div className="froup-field d-flex align-items-center">
                                        <div
                                            className="accordion accordion-boder-in accordion-select"
                                            id="accordionExample"
                                        >
                                            <div className="accordion-item select-collapse">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse0066"
                                                        aria-expanded="true"
                                                        aria-controls="collapse0066"
                                                    >
                                                        {(selectedEmployment[0] ? selectedEmployment[0] + ", " : "") + (selectedEmployment[1] ? selectedEmployment[1] + ", " : "") + (selectedEmployment[2] ? selectedEmployment[2] + ", " : "") || 'Any'}
                                                        {employmentShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse0066"
                                                    className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <div className="search-field d-flex align-items-center mb-3">
                                                            <input
                                                                type="search"
                                                                name=""
                                                                placeholder="Search Profile created by"
                                                                className="form-control"
                                                            />
                                                            <button className="search-btn">
                                                                <i className="ri-search-line" />
                                                            </button>
                                                        </div>
                                                        <ul className="coustom-check-list">
                                                            <li>
                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                    <input
                                                                        className="form-check-input cp"
                                                                        type="checkbox"
                                                                        defaultValue=""
                                                                        id="flexCheckDefault-employmentType"
                                                                    />
                                                                    <label
                                                                        className="form-check-label cp"
                                                                        htmlFor="flexCheckDefault-employmentType"
                                                                    >
                                                                        Any
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            {
                                                                EMPLOYED_IN_OPTIONS.map((item, i) => {
                                                                    return (
                                                                        <li key={i}>
                                                                            <div className="form-check coustom-check d-flex align-items-center">
                                                                                <input
                                                                                    className="form-check-input cp"
                                                                                    type="checkbox"
                                                                                    // defaultValue=""
                                                                                    checked={selectedEmployment.includes(item?.value)}
                                                                                    value={item?.value}
                                                                                    id={"flexCheckDefault-employmentType" + i}
                                                                                    onChange={() => handleEmploymentSelect(item.value)}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label cp"
                                                                                    htmlFor={"flexCheckDefault-employmentType" + i}
                                                                                >
                                                                                    {/* {prepareLabel(item.label)} */}
                                                                                    {item?.label}
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-input-box mb-3">
                                <div className="form-group d-flex align-items-start">
                                    <label className="inline-label">Education</label>
                                    <div className="froup-field d-flex align-items-center">
                                        <div
                                            className="accordion accordion-boder-in accordion-select"
                                            id="accordionExample"
                                        >
                                            <div className="accordion-item select-collapse">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse0077"
                                                        aria-expanded="true"
                                                        aria-controls="collapse0077"
                                                    >
                                                        {(selectedEducation[0] ? selectedEducation[0] + ", " : "") + (selectedEducation[1] ? selectedEducation[1] + ", " : "") + (selectedEducation[2] ? selectedEducation[2] + ", " : "") || 'Any'}
                                                        {educationShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse0077"
                                                    className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <ul className="coustom-check-list">
                                                            <li>
                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                    <input
                                                                        className="form-check-input cp"
                                                                        type="checkbox"
                                                                        defaultValue=""
                                                                        id="flexCheckDefault-education"
                                                                    />
                                                                    <label
                                                                        className="form-check-label cp"
                                                                        htmlFor="flexCheckDefault-education"
                                                                    >
                                                                        Any
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            {
                                                                educations.map((item, i) => {
                                                                    return (
                                                                        <li key={i}>
                                                                            <div className="form-check coustom-check d-flex align-items-center">
                                                                                <input
                                                                                    className="form-check-input cp"
                                                                                    type="checkbox"
                                                                                    checked={selectedOccupation.includes(item?.name)}
                                                                                    value={item?.id}
                                                                                    id={"flexCheckDefault-education" + i}
                                                                                    onChange={() => handleEducationSelect(item?.name)}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label cp"
                                                                                    htmlFor={"flexCheckDefault-education" + i}
                                                                                >
                                                                                    {item?.name}
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="inline-input-box mb-3 lock-inline-box">
                                            <div className="lock-box-info pt-4 pb-4 mt-4">
                                                <span className="lock-icon">
                                                    <i className="ri-lock-line" />
                                                </span>
                                                <div className="form-group d-flex align-items-center mb-3">
                                                    <label className="inline-label">
                                                        Institution Details
                                                    </label>
                                                    <select className="form-select">
                                                        <option>1</option>
                                                    </select>
                                                </div>
                                                <div className="form-group d-flex align-items-center mb-3">
                                                    <label className="inline-label">
                                                        Organisation Details
                                                    </label>
                                                    <select className="form-select">
                                                        <option>1</option>
                                                    </select>
                                                </div>
                                                <div className="btn-group-box d-flex align-items-center justify-content-center mt-4">
                                                    <p className="me-4">To access these premium filters</p>
                                                    <a className="btn-sm"> Become a paid member </a>
                                                </div>
                                            </div>
                                        </div> */}
                        </div>
                        <div className="serch-content-box location-detail-info pb-4">
                            <div className="bg-title mb-3">
                                <h4>Location Details</h4>
                            </div>
                            <div className="inline-input-box mb-3">
                                <div className="form-group d-flex align-items-center">
                                    <label className="inline-label">
                                        Nearby Profiles
                                    </label>
                                    <div className="form-check coustom-check d-flex align-items-center">
                                        <input
                                            className="form-check-input cp"
                                            type="checkbox"
                                            defaultValue=""
                                            checked={searchForm?.values?.is_near_by_profile}
                                            value={searchForm?.values?.is_near_by_profile}
                                            onChange={(e) => searchForm?.setFieldValue('is_near_by_profile', e.target.checked)}
                                            onBlur={searchForm?.handleBlur}
                                            id="flexCheckDefault-nearByProfiles"
                                        />
                                        <label
                                            className="form-check-label cp"
                                            htmlFor="flexCheckDefault-nearByProfiles"
                                        >
                                            Matches near your location
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-input-box mb-3">
                                <div className="form-group d-flex align-items-center">
                                    <label className="inline-label">Country</label>
                                    <div className="froup-field d-flex align-items-center">
                                        <div
                                            className="accordion accordion-boder-in accordion-select"
                                            id="accordionExample"
                                        >
                                            <div className="accordion-item select-collapse">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse0010"
                                                        aria-expanded="true"
                                                        aria-controls="collapse0010"
                                                    >
                                                        {(selectedCountries[0] ? selectedCountries[0] + ", " : "") + (selectedCountries[1] ? selectedCountries[1] + ", " : "") + (selectedCountries[2] ? selectedCountries[2] + ", " : "") || 'Any'}
                                                        {countriesShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse0010"
                                                    className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <div className="search-field d-flex align-items-center mb-3">
                                                            <input
                                                                type="search"
                                                                name=""
                                                                placeholder="Search Profile created by"
                                                                className="form-control"
                                                            />
                                                            <button className="search-btn">
                                                                <i className="ri-search-line" />
                                                            </button>
                                                        </div>
                                                        <ul className="coustom-check-list">
                                                            <li>
                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                    <input
                                                                        className="form-check-input cp"
                                                                        type="checkbox"
                                                                        defaultValue=""
                                                                        id="flexCheckDefault-countries"
                                                                    />
                                                                    <label
                                                                        className="form-check-label cp"
                                                                        htmlFor="flexCheckDefault-countries"
                                                                    >
                                                                        Any
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            {
                                                                countries.map((item, i) => {
                                                                    return (
                                                                        <li key={i}>
                                                                            <div className="form-check coustom-check d-flex align-items-center">
                                                                                <input
                                                                                    className="form-check-input cp"
                                                                                    type="checkbox"
                                                                                    checked={selectedCountries.includes(item?.name)}
                                                                                    value={item?.id}
                                                                                    id={"flexCheckDefault-countries" + i}
                                                                                    onChange={() => handleCountriesSelect(item?.name)}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label cp"
                                                                                    htmlFor={"flexCheckDefault-countries" + i}
                                                                                >
                                                                                    {item?.name}
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'filter-list moretext'} style={isLocationAccordionVisible ? { display: 'block' } : { display: 'none' }}>
                                <div className="inline-input-box mb-3">
                                    <div className="form-group d-flex align-items-center">
                                        <label className="inline-label">Citizenship</label>
                                        <div className="froup-field d-flex align-items-center">
                                            <div
                                                className="accordion accordion-boder-in accordion-select"
                                                id="accordionExample"
                                            >
                                                <div className="accordion-item select-collapse">
                                                    <h2 className="accordion-header">
                                                        <button
                                                            className="accordion-button"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapse0011"
                                                            aria-expanded="true"
                                                            aria-controls="collapse0011"
                                                        >
                                                            {(selectedCitizenship[0] ? selectedCitizenship[0] + ", " : "") + (selectedCitizenship[1] ? selectedCitizenship[1] + ", " : "") + (selectedCitizenship[2] ? selectedCitizenship[2] + ", " : "") || 'Any'}
                                                            {citizenshipShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapse0011"
                                                        className="accordion-collapse collapse"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            <div className="search-field d-flex align-items-center mb-3">
                                                                <input
                                                                    type="search"
                                                                    name=""
                                                                    placeholder="Search Profile created by"
                                                                    className="form-control"
                                                                />
                                                                <button className="search-btn">
                                                                    <i className="ri-search-line" />
                                                                </button>
                                                            </div>
                                                            <ul className="coustom-check-list">
                                                                <li>
                                                                    <div className="form-check coustom-check d-flex align-items-center">
                                                                        <input
                                                                            className="form-check-input cp"
                                                                            type="checkbox"
                                                                            defaultValue=""
                                                                            id="flexCheckDefault-citizenship"
                                                                        />
                                                                        <label
                                                                            className="form-check-label cp"
                                                                            htmlFor="flexCheckDefault-citizenship"
                                                                        >
                                                                            Any
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                {
                                                                    countries.map((item, i) => {
                                                                        return (
                                                                            <li key={i}>
                                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                                    <input
                                                                                        className="form-check-input cp"
                                                                                        type="checkbox"
                                                                                        checked={selectedCitizenship.includes(item?.name)}
                                                                                        value={item?.id}
                                                                                        id={"flexCheckDefault-citizenship" + i}
                                                                                        onChange={() => handleCitizenshipSelect(item?.name)}
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label cp"
                                                                                        htmlFor={"flexCheckDefault-citizenship" + i}
                                                                                    >
                                                                                        {item?.name}
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="inline-input-box mb-3">
                                    <div className="form-group d-flex align-items-center">
                                        <label className="inline-label">Ancestral Origin</label>
                                        <div className="froup-field d-flex align-items-center">
                                            <div
                                                className="accordion accordion-boder-in accordion-select"
                                                id="accordionExample"
                                            >
                                                <div className="accordion-item select-collapse">
                                                    <h2 className="accordion-header">
                                                        <button
                                                            className="accordion-button"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapse0012"
                                                            aria-expanded="true"
                                                            aria-controls="collapse0012"
                                                        >
                                                            {(selectedAncestralOriginOptions[0] ? selectedAncestralOriginOptions[0] + ", " : "") + (selectedAncestralOriginOptions[1] ? selectedAncestralOriginOptions[1] + ", " : "") + (selectedAncestralOriginOptions[2] ? selectedAncestralOriginOptions[2] + ", " : "") || 'Any'}
                                                            {ancestralOriginShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapse0012"
                                                        className="accordion-collapse collapse"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            <div className="search-field d-flex align-items-center mb-3">
                                                                <input
                                                                    type="search"
                                                                    name=""
                                                                    placeholder="Search Profile created by"
                                                                    className="form-control"
                                                                />
                                                                <button className="search-btn">
                                                                    <i className="ri-search-line" />
                                                                </button>
                                                            </div>
                                                            <ul className="coustom-check-list">
                                                                <li>
                                                                    <div className="form-check coustom-check d-flex align-items-center">
                                                                        <input
                                                                            className="form-check-input cp"
                                                                            type="checkbox"
                                                                            defaultValue=""
                                                                            id="flexCheckDefault-ancestralOrigin"
                                                                        />
                                                                        <label
                                                                            className="form-check-label cp"
                                                                            htmlFor="flexCheckDefault-ancestralOrigin"
                                                                        >
                                                                            Any
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                {
                                                                    ancestralOptions.map((item, i) => {
                                                                        return (
                                                                            <li key={i}>
                                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                                    <input
                                                                                        className="form-check-input cp"
                                                                                        type="checkbox"
                                                                                        checked={selectedAncestralOriginOptions.includes(item?.name)}
                                                                                        value={item?.id}
                                                                                        id={"flexCheckDefault-ancestralOrigin" + i}
                                                                                        onChange={() => handleAncestralOriginOptionsSelect(item?.name)}
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label cp"
                                                                                        htmlFor={"flexCheckDefault-ancestralOrigin" + i}
                                                                                    >
                                                                                        {item?.name}
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="view-box">
                                <a className="link-text moreless-button" onClick={toggleLocationAccordion}> {isLocationAccordionVisible ? 'View Less' : 'View More'}</a>
                            </div>
                        </div>
                        <div className="serch-content-box lifestyle-detail-info pb-4">
                            <div className="bg-title mb-3">
                                <h4>Lifestyle</h4>
                            </div>
                            <div className="inline-input-box mb-3">
                                <div className="form-group d-flex align-items-center">
                                    <label className="inline-label">Mutual Hobbies</label>
                                    <div className="form-check coustom-check d-flex align-items-center">
                                        <input
                                            className="form-check-input cp"
                                            type="checkbox"
                                            checked={searchForm?.values?.is_mutual_hobbies}
                                            value={searchForm?.values?.is_mutual_hobbies}
                                            onChange={(e) => searchForm?.setFieldValue('is_mutual_hobbies', e.target.checked)}
                                            onBlur={searchForm?.handleBlur}
                                            id="flexCheckDefault-mutualHobbies"
                                        />
                                        <label
                                            className="form-check-label cp"
                                            htmlFor="flexCheckDefault-mutualHobbies"
                                        >
                                            Matches who have similar hobbies as you
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-input-box mb-3">
                                <div className="form-group d-flex align-items-center">
                                    <label className="inline-label">Eating Habits</label>
                                    <div className="froup-field d-flex align-items-center">
                                        <div
                                            className="accordion accordion-boder-in accordion-select"
                                            id="accordionExample"
                                        >
                                            <div className="accordion-item select-collapse">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse0013"
                                                        aria-expanded="true"
                                                        aria-controls="collapse0013"
                                                    >
                                                        {(selectedEatingOptions[0] ? selectedEatingOptions[0] + ", " : "") + (selectedEatingOptions[1] ? selectedEatingOptions[1] + ", " : "") + (selectedEatingOptions[2] ? selectedEatingOptions[2] + ", " : "") || 'Any'}
                                                        {eatingShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse0013"
                                                    className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <ul className="coustom-check-list">
                                                            <li>
                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                    <input
                                                                        className="form-check-input cp"
                                                                        type="checkbox"
                                                                        defaultValue=""
                                                                        id="flexCheckDefault-EatingOptions"
                                                                    />
                                                                    <label
                                                                        className="form-check-label cp"
                                                                        htmlFor="flexCheckDefault-EatingOptions"
                                                                    >
                                                                        Any
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            {
                                                                EATING_VALUE.map((item, i) => {
                                                                    return (
                                                                        <li key={i}>
                                                                            <div className="form-check coustom-check d-flex align-items-center">
                                                                                <input
                                                                                    className="form-check-input cp"
                                                                                    type="checkbox"
                                                                                    checked={selectedEatingOptions.includes(item?.value)}
                                                                                    value={item?.value}
                                                                                    id={"flexCheckDefault-EatingOptions" + i}
                                                                                    onChange={() => handleEatingOptionsSelect(item?.value)}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label cp"
                                                                                    htmlFor={"flexCheckDefault-EatingOptions" + i}
                                                                                >
                                                                                    {/* {prepareLabel(EATING_OPTIONS[item])} */}
                                                                                    {item?.label}
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'filter-list moretext'} style={isLifeStyleAccordionVisible ? { display: 'block' } : { display: 'none' }}>
                                <div className="inline-input-box mb-3">
                                    <div className="form-group d-flex align-items-center">
                                        <label className="inline-label">Smoking Habits</label>
                                        <div className="froup-field d-flex align-items-center">
                                            <div
                                                className="accordion accordion-boder-in accordion-select"
                                                id="accordionExample"
                                            >
                                                <div className="accordion-item select-collapse">
                                                    <h2 className="accordion-header">
                                                        <button
                                                            className="accordion-button"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapse0014"
                                                            aria-expanded="true"
                                                            aria-controls="collapse0014"
                                                        >
                                                            {(selectedSmokingOptions[0] ? selectedSmokingOptions[0] + ", " : "") + (selectedSmokingOptions[1] ? selectedSmokingOptions[1] + ", " : "") + (selectedSmokingOptions[2] ? selectedSmokingOptions[2] + ", " : "") || "Doesn't Matter"}
                                                            {smokingShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapse0014"
                                                        className="accordion-collapse collapse"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            <ul className="coustom-check-list">
                                                                <li>
                                                                    <div className="form-check coustom-check d-flex align-items-center">
                                                                        <input
                                                                            className="form-check-input cp"
                                                                            type="checkbox"
                                                                            defaultValue=""
                                                                            id="flexCheckDefault-SmokingOptions"
                                                                        />
                                                                        <label
                                                                            className="form-check-label cp"
                                                                            htmlFor="flexCheckDefault-SmokingOptions"
                                                                        >
                                                                            Doesn't Matter
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                {
                                                                   SMOKING_VALUE.map((item, i) => {
                                                                        return (
                                                                            <li key={i}>
                                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                                    <input
                                                                                        className="form-check-input cp"
                                                                                        type="checkbox"
                                                                                        checked={selectedSmokingOptions.includes(item?.value)}
                                                                                        value={item?.value}
                                                                                        id={"flexCheckDefault-SmokingOptions" + i}
                                                                                        onChange={() => handleSmokingOptionsSelect(item?.value)}
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label cp"
                                                                                        htmlFor={"flexCheckDefault-SmokingOptions" + i}
                                                                                    >
                                                                                        {/* {prepareLabel(SMOKING_OPTIONS[item])} */}
                                                                                        {item?.label}
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="inline-input-box mb-3">
                                    <div className="form-group d-flex align-items-center">
                                        <label className="inline-label">Drinking Habits</label>
                                        <div className="froup-field d-flex align-items-center">
                                            <div
                                                className="accordion accordion-boder-in accordion-select"
                                                id="accordionExample"
                                            >
                                                <div className="accordion-item select-collapse">
                                                    <h2 className="accordion-header">
                                                        <button
                                                            className="accordion-button"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapse0015"
                                                            aria-expanded="true"
                                                            aria-controls="collapse0015"
                                                        >
                                                            {(selectedDrinkingOptions[0] ? selectedDrinkingOptions[0] + ", " : "") + (selectedDrinkingOptions[1] ? selectedDrinkingOptions[1] + ", " : "") + (selectedDrinkingOptions[2] ? selectedDrinkingOptions[2] + ", " : "") || "Doesn't Matter"}
                                                            {drinkingShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapse0015"
                                                        className="accordion-collapse collapse"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            <ul className="coustom-check-list">
                                                                <li>
                                                                    <div className="form-check coustom-check d-flex align-items-center">
                                                                        <input
                                                                            className="form-check-input cp"
                                                                            type="checkbox"
                                                                            defaultValue=""
                                                                            id="flexCheckDefault-DrinkingOptions"
                                                                        />
                                                                        <label
                                                                            className="form-check-label cp"
                                                                            htmlFor="flexCheckDefault-DrinkingOptions"
                                                                        >
                                                                            Doesn't Matter
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                {
                                                                   DRINKING_VALUE.map((item, i) => {
                                                                        return (
                                                                            <li key={i}>
                                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                                    <input
                                                                                        className="form-check-input cp"
                                                                                        type="checkbox"
                                                                                        checked={selectedDrinkingOptions.includes(item?.value)}
                                                                                        value={item?.value}
                                                                                        id={"flexCheckDefault-DrinkingOptions" + i}
                                                                                        onChange={() => handleDrinkingOptionsSelect(item?.value)}
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label cp"
                                                                                        htmlFor={"flexCheckDefault-DrinkingOptions" + i}
                                                                                    >
                                                                                        {/* {prepareLabel(item?.label)} */}
                                                                                        {item?.label}
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="view-box">
                                <a className="link-text moreless-button" onClick={toggleLifeStyleAccordion}> {isLifeStyleAccordionVisible ? 'View Less' : 'View More'}</a>
                            </div>
                        </div>
                        <div className="serch-content-box family-detail-info pb-4">
                            <div className="bg-title mb-3">
                                <h4>Family Details</h4>
                            </div>
                            <div className="inline-input-box mb-3">
                                <div className="form-group d-flex align-items-center">
                                    <label className="inline-label">Family Status</label>
                                    <div className="froup-field d-flex align-items-center">
                                        <div
                                            className="accordion accordion-boder-in accordion-select"
                                            id="accordionExample"
                                        >
                                            <div className="accordion-item select-collapse">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse0015"
                                                        aria-expanded="true"
                                                        aria-controls="collapse0015"
                                                    >
                                                        {(selectedFamilyStatusOptions[0] ? selectedFamilyStatusOptions[0] + ", " : "") + (selectedFamilyStatusOptions[1] ? selectedFamilyStatusOptions[1] + ", " : "") + (selectedFamilyStatusOptions[2] ? selectedFamilyStatusOptions[2] + ", " : "") || 'Any'}
                                                        {familyStatusShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse0015"
                                                    className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <ul className="coustom-check-list">
                                                            <li>
                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                    <input
                                                                        className="form-check-input cp"
                                                                        type="checkbox"
                                                                        defaultValue=""
                                                                        id="flexCheckDefault-FamilyStatusOptions"
                                                                    />
                                                                    <label
                                                                        className="form-check-label cp"
                                                                        htmlFor="flexCheckDefault-FamilyStatusOptions"
                                                                    >
                                                                        Any
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            {
                                                                FAMILY_STATUS.map((item, i) => {
                                                                    return (
                                                                        <li key={i}>
                                                                            <div className="form-check coustom-check d-flex align-items-center">
                                                                                <input
                                                                                    className="form-check-input cp"
                                                                                    type="checkbox"
                                                                                    checked={selectedFamilyStatusOptions.includes(item?.value)}
                                                                                    value={item?.value}
                                                                                    id={"flexCheckDefault-FamilyStatusOptions" + i}
                                                                                    onChange={() => handleFamilyStatusOptionsSelect(item?.value)}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label cp"
                                                                                    htmlFor={"flexCheckDefault-FamilyStatusOptions" + i}
                                                                                >
                                                                                    {/* {prepareLabel(FAMILY_STATUS_OPTIONS[item])} */}
                                                                                    {item?.label}
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'filter-list moretext'} style={isFamilyDetailsAccordionVisible ? { display: 'block' } : { display: 'none' }}>
                                <div className="inline-input-box mb-3">
                                    <div className="form-group d-flex align-items-center">
                                        <label className="inline-label">Family Value</label>
                                        <div className="froup-field d-flex align-items-center">
                                            <div
                                                className="accordion accordion-boder-in accordion-select"
                                                id="accordionExample"
                                            >
                                                <div className="accordion-item select-collapse">
                                                    <h2 className="accordion-header">
                                                        <button
                                                            className="accordion-button"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapse0016"
                                                            aria-expanded="true"
                                                            aria-controls="collapse0016"
                                                        >
                                                            {(selectedFamilyValueOptions[0] ? selectedFamilyValueOptions[0] + ", " : "") + (selectedFamilyValueOptions[1] ? selectedFamilyValueOptions[1] + ", " : "") + (selectedFamilyValueOptions[2] ? selectedFamilyValueOptions[2] + ", " : "") || 'Any'}
                                                            {familyValueShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapse0016"
                                                        className="accordion-collapse collapse"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            <ul className="coustom-check-list">
                                                                <li>
                                                                    <div className="form-check coustom-check d-flex align-items-center">
                                                                        <input
                                                                            className="form-check-input cp"
                                                                            type="checkbox"
                                                                            defaultValue=""
                                                                            id="flexCheckDefault-FamilyValueOptions"
                                                                        />
                                                                        <label
                                                                            className="form-check-label cp"
                                                                            htmlFor="flexCheckDefault-FamilyValueOptions"
                                                                        >
                                                                            Any
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                {
                                                                    FAMILY_VALUES.map((item, i) => {
                                                                        return (
                                                                            <li key={i}>
                                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                                    <input
                                                                                        className="form-check-input cp"
                                                                                        type="checkbox"
                                                                                        checked={selectedFamilyValueOptions.includes(item?.value)}
                                                                                        value={item?.value}
                                                                                        id={"flexCheckDefault-FamilyValueOptions" + i}
                                                                                        onChange={() => handleFamilyValueOptionsSelect(item?.value)}
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label cp"
                                                                                        htmlFor={"flexCheckDefault-FamilyValueOptions" + i}
                                                                                    >
                                                                                        {item?.label}
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="inline-input-box mb-3">
                                    <div className="form-group d-flex align-items-center">
                                        <label className="inline-label">Family Type</label>
                                        <div className="froup-field d-flex align-items-center">
                                            <div
                                                className="accordion accordion-boder-in accordion-select"
                                                id="accordionExample"
                                            >
                                                <div className="accordion-item select-collapse">
                                                    <h2 className="accordion-header">
                                                        <button
                                                            className="accordion-button"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapse0017"
                                                            aria-expanded="true"
                                                            aria-controls="collapse0017"
                                                        >
                                                            {(selectedFamilyTypeOptions[0] ? selectedFamilyTypeOptions[0] + ", " : "") + (selectedFamilyTypeOptions[1] ? selectedFamilyTypeOptions[1] + ", " : "") + (selectedFamilyTypeOptions[2] ? selectedFamilyTypeOptions[2] + ", " : "") || 'Any'}
                                                            {familyTypeShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapse0017"
                                                        className="accordion-collapse collapse"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            <ul className="coustom-check-list">
                                                                <li>
                                                                    <div className="form-check coustom-check d-flex align-items-center">
                                                                        <input
                                                                            className="form-check-input cp"
                                                                            type="checkbox"
                                                                            defaultValue=""
                                                                            id="flexCheckDefault-FamilyTypeOptions"
                                                                        />
                                                                        <label
                                                                            className="form-check-label cp"
                                                                            htmlFor="flexCheckDefault-FamilyTypeOptions"
                                                                        >
                                                                            Any
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                {
                                                                   FAMILY_TYPES.map((item, i) => {
                                                                        return (
                                                                            <li key={i}>
                                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                                    <input
                                                                                        className="form-check-input cp"
                                                                                        type="checkbox"
                                                                                        checked={selectedFamilyTypeOptions.includes(item?.value)}
                                                                                        value={item?.value}
                                                                                        id={"flexCheckDefault-FamilyTypeOptions" + i}
                                                                                        onChange={() => handleFamilyTypeOptionsSelect(item?.value)}
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label cp"
                                                                                        htmlFor={"flexCheckDefault-FamilyTypeOptions" + i}
                                                                                    >
                                                                                        {item?.label}
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="view-box">
                                <a className="link-text moreless-button" onClick={toggleFamilyDetailsAccordion}> {isFamilyDetailsAccordionVisible ? 'View Less' : 'View More'}</a>
                            </div>
                        </div>
                        <div className="serch-content-box recently-profiles-info pb-4">
                            <div className="bg-title mb-3">
                                <h4>Recently active profiles</h4>
                            </div>
                            <h5 className="inner-sub-title">
                                Profile Created <small>Profiles based on created date</small>
                            </h5>
                            <ul className="radio-box-btn d-flex mt-3">
                                <li>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input cp"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexCheckDefault-profileCreated"
                                            defaultChecked=""
                                        />
                                        <label className="form-check-label cp"
                                            htmlFor="flexCheckDefault-profileCreated"
                                        >
                                            Any
                                        </label>
                                    </div>
                                </li>
                                {
                                    RECENTLY_ACTIVE_PROFILE.map((item, i) => {
                                        return (
                                            <li>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        checked={selectedProfileCreated.includes(item?.value)}
                                                        value={selectedProfileCreated}
                                                        name="flexRadioDefault"
                                                        id={"flexCheckDefault-profileCreated" + i}
                                                        onChange={(e) => setSelectedProfileCreated(RECENTLY_ACTIVE_PROFILE[i].value)}

                                                    />
                                                    <label className="form-check-label"
                                                        htmlFor={"flexCheckDefault-profileCreated" + i}
                                                    >
                                                        {prepareLabel(item?.label)}
                                                        {/* {item.label} */}
                                                    </label>
                                                </div>
                                            </li>
                                        )

                                    })
                                }
                            </ul>
                        </div>
                        <div className="serch-content-box profile-type-info pb-4">
                            <div className="bg-title mb-3">
                                <h4>Profile Type</h4>
                            </div>
                            <div className="inline-input-box mb-3">
                                <div className="form-group d-flex align-items-center">
                                    <label className="inline-label">Profiles with Photo</label>
                                    <div className="form-check coustom-check d-flex align-items-center">
                                        <input
                                            className="form-check-input cp"
                                            type="checkbox"
                                            checked={searchForm?.values?.is_profile_added}
                                            value={searchForm?.values?.is_profile_added}
                                            onChange={(e) => searchForm?.setFieldValue('is_profile_added', e.target.checked)}
                                            onBlur={searchForm?.handleBlur}
                                            id="flexCheckDefault-profileAdded"
                                        />
                                        <label
                                            className="form-check-label cp"
                                            htmlFor="flexCheckDefault-profileAdded"
                                        >
                                            Matches who have added photos
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-input-box mb-3">
                                <div className="form-group d-flex align-items-center">
                                    <label className="inline-label">Don't show profiles</label>
                                    <div className="froup-field d-flex align-items-center">
                                        <div
                                            className="accordion accordion-boder-in accordion-select"
                                            id="accordionExample"
                                        >
                                            <div className="accordion-item select-collapse">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse0013"
                                                        aria-expanded="true"
                                                        aria-controls="collapse0013"
                                                    >
                                                        {(selectedShowProfile[0] ? selectedShowProfile[0] + ", " : "") + (selectedShowProfile[1] ? selectedShowProfile[1] + ", " : "") + (selectedShowProfile[2] ? selectedShowProfile[2] + ", " : "") || 'Any'}
                                                        {showProfileShowReadMore && <a className="read-more text-link">Read more...</a>}
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse0013"
                                                    className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <ul className="coustom-check-list">
                                                            <li>
                                                                <div className="form-check coustom-check d-flex align-items-center">
                                                                    <input
                                                                        className="form-check-input cp"
                                                                        type="checkbox"
                                                                        defaultValue=""
                                                                        id="flexCheckDefault-showProfile"
                                                                    />
                                                                    <label
                                                                        className="form-check-label cp"
                                                                        htmlFor="flexCheckDefault-showProfile"
                                                                    >

                                                                        Any
                                                                        <small>
                                                                            Members whom you have already contacted
                                                                        </small>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            {
                                                                SHOW_PROFILE.map((item, i) => {
                                                                    return (
                                                                        <li key={i}>
                                                                            <div className="form-check coustom-check d-flex align-items-center">
                                                                                <input
                                                                                    className="form-check-input cp"
                                                                                    type="checkbox"
                                                                                    checked={selectedShowProfile.includes(item?.heading)}
                                                                                    value={item.heading}
                                                                                    id={"flexCheckDefault-showProfile" + i}
                                                                                    onChange={() => handleShowProfileSelect(item.heading)}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label cp"
                                                                                    htmlFor={"flexCheckDefault-showProfile" + i}
                                                                                >
                                                                                    {/* {prepareLabel(item.heading)} */}
                                                                                    {item?.heading}
                                                                                    <small>
                                                                                        {item.sub_heading}
                                                                                    </small>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="matches-preferences d-flex align-items-center justify-content-center">
                                    <h4>4,819 matches based on your preferences</h4>
                                    <button className="btn-sm ms-3">Seacrh</button>
                                </div> */}
                        <button className="btn-sm" type="submit">
                            {/* <i className="ace-icon fa fa-check bigger-110 mx-1"></i> */}
                            Save
                        </button>
                    </div>
                </div>
            </form>
            <button className="d-none" data-bs-toggle="modal" data-bs-target="#save_search" ref={save_search_open}></button>
            <div className="modal common-modal xs-modal pure-modal" id="save_search" tabIndex={-1} aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-head">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" id='close_modal_search' aria-label="Close" />
                        </div>
                        <div className="modal-body">

                            <form onSubmit={saveSearchForm?.handleSubmit}>
                                <div className="form-group mb-3">
                                    <label className="form-label">Save Search</label>
                                    <input type="text" name="title" placeholder="E.g. my choice" onChange={saveSearchForm?.handleChange} className="form-control" value={saveSearchForm?.values?.title} />
                                    <CustomError name='title' form={saveSearchForm} />
                                </div>
                                <button className="btn-sm" type='submit'>Save and show messages</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserSearch