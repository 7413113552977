import React from "react";
import AvatarProfile from "../../../../assets/user/images/avtar-profile.webp";
import EditAddPartner from "../../../../assets/user/images/edit-add-partnerpref.webp";
import AddHoroscope from "../../../../assets/user/images/edit-add-horoscope.webp";
import AddAvatar from "../../../../assets/user/images/add-photo-edit-avatar.webp";
const EditProfile2 = () => {
  return (
    <div className="wraper-inner">
      <section className="profile-edit-add-hobbies ">
        <div className="add-hobbies-head">
          <div className="container">
            <ul className="add-hobbies-list d-flex">
              <li className="active">
                <a href="#hobbies-interests">Hobbies & Interests</a>
              </li>
              <li>
                <a href="#Music">Music</a>
              </li>
              <li>
                <a href="#Reading">Reading</a>
              </li>
              <li>
                <a href="#movie-tv">Movie and TV shows</a>
              </li>
              <li>
                <a href="#sports-fitness">Sports and Fitness</a>
              </li>
              <li>
                <a href="#food">Food</a>
              </li>
              <li>
                <a href="#spoken-languages">Spoken languages</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="container">
          <div className="add-hobbies-body similer-box-shadow mt-4">
            <div className="scroll-box-profile">
              <div id="hobbies-interests" className="mb-4">
                <h4 className="inner-title mb-4">
                  Choose your Hobbies & Interests
                </h4>
                <ul className="add-hobbies-content-list">
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                </ul>
              </div>
              <div id="Music" className="mb-4">
                <h4 className="inner-title mb-4">
                  Choose the music genres you listen to
                </h4>
                <ul className="add-hobbies-content-list">
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                </ul>
              </div>
              <div id="Reading" className="mb-4">
                <h4 className="inner-title mb-4">
                  Choose the type of books you read
                </h4>
                <ul className="add-hobbies-content-list">
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                </ul>
              </div>
              <div id="movie-tv" className="mb-4">
                <h4 className="inner-title mb-4">
                  Choose the type of movies you watch
                </h4>
                <ul className="add-hobbies-content-list ">
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                </ul>
              </div>
              <div id="sports-fitness" className="mb-4">
                <h4 className="inner-title mb-4">
                  Select Sports/ Fitness Activities/ Games
                </h4>
                <ul className="add-hobbies-content-list">
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                </ul>
              </div>
              <div id="food" className="mb-4">
                <h4 className="inner-title mb-4">Choose your favourite cuisine</h4>
                <ul className="add-hobbies-content-list">
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                </ul>
              </div>
              <div id="spoken-languages" className="mb-4">
                <h4 className="inner-title mb-4">Choose the languages you know</h4>
                <ul className="add-hobbies-content-list ">
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                  <li>
                    <a>Acting</a>
                  </li>
                  <li>
                    <a>Art / Handicraft</a>
                  </li>
                  <li>
                    <a>Bike / Car enthusiast</a>
                  </li>
                  <li>
                    <a>Board games</a>
                  </li>
                  <li>
                    <a>Clubbing</a>
                  </li>
                </ul>
              </div>
            </div>
            <hr />
            <div className="btn-group-box mt-3 text-end">
              <button className=" btn-sm">Submit</button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditProfile2;
