import Swal from 'sweetalert2';
import { SWAL_SETTINGS } from './Constants';

export const successResponse = (msg) => {
    return Swal.fire({
        text:msg,
        icon: 'success',
        ...SWAL_SETTINGS
      });
};


export const failedResponse = (msg) => {
    return Swal.fire({
        text:msg,
        icon: 'error',
        ...SWAL_SETTINGS
      });
};