import { createBrowserRouter, Navigate } from "react-router-dom";

/** USER ROUTE START */
import UserLayout from "./modules/user/shared/UserLayout";


/** USER ROUTE END */

import Error404User from "./modules/user/pages/user404";
import Home from './modules/user/pages/home';
import SignupStep1 from './modules/user/pages/signupStep1';
import SignupStep2 from './modules/user/pages/signupStep2';
import SignupStep3 from './modules/user/pages/signupStep3';
import SignupStep4 from './modules/user/pages/signupStep4';
import SignupStep5 from './modules/user/pages/signupStep5';
import MobileVerify from './modules/user/pages/verifyMobile';
import Matches from './modules/user/pages/matches';
import Chat from './modules/user/pages/chat';
import Dashboard from './modules/user/pages/dashboard';
import ContinueHome from './modules/user/pages/continue-my-home';
import EmailVerify from './modules/user/pages/emailVerify';
import Subscription from './modules/user/pages/subscription';
import Verification from './modules/user/pages/verification';
import VerificationDetail from './modules/user/pages/verificationDetail';
import EditProfile from './modules/user/pages/editDetails/edit-profile';
import FrontRoot from './modules/user/shared/frontroot';
import UserSearch from "./modules/user/pages/searchDetails/search";
import SearchFiles from "./modules/user/pages/searchDetails/searchFiles";
import SearchList from "./modules/user/pages/searchDetails/searchList";
import FileUploader from './modules/user/demos/file-uploader';


/** Admin Routes */

import AdminLayout from './modules/admin/shared/adminlayout';
import AdminLogin from './modules/admin/pages/login';
import AdminDashboard from './modules/admin/pages/AdminDashboard';
import AdminError404 from './modules/admin/pages/404';
import PrivateRoute from './modules/admin/shared/privateRoute';
import UsersList from './modules/admin/pages/userMgmt/list';
import CmsPagesList from './modules/admin/pages/cms/pages/list';
import AddCmsPage from './modules/admin/pages/cms/pages/add';
import EditCmsPage from './modules/admin/pages/cms/pages/edit';
import ResetPassword from './modules/admin/pages/resetPassword';
import PublicRoute from './modules/admin/shared/publicRoute';
import AdminForgotPassword from './modules/admin/pages/forgotPassword';
import UserPrivateRoute from './modules/user/shared/userprivateroute';
import UserPublicRoute from './modules/user/shared/userpublicroute';
import ProfilePreview from './modules/user/pages/profile-preview';
import EditProfile2 from './modules/user/pages/editDetails/edit-profile-2';
import EmailTemplatesList from './modules/admin/pages/cms/emailTemplates/list';
import AddEmailTemplatesPage from './modules/admin/pages/cms/emailTemplates/add';
import EditEmailTemplatesPage from './modules/admin/pages/cms/emailTemplates/edit';
import GlobalSettings from './modules/admin/pages/cms/settings/settings';
import PortalDesign from './modules/admin/pages/cms/portaldesign/poratldesign';
import Settings from './modules/user/pages/settings';
import EditHobbies from "./modules/user/pages/editDetails/edit-hobbies";
import EditPreference from "./modules/user/pages/editDetails/edit-prefence";
import ProfileDetails from "./modules/user/pages/searchDetails/searchViewDetails";
import ViewSearchList from "./modules/user/pages/searchDetails/searchView";
import ManageProfileImages from "./modules/user/pages/manage-profile-images";


const router = createBrowserRouter([
  /** USER ROUTE START */
  {
    // path: "/",
    // element: <FrontRoot />,
    // children: [
    //   {
        path: "/",
        element: <UserLayout />,

        children: [
          {
            path: "",
            // element: <Home />
            element: <UserPublicRoute component={<Home/>} />,
          },
          {
            path: "/signup-step-1",
            element: <UserPublicRoute component={<SignupStep1/>} />,
            // element: <SignupStep1 />
          },
          {
            path: "/signup-step-2",
            element: <UserPublicRoute component={<SignupStep2/>} />,
            // element: <SignupStep2 />
          },
          {
            path: "/signup-step-3",
            element: <UserPublicRoute component={<SignupStep3/>} />,
            // element: <SignupStep3 />
          },
          {
            path: "/signup-step-4",
            element: <UserPublicRoute component={<SignupStep4/>} />,
            // element: <SignupStep4 />
          },
          {
            path: "/signup-step-5",
            element: <UserPublicRoute component={<SignupStep5/>} />,
            // element: <SignupStep5 />
          },
          {
            path: "/mobile-verification",
            element: <UserPublicRoute component={<MobileVerify/>} />,
          },
          {
            path: "/dashboard",
            element: <UserPrivateRoute component={<Dashboard/>} />
          },
          {
            path: "search-files",
            element: <UserPrivateRoute component={<SearchFiles/>}/>,
            children: [
              {
                path: "search",
                element: <UserPrivateRoute component={<UserSearch/>}/>,
              },
              {
                path: "search-list",
                element: <UserPrivateRoute component={<SearchList/>}/>,
              },
              {
                path: "search-view",
                element: <UserPrivateRoute component={<ViewSearchList/>}/>,
              },
              // {
              //   path: "edit",
              //   element: <PrivateRoute component={EditLanguageFiles} route={'/admin/cms/language-files/edit'} />,
              // },
            ],
          },
          {
            path: "search-view/details/:id",
            element: <UserPrivateRoute component={<ProfileDetails />}/>,
          },
          {
            path: "/edit-profile-2",
            element: <UserPrivateRoute component={<EditProfile2/>} />
          },
          {
            path: "/file-upload",
            element: <FileUploader />
          },
          {
          // {
          //   path: "/my-profile",
          //   element: <UserPrivateRoute component={<ProfilePreview/>} />
          // },
            path: "/edit-profile",
            element:<ProfilePreview/>
            // element: <UserPrivateRoute component={<ProfilePreview/>} />
          },
          {
            path: "/manage-profile-images",
            element:<ManageProfileImages/>
          },
          {
            path: "/settings",
            element: <UserPrivateRoute component={<Settings/>} />
          },
          {
            path: "/edit-hobbies",
            element: <UserPrivateRoute component={<EditHobbies/>} />
          },
          {
            path: "/edit-preference",
            element: <UserPrivateRoute component={<EditPreference/>} />
          },
          { path: "*", element: <Error404User /> },
        ],
       },
  //     ],
  // },
  /** USER ROUTE END */

  {/* Admin section routes start */ },
  {
    path: "admin",
    element: <AdminLayout />,
    children: [
      {/**When url empty after admin then automatically navigate to dashboard */ },
      {
        path: "",
        element: <Navigate to={"/admin/dashboard"} />,
      },
      {
        path: "dashboard",
        element: <PrivateRoute component={<AdminDashboard />} />
      },

      {/**user management route start */ },
      {
        path: "user-management/users/list/:pgno",
        element: <PrivateRoute component={<UsersList />} />,
      },

      {/**cms route start */ },
      {
        path: 'cms/pages/list/:pgno',
        element: <PrivateRoute component={<CmsPagesList />} />
      },
      {
        path: 'cms/pages/add',
        element: <PrivateRoute component={<AddCmsPage />} />
      },
      {
        path:'cms/pages/edit/:id',
        element: <PrivateRoute component={<EditCmsPage/>}/>
      },

      {
        path: 'cms/email-templates/list/:pgno',
        element: <PrivateRoute component={<EmailTemplatesList/>} />
      },
      {
        path: 'cms/email-templates/add',
        element: <PrivateRoute component={<AddEmailTemplatesPage/>} />
      },
      {
        path:'cms/email-templates/edit/:id',
        element: <PrivateRoute component={<EditEmailTemplatesPage/>}/>
      },
      {
        path: "cms/settings",
        element: <PrivateRoute component={<GlobalSettings/>}/>,
      },
      {
        path: "cms/portal-design",
        element: <PrivateRoute component={<PortalDesign/>}/>,
      },

      {/**cms route end */ },
    ],
  },
  /* For those pages which out from layout */
  { path: "admin/login", element: <PublicRoute component={<AdminLogin/>} /> },
  { path: "admin/forget-password", element: <PublicRoute component={<AdminForgotPassword/>} /> },
  { path: "admin/reset-password", element: <PublicRoute component={<ResetPassword />}/> },
  /* For unassigned routes */
  { path: "admin/*", element: <AdminError404 /> },
  {/* Admin section routes end */ },
]);

export default router;