import React, { useState, useEffect } from "react";
import AvatarProfile from "../../../assets/user/images/avtar-profile.webp";
import AvatarMaleProfile from "../../../assets/user/images/male.png";
import EditAddPartner from "../../../assets/user/images/edit-add-partnerpref.webp";
import AddHoroscope from "../../../assets/user/images/edit-add-horoscope.webp";
import AddAvatar from "../../../assets/user/images/add-photo-edit-avatar.webp";
import EditPersonalInfo from "./editDetails/edit-personal-info";
import EditBasicDetail from "./editDetails/edit-basic-detail";
import EditReligionInfo from "./editDetails/edit-religion-info";
import EditLocationDetail from "./editDetails/edit-location";
import EditProfessionalInfo from "./editDetails/edit-professional-info";
import EditFamilyInfo from "./editDetails/edit-family-detail";
import EditAboutFamily from "./editDetails/edit-about-family";
import { Link } from "react-router-dom";
import { getProfile, getLocation, getReligionalInfo, getProfessionalInfo,getInterest } from "../services/user.service";
import { convertCmToFeetAndInches } from "../../../utils/commonfunction";

const ProfilePreview = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [userLocationInfo, setUserLocationInfo] = useState(null);
  const [userReligionInfo, setUserReligionInfo] = useState(null);
  const [userProfessionalInfo, setUserProfessionalInfo] = useState(null);
  const [userInterests, setUserInterests] = useState(null);
  async function fetchUserLocationData() {
      await getLocation()
        .then((response) => {
          if (response.success) {
            setUserLocationInfo(response.data ? response.data : null);
          }
        })
        .catch((err) => {
          console.log("error in fetching family info", err);
        });
  }

  async function fetchUserData() {
    await getProfile().then((response) => {
      if (response.success) {
        setUserInfo(response.data ? response.data : null);
      }
    })
    .catch((err) => {
      console.log("error in fetching family info", err);
    });
  }

  async function fetchUserReligionalData() {
    await getReligionalInfo().then((response) => {
      if (response.success) {
        setUserReligionInfo(response.data ? response.data : null);
      }
    })
    .catch((err) => {
      console.log("error in fetching family info", err);
    });
  }

  async function fetchUserProfessionalData() {
    await getProfessionalInfo().then((response) => {
      if (response.success) {
        setUserProfessionalInfo(response.data ? response.data:{});
      }
    })
    .catch((err) => {
      console.log("error in fetching family info", err);
    });
  }
  async function fetchUserInterests() {
    await getInterest().then((response) => {
      if (response.success) {
        setUserInterests(response.data ? response.data : null);
      }
    })
    .catch((err) => {
      console.log("error in fetching family info", err);
    });
  }
  useEffect(() => {
    fetchUserData();
    fetchUserLocationData();
    fetchUserReligionalData();
    fetchUserProfessionalData();
    fetchUserInterests();
    console.log(userInterests,"userInterests")
  }, []);

  const handleUserInfoChange = () => {
    fetchUserData();
  };

  const handleUserReligionInfoChange= () => {
    fetchUserReligionalData();
  };

  const handleUserLocationInfoChange=()=>{
    fetchUserLocationData();
  }
  const handleUserProfessionalInfoChange=()=>{
    fetchUserProfessionalData();
  }

  return (
    <div className="wraper-inner">
      <section className="profile-page padding-space">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="wraper-inner">
                {/* <section className="manage-photo padding-space">
                  <div className="container">
                    <div className="manage-photo-main">
                      <div className="manage-photo-head d-flex align-items-center justify-content-between">
                        <div className="manage-photo-head-left">
                          <ul className="manage-photo-list">
                            <li><a>Manage Photos</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="manage-photo-body p-4">
                        <div className="row row-cols-4 manage-photo-rows">
                          {userImages.length ? userImages.map((item, index) => {
                            return (
                              <div className="cols manage-photo-cols" key={index}>
                                <div className="manage-photo-frame">
                                  <figure><img src={item.picture_url} alt={item.picture_name} /></figure>
                                  <div className="dropdown">
                                    <button className="btn  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      <i className="ri-pencil-fill"></i>
                                    </button>
                                    <ul className="dropdown-menu">
                                      <li><button disabled={item.is_main || item.status !== 1} className="dropdown-item active" onClick={() => handleMakeMainPhoto(item.id)}>Main Photo</button></li>
                                      <li><button disabled={item.is_main} className="dropdown-item" type="button" onClick={() => handleDeletePhoto(item.id)}>Delete</button></li>
                                    </ul>
                                  </div>
                                </div>
                                {item.status === 0 ? <h4 className="pending">Pending approval</h4> : item.status === 1 ? <h4 className="accepted">Accepted</h4> : <h4>Rejected</h4>}
                              </div>
                            )
                          }) : ""}
                          <div className="cols manage-add-btn-cols">
                            <a className="add-more-btn" onClick={() => {
                              document.getElementById("upfile").click();
                            }}>
                              <i className="ri-add-circle-fill"></i>
                              <h4>Add More Photo</h4>
                              <input
                                type="file"
                                accept="image/*"
                                id="upfile"
                                onChange={handleUpload}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section> */}
              </div>
              <div className="profile-head-info d-flex justify-content-between mb-4">
                <div className="profile-head-info-left d-flex ">
                  <div className="profile-head-inner">
                      <figure>
                        <img src={userInfo && userInfo.picture_url ? userInfo.picture_url : userInfo?.gender=='female' ? AvatarProfile : AvatarMaleProfile} alt="User Profile" />
                      </figure>
                      <span><Link to={"/manage-profile-images"}> Add / Edit Photos</Link></span>
                  </div>
                  <figcaption>
                    <div className="profile-name mb-1">
                      <h3>{userInfo && userInfo.name ? userInfo.name : ""}</h3>
                      <span>
                        Profile created for{" "}
                        {userInfo && userInfo.profile_for === "myself"
                          ? "myself"
                          : userInfo && userInfo.profile_for}
                      </span>

                      {/* <h3>Pooja</h3>
                      <span>Profile created for Daughter</span> */}
                    </div>
                    <ul className="Profile-info-list">
                      <li>
                        {userInfo && userInfo.age} Yrs,{" "}
                        {convertCmToFeetAndInches(userInfo && userInfo.height)}
                      </li>
                      {/* <li>Hindu, Agarwal</li> */}
                      <li>
                        {userReligionInfo && userReligionInfo.religion},
                        {userReligionInfo && userReligionInfo.caste}
                      </li>
                      <li>
                        {userLocationInfo && userLocationInfo.city},
                        {userLocationInfo && userLocationInfo.state},{" "}
                        {userLocationInfo && userLocationInfo.country}
                      </li>
                      <li>{userProfessionalInfo && userProfessionalInfo.education}, {userProfessionalInfo && userProfessionalInfo.occupation}</li>
                    </ul>
                    <div className="edit-mobile-box d-flex align-items-center">
                      <span>
                        <i className="ri-cellphone-fill"></i>
                        {/* <b>+91-8290475917</b> */}
                        <b>
                          {userInfo && userInfo.phone_code
                            ? userInfo.phone_code
                            : ""}
                          -
                          {userInfo && userInfo.phone_number
                            ? userInfo.phone_number
                            : ""}
                        </b>
                      </span>
                      <span className="varify-text">
                        {userInfo && userInfo.is_mobile_verified ? (
                          <>
                            (<i className="ri-check-line"></i>
                            <b>
                              {userInfo && userInfo.is_mobile_verified
                                ? "Verified"
                                : ""}
                            </b>{" "}
                            ) &nbsp;
                          </>
                        ) : null}
                        <a className="text-link"> Edit Mobile No</a>
                      </span>
                    </div>
                  </figcaption>
                </div>
                {/* <div className="profile-head-info-right">
                        <h5>How your profile looks to others</h5>
                        <a href="profile-preview " className="btn-sm"><i className="ri-eye-fill"></i>Profile Preview</a>
                    </div> */}
              </div>
              {/* Section Removed For Now, might use it later */}
              {/* <div className="prospects-looks pb-5 mb-4">
                <h4>Photos are the first thing that prospects look at.</h4>
                <p>Add bride's photo and get 10 times more responses!</p>
                <a className="btn-sm btn-outline me-3">Skip</a>
                <a className="btn-sm">Upload Photos Now</a>
              </div> */}
              <div className="personal-info">
                <h2 className="personal-main-title">Personal Information</h2>
              </div>
              <EditPersonalInfo userInfo={userInfo} onUserInfoChange={handleUserInfoChange}/>
              <div className="personal-info-content border-sec mb-4">
              <EditBasicDetail userInfo={userInfo} onUserInfoChange={handleUserInfoChange}/>
              </div>
              <div className="personal-info-content border-sec">
                <EditReligionInfo userInfo={userInfo} userReligionInfo={userReligionInfo} onUserReligionInfoChange={handleUserReligionInfoChange}/>
              </div>
              <div className="personal-info-content border-sec">
                <EditLocationDetail userInfo={userInfo} userLocationInfo={userLocationInfo} onUserLocationInfoChange={handleUserLocationInfoChange}/>
              </div>
              <div className="personal-info-content border-sec">
                <EditProfessionalInfo userInfo={userInfo} userProfessionalInfo={userProfessionalInfo} onUserProfessionalInfoChange={handleUserProfessionalInfoChange}/>
              </div>
              <div className="personal-info-content border-sec">
                <EditFamilyInfo userInfo={userInfo}/>
              </div>
              <div className="personal-info-content border-sec">
                <EditAboutFamily />
              </div>
              <div className="personal-info-content border-sec">
                <div className="personal-info-head d-flex align-items-center justify-content-between mb-3">
                  <h3>Hobbies and Interests</h3>
                  <Link to="/edit-hobbies" className="btn-sm"> <i className="ri-pencil-fill"></i>Edit</Link>
                </div>
                  <div className="personal-info-body">
            <div className="personal-info-list-box">
              <ul className="personal-info-list hobbies ">
                <li>
                  <span>Hobbies & Interests</span>
                  <p>:{" "}{userInterests && userInterests.hobbie ? userInterests.hobbie.name.join(", ") : "Not Specified"}</p>
                </li>
                <li>
                  <span>Music</span>
                  <p>:{" "}{userInterests && userInterests.music ? userInterests.music.name.join(", ") : "Not Specified"}</p>
                </li>
                <li>
                  <span>Reading</span>
                  <p>:{" "}{userInterests && userInterests.reading ? userInterests.reading.name.join(", ") : "Not Specified"}</p>
                </li>
                <li>
                  <span>Movies and TV Shows</span>
                  <p>:{" "}{userInterests && userInterests.music ? userInterests.music.name.join(", ") : "Not Specified"}</p>
                </li>
                <li>
                  <span>Sports and Fitness</span>
                  <p>:{" "}{userInterests && userInterests['sports-fitness'] ? userInterests['sports-fitness'].name.join(", ") : "Not Specified"}</p>
                </li>
                <li>
                  <span>Food</span>
                  <p>:{" "}{userInterests && userInterests.food ? userInterests.food.name.join(", ") : "Not Specified"}</p>
                </li>
                <li>
                  <span>Spoken Languages</span>
                  <p>:{" "}{userInterests && userInterests['spoken-language'] ? userInterests['spoken-language'].name.join(", ") : "Not Specified"}</p>
                </li>
                
              </ul>
              </div>
              </div>
              </div>
              <div className="personal-info-content border-sec">
                <div className="personal-info-head d-flex align-items-center justify-content-between mb-3">
        <h3>Edit Your preferences to get relevant matches.</h3>
        <Link to="/edit-preference" className="btn-sm"> <i className="ri-pencil-fill"></i>Edit</Link>
      </div>
      <div className="personal-info-body"> <p>Not Specified </p>
    </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="personal-info-content border-sec add-info-box mb-4">
                <div className="add-info-box-inner d-flex">
                  <figure>
                    <img src={EditAddPartner} alt="" />
                  </figure>
                  <figcaption>
                    <h3>Add Partner Preferences</h3>
                    <span>To find your perfect match</span>
                  </figcaption>
                </div>
                <a className="add-ancor">
                  Add Partner Preferences{" "}
                  <i className="ri-arrow-right-s-fill"></i>
                </a>
              </div>
              <div className="personal-info-content border-sec add-info-box mb-4">
                <div className="add-info-box-inner d-flex">
                  <figure>
                    <img src={AddHoroscope} alt="" />
                  </figure>
                  <figcaption>
                    <h3>Add Horoscope</h3>
                    <span>It is simple and absolutely FREE!</span>
                  </figcaption>
                </div>
                <a className="add-ancor">
                  Add Horoscope <i className="ri-arrow-right-s-fill"></i>
                </a>
              </div>
              <div className="personal-info-content border-sec add-info-box mb-4">
                <div className="add-info-box-inner d-flex">
                  <figure>
                    <img src={AddAvatar} alt="" />
                  </figure>
                  <figcaption>
                    <h3>Add Photos</h3>
                    <span>
                      Photos are the first things members look for in a profile.
                    </span>
                  </figcaption>
                </div>
                <a className="add-ancor">
                  Add Photos Now <i className="ri-arrow-right-s-fill"></i>
                </a>
              </div>
              <div className="personal-info-content border-sec add-info-box mb-4">
                <div className="add-info-box-inner text-center">
                  <figcaption>
                    <h3>What matters to you</h3>
                    <h3>the most in a</h3>
                    <p className="color-text">
                      <span>happy</span>marriage <sup>TM</sup>
                    </p>
                  </figcaption>
                </div>
                <a className="add-ancor">
                  Add Now <i className="ri-arrow-right-s-fill"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProfilePreview;
