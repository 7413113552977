import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import logoImg from '../../../assets/user/images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import CustomError from '../../user/shared/CustomError';
// import * as authService from '../services/auth.services'
import { globalLoader, setLocalKey } from '../../../utils/commonfunction';
import Swal from 'sweetalert2';
import { AwsRegion, IdentityPoolId, superAdminPoolData, SWAL_SETTINGS } from '../../../utils/Constants';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import AWS from 'aws-sdk';
import { Tooltip, Whisper,Modal } from 'rsuite';

let userPool = new CognitoUserPool(superAdminPoolData);

const AdminLogin = () => {
    const navigate = useNavigate();
    const [pageLoaded, setPageLoaded] = useState(false);
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [amazonState, setAmazonState] = useState({});

    useEffect(() => {
        Promise.all([
        import('rsuite/dist/rsuite.min.css'),
        import('bootstrap/dist/css/bootstrap.min.css'),
        import("../../../assets/admin/css/skins.css"),
        import("../../../assets/admin/css/icon-list.css"),
        import("../../../assets/admin/plugins/select2/css/select2.min.css"),
        import("../../../assets/admin/css/style.css"),
        import("../../../assets/admin/css/dark-style.css"),
        import("../../../assets/admin/css/colors/default.css"),
        import("../../../assets/admin/plugins/multipleselect/multiple-select.css"),
        import("../../../assets/admin/css/sidemenu/sidemenu.css"),
        import("../../../assets/admin/plugins/summernote/summernote-bs4.css"),
        import("../../../assets/admin/plugins/fileuploads/css/fileupload.css"),
        import("../../../assets/admin/plugins/fancyuploder/fancy_fileupload.css")]).then(() => {
            setPageLoaded(true);
        })
    }, []);

    const loginValidations = Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    const modalValidate = Yup.object().shape({
        password: Yup.string().required("New password is required").min(8,"Minimum 8 digits").matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/,"Invalid Password"),
        confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required("Confirm new password is required").min(8,"Minimum 8 digits").matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/,"Invalid Password"),
    })

    let modalForm = useFormik({
        initialValues:{
            password: '',
            confirm_password: ''
        },
        validationSchema:modalValidate,
        onSubmit:(values) => {

            globalLoader(true);

            const cognitoUser = amazonState.user;
            const userAttr = amazonState.userAttributes;

            cognitoUser.completeNewPasswordChallenge(values.password, {}, {
                onSuccess: result => {
                    setOpen(false);
                    console.log("result",result);
                    globalLoader(false);
                },

                mfaRequired: function(codeDeliveryDetails) {
                    // MFA is required to complete user authentication.
                    // Get the code from user and call
                    Swal.fire({
                        title: "Please enter otp",
                        input: "text",
                        inputLabel: "Your otp ?",
                        showCancelButton: true,
                        inputValidator: (value) => {
                          if (!value) {
                            return "Otp is required!";
                          }
                        }
                    }).then((value) => {
                        cognitoUser.sendMFACode(value, this);
                    })
                },

                onFailure: error => {
                    console.log("error", error);
                    globalLoader(false);
                    Swal.fire({
                        icon: 'error',
                        text: error.message,
                        ...SWAL_SETTINGS
                    })
                }
            })
        }
    })

    const loginForm = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: loginValidations,
        onSubmit: async values => {

            globalLoader(true);

            let userData = {
                Username: values?.email,
                Pool: userPool
            };
        
            let cognitoToUser = new CognitoUser(userData);
        
            let authenticationData = {
                Username: values?.email,
                Password: values?.password
            }
        
            let authenticationDetails = new AuthenticationDetails(authenticationData);
        
            await cognitoToUser.authenticateUser(authenticationDetails, {
                onSuccess: (result) => {
                    let accessToken  = result.getAccessToken().getJwtToken();
        
                    AWS.config.region = AwsRegion;
        
                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: process.env.REACT_APP_ADMIN_IDENTITY_POOL_ID,
                        Logins : {
                            [`cognito-idp.${AwsRegion}.amazonaws.com/${superAdminPoolData.UserPoolId}`] : result.getIdToken().getJwtToken()
                        }
                    })
        
                    AWS.config.credentials.refresh(error => {
                        if(error){
                            globalLoader(false);
                            Swal.fire({
                                icon: 'error',
                                text: error.message,
                                ...SWAL_SETTINGS
                            })
                        }else{
                            console.log("LoggedIn Successfully");
                            setLocalKey("admin_aws_auth", JSON.stringify(result?.accessToken));
                            Swal.fire({
                                icon: 'success',
                                text: "LoggedIn Successfully",
                                ...SWAL_SETTINGS
                            })
                            navigate(`/admin/dashboard`);
                            globalLoader(false);
                        }
                    })
                },

                mfaRequired: function(codeDeliveryDetails) {
                    // MFA is required to complete user authentication.
                    // Get the code from user and call
                    Swal.fire({
                        title: "Please enter otp",
                        input: "text",
                        inputLabel: "Your otp ?",
                        showCancelButton: true,
                        inputValidator: (value) => {
                          if (!value) {
                            return "Otp is required!";
                          }
                        }
                    }).then((value) => {
                        console.log("value",value);
                        cognitoToUser.sendMFACode(value.value, this);
                    })
                },
        
                onFailure: (error) => {
                    console.log("Error",error);
                    globalLoader(false);
                    Swal.fire({
                        icon: 'error',
                        text: error.message,
                        ...SWAL_SETTINGS
                    })
                },
                
                newPasswordRequired: function(userAttributes, requiredAttributes) {

                    setAmazonState({
                        isFirstLogin:true,
                        user:cognitoToUser,
                        userAttributes:userAttributes
                    });

                    setOpen(true);
                    console.log("Done ==>>>")
                    // User was signed up by an admin and must provide new
                    // password and required attributes, if any, to complete
                    // authentication.
    
                    // the api doesn't accept this field back
                    // delete userAttributes.email_verified;
    
                    // store userAttributes on global variable
                    // sessionUserAttributes = userAttributes;
                }
            });

            // let formData = new FormData();
            // formData.append("username", values.email);
            // formData.append("password", values.password);
            // authService.login(formData).then(response => {
            //     if (response.success) {
            //         setLocalKey("admin", JSON.stringify(response.data))
            //         Swal.fire({
            //             icon: 'success',
            //             text: response.message,
            //             ...SWAL_SETTINGS
            //         })
            //         navigate(`/admin/dashboard`)
            //     } else {
            //         Swal.fire({ 
            //             icon: 'error',
            //             text: handleServerValidations(response),
            //             ...SWAL_SETTINGS
            //         })
            //     }
            // }).catch(error => {
            //     console.log("error: ", error);
            // })
        },
    });

    return (
        <>
            {pageLoaded?
                    <div className='page main-signin-wrapper'>
                        <div className='innerbody'>
                            <div className="row signpages text-center">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="row row-sm">
                                            <div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
                                                <div className="mt-5 pt-4 p-2 pos-absolute ">
                                                    <img src={logoImg} className="ht-120 mb-4 admin-logo" alt="logo" style={{height:"65px"}}/>
                                                    <div className="clearfix"></div>
                                                    <h5 className="mt-4 text-white">Login Your Account</h5>
                                                    <span className="tx-white-6 tx-13 mb-5 mt-xl-0">Signup to create, discover and connect with the global community</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
                                                <div className="container-fluid">
                                                    <div className="row row-sm">
                                                        <div className="card-body mt-2 mb-2">
                                                            <div className="clearfix"></div>
                                                            <form onSubmit={loginForm.handleSubmit}>
                                                                <h5 className="text-left mb-2">Signin to Your Account</h5>
                                                                <p className="mb-4 text-muted tx-13 ml-0 text-left">Signin to create, discover and connect with the global community</p>
                                                                <div className="form-group text-left">
                                                                    <label>Email</label>
                                                                    <input className={"form-control"}
                                                                        placeholder="Enter your email"
                                                                        type="text"
                                                                        name="email"
                                                                        autoComplete="off" 
                                                                        value={loginForm.values.email}
                                                                        onBlur={loginForm.handleBlur}
                                                                        onChange={loginForm.handleChange}
                                                                    />
                                                                    <CustomError className="text-danger" name='email' form={loginForm} />
                                                                </div>
                                                                <div className="form-group text-left">
                                                                    <label>Password</label>
                                                                    <div className="input-group">
                                                                        <input className={"form-control" }
                                                                            placeholder="Enter your password"
                                                                            type={showPassword ? "text" :"password"}
                                                                            name="password"
                                                                            onChange={loginForm.handleChange}
                                                                            onBlur={loginForm.handleBlur}
                                                                            value={loginForm.values.password}
                                                                            autoComplete="off" />
                                                                        <span className='input-group-text cp' onClick={() => setShowPassword(!showPassword)}>{(!showPassword === true ? <i className="fa fa-eye-slash" aria-hidden="true"></i> : <i className="fa fa-eye" aria-hidden="true"></i>)}</span>
                                                                    </div>
                                                                    <CustomError className="text-danger" name='password' form={loginForm} />
                                                                    {/* <span className='text-danger text-left d-block err-spn'>{errors.password && touched.password && errors.password}</span> */}
                                                                </div>
                                                                <button className={"btn ripple btn-main-primary btn-block signbtn"} type="submit">
                                                                    Sign In
                                                                </button>
                                                            </form>
                                                            <div className="text-left mt-5 ml-0">
                                                                <div className="mb-1"><Link to="/admin/forget-password">Forgot password?</Link></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal backdrop={'static'} open={open} onClose={() => setOpen(false)}>
                            <Modal.Header>
                            <Modal.Title>Reset password</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <form onSubmit={modalForm.handleSubmit}> 
                                    <div className="form-group text-left">
                                        <label>New Password 
                                            <Whisper placement="top" controlId="control-id-hover" trigger="hover" 
                                            speaker={<Tooltip><ol className='ps-3 pt-2'>
                                                <li>Contains at least 1 number</li>
                                                <li>Contains at least 1 special character</li>
                                                <li>Contains at least 1 uppercase letter</li>
                                                <li>Contains at least 1 lowercase letter</li>
                                                <li>Minimum 8 characters</li>
                                                </ol></Tooltip>}>
                                                <span className='field-more-info mt-1 ms-1 cp'>(Password requirements)</span>
                                            </Whisper></label>
                                        <div className="input-group">
                                            <input className={"form-control" }
                                                placeholder="Enter your new password"
                                                type={show ? "text" : "password"}
                                                name="password"
                                                onChange={modalForm.handleChange}
                                                onBlur={modalForm.handleBlur}
                                                value={modalForm.values.password}
                                                autoComplete="off" />
                                            <span className='input-group-text cp' onClick={() => setShow(!show)}>{(!show === true ? <i className="fa fa-eye-slash" aria-hidden="true"></i> : <i className="fa fa-eye" aria-hidden="true"></i>)}</span>
                                        </div>
                                        <CustomError className="text-danger" name='password' form={modalForm} />
                                    </div>
                                    <div className="form-group text-left">
                                        <label>Confirm New Password</label>
                                        <div className="input-group">
                                            <input className={"form-control" }
                                                placeholder="Enter your confirm new password"
                                                type={show ? "text" : "password"}
                                                name="confirm_password"
                                                onChange={modalForm.handleChange}
                                                onBlur={modalForm.handleBlur}
                                                value={modalForm.values.confirm_password}
                                                autoComplete="off" />
                                            <span className='input-group-text cp' onClick={() => setShow(!show)}>{(!show === true ? <i className="fa fa-eye-slash" aria-hidden="true"></i> : <i className="fa fa-eye" aria-hidden="true"></i>)}</span>
                                        </div>
                                        <CustomError className="text-danger" name='confirm_password' form={modalForm} />
                                    </div>
                                    <button className={"btn ripple btn-main-primary btn-block signbtn"} type="submit">
                                        Continue
                                    </button>
                                </form>
                            </Modal.Body>
                        </Modal>
                    </div>
                :''
        }
        </>
    )
}
export default AdminLogin;