import React, { useEffect, useState } from "react";
import { useFormik } from 'formik';
import AppBreadCrumbs from "../../../common/breadcrumbs";
import GlobalLoader from "../../../shared/globalLoader";
import CustomCkEditor from "../../../common/customCkEditor";
import { GlobalSettingAdd, GlobalSettingDetails } from "../../../services/globalsetting.services";
import Swal from "sweetalert2";
import { SWAL_SETTINGS } from "../../../../../utils/Constants";
import { handleServerValidations } from "../../../../../utils/commonfunction";
import CustomError from "../../../../user/shared/CustomError";
import * as Yup from 'yup';

function GlobalSettings() {

    const [showdefault, setShowDefault] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        GlobalSettingDetails()
            .then((response) => {
                setShowDefault(response && response.data ? response.data : []);
                setTimeout(() => {
                    setDataLoaded(true);
                }, 100)
            })
            .catch((error) => {
                console.log("error=====>", error);

            });
    }, []);

    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Global Settings", url: "" }];

    const globalSettingFormValidation = Yup.object().shape({
        Address: Yup.string().required("Address is required"),
        username: Yup.string().required("Username is required").test("no-spaces", "First name is required", (value) => value.trim()).matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed in the username"),
        AdminEmail: Yup.string().required("Email is required").email('Invalid email format'),
        TermsAndConditionOne: Yup.string().required("Terms and condition text is required"),
        TermsAndConditionTwo: Yup.string().required("Terms and condition text is required"),
        richtext: Yup.string().required("Login banner page text is required"),
        CopyRighttxt: Yup.string().required("Copy right text is required"),
        footer_text: Yup.string().required("Footer text is required"),
        Contact: Yup.string()
            .trim()
            .required("Contact number is required")
            .test(
                "min-length",
                "Contact number minimum length should be 4 digits",
                (value) => {
                    if (value?.trim()?.length < 5) {
                        return false;
                    } else {
                        return true;
                    }
                }
            )
            .test(
                "max-length",
                "Contact number cannot be more than 10 digits",
                (value) => {
                    if (value?.trim()?.length > 10) {
                        return false;
                    } else {
                        return true;
                    }
                }
            ),
    });

    const globalSettingForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            username: showdefault && showdefault.username ? showdefault.username : "",
            AdminEmail: showdefault && showdefault.admin_email ? showdefault.admin_email : "",
            Contact: showdefault && showdefault.contact_no ? showdefault.contact_no : "",
            Address: showdefault && showdefault.address ? showdefault.address : "",
            CopyRighttxt: showdefault && showdefault.copy_right_text ? showdefault.copy_right_text : "",
            TermsAndConditionOne: showdefault && showdefault.terms_and_condition_one ? showdefault.terms_and_condition_one : "",
            TermsAndConditionTwo: showdefault && showdefault.terms_and_condition_two ? showdefault.terms_and_condition_two : "",
            richtext: showdefault && showdefault.login_content ? showdefault.login_content : "",
            footer_text: showdefault && showdefault.footer_text ? showdefault.footer_text : "",
        },
        validationSchema: globalSettingFormValidation,
        onSubmit: (values) => {
            let formData = new FormData();
            formData.append("username", values.username);
            formData.append("admin_email", values.AdminEmail);
            formData.append("contact_no", values.Contact);
            formData.append("address", values.Address);
            formData.append("copy_right_text", values.CopyRighttxt);
            formData.append("terms_and_condition_one", values.TermsAndConditionOne);
            formData.append("terms_and_condition_two", values.TermsAndConditionTwo);
            formData.append("login_content", values.richtext);
            formData.append("footer_text", values.footer_text);
            GlobalSettingAdd(formData)
                .then((response) => {
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                    }
                })
                .catch((error) => {
                    console.log("error ====> ", error);
                });
        }
    })

    return (
        <>
            <AppBreadCrumbs data={breadcrumbs} />
            {dataLoaded ? (
                <form onSubmit={globalSettingForm.handleSubmit}>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-md-12 animation_fade">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div>
                                        <h6 className="main-content-label mb-3">
                                            Global Settings
                                        </h6>
                                    </div>
                                    <div className="row row-sm">
                                        <div className="col-md-12 form-group">
                                            <label
                                                htmlFor="username"
                                                className="text-left d-flex"
                                            >
                                                Username:<span className="requirestar">*</span>
                                            </label>
                                            <input
                                                name="username"
                                                type="text"
                                                id="username"
                                                onChange={globalSettingForm.handleChange}
                                                onBlur={globalSettingForm.handleBlur}
                                                value={globalSettingForm?.values?.username}
                                                className="form-control"
                                            />
                                            <CustomError className={'text-danger'} name='username' form={globalSettingForm} />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label
                                                htmlFor="AdminEmail"
                                                className="text-left d-flex"
                                            >
                                                Admin Email:<span className="requirestar">*</span>
                                            </label>
                                            <input
                                                name="AdminEmail"
                                                type="text"
                                                id="AdminEmail"
                                                onChange={globalSettingForm.handleChange}
                                                onBlur={globalSettingForm.handleBlur}
                                                value={globalSettingForm?.values?.AdminEmail}
                                                className="form-control"
                                            />
                                            <CustomError className={'text-danger'} name='AdminEmail' form={globalSettingForm} />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label
                                                htmlFor="Contact"
                                                className="text-left d-flex"
                                            >
                                                Contact:<span className="requirestar">*</span>
                                            </label>
                                            <input
                                                name="Contact"
                                                type="number"
                                                onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                id="Contact"
                                                onChange={globalSettingForm.handleChange}
                                                onBlur={globalSettingForm.handleBlur}
                                                value={globalSettingForm?.values?.Contact}
                                                className="form-control"
                                            />
                                            <CustomError className={'text-danger'} name='Contact' form={globalSettingForm} />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label htmlFor="Address" className="text-left d-flex">
                                                Address:<span className="requirestar">*</span>{" "}
                                            </label>
                                            <textarea
                                                name="Address"
                                                id="Address"
                                                type="text"
                                                style={{ minHeight: "100px" }}
                                                onChange={globalSettingForm.handleChange}
                                                onBlur={globalSettingForm.handleBlur}
                                                value={globalSettingForm?.values?.Address}
                                                className="form-control"
                                            />
                                            <CustomError className={'text-danger'} name='Address' form={globalSettingForm} />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label
                                                htmlFor="CopyRighttxt"
                                                className="text-left d-flex"
                                            >
                                                Copy Right Text:<span className="requirestar">*</span>{" "}
                                            </label>
                                            <input
                                                name="CopyRighttxt"
                                                type="text"
                                                id="CopyRighttxt"
                                                onChange={globalSettingForm.handleChange}
                                                onBlur={globalSettingForm.handleBlur}
                                                value={globalSettingForm?.values?.CopyRighttxt}
                                                className="form-control"
                                            />
                                            <CustomError className={'text-danger'} name='CopyRighttxt' form={globalSettingForm} />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label
                                                htmlFor="TermsAndConditionOne"
                                                className="text-left d-flex"
                                            >
                                                Terms and Condition One:<span className="requirestar">*</span>{" "}
                                            </label>
                                            {dataLoaded ? (
                                                <CustomCkEditor
                                                    fieldname={"TermsAndConditionOne"}
                                                    setFieldValue={globalSettingForm.setFieldValue}
                                                    value={globalSettingForm?.values?.TermsAndConditionOne}
                                                    setFieldTouched={globalSettingForm.setFieldTouched}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                            <CustomError className={'text-danger'} name='TermsAndConditionOne' form={globalSettingForm} />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label
                                                htmlFor="TermsAndConditionTwo"
                                                className="text-left d-flex"
                                            >
                                                Terms And Condition Two:<span className="requirestar">*</span>{" "}
                                            </label>
                                            {dataLoaded ? (
                                                <CustomCkEditor
                                                    fieldname={"TermsAndConditionTwo"}
                                                    setFieldValue={globalSettingForm.setFieldValue}
                                                    value={globalSettingForm?.values?.TermsAndConditionTwo}
                                                    setFieldTouched={globalSettingForm.setFieldTouched}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                            <CustomError className={'text-danger'} name='TermsAndConditionTwo' form={globalSettingForm} />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label
                                                htmlFor="richtext"
                                                className="text-left d-flex"
                                            >
                                                Login Page Content:<span className="requirestar">*</span>{" "}
                                            </label>
                                            {dataLoaded ? (
                                                <CustomCkEditor
                                                    fieldname={"richtext"}
                                                    setFieldValue={globalSettingForm.setFieldValue}
                                                    value={globalSettingForm?.values?.richtext}
                                                    setFieldTouched={globalSettingForm.setFieldTouched}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                            <CustomError className={'text-danger'} name='richtext' form={globalSettingForm} />
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <label
                                                    htmlFor="footer_text"
                                                    className="text-left d-flex"
                                                >
                                                    Footer Text:<span className="requirestar">*</span>{" "}
                                                </label>
                                                <input
                                                    name="footer_text"
                                                    type="text"
                                                    id="footer_text"
                                                    onChange={globalSettingForm.handleChange}
                                                    onBlur={globalSettingForm.handleBlur}
                                                    value={globalSettingForm?.values?.footer_text}
                                                    className="form-control"
                                                />
                                                <CustomError className={'text-danger'} name='footer_text' form={globalSettingForm} />
                                            </div>
                                        <div className="">
                                            <button className="btn btn-main-primary signbtn mr-2" type="submit">
                                                <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            ) : (
                <GlobalLoader height={'10vh'} background={'#fff'} textColor={'#504fb3'} />
            )}
        </>
    )
}

export default GlobalSettings;