import React, { useEffect } from 'react'

export default function GlobalLoaderTwo(props) {

    useEffect(() => {
        let dot1 = document.querySelector(".loader-dot.dot1");
        let dot2 = document.querySelector(".loader-dot.dot2");
        let dot3 = document.querySelector(".loader-dot.dot3");
        function initLoader(){
            setTimeout(() => {
                dot1.style.opacity = "1";
                setTimeout(() => {
                    dot2.style.opacity = "1";
                    setTimeout(() => {
                        dot3.style.opacity = "1";
                        setTimeout(() => {
                            dot1.style.opacity = "0";
                            dot2.style.opacity = "0";
                            dot3.style.opacity = "0";
                        }, 500);
                    }, 500);
                }, 500);
            }, 500);
        }
        initLoader();
        setInterval(initLoader, 2000);
    },[])

    return (
        <>
            <div id={props.id ? props.id : ""} className="loader-container loaders" style={{height:props.height, background:props.background ? props.background : "#000000b8"}}>
                <span className="loader-text" style={{color:props.textColor}}>
                    L
                    <div className="o-animation-container">
                    <div className="o-animation" style={{borderColor:props.textColor}}/>
                    </div>
                    ading
                    <span className="loader-dot dot1" style={{backgroundColor:props.textColor}}/>
                    <span className="loader-dot dot2" style={{backgroundColor:props.textColor}}/>
                    <span className="loader-dot dot3" style={{backgroundColor:props.textColor}}/>
                </span>
            </div>
        </>
    )
}
