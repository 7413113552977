import React, { useEffect, useState } from 'react';
import trustedImg from '../../../assets/user/images/trusted-img-5.webp';
import { getSessionKey, prepareLabel, setSessionKey } from '../../../utils/commonfunction';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CustomError from '../shared/CustomError';
import { useNavigate } from 'react-router-dom';
import * as globalServices from '../services/global.service'; 
import { ANCESTRAL_ORIGIN_OPTIONS, ANNUAL_INCOME_OPTIONS, CURRENCIES_OPTIONS, EMPLOYED_IN_OPTIONS, HIGHEST_EDUCATION_OPTIONS, OCCUPATION_OPTIONS } from '../../../utils/Constants';

export default function SignupStep4() {
    const navigate = useNavigate();
    const [educations, setEducations] = useState([]);
    const [occupations, setOccupations] = useState([]);
    const [ancestralOptions, setAncestralOptions] = useState([]);

    useEffect(() => {
        document.querySelector('body').classList.add('bg-lignt-grey-shade');
        return () => {
            document.querySelector('body').classList.remove('bg-lignt-grey-shade');
        };
    }, []);
    useEffect(() => {
        /**Checking that user is coming from prev step */
        const prevStep = getSessionKey('REGISTRATION_COMPLETED_STEPS');
        if (prevStep && prevStep === "3") {
            /** Everything is fine */
        } else {
            /**Go back to initial step */
            navigate('/');
        }
    }, [navigate]);
    async function getEducationsData() {
        globalServices.getEducations()
          .then((response) => {
            if (response.success) {
              setEducations(response.data);
            }
          })
          .catch((err) => {
            console.log("Server error ", err);
          });
      }
      async function getOccupationsData(id = 1) {
        globalServices.getOccupations({ state_id: id })
          .then((response) => {
            if (response.success) {
              setOccupations(response.data);
            }
          })
          .catch((err) => {
            console.log("Server error ", err);
          });
      }
    
    useEffect(() => {
        globalServices.getQueries({type:'ancestral-origin'}).then((response) => {
            if(response?.success){
                setAncestralOptions(response?.data);
            }
        }).catch((error) => {
            console.log("error",error);
        });
        getEducationsData();
        getOccupationsData();
    },[])

    const signupValidations = Yup.object().shape({
        highestEducation: Yup.string().required('Highest education is required'),
        employedIn: Yup.string().required('Employed-in is required'),
        occupation: Yup.string().required('Occupation is required'),
        annualIncome: Yup.string().required('Annual income is required'),
        ancestralOrigin: Yup.string().required('Ancestral origin is required'),
    });
    const signupForm = useFormik({
        initialValues: {
            highestEducation: '',
            employedIn: '',
            occupation: '',
            incomeCurrency: CURRENCIES_OPTIONS[0].value,
            annualIncome: '',
            ancestralOrigin: '',
        },
        validationSchema: signupValidations,
        onSubmit: values => {
            try {
                /**Retrieving previous step's data */
                const prevStepData = JSON.parse(getSessionKey('REGISTRATION_FORM_DATA'));
                const allData = { ...prevStepData, ...values };
                /**Saving data to localStorage */
                setSessionKey('REGISTRATION_COMPLETED_STEPS', '4');
                setSessionKey('REGISTRATION_FORM_DATA', JSON.stringify(allData));
                /**Sending to next step */
                navigate('/signup-step-5');
            } catch (err) {
                console.log("Error", err);
            }
        },
    });
    let prevStepData = JSON.parse(getSessionKey('REGISTRATION_FORM_DATA'));

    return (
        <div className="wraper-inner">
            <section className="accounts-page padding-space">
                <div className="container">
                    <span className="tag-line">
                        Great! You have completed <b>80%</b>
                    </span>
                    <div className="signup-box-main">
                        <div className="row">
                            <div className="col-md-4 pe-0">
                                <div className="signup-box-l">
                                    <figure>
                                        <img src={trustedImg} alt="trusted-img-4.webp" />
                                    </figure>
                                    <figcaption>
                                        <p>
                                            The world's largest and No.1 matrimony service for Indians.
                                        </p>
                                    </figcaption>
                                </div>
                            </div>
                            <div className="col-md-8 ps-0">
                                <div className="signup-box-r">
                                    <h3>
                                        Professional details help {prevStepData?.profileFor == "myself" ? "your" : "your "+prevStepData?.profileFor+"'s"} get relevant matches
                                    </h3>
                                    <div className="singup-form">
                                        <form onSubmit={signupForm.handleSubmit}>
                                            <div className="form-group mb-3">
                                                <div className="inline-field ">
                                                    <label className="form-label">Highest Education</label>
                                                    <div className='w-100'>
                                                        <select className="form-select b-border-input w-100"
                                                            name='highestEducation' onChange={signupForm.handleChange} value={signupForm?.values?.highestEducation}>
                                                            <option value={''}>Select</option>
                                                            {educations.map((m) => (
                                                            <option key={m.id} value={m.id}>
                                                                {m.name}
                                                            </option>
                                                            ))}
                                                            {/* {
                                                                HIGHEST_EDUCATION_OPTIONS.map((item, i) => {
                                                                    return (<option key={i} value={item?.value}>{prepareLabel(item.label)}</option>)
                                                                })
                                                            } */}
                                                        </select>
                                                        <CustomError name='highestEducation' form={signupForm} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 ">
                                                <div className="inline-field">
                                                    <label className="form-label">Employed in</label>
                                                    <div>
                                                        <div className="cutom-checked d-flex">
                                                            {
                                                                EMPLOYED_IN_OPTIONS.map((item, i) => {
                                                                    return (<div className="form-check" key={i}>
                                                                        <input
                                                                            className="form-check-input cp"
                                                                            type="radio"
                                                                            name={'employedIn'} onChange={signupForm.handleChange}
                                                                            value={item?.value}
                                                                            id={"employedIn" + i}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor={"employedIn" + i}
                                                                        >
                                                                            {item?.label}
                                                                        </label>
                                                                    </div>)
                                                                })
                                                            }
                                                        </div>
                                                        <CustomError name='employedIn' form={signupForm} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="inline-field ">
                                                    <label className="form-label">Occupation</label>
                                                    <div className='w-100'>
                                                        <select className="form-select b-border-input w-100"
                                                        name='occupation' onChange={signupForm.handleChange} value={signupForm?.values?.occupation}>
                                                            <option value={''}>Select</option>
                                                            {/* {
                                                                OCCUPATION_OPTIONS.map((item, i) => {
                                                                    return (<option key={i} value={item?.value}>{prepareLabel(item.label)}</option>)
                                                                })
                                                            } */}
                                                            {occupations.map((m) => (
                                                            <option key={m.id} value={m.id}>
                                                                {m.name}
                                                            </option>
                                                            ))}
                                                        </select>
                                                        <CustomError name='occupation' form={signupForm} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="inline-field ">
                                                    <label className="form-label">Annual Income</label>
                                                    <div className="d-flex combine-field">
                                                        <select style={{minWidth:'77px'}} className="form-select b-border-input select-min "
                                                         name='incomeCurrency' onChange={signupForm.handleChange} value={signupForm?.values?.incomeCurrency}>
                                                            {
                                                                CURRENCIES_OPTIONS.map((item, i) => {
                                                                    return (<option key={i} value={item?.value}>{prepareLabel(item.label)}</option>)
                                                                })
                                                            }
                                                        </select>
                                                        <div className='w-100'>
                                                            <select className="form-select b-border-input select-max w-100"
                                                                name='annualIncome' onChange={signupForm.handleChange} value={signupForm?.values?.annualIncome}>
                                                                <option value={''}>Select</option>
                                                                {
                                                                    ANNUAL_INCOME_OPTIONS.map((item, i) => {
                                                                        return (<option key={i} value={item?.value}>{prepareLabel(item.label)}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                            <CustomError name='annualIncome' form={signupForm} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="inline-field ">
                                                    <label className="form-label">Ancestral Origin</label>
                                                    <div className='w-100'>
                                                        <select className="form-select b-border-input w-100"
                                                            name='ancestralOrigin' onChange={signupForm.handleChange} value={signupForm?.values?.ancestralOrigin}>
                                                            <option value={''}>Select</option>
                                                            {
                                                                ancestralOptions && ancestralOptions.map((item, i) => {
                                                                    return (<option key={i} value={item?.id}>{prepareLabel(item?.name)}</option>)
                                                                })
                                                            }
                                                        </select>
                                                        <CustomError name='ancestralOrigin' form={signupForm} />
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn btn-primary min-w mt-4" type='submit'>
                                                Continue
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-text text-center mt-4 mb-4">
                        <p className="">Copyright © 2023. All rights reserved.</p>
                    </div>
                </div>
            </section>
        </div>
    )
}