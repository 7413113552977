import React, { useState, useEffect } from 'react';
import bannerImg from '../../../assets/user/images/banner-img.jpg';
import blocksImg from '../../../assets/user/images/blocks-img-1.svg';
import blocks2Img from '../../../assets/user/images/blocks-img-2.svg';
import blocks3Img from '../../../assets/user/images/blocks-img-3.svg';
import limcaImg from '../../../assets/user/images/limca-records-img-new.png';
import recordImg from '../../../assets/user/images/records-corner-loading.svg';
import registerFreeImg from '../../../assets/user/images/register-free-icon.svg';
import { PROFILE_FOR_OPTIONS } from '../../../utils/Constants';
import { capitalizeFirstLetter, setSessionKey } from '../../../utils/commonfunction';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CustomError from '../shared/CustomError';
import { useNavigate } from 'react-router-dom';
export default function Home() {
  const navigate = useNavigate();
  const [msgPhoneRegsitered, setMsgPhoneRegsitered] = useState({ msg: null, status: null });
  const [disableGender, setDisableGender] = useState(false);
  const signupValidations = Yup.object().shape({
    profileFor: Yup.string().required('Profile is required'),
    gender: Yup.string().required('Gender is required'),
    name: Yup.string().trim().required('Name is required')
  });


  const signupForm = useFormik({
    initialValues: {
      profileFor: '',
      gender: '',
      name: ''
    },
    validationSchema: signupValidations,
    onSubmit: async values => {
      try {
        /**Saving data to session */
        setSessionKey('REGISTRATION_COMPLETED_STEPS', '0');
        setSessionKey('REGISTRATION_FORM_DATA', JSON.stringify(values));
        /**Sending to next step */
        navigate('/signup-step-1');
      } catch (err) {
        console.log(err)
      }
    }
  });
  function handleGenderSelection(e){
    const value = e.target.value;
    if (value) { 
      const maleOptions = [PROFILE_FOR_OPTIONS.BROTHER, PROFILE_FOR_OPTIONS.SON];
      const femaleOptions = [PROFILE_FOR_OPTIONS.DAUGHTER, PROFILE_FOR_OPTIONS.SISTER];
      if(maleOptions.indexOf(value) >= 0){
        signupForm.setFieldValue('gender','male');
        setDisableGender(true);
      }else if(femaleOptions.indexOf(value) >= 0){
        signupForm.setFieldValue('gender','female');
        setDisableGender(true);
      }else{
        signupForm.setFieldValue('gender','');
        setDisableGender(false);
      }
    }else{
      signupForm.setFieldValue('gender', '') ;
    }
  }
  return (
    <div className="wraper-inner cpt">
      {/*-banner-section---*/}
      <section className="banner-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner-title-head">
                <h1>
                  The biggest and most trusted <br /> matrimony service for Indians!
                </h1>
                <p>Now find matches based on your hobbies &amp; interests</p>
              </div>
            </div>
            <div className="col-md-5 ms-auto">
              <div className="banner-form">
                <div className="banner-form-head">
                  <h2>Create a Matrimony Profile</h2>
                  <div className="banner-form-body">
                    <h3>Find your perfect match</h3>
                    <form onSubmit={signupForm.handleSubmit}>
                      <div className="form-group mb-4">
                        <select className="form-select" name='profileFor' onChange={(e) => { signupForm.handleChange(e); handleGenderSelection(e); }} value={signupForm?.values?.profileFor}>
                          <option value="">Select Profile</option>
                          {
                            Object.keys(PROFILE_FOR_OPTIONS).map((key, i) => {
                              return (<option key={i} value={PROFILE_FOR_OPTIONS[key]}>{capitalizeFirstLetter(PROFILE_FOR_OPTIONS[key])}</option>)
                            })
                          }
                        </select>
                        <CustomError name='profileFor' form={signupForm} />
                      </div>
                      {/* {
                        signupForm?.values?.profileFor ?
                          : null
                      } */}
                      <div className="form-group mb-4">
                        <select className="form-select" disabled={disableGender} name='gender' onChange={signupForm.handleChange} value={signupForm?.values?.gender}>
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                        <CustomError name='gender' form={signupForm} />
                      </div>
                      <div className="form-group mb-4">
                        <input
                          type="text"
                          name="name"
                          onChange={signupForm.handleChange} value={signupForm?.values?.name}
                          placeholder="Enter Name"
                          className="form-control"
                        />
                        <CustomError name='name' form={signupForm} />
                      </div>
                      {/* <div className="form-group mb-4 select-num">
                        <div className='d-flex'>
                          <select className="form-select me-3" name='phone.code' onChange={signupForm.handleChange} value={signupForm?.values?.phone.code}>
                            <option value="+91">+91</option>
                            <option value="+1">+1</option>
                          </select>
                          <div className='w-100'>
                            <input
                              type="text"
                              name='phone.number' onChange={signupForm.handleChange} value={signupForm?.values?.phone.number}
                              placeholder="Enter Mobile Number"
                              className="form-control"
                            />
                            <CustomError name='phone.number' form={signupForm} className="d-block" />
                          </div>
                        </div>
                      </div> */}
                      
                      <button className="btn btn-primary w-100 " type='submit'>
                        REGISTER FREE
                        <img className="ms-3" src={registerFreeImg} alt='register-free-icon.svg' />
                      </button>
                    </form>
                    <span className="form-text-bottom">
                      *By clicking register free, I agree to the
                      <a href="/privacy"> T&amp;C </a> and <a href="/privacy">Privacy Policy</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <figure className="banner-img">
          <img src={bannerImg} alt='Banner' />
        </figure>
      </section>
      <section className="banner-blocks">
        <div className="container">
          <div className="banner-blocks-inner">
            <ul className="banner-blocks-list">
              <li>
                <figure>
                  <img src={blocksImg} alt="blocks-img-1.svg" />
                </figure>
                <figcaption>
                  <h3>100%</h3>
                  <p>Mobile-verified profiles</p>
                </figcaption>
              </li>
              <li>
                <figure>
                  <img src={blocks2Img} alt="blocks-img-2.svg" />
                </figure>
                <figcaption>
                  <h3>4 Crores+</h3>
                  <p>Customers served</p>
                </figcaption>
              </li>
              <li>
                <figure>
                  <img src={blocks3Img} alt="blocks-img-3.svg" />
                </figure>
                <figcaption>
                  <h3>23 Years</h3>
                  <p>of successful matchmaking</p>
                </figcaption>
              </li>
            </ul>
            <div className="records-box d-flex">
              <span className="records-load-img">
                <img src={recordImg} alt="records-corner-loading.svg" />
              </span>
              <figure>
                <img src={limcaImg} alt='limca-records-img-new.png' />
              </figure>
              <figcaption>
                <h3>
                  Featured in the Limca Book of Records for highest number of
                  documented marriages online
                </h3>
              </figcaption>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
