import React from 'react';
import { useLocation } from 'react-router-dom';
import $ from "jquery";
import logoImg from '../../../assets/user/images/logo.png';
import shortLogoImg from '../../../assets/user/images/short_logo.png';
import { Link } from 'react-router-dom';

const SideBar = () => {
   const loc = useLocation();

   const hoverSidebar = (e) => {
      if ($("body").hasClass("main-sidebar-hide")) {
         $("body").addClass("main-sidebar-open");
      } else {
         $("body").removeClass("main-sidebar-open");
      }
   }

   const toggleDropdown = (e) => {
      e.preventDefault();
      const dropDown = e.target.parentElement;
      if (dropDown.classList.contains('show')) {
         dropDown.classList.remove('show');
      } else {
         dropDown.classList.add('show');
      }
   }

   const removeHoverSidebar = (e) => {
      $("body").removeClass("main-sidebar-open");
   }


   return (
     <div
       className="main-sidebar main-sidebar-sticky side-menu ps ps--active-y "
       id="app_sidebar"
       onMouseEnter={hoverSidebar}
       onMouseLeave={removeHoverSidebar}
     >
       <div className="sidemenu-logo">
         <a className="main-logo" href="/admin/dashboard">
           <img
             src={shortLogoImg}
             className="header-brand-img icon-logo admin-logo"
             alt="logo"
             style={{ height: "50px", width: "50px" }}
             id="logo_short"
           />
           <img
             src={logoImg}
             className="admin-logo header-brand-img desktop-logo logo"
             alt="logo"
             id="logo_full"
           />
         </a>
       </div>
       {/* Dashboard section start */}
       <div className="main-sidebar-body">
         <ul className="nav ps-2">
           <li
             className={
               "nav-item w-100 " +
               (loc.pathname === "/admin/dashboard" ? " active" : "")
             }
           >
             <Link className="nav-link" to="/admin/dashboard">
               <span className="shape1"></span>
               <span className="shape2"></span>
               <i className="fa fa-home sidemenu-icon"></i>
               <span className="sidemenu-label">Dashboard</span>
             </Link>
           </li>
           {/* Dashboard section end */}

           {/* User management section start */}
           <li
             className={
               `nav-item w-100 ` +
               (loc.pathname.includes("/user-management") ? "show active" : "")
             }
           >
             <Link
               className="nav-link"
               to={`/admin/user-management/users/list/${1}`}
             >
               <span className="shape1"></span>
               <span className="shape2"></span>
               <i className="fa fa-users sidemenu-icon"></i>
               <span className="sidemenu-label">Users</span>
             </Link>
           </li>
           {/* User management section end */}
           {/* Cms section start */}
             <li
               className={
                 `nav-item ` +
                 (loc.pathname.includes("/cms") ? "show active" : "")
               }
             >
               <a
                 className="nav-link with-sub submenu"
                 onClick={toggleDropdown}
                 href={"#"}
               >
                 <span className="shape1"></span>
                 <span className="shape2"></span>
                 <i className="fa fa-folder-open-o sidemenu-icon"></i>
                 <span className="sidemenu-label">Cms</span>
                 <i className="angle fa fa-angle-right"></i>
               </a>
               <ul className="nav-sub">
                 <li
                   className={`nav-sub-item ${
                     loc.pathname.includes("/cms/pages") ? " active" : ""
                   }`}
                 >
                   <Link
                     className="nav-sub-link"
                     to={`/admin/cms/pages/list/${1}`}
                   >
                     Pages
                   </Link>
                 </li>
               </ul>
               <ul className="nav-sub">
                 <li
                   className={`nav-sub-item ${
                     loc.pathname.includes("/cms/email-templates") ? " active" : ""
                   }`}
                 >
                   <Link
                     className="nav-sub-link"
                     to={`/admin/cms/email-templates/list/${1}`}
                   >
                     Email Templates
                   </Link>
                 </li>
               </ul>
               <ul className="nav-sub">
                 <li
                   className={`nav-sub-item ${
                     loc.pathname.includes("/cms/portal-design") ? " active" : ""
                   }`}
                 >
                   <Link
                     className="nav-sub-link"
                     to={`/admin/cms/portal-design`}
                   >
                    Portal Designer
                   </Link>
                 </li>
               </ul>
               <ul className="nav-sub">
                 <li
                   className={`nav-sub-item ${
                     loc.pathname.includes("/cms/settings") ? " active" : ""
                   }`}
                 >
                   <Link
                     className="nav-sub-link"
                     to={`/admin/cms/settings`}
                   >
                    Global Settings
                   </Link>
                 </li>
               </ul>
             </li>
           {/* Cms section end */}
         </ul>
       </div>
     </div>
   );
}
export default SideBar;