import React, { useState, useEffect } from "react";
import { getProfile, imageRecongnition, getUserPhotos, deleteUserPhotos, makeMainPhoto } from "../services/user.service";
import AWS from 'aws-sdk';
import { failedResponse, successResponse } from "../../../utils/toastr";
import Swal from "sweetalert2";

const ManageProfileImages = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [userImages, setUserImages] = useState([]);

    async function fetchUserData() {
        await getProfile().then((response) => {
            if (response.success) {
                setUserInfo(response.data ? response.data : null);
            }
        })
            .catch((err) => {
                console.log("error in fetching family info", err);
            });
    }

    async function fetchUserPhotos() {
        getUserPhotos().then(response => {
            if (response?.success) {
                setUserImages(response.data)
            }
        }).catch(err => {
            console.error("error :", err)
        })
    }

    useEffect(() => {
        fetchUserData();
        fetchUserPhotos()
    }, []);

    const handleUpload = async (e) => {
        const file = e.target.files[0]
        if (!file) {
            failedResponse("No Image Selected!")
            return
        }
        // const S3_BUCKET = "test-awsbkt";
        const S3_BUCKET = "matrimony-sp-south-1";
        const REGION = "ap-south-1";
        const FILE_NAME = `${new Date().getTime()}.${file.name.split(".").reverse()[0]}`

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        });
        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        const params = {
            Bucket: S3_BUCKET,
            Key: FILE_NAME,
            Body: file,
        };

        var upload = s3
            .putObject(params)
            .on("httpUploadProgress", (evt) => {
                console.log(
                    "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                );
            })
            .promise();

        await upload.then((res) => {
            console.log(res);
            successResponse("Image Uploaded!")
            imageRecongnition({ image_name: FILE_NAME }).then(res => {
                fetchUserPhotos()
            }).catch(err => {
                console.error("error :", err)
            })
            // s3.listObjectsV2({Bucket: S3_BUCKET}, (err, data) => {
            //   if (err) {
            //     console.log('Error fetching the object:', err);
            //   } else {
            //     console.log('Object data:', data);
            //     // Process or use the fetched object data here

            //     // const imageData = data.Body

            //     // const blob = new Blob([imageData], { type: 'image/jpeg' }); // Adjust type based on image format

            //     // // // Create a URL for the Blob object
            //     // const imageUrl = URL.createObjectURL(blob);
            //     setImageUrls([...imageUrls, `https://test-awsbkt.s3.ap-south-1.amazonaws.com/${file.name}`])
            //   }
            // });
        }).catch(error => {
            console.log(error);
            failedResponse("Image Not uploaded!")
        });
    }

    // DONOT DELETE BELOW CODE
    // const [objectUrl, setObjectUrl] = useState('');

    // useEffect(() => {
    //   const S3_BUCKET = "test-awsbkt";
    //   const REGION = "ap-south-1";

    //   // Create S3 client
    //   const s3Client = new S3Client({
    //     region: "ap-south-1",
    //     credentials: {
    //       accessKeyId: "",
    //       secretAccessKey: "",
    //     },
    //   });

    //   AWS.config.update({
    //     accessKeyId: "",
    //     secretAccessKey: "",
    //   });
    //   const s3 = new AWS.S3({
    //     params: { Bucket: S3_BUCKET },
    //     region: REGION,
    //   });

    //   // Function to fetch the S3 object
    //   const fetchS3Object = async () => {
    //     try {
    //       s3.listObjectsV2({Bucket: S3_BUCKET}, (err, data) => {
    //       if (err) {
    //         console.log('Error fetching the object:', err);
    //       } else {
    //         console.log('Object data:', data);
    //         // Process or use the fetched object data here

    //         // const imageData = data.Body

    //         // const blob = new Blob([imageData], { type: 'image/jpeg' }); // Adjust type based on image format

    //         // // // // Create a URL for the Blob object
    //         // const imageUrl = URL.createObjectURL(blob);
    //         // setImageUrls([...imageUrls, imageUrl])
    //       }
    //     });


    //       const getObjectParams = {
    //         Bucket: "test-awsbkt",
    //         Key: "19602.jpg",
    //       };

    //       const command = new GetObjectCommand(getObjectParams);
    //       const response = await s3Client.send(command);

    //       // Convert the object data to a URL for image display (for example, for images)
    //       const objectData = await new Response(response.Body).blob();
    //       const objectUrl = URL.createObjectURL(objectData);
    //       setObjectUrl(objectUrl);
    //     } catch (error) {
    //       console.error('Error fetching S3 object:', error);
    //     }
    //   };

    //   fetchS3Object();
    // }, []);

    //DO NOT DELETE ABOVE CODE

    const handleMakeMainPhoto = async (imageId) => {
        makeMainPhoto(imageId).then(res => {
            successResponse("Image Updated")
            fetchUserPhotos()
        }).catch(err => {
            failedResponse("Something Went Wrong")
        })
    }

    const handleDeletePhoto = async (imageId) => {
        Swal.fire({
            customClass: "swal-wide",
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#403fad",
            cancelButtonColor: "#f1388b",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUserPhotos(imageId).then(res => {
                    successResponse("Image Deleted")
                    fetchUserPhotos()
                }).catch(err => {
                    console.error("error :", err)
                    failedResponse("Something Went Wrong")
                })
            }
        })
    }

    return (
        <div className="wraper-inner">
            <section className="profile-page padding-space">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="wraper-inner">
                                <section className="manage-photo padding-space">
                                    <div className="container">
                                        <div className="manage-photo-main">
                                            <div className="manage-photo-head d-flex align-items-center justify-content-between">
                                                <div className="manage-photo-head-left">
                                                    <ul className="manage-photo-list">
                                                        <li><a>Manage Photos</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="manage-photo-body">
                                                <div className="row row-cols-5 manage-photo-rows">
                                                    {userImages.length ? userImages.map((item, index) => {
                                                        return (
                                                            <div className="cols manage-photo-cols" key={index}>
                                                                <div className="manage-photo-frame">
                                                                    <figure><img src={item.picture_url} alt={item.picture_name} /></figure>
                                                                    <div className="dropdown">
                                                                        <button className="btn  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="ri-pencil-fill"></i>
                                                                        </button>
                                                                        <ul className="dropdown-menu">
                                                                            <li><button disabled={item.is_main || item.status !== 1} className="dropdown-item active" onClick={() => handleMakeMainPhoto(item.id)}>Main Photo</button></li>
                                                                            <li><button disabled={item.is_main} className="dropdown-item" type="button" onClick={() => handleDeletePhoto(item.id)}>Delete</button></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                {item.status === 0 ? <h4 className="pending">Pending approval</h4> : item.status === 1 ? <h4 className="accepted">Accepted</h4> : <h4>Rejected</h4>}
                                                            </div>
                                                        )
                                                    }) : ""}
                                                    <div className="cols manage-add-btn-cols">
                                                        <a className="add-more-btn" onClick={() => {
                                                            document.getElementById("upfile").click();
                                                        }}>
                                                            <i className="ri-add-circle-fill"></i>
                                                            <h4>Add More Photo</h4>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                id="upfile"
                                                                onChange={handleUpload}
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ManageProfileImages;
