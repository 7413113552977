import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import router from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

// import '../src/assets/admin/css/skins.css';
// import '../src/assets/admin/css/icon-list.css';
// import '../src/assets/admin/plugins/select2/css/select2.min.css';
// import '../src/assets/admin/css/style.css';
// import '../src/assets/admin/css/dark-style.css';
// import '../src/assets/admin/css/colors/default.css';
// import '../src/assets/admin/plugins/multipleselect/multiple-select.css';
// import '../src/assets/admin/css/sidemenu/sidemenu.css';
// import '../src/assets/admin/plugins/summernote/summernote-bs4.css';
// import '../src/assets/admin/plugins/fileuploads/css/fileupload.css';
// import '../src/assets/admin/plugins/fancyuploder/fancy_fileupload.css';


import 'rsuite/dist/rsuite.min.css';
import '../src/assets/user/fonts/remixicon.css';
import '../src/assets/user/css/owl.carousel.min.css';
import '../src/assets/user/css/all.css';
import '../src/assets/user/css/animation.css';
import '../src/assets/user/css/style.css';
import '../src/assets/user/css/newStyle.css';
import './App.css';



import {Amplify} from 'aws-amplify';
import awsmobile from './aws-exports';
import GlobalLoaderTwo from './modules/admin/shared/globalLoaderTwo';

Amplify.configure(awsmobile);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <GlobalLoaderTwo id="main-loader"/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();