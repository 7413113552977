import React, { useEffect, useRef, useState } from 'react';
import logoImg from '../../../assets/user/images/logo.png';
import helpImg from '../../../assets/user/images/hp-helpicn.webp';
import arrowDrop from '../../../assets/user/images/arrow-drop.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import CustomError from '../shared/CustomError';
import { useFormik } from 'formik';
import UserAvatar from '../../../assets/user/images/user-avtar.svg';
import {IsPhoneVerified, getUsername, verifyOtpForgetPassword} from '../services/auth.service'
import { getLocalKey, getSessionKey, globalLoader, hideAllErrors, logOutUser, setLocalKey, setSessionKey, greet } from '../../../utils/commonfunction';
import { checkUserSession, loginByOTP,verifyOtp } from '../../../utils/amazon_utils';
import { forgetPassword, getLoggedInUser, resetPassword, signIn } from '../../../utils/amazon_utils2';
import Swal from 'sweetalert2';
import { failedResponse, successResponse } from '../../../utils/toastr';
import editProfile from '../../../assets/user/images/edit-profile.svg';
import editPreference from '../../../assets/user/images/edit-preference.svg';
import menuHoroscope from '../../../assets/user/images/menu_horoscope.svg';
import menuIdentity from '../../../assets/user/images/menu_identity.svg';
import settingSvg from '../../../assets/user/images/setting.svg';
import helpSvg from '../../../assets/user/images/help.svg';
import safeMatrimony from '../../../assets/user/images/safe-matrimony.svg';
import sucessStory from '../../../assets/user/images/success-story.svg';
import userAvatar from "../../../assets/user/images/user-avtar.svg";
import maleAvatar from "../../../assets/user/images/male-avatar.svg";
import { ATLEAST_ONE_CAPITAL_REGEX, ATLEAST_ONE_NUMBER_REGEX, ATLEAST_ONE_SMALL_REGEX, ATLEAST_ONE_SPECIAL_CHARACTER_REGEX, PASSWORD_MIN_LENGTH } from '../../../utils/Constants';
import { getProfile } from '../services/user.service';

const Header = () => {
    const resetPasswordModalCloseHandler = useRef();

    const navigate = useNavigate();
    const usrToken = getLocalKey('token') ? JSON.parse(getLocalKey('token')) : {};
    const loc = useLocation();

    const [session, setSession] = useState(null);
    const [forgetToken, setForgetToken] = useState(null);
    const [userData, setUserData] = useState({});

    useEffect(() => {
        if (usrToken?.jwtToken) {
            getProfile().then(res => {
                setUserData(res.data)
            }).catch(err => {
                console.error("error :", err)
            })
        }
    }, [])

    /** Login Form Validations */
    const loginValidations = Yup.object().shape({
        username: Yup.string()
            .required('Mobile No./E-mail is required')
            .test('is-email-or-phone', 'Invalid email or phone number', (value) => {
                if (!value) return false;
                function isValidEmail(email) {
                    // Regular expression for a simple email validation
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    return emailRegex.test(email);
                }
                function isValidPhone(phone) {
                    const ar = phone.split('+');
                    if(ar.length > 1 && ar[1].length > 10){
                        return !isNaN(ar[1]);
                    }else{
                        return false;
                    }
                }
                // const isEmail = Yup.string().email().isValidSync(value);
                // const isPhone = /^\+?\d{10}$/.test(value);
                return isValidEmail(value) || isValidPhone(value);
            }),
        password: Yup.string().trim().required('Password is required'),
    });

    const loginForm = useFormik({
        initialValues: {
            username: '',
            password: '',
            rememberMe: false
        },
        validationSchema: loginValidations,
        onSubmit: async values => {
            globalLoader(true);
            try {
                signIn(values.username, values.password).then((signInResponse) => {
                    setLocalKey("token", JSON.stringify({...signInResponse.signInUserSession.accessToken}));
                    successResponse("Successfully logged in");
                    navigate("/dashboard");
                    document.getElementById('modal_close').click();
                    globalLoader(false);
                }).catch((err) => {
                    console.log("####Sign In Error", err);
                    globalLoader(false);
                    failedResponse(err?.message);
                });
            } catch (err) {
                console.log("Error", err);
            }
        }
    });

    const ForgetPasswordValidations = Yup.object().shape({
        email: Yup.string().required('E-mail is required').email('Invalid email'),
    });

    const otpValidationSchema = Yup.object().shape({
        otp: Yup.string().required('OTP is required').min(6,'Invalid OTP').max(6, 'Invalid OTP'),
        password: Yup.string().required('Password is required')
        .matches(ATLEAST_ONE_SMALL_REGEX, "Password should have at least 1 lowercase letter")
        .matches(ATLEAST_ONE_CAPITAL_REGEX, "Password should have at least 1 capital letter")
        .matches(ATLEAST_ONE_NUMBER_REGEX, "Password should have at least 1 number")
        .matches(ATLEAST_ONE_SPECIAL_CHARACTER_REGEX, "Password should have at least 1 special character")
        .min(PASSWORD_MIN_LENGTH, "Password should be at least 8 characters long"),
        confirm_password: Yup.string().required('Confirm password is required').oneOf([Yup.ref('password'), null], 'Password and Confirm Password should be same'),
    });

    const OtpFormForForget = useFormik({
        initialValues:{
            otp:'',
            password:'',
            confirm_password:''
        },
        validationSchema: otpValidationSchema,
        onSubmit: (values, {resetForm}) => {
            globalLoader(true);
            const userEmail = getSessionKey('user_email_forget_password');
            getUsername({type: "email", email: userEmail}).then((response) => {
                if(response?.success){
                    resetPassword(response?.data, values.otp, values.password).then(()=>{
                        successResponse("Password changed successfully");
                        resetForm();
                        resetPasswordModalCloseHandler.current.click();
                        globalLoader(false);
                    }).catch(err=>{
                        failedResponse(err.message);
                        globalLoader(false);
                    });                    
                }else{
                    failedResponse(response?.message);
                    globalLoader(false);
                }
            }).catch((error) => {
                failedResponse(error);
                globalLoader(false);
            })
        }
    });

    const ForgetPasswordForm = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: ForgetPasswordValidations,
        onSubmit: async (values, {resetForm}) => {
            globalLoader(true);
            try {
                forgetPassword(values?.email).then((response) => {
                    globalLoader(false);
                    setSessionKey('user_email_forget_password', values?.email);
                    document.getElementById('forget_password').click();
                    resetForm();
                }).catch((err) => {
                    globalLoader(false);
                    console.log("err",err)
                });
            } catch (err) {
                globalLoader(false);
                console.log("Error", err);
            }
        }
    });

    // const ResetPasswordValidations = Yup.object().shape({
        
    // });
    
    // const ResetPasswordForm = useFormik({
    //     initialValues: {
    //         password:'',
    //         confirm_password:''
    //     },
    //     validationSchema: ResetPasswordValidations,
    //     onSubmit: async values => {
    //         console.log("values",values);
    //         // try {
    //         //     const loginResponse = await login(values);
    //         //     setSessionKey('USER_DATA', JSON.stringify(loginResponse.data));
                
    //         //     if(values.rememberMe){
    //         //         localStorage.setItem('USER_DATA', JSON.stringify(loginResponse.data))
    //         //     }
    //         // } catch (err) {
    //         //     console.log("Error", err);
    //         // }
    //     }
    // });

    const LoginByOTPValidations = Yup.object().shape({
        phone: Yup.object().shape({
            number: Yup.string().trim().required('Phone is required').min(10,'Invalid phone number').max(10,'Invalid phone number').matches(/^[0-9]+$/,'Invalid phone number'),
        })
    });

    /* Login by OTP */ 
    const LoginByOTPForm = useFormik({
        initialValues: {
            phone: {
                code: '+91',
                number: ''
            },
        },
        validationSchema: LoginByOTPValidations,
        onSubmit: async (values, {resetForm}) => {
            globalLoader(true);
            try {
                let phoneNumber = values?.phone?.code+(values?.phone?.number.trim());
                const cognitoSignInData = { phone_number: phoneNumber};
                IsPhoneVerified({
                    code: values?.phone?.code,
                    number: (values?.phone?.number.trim())
                }).then((response) => {
                    if(response?.success){
                        loginByOTP(cognitoSignInData).then((signInResponse) => {
                            setSession(signInResponse.data);
                            document.getElementById('verify_otp_modal').click();
                            globalLoader(false);
                            resetForm();
                        }).catch((err) => {
                            globalLoader(false);
                            failedResponse(err?.message);
                        });
                    }else{
                        globalLoader(false);
                        failedResponse(response?.message);
                    }
                }).catch((error) => {
                    globalLoader(false);
                    failedResponse(error[0]?.msg);
                })
            } catch (err) {
                globalLoader(false);
                failedResponse(err?.message);
                console.log("Error", err);
            }
        }
    });

    const verificationOTPValidations = Yup.object().shape({
        login_otp: Yup.string().trim().required('Please enter OTP').min(6,'Invalid OTP').max(6,'Invalid OTP').matches(/^[0-9]+$/,'Invalid OTP'),
    });

    const VerifyOTPForm = useFormik({
        initialValues: {
            login_otp: ''
        },
       validationSchema: verificationOTPValidations,
        onSubmit: async (values, {resetForm}) => {
            globalLoader(true);
            try {
                const cognitoSignInData = { session:session,otp:String(values.login_otp.trim()) };
                verifyOtp(cognitoSignInData).then((signInResponse) => {
                    setLocalKey('token', JSON.stringify({...signInResponse?.data?.signInUserSession?.accessToken}));
                    document.getElementById('close_verify_otp').click();
                    successResponse("Successfully logged in");
                    navigate("/dashboard");
                    resetForm();
                    globalLoader(false);
                }).catch((err) => {
                     console.log("####Sign In Error", err);
                    failedResponse("OTP not match");
                    globalLoader(false);
                });
            } catch (err) {
                console.log("Error", err);
                failedResponse(err?.message);
                globalLoader(false);
            }
        }
    });

    return (
        <>
            <div id="fixed-header">
                {!Object.values(usrToken).length ? (
                <header className="topHeader">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg " id="navbar-scrollspy">
                            <div className="navbar-inner-box d-flex align-items-center justify-content-between">
                                <div className="nav-L d-flex">
                                    {" "}
                                    <Link className="navbar-brand desktop-view-logo" to="/">
                                        <img src={logoImg} alt='Logo' />
                                    </Link>
                                </div>
                                <div className="nav-R d-flex align-items-center ">
                                    <a className="alredy-member" href="/">Already a member?</a>
                                    <a href="/"
                                        className="login-btn cp"
                                        data-bs-toggle="modal"
                                        data-bs-target="#login"
                                    >
                                        Login
                                    </a>
                                    <a className="help-text" href="/">
                                        <img className="help-info" src={helpImg} alt='Help' />
                                        <span>Help </span>{" "}
                                        <img className="help-drop" src={arrowDrop} alt='Arrow' />
                                    </a>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>
                ) : (
                    <header className="topHeader" >
                        <div className="container">
                            <nav className="navbar navbar-expand-lg " id="navbar-scrollspy">
                                <div className="navbar-inner-box d-flex align-items-center justify-content-between">
                                    <div className="nav-L d-flex">  <a className="navbar-brand desktop-view-logo" href="/"><img src={logoImg} /></a></div>
                                    <div className="nav-R d-flex">
                                        <button className="navbar-toggler" id="toggle_button" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"><i className="ri-menu-3-line"></i>
                                            </span>
                                        </button>
                                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                                <li className="nav-item">
                                                    <Link className="nav-link" aria-current="page" to="/dashboard"><i className="ri-home-4-line"></i>Home</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link " aria-current="page" href="#about-us"><i className="ri-group-line"></i>Matches</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link " aria-current="page" href="#video-box"><i className="ri-mail-line"></i>Mailbox</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link " aria-current="page" href="#faq"><i className="ri-wechat-line"></i>Chat</a>
                                                </li>
                                                <li className="nav-item">
                                                <Link className={`nav-link` + (loc.pathname == "/search-files/search" ? " active" : "")} to={"/search-files/search"}><i className="ri-search-line"></i>Search</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link " aria-current="page" href="#testimonials"> <i className="ri-notification-2-line"></i>Notification </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown dropdown-profile ms-3">
                                            <button className=" dropdown-toggle profile" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={userData && userData.picture_url ? userData.picture_url : userData.gender === "female" ? userAvatar : maleAvatar} alt="" />
                                            </button>
                                            <div className="dropdown-menu">
                                                <div className="profile-content">
                                                    <h5>{greet()}</h5>
                                                    <h1>{userData && (userData.name ?? "")}</h1>
                                                    <span>{userData && (userData.matrimony_id ?? "")}</span>
                                                    <small>Membership: Free</small>
                                                    <p>Become a paid member now</p>
                                                    <a className="btn-sm btn-outline m-auto mt-2">Upgrade</a>
                                                </div>
                                                <hr className="seprator" />
                                                <ul className="profile-list">
                                                    <li><Link to="/edit-profile"> <img src={editProfile} alt="" />Edit Profile</Link></li>
                                                    {/* <li><a href="/"><img src={editProfile} alt="" />Edit Profile</a></li> */}
                                                    <li><a href="/"><img src={editPreference} alt="" />Edit Preferencess</a></li>
                                                    <li><a href="/"><img src={menuHoroscope} alt="" />Generate Horoscope</a></li>
                                                    <li><a href="/"><img src={menuIdentity} alt="" />Verify Your Profile</a></li>
                                                </ul>
                                                <hr className="seprator" />
                                                <ul className="profile-list">
                                                    <li><Link to="/settings"> <img src={settingSvg} alt="" />Settings</Link></li>
                                                    <li><a href="/"><img src={helpSvg} alt="" />Help</a></li>
                                                    <li><a href="/"><img src={safeMatrimony} alt="" />Safe Matrimony</a></li>
                                                    <li><a href="/"><img src={sucessStory} alt="" />Success Stories</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* <div className="dropdown ms-3">
                                            <button className=" dropdown-toggle profile" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={UserAvatar} alt="" />
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li><button className="dropdown-item" onClick={() => handleLogout()}>Logout</button></li>
                                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </header>
                )}
            </div>
            {/* login--Modal */}
            <div className="modal fade common-modal xs-modal" data-bs-backdrop="static" id="login" tabIndex={-1} aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-head">
                            <h1 className="modal-title">Login</h1>
                            <button type="button" id='modal_close' onClick={() => hideAllErrors(loginForm)} className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={loginForm.handleSubmit}>
                                <div className="form-group mb-3">
                                    <label className="form-label">E-mail / Phone</label>
                                    <input type="text" name="username" placeholder="email@example.com/+911234567890" className="form-control"
                                        onChange={loginForm.handleChange} value={loginForm?.values?.username} />
                                    <CustomError name='username' form={loginForm} />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Password</label>
                                    <input type="password" name="password" placeholder="" className="form-control"
                                        onChange={loginForm.handleChange} value={loginForm?.values?.password} />
                                    <CustomError name='password' form={loginForm} />
                                </div>
                                {/* <div className="form-check mb-3">
                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={loginForm?.values?.rememberMe}
                                        name="rememberMe" onChange={loginForm.handleChange} value={loginForm?.values?.rememberMe} />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">Keep me logged in</label>
                                </div> */}
                                <button className="btn btn-primary w-100" type='submit'>Login</button>
                                {/* <button className="btn btn-primary w-100 mt-2" onClick={() => hideAllErrors(loginForm)} type='button'>Login</button> */}
                            </form>

                            
                            <ul className="login-info-link">
                                <li>
                                    <a href="/" data-bs-target="#forget-password" onClick={() => hideAllErrors(loginForm)} data-bs-toggle="modal">Forgot password?</a>
                                </li>
                                <li>
                                    <a href="/" data-bs-target="#login-by-otp" onClick={() => hideAllErrors(loginForm)} data-bs-toggle="modal">Login Via OTP</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal of forget password */}
            <div className="modal fade common-modal xs-modal" id="forget-password" tabIndex={-1} aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-head">
                            <h1 className="modal-title">Forget Password</h1>
                            <button type="button" className="btn-close" onClick={() => hideAllErrors(ForgetPasswordForm)} data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <form onSubmit={ForgetPasswordForm.handleSubmit}>
                                <div className="form-group mb-3">
                                    <label className="form-label">E-mail</label>
                                    <input type="email" name="email" placeholder="" className="form-control"
                                        onChange={ForgetPasswordForm.handleChange} value={ForgetPasswordForm?.values?.email} />
                                    <CustomError name='email' form={ForgetPasswordForm} />
                                </div>
                                <button className="btn btn-primary w-100" type='submit'>Submit</button>
                                <a href="/" style={{display:'none'}} id='forget_password' data-bs-target="#otp-verification" data-bs-toggle="modal"></a>
                            </form>
                            <ul className="login-info-link">
                                <li>
                                    {/* <a className='cp' onClick={() => handleBackLogin('forget-password')}>Back To Login</a> */}
                                    <a href="/" data-bs-target="#login" onClick={() => hideAllErrors(ForgetPasswordForm)} data-bs-toggle="modal">Back To Login</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal of otp verification after forget password */}
            <div className="modal fade common-modal xs-modal" data-bs-backdrop="static" id="otp-verification" tabIndex={-1} aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-head">
                            <h1 className="modal-title">OTP Verification</h1>
                            <button type="button" ref={resetPasswordModalCloseHandler} className="btn-close" onClick={() => hideAllErrors(OtpFormForForget)} data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <form onSubmit={OtpFormForForget.handleSubmit}>
                                <div className="form-group mb-3">
                                    <label className="form-label">OTP</label>
                                    <input type="otp" name="otp" placeholder="" className="form-control"
                                        onChange={OtpFormForForget.handleChange} value={OtpFormForForget?.values?.otp} />
                                    <CustomError name='otp' form={OtpFormForForget} />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Password</label>
                                    <input type="password" name="password" placeholder="" className="form-control"
                                        onChange={OtpFormForForget.handleChange} value={OtpFormForForget?.values?.password} />
                                    <CustomError name='password' form={OtpFormForForget} />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Confirm Password</label>
                                    <input type="password" name="confirm_password" placeholder="" className="form-control"
                                        onChange={OtpFormForForget.handleChange} value={OtpFormForForget?.values?.confirm_password} />
                                    <CustomError name='confirm_password' form={OtpFormForForget} />
                                </div>
                                <button className="btn btn-primary w-100" type='submit'>Submit</button>
                                <a href="/" style={{display:'none'}} id='otp_verification' data-bs-target="#reset-password" data-bs-toggle="modal"></a>
                            </form>
                            <ul className="login-info-link">
                                <li>
                                    <a href="/" data-bs-target="#login" onClick={() => hideAllErrors(OtpFormForForget)} data-bs-toggle="modal">Back To Login</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for reset password */}
            {/* <div className="modal fade common-modal xs-modal" data-bs-backdrop="static" id="reset-password" tabIndex={-1} aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-head">
                            <h1 className="modal-title">Reset Password</h1>
                            <button type="button" id='modal_close_reset' className="btn-close" onClick={() => hideAllErrors(ResetPasswordForm)} data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <form onSubmit={ResetPasswordForm.handleSubmit}>
                            <div className="form-group mb-3">
                                    <label className="form-label">Password</label>
                                    <input type="password" name="password" placeholder="" className="form-control"
                                        onChange={ResetPasswordForm.handleChange} value={ResetPasswordForm?.values?.password} />
                                    <CustomError name='password' form={ResetPasswordForm} />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Confirm Password</label>
                                    <input type="password" name="confirm_password" placeholder="" className="form-control"
                                        onChange={ResetPasswordForm.handleChange} value={ResetPasswordForm?.values?.confirm_password} />
                                    <CustomError name='confirm_password' form={ResetPasswordForm} />
                                </div>
                            
                                <button className="btn btn-primary w-100" type='submit'>Submit</button>
                            </form>
                            <ul className="login-info-link">
                                <li>
                                    <a href="/" data-bs-target="#login" onClick={() => hideAllErrors(ResetPasswordForm)} data-bs-toggle="modal">Back To Login</a>
                                    
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* Modal for login-by-otp form */}
            <div className="modal fade common-modal xs-modal" data-bs-backdrop="static" id="login-by-otp" tabIndex={-1} aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-head">
                            <h1 className="modal-title">Login By OTP</h1>
                            <button type="button" className="btn-close" onClick={() => hideAllErrors(LoginByOTPForm)} data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <form onSubmit={LoginByOTPForm.handleSubmit}>
                                <div className="form-group mb-4 select-num">
                                    <div className='d-flex'>
                                    <select className="form-select me-3" name='phone.code' onChange={LoginByOTPForm.handleChange} value={LoginByOTPForm?.values?.phone.code}>
                                        <option value="+91">+91</option>
                                        <option value="+1">+1</option>
                                    </select>
                                    <div className='w-100'>
                                        <input
                                        type="text"
                                        name='phone.number' onChange={LoginByOTPForm.handleChange} value={LoginByOTPForm?.values?.phone.number}
                                        placeholder="Enter Mobile Number"
                                        className="form-control"
                                        />
                                        <CustomError name='phone.number' form={LoginByOTPForm} className="d-block" />
                                    </div>
                                    </div>
                                </div>
                                <button className="btn btn-primary w-100" type='submit'>Submit</button>
                                <a href="/" style={{display:'none'}} id='verify_otp_modal' data-bs-target="#login-verify-otp" data-bs-toggle="modal"></a>
                            </form>
                            <ul className="login-info-link">
                                <li>
                                    <a href="/" data-bs-target="#login" onClick={() => hideAllErrors(LoginByOTPForm)} data-bs-toggle="modal">Back To Login</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Modal for login-by-otp verify otp */}
            <div className="modal fade common-modal xs-modal" data-bs-backdrop="static" id="login-verify-otp" tabIndex={-1} aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-head">
                            <h1 className="modal-title">Enter your OTP</h1>
                            <button type="button" onClick={() => {VerifyOTPForm.resetForm(); hideAllErrors(VerifyOTPForm);}} id='close_verify_otp' className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <form onSubmit={VerifyOTPForm.handleSubmit}>
                                <div className="form-group mb-3">
                                    <label className="form-label">One time password</label>
                                    <input type="text" name="login_otp" placeholder="" className="form-control"
                                        onChange={VerifyOTPForm.handleChange} value={VerifyOTPForm?.values?.login_otp} />
                                    <CustomError name='login_otp' form={VerifyOTPForm} />
                                </div>
                                <button className="btn btn-primary w-100" type='submit'>Submit</button>
                            </form>
                            <ul className="login-info-link">
                                <li>
                                    <a href="/" onClick={() => () => {VerifyOTPForm.resetForm(); hideAllErrors(VerifyOTPForm);}} data-bs-target="#login" data-bs-toggle="modal">Back To Login</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header