import React, { useState } from "react";
import HeartImg from "../../../../assets/user/images/pp-heart.svg";
import Icon1 from "../../../../assets/user/images/icon-1.svg";
import Icon2 from "../../../../assets/user/images/icon-2.svg";
import Icon3 from "../../../../assets/user/images/icon-3.svg";
import Icon4 from "../../../../assets/user/images/icon-4.svg";
import Icon5 from "../../../../assets/user/images/icon-5.svg";
import EditBasicPreference from "../editPreference/basic";

const EditPreference = () => {
  return (
    <>
      <div className="wraper-inner">
        <section className="partner-preferences-page padding-space">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="preferences-sidebar">
                  <h4>
                    <img src={HeartImg} />
                    PARTNER PREFERENCES
                  </h4>
                  <ul className="partner-preferences-list">
                    <li>
                      <a href="#Basic-Preferences">Basic</a>
                    </li>
                    <li>
                      <a href="#Religious-Preferences">Religious</a>
                    </li>
                    <li>
                      <a href="#Professional-Preferences">Professional</a>
                    </li>
                    <li>
                      <a href="#Location-Preferences">Location</a>
                    </li>
                    <li>
                      <a href="#looking-Preferences">About My Partner</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-9">
                <div className="similer-box-shadow">
                  <div className="partner-preferences-head mb-5 ">
                    <h4>Partner Preferences</h4>
                    <p>
                      Matches recommended by us are based on{" "}
                      <b>Acceptable matches</b> criteria and at times might go
                      slightly beyond your preferences.
                    </p>
                    <p>
                      Turn on "Compulsory" to get matches exactly as per your
                      preferences.
                    </p>
                    <small>*Patent pending</small>
                  </div>
                  <EditBasicPreference/>
                  <div
                    className="partner-preferences-content mb-5"
                    id="Religious-Preferences"
                  >
                    <div className="partner-preferences-head d-flex align-items-center">
                      <figure>
                        <img src={Icon2} />
                      </figure>
                      <figcaption>
                        <h5>Religious Preferences</h5>
                      </figcaption>
                    </div>
                    <div className="partner-preferences-body">
                      <ul className="partner-preferences-content">
                        <li>
                          <div className="partner-preferences-list-left">
                            <h5>Groom's Age</h5>
                            <span>25 - 32 years</span>
                          </div>
                          <a className="partner-preferences-edit">
                            <i className="ri-pencil-line"></i>
                          </a>
                        </li>
                        <li>
                          <div className="partner-preferences-list-left">
                            <h5>Height</h5>
                            <span>4 Ft 6 In- 5 Ft 6 In/137 Cms- 168 Cms</span>
                          </div>
                          <a className="partner-preferences-edit">
                            <i className="ri-pencil-line"></i>
                          </a>
                        </li>
                        <li>
                          <div className="partner-preferences-list-left">
                            <h5>Marital Status</h5>
                            <span>Never Married</span>
                          </div>
                          <a className="partner-preferences-edit">
                            <i className="ri-pencil-line"></i>
                          </a>
                        </li>
                        <li>
                          <div className="partner-preferences-list-left">
                            <h5>Mother Tongue</h5>
                            <span>
                              Awadhi, Brij, Chatisgarhi, Dogri, Garhwali,
                              Haryanvi, Himachali ...
                              <a className="link-text">Read More</a>
                            </span>
                          </div>
                          <a className="partner-preferences-edit">
                            <i className="ri-pencil-line"></i>
                          </a>
                        </li>
                        <li>
                          <div className="partner-preferences-list-left">
                            <h5>Eating Habits</h5>
                            <span>Doesn't matter</span>
                          </div>
                          <a className="partner-preferences-edit">
                            <i className="ri-pencil-line"></i>
                          </a>
                        </li>
                        <li>
                          <div className="partner-preferences-list-left">
                            <h5>Drinking Habits</h5>
                            <span>Doesn't matter</span>
                          </div>
                          <a className="partner-preferences-edit">
                            <i className="ri-pencil-line"></i>
                          </a>
                        </li>
                        <li>
                          <div className="partner-preferences-list-left">
                            <h5>Smoking Habits</h5>
                            <span>Doesn't matter</span>
                          </div>
                          <a className="partner-preferences-edit">
                            <i className="ri-pencil-line"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="partner-preferences-content mb-5"
                    id="Professional-Preferences"
                  >
                    <div className="partner-preferences-head d-flex align-items-center">
                      <figure>
                        <img src={Icon3} />
                      </figure>
                      <figcaption>
                        <h5>Professional Preferences</h5>
                      </figcaption>
                    </div>
                    <div className="partner-preferences-body">
                      <ul className="partner-preferences-content">
                        <li>
                          <div className="partner-preferences-list-left">
                            <h5>Education</h5>
                            <span>
                              Professional Preferences EducationBachelors -
                              Engineering / Computers / Others, Masters - Engi
                              ...<a className="link-text">Read More</a>
                            </span>
                          </div>
                          <a className="partner-preferences-edit">
                            <i className="ri-pencil-line"></i>
                          </a>
                        </li>
                        <li>
                          <div className="partner-preferences-list-left">
                            <h5>Employed In</h5>
                            <span>Any</span>
                          </div>
                          <a className="partner-preferences-edit">
                            <i className="ri-pencil-line"></i>
                          </a>
                        </li>
                        <li>
                          <div className="partner-preferences-list-left">
                            <h5>Occupation</h5>
                            <span>Any</span>
                          </div>
                          <a className="partner-preferences-edit">
                            <i className="ri-pencil-line"></i>
                          </a>
                        </li>
                        <li>
                          <div className="partner-preferences-list-left">
                            <h5>Annual Income</h5>
                            <span>Any</span>
                          </div>
                          <a className="partner-preferences-edit">
                            <i className="ri-pencil-line"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="partner-preferences-content mb-5"
                    id="Location-Preferences"
                  >
                    <div className="partner-preferences-head d-flex align-items-center">
                      <figure>
                        <img src={Icon4} />
                      </figure>
                      <figcaption>
                        <h5>Location Preferences</h5>
                      </figcaption>
                    </div>
                    <div className="partner-preferences-body">
                      <ul className="partner-preferences-content">
                        <li>
                          <div className="partner-preferences-list-left">
                            <h5>Country</h5>
                            <span>Any</span>
                          </div>
                          <a className="partner-preferences-edit">
                            <i className="ri-pencil-line"></i>
                          </a>
                        </li>
                        <li>
                          <div className="partner-preferences-list-left">
                            <h5>Ancestral Origin</h5>
                            <span>Any</span>
                          </div>
                          <a className="partner-preferences-edit">
                            <i className="ri-pencil-line"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="partner-preferences-content"
                    id="looking-Preferences"
                  >
                    <div className="partner-preferences-head d-flex align-items-center">
                      <figure>
                        <img src={Icon5} />
                      </figure>
                      <figcaption>
                        <h5>What we are looking for</h5>
                      </figcaption>
                    </div>
                    <div className="partner-preferences-body">
                      <ul className="partner-preferences-content">
                        <li>
                          <div className="partner-preferences-list-left">
                            <span>Not Specified</span>
                          </div>
                          <a className="partner-preferences-edit">
                            <i className="ri-pencil-line"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </>
  );
};

export default EditPreference;
