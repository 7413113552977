import React, { useState, useEffect, useRef } from "react";
import {
  getProfessionalInfo,
  setProfessionalInfo,
  getProfile,
} from "../../services/user.service";
import { getEducations, getOccupations } from "../../services/global.service";
import {capitalizeFirstLetter, prepareLabel,} from "../../../../utils/commonfunction";
import * as Yup from "yup";
import CustomError from "../../shared/CustomError";
import { useFormik } from "formik";
import { failedResponse, successResponse } from "../../../../utils/toastr";
import { EMPLOYED_IN_OPTIONS,CURRENCIES_OPTIONS,ANNUAL_INCOME_OPTIONS } from "../../../../utils/Constants";
const EditProfessionalInfo = ({userInfo,userProfessionalInfo,onUserProfessionalInfoChange}) => {
  const [educations, setEducations] = useState([]);
  const [occupations, setOccupations] = useState([]);
  async function getEducationsData() {
    getEducations()
      .then((response) => {
        if (response.success) {
          setEducations(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });
  }
  async function getOccupationsData(id = 1) {
    getOccupations({ state_id: id })
      .then((response) => {
        if (response.success) {
          setOccupations(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });
  }

  useEffect(() => {
    getEducationsData();
    getOccupationsData();
  }, [userInfo,userProfessionalInfo]);

  const infoValidations = Yup.object().shape({
    education_id: Yup.string().required("This field is required"),
    education_detail: Yup.string(),
    college_institution: Yup.string().required("This field is required"),
    employed_in: Yup.string().required("This field is required"),
    occupation_id: Yup.string().required("This field is required"),
    occupation_detail: Yup.string(),
    organization_name: Yup.string(),
    annual_income_currency: Yup.string().required("This field is required"),
    annual_income_min: Yup.string().required("This field is required"),
    annual_income_max: Yup.string().required("This field is required"),
  });
  const infoForm = useFormik({
    initialValues: {
      education_id:
        (userProfessionalInfo && userProfessionalInfo.education_id) || "",
      education_detail:
        (userProfessionalInfo && userProfessionalInfo.education_detail) || "",
      college_institution:
        (userProfessionalInfo && userProfessionalInfo.college_institution) ||
        "",
      employed_in:
        (userProfessionalInfo && userProfessionalInfo.employed_in) || "",
      occupation_id:
        (userProfessionalInfo && userProfessionalInfo.occupation_id) || "",
      occupation_detail:
        (userProfessionalInfo && userProfessionalInfo.occupation_detail) || "",
      organization_name:
        (userProfessionalInfo && userProfessionalInfo.organization_name) || "",
      annual_income_currency:
        (userProfessionalInfo && userProfessionalInfo.annual_income_currency) ||
        "",
      annual_income_min:
        Math.round(userProfessionalInfo && userProfessionalInfo.annual_income_min) || "",
      annual_income_max:
      Math.round(userProfessionalInfo && userProfessionalInfo.annual_income_max) || (userInfo && userInfo.annual_income) ||'',
    },
    validationSchema: infoValidations,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const updateResponse = await setProfessionalInfo(values);
        if(updateResponse.success){
          toggleInfoEdit();
          onUserProfessionalInfoChange();
          successResponse(updateResponse.message);
        }
        else{
          failedResponse("Something Went Wrong");
        }
      } catch (err) {
        console.log("Error", err);
      }
    },
  });
  const [isInfoEditing, setIsInfoEditing] = useState(false);
  const toggleInfoEdit = () => {
    setIsInfoEditing(!isInfoEditing);
    if(!isInfoEditing){
      infoForm.resetForm()
    }
  };
  const handleCancel = () => {
    toggleInfoEdit();
    infoForm.resetForm()
  };
  const saveInfo = () => {
    toggleInfoEdit();
  };
  return (
    <>
      <div className="personal-info-head d-flex align-items-center justify-content-between mb-3">
        <h3>Professional Information</h3>
        <a className="btn-sm" onClick={toggleInfoEdit}>
          <i className="ri-pencil-fill"></i> {isInfoEditing ? "Close" : "Edit"}
        </a>
      </div>

      <div className="content-wrapper">
        {!isInfoEditing ? (
          <div className="personal-info-body">
            <div className="personal-info-list-box  d-flex">
              <ul className="personal-info-list">
                <li>
                  <span>Education</span>
                  <p>: {userProfessionalInfo && userProfessionalInfo.education || 'Not Specified'}</p>
                </li>
                <li>
                  <span>College / Institution</span>
                  <p>: {userProfessionalInfo && userProfessionalInfo.college_institution || 'Not Specified'}</p>
                </li>
                <li>
                  <span>Employed in</span>
                  <p>: {(userProfessionalInfo && userProfessionalInfo.employed_in) ? prepareLabel(userProfessionalInfo.employed_in): 'Not Specified'}</p>
                </li>
                <li>
                  <span>Education in Detail</span>
                  <p className="caps">: {userProfessionalInfo && userProfessionalInfo.education_detail || 'Not Specified'}</p>
                </li>
                </ul>
                <ul className="personal-info-list">
                <li>
                  <span>Occupation</span>
                  <p>: {userProfessionalInfo && userProfessionalInfo.occupation || 'Not Specified'}</p>
                </li>
                <li>
                  <span>Organization</span>
                  <p>: {userProfessionalInfo && userProfessionalInfo.organization_name || 'Not Specified'}</p>
                </li>
                <li>
                  <span>Annual Income</span>
                  <p>: {(userProfessionalInfo && userProfessionalInfo.annual_income_max) ? Math.round(userProfessionalInfo.annual_income_max) : (userInfo && userInfo.annual_income)
                  ? Math.round(userInfo.annual_income):  'Not Specified'}</p>
                </li>

                <li>
                  <span>Occupation in Detail</span>
                  <p className="caps">: {userProfessionalInfo && userProfessionalInfo.occupation_detail || 'Not Specified'}</p>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="personal-info-body">
            <form onSubmit={infoForm.handleSubmit}>
              <div className="personal-info-edit">
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Highest Education:
                      <small className="validate-tag"> *</small>
                    </label>
                      <select
                        className="form-select"
                        name="education_id"
                        onChange={infoForm.handleChange}
                        value={infoForm?.values?.education_id}
                      >
                        <option value="">Education</option>
                        {educations.map((m) => (
                          <option key={m.id} value={m.id}>
                            {m.name}
                          </option>
                        ))}
                      </select>

                  </div>
                      <CustomError name="education_id" form={infoForm} className="error-ml"/>
                </div>
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Education in Detail :
                    </label>
                      <input
                        type="text"
                        className="form-control"
                        name="education_detail"
                        onChange={infoForm.handleChange}
                        value={infoForm?.values?.education_detail}
                      />
                      <CustomError name="education_detail" form={infoForm} />
                  </div>
                </div>
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      College / Institution:
                      <small className="validate-tag"> *</small>
                    </label>
                      <input
                        type="text"
                        className="form-control"
                        name="college_institution"
                        onChange={infoForm.handleChange}
                        value={infoForm?.values?.college_institution}
                      />
                  </div>

                  <CustomError name="college_institution" form={infoForm} className="error-ml"/>
                </div>
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Employed in : <small className="validate-tag"> *</small>
                    </label>
                      <ul className="list-sec d-flex align-items-center">
                        {EMPLOYED_IN_OPTIONS.map((item, i) => {
                          return (
                            <li key={i}>
                              <div className="form-check d-flex align-items-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  checked={infoForm.values.employed_in==item?.value}
                                  name={"employed_in"}
                                  onChange={infoForm.handleChange}
                                  value={item?.value}
                                  id={"employed_in" + i}
                                />
                                <label
                                  className="form-check-label ms-2"
                                  htmlFor={"employed_in" + i}
                                >
                                  {" "}
                                  {item?.label}
                                </label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                  </div>
                  <CustomError name="employed_in" form={infoForm} className="error-ml"/>
                </div>
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Occupation :<small className="validate-tag"> *</small>
                    </label>
                    <select
                        className="form-select"
                        name="occupation_id"
                        onChange={infoForm.handleChange}
                        value={infoForm?.values?.occupation_id}
                      >
                        <option value="">Occupation</option>
                        {occupations.map((m) => (
                          <option key={m.id} value={m.id}>
                            {m.name}
                          </option>
                        ))}
                      </select>
                  </div>
                  <CustomError name="occupation_id" form={infoForm} className="error-ml"/>
                </div>
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Occupation in Detail :
                    </label>
                     
                    <input
                        type="text"
                        className="form-control"
                        name="occupation_detail"
                        onChange={infoForm.handleChange}
                        value={infoForm?.values?.occupation_detail}
                      />
                      <CustomError name="occupation_detail" form={infoForm} />
                    </div>
                </div>
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">Organization :</label>
            
                    <input
                        type="text"
                        className="form-control"
                        name="organization_name"
                        onChange={infoForm.handleChange}
                        value={infoForm?.values?.organization_name}
                      />

                      <CustomError name="organization_name" form={infoForm} className="error-ml"/>
                    </div>
                </div>
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">Annual Income Currency:<small className="validate-tag"> *</small></label>
                      <select className="form-select me-3" name="annual_income_currency" onChange={infoForm.handleChange}
                        value={infoForm?.values?.annual_income_currency}>
                        <option value=''>Currency</option>
                        {
                          CURRENCIES_OPTIONS.map((item, i) => {
                          return (<option key={i} value={item?.value}>{prepareLabel(item.label)}</option>) })
                        }
                      </select>
                  </div>
                      <CustomError name="annual_income_currency" form={infoForm} className="error-ml"/>
                </div>
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">Min Annual Income :<small className="validate-tag"> *</small></label>
                   
                    <select className="form-select me-3" name='annual_income_min' 
                      onChange={infoForm.handleChange} value={infoForm?.values?.annual_income_min}>
                        <option value={''}>Select</option>
                         {ANNUAL_INCOME_OPTIONS.map((item, i) => {
                            return (<option key={i} value={item?.value}>{prepareLabel(item.label)}</option>)})
                           }
                      </select>
                  </div>
                      <CustomError name="annual_income_min" form={infoForm} className="error-ml"/>
                </div>
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">Max Annual Income :<small className="validate-tag"> *</small></label>
                      <select className="form-select me-3" name='annual_income_max' 
                      onChange={infoForm.handleChange} value={infoForm?.values?.annual_income_max}>
                        <option value={''}>Select</option>
                         {ANNUAL_INCOME_OPTIONS.map((item, i) => {
                            return (<option key={i} value={item?.value}>{prepareLabel(item.label)}</option>)})
                           }
                      </select>
                    </div>
                      <CustomError name="annual_income_max" form={infoForm} className="error-ml"/>
                </div>
                <div className="personal-info-btn mt-3 text-end">
                  <button className="btn-sm" type="submit">Save</button>
                  <button className="btn-sm btn-outline ms-2" type="button" onClick={handleCancel}>Cancel</button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default EditProfessionalInfo;
