import {
  FAMILY_VALUES_OPTIONS,
  FAMILY_TYPES_OPTIONS,
  FAMILY_STATUS_OPTIONS,
} from "../../../../utils/Constants";
import React, { useState, useEffect, useRef } from "react";
import {
  getFamilyInfo,
  getProfile,
  setFamilyInfo,
} from "../../services/user.service";
import {
  getCities,
  getCountries,
  getStates,
  getOccupations,
} from "../../services/global.service";
import {
  capitalizeFirstLetter,
  prepareLabel,
} from "../../../../utils/commonfunction";
import * as Yup from "yup";
import CustomError from "../../shared/CustomError";
import { useFormik } from "formik";
import { failedResponse, successResponse } from "../../../../utils/toastr";
const EditFamilyInfo = ({ userInfo }) => {
  const [isInfoEditing, setIsInfoEditing] = useState(false);
  const [userFamilyInfo, setUserFamilyInfo] = useState(null);
  const [occupations, setOccupations] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [Cities, setCities] = useState([]);
  async function fetchUserFamilyData() {
    getFamilyInfo()
      .then((response) => {
        if (response.success) {
          setUserFamilyInfo(response.data ? response.data : {});
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });
  }

  async function getOccupationsData(id = 1) {
    getOccupations({ state_id: id })
      .then((response) => {
        if (response.success) {
          setOccupations(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });
  }

  const toggleInfoEdit = () => {
    setIsInfoEditing(!isInfoEditing);
  };
  const handleCancel = () => {
    toggleInfoEdit();
  };
  const saveInfo = () => {
    toggleInfoEdit();
  };

  async function getCountriesData() {
    getCountries()
      .then((response) => {
        if (response.success) {
          setCountries(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });
  }
  async function getCitiesData(id = 1) {
    getCities({ state_id: id })
      .then((response) => {
        if (response.success) {
          setCities(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });
  }
  async function getStatesData(id = 1) {
    getStates({ country_id: id })
      .then((response) => {
        if (response.success) {
          setStates(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });
  }
  const handelCountryChange = (e) => {
    infoForm.setFieldValue("family_location_country", e.target.value);
    getStatesData(e.target.value);
    infoForm.setFieldValue("family_location_state", "");
    infoForm.setFieldValue("family_location_city", "");
  };

  const handelStateChange = (e) => {
    infoForm.setFieldValue("family_location_state", e.target.value);
    getCitiesData(e.target.value);
    infoForm.setFieldValue("family_location_city", "");
  };

  // const setFamilyLocationType =()=> {
  //   setFamilyLocationType(1)
  //   infoForm.setFieldValue("family_location_country", "");
  //   infoForm.setFieldValue("family_location_state", "");
  //   infoForm.setFieldValue("family_location_city", "");
  // }
  useEffect(() => {
    fetchUserFamilyData();
    getOccupationsData();
    getCountriesData();
    getStatesData();
    getCitiesData();
  }, [userInfo]);

  const infoValidations = Yup.object().shape({
    family_status: Yup.string().required("Family status is required"),
    family_type: Yup.string().required("Family type is required"),
    family_values: Yup.string().required("Family values is required"),
    father_occupation: Yup.string(),
    mother_occupation: Yup.string(),
    number_of_brothers: Yup.string(),
    number_of_sisters: Yup.string(),
    number_of_sisters_married: Yup.string().test({
      name: 'sisters-married-validation',
      exclusive: true,
      message: 'Number of sisters married cannot be greater than the number of sisters',
      test: function (value) {
        const { number_of_sisters, number_of_sisters_married } = this.parent;
        return (
          !number_of_sisters ||
          !number_of_sisters_married ||
          parseInt(number_of_sisters_married, 10) <= parseInt(number_of_sisters, 10)
        );
      },
    }),
    number_of_brothers_married: Yup.string().test({
      name: 'brothers-married-validation',
      exclusive: true,
      message: 'Number of brothers married cannot be greater than the number of brothers',
      test: function (value) {
        const { number_of_brothers, number_of_brothers_married } = this.parent;
        return (
          !number_of_brothers ||
          !number_of_brothers_married ||
          parseInt(number_of_brothers_married, 10) <= parseInt(number_of_brothers, 10)
        );
      },
    }),
    family_location_same_as_mine: Yup.boolean(),
    family_location_country: Yup.string(),
    family_location_state: Yup.string(),
    family_location_city: Yup.string(),
    // family_location_country: Yup.string().when('family_location_same_as_mine', {
    //   is: false,
    //   then: Yup.string().required('This field is required when family_location_same_as_mine is false'),
    // }),
    // family_location_state: Yup.string().when('family_location_same_as_mine', {
    //   is: false,
    //   then: Yup.string().required('This field is required when family_location_same_as_mine is false'),
    // }),
    // family_location_city: Yup.string().when('family_location_same_as_mine', {
    //   is: false,
    //   then: Yup.string().required('This field is required when family_location_same_as_mine is false'),
    // }),
  });

  const infoForm = useFormik({
    initialValues: {
      family_values: (userFamilyInfo && userFamilyInfo.family_values) || "",
      family_type: (userFamilyInfo && userFamilyInfo.family_type) || "",
      family_status: (userFamilyInfo && userFamilyInfo.family_status) || "",
      father_occupation:
        (userFamilyInfo && userFamilyInfo.father_occupation) || "",
      mother_occupation:
        (userFamilyInfo && userFamilyInfo.mother_occupation) || "",
      number_of_brothers:
        userFamilyInfo && userFamilyInfo.number_of_brothers
          ? String(userFamilyInfo.number_of_brothers).padStart(2, "0")
          : "",
      number_of_sisters:
        userFamilyInfo && userFamilyInfo.number_of_sisters
          ? String(userFamilyInfo.number_of_sisters).padStart(2, "0")
          : "",
      family_location_country:
        (userFamilyInfo && userFamilyInfo.family_location_country) || "",
      family_location_state:
        (userFamilyInfo && userFamilyInfo.family_location_state) || "",
      family_location_city:
        (userFamilyInfo && userFamilyInfo.family_location_city) || "",
      number_of_sisters_married:
        userFamilyInfo && userFamilyInfo.number_of_sisters_married
          ? String(userFamilyInfo.number_of_sisters_married).padStart(2, "0")
          : "",
      number_of_brothers_married:
        userFamilyInfo && userFamilyInfo.number_of_brothers_married
          ? String(userFamilyInfo.number_of_brothers_married).padStart(2, "0")
          : "",
      family_location_same_as_mine:
        userFamilyInfo && userFamilyInfo.family_location_same_as_mine
          ? Number(userFamilyInfo.family_location_same_as_mine)
          : 1,
    },
    validationSchema: infoValidations,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const updateResponse = await setFamilyInfo(values);
        if (updateResponse.success) {
          fetchUserFamilyData();
          toggleInfoEdit();
          successResponse(updateResponse.message);
        } else {
          failedResponse("Something Went Wrong");
        }
      } catch (err) {
        console.log("Error", err);
      }
    },
  });
  return (
    <>
      <div className="personal-info-head d-flex align-items-center justify-content-between mb-3">
        <h3>Family Details</h3>
        <a className="btn-sm" onClick={toggleInfoEdit}>
          <i className="ri-pencil-fill"></i> {isInfoEditing ? "Close" : "Edit"}
        </a>
      </div>

      <div className="content-wrapper">
        {!isInfoEditing ? (
          <div className="personal-info-body">
            <div className="personal-info-list-box  d-flex">
              <ul className="personal-info-list">
                <li>
                  <span>Family Values</span>
                  <p className="caps">
                    :{" "}
                    {userFamilyInfo && userFamilyInfo.family_values
                      ? userFamilyInfo && userFamilyInfo.family_values
                      : "Not Specified"}
                  </p>
                </li>
                <li>
                  <span>Family Type</span>
                  <p className="caps">
                    :{" "}
                    {userFamilyInfo && userFamilyInfo.family_type
                      ? userFamilyInfo && userFamilyInfo.family_type
                      : "Not Specified"}
                  </p>
                </li>
                <li>
                  <span>Family Status</span>
                  <p className="caps">
                    :{" "}
                    {userFamilyInfo && userFamilyInfo.family_status
                      ? prepareLabel(
                          userFamilyInfo && userFamilyInfo.family_status
                        )
                      : "Not Specified"}
                  </p>
                </li>
                <li>
                  <span>No of Sister(s)</span>
                  <p>
                    :{" "}
                    {userFamilyInfo && userFamilyInfo.number_of_sisters
                      ? userFamilyInfo.number_of_sisters :'Not Specified'}
                      {userFamilyInfo && userFamilyInfo.number_of_sisters &&
                        userFamilyInfo.number_of_sisters_married
                      ? " / " +
                        userFamilyInfo.number_of_sisters_married +
                        " Married"
                      : ""}
                  </p>
                </li>
              </ul>
              <ul className="personal-info-list">
                <li>
                  <span>Father's Occupation</span>
                  <p>
                    :{" "}
                    {userFamilyInfo && userFamilyInfo.fatherOccupation
                      ? userFamilyInfo && userFamilyInfo.fatherOccupation
                      : "Not Specified"}
                  </p>
                </li>
                <li>
                  <span>Mother's Occupation</span>
                  <p>
                    :{" "}
                    {userFamilyInfo && userFamilyInfo.motherOcupation
                      ? userFamilyInfo && userFamilyInfo.motherOcupation
                      : "Not Specified"}
                  </p>
                </li>
                <li>
                  <span>No of Brothers</span>
                  <p>
                    :{" "}
                    {userFamilyInfo && userFamilyInfo.number_of_brothers
                      ? userFamilyInfo.number_of_brothers :'Not Specified'}
                      {userFamilyInfo && userFamilyInfo.number_of_brothers &&
                        userFamilyInfo.number_of_brothers_married
                      ? " / " +
                        userFamilyInfo.number_of_brothers_married +
                        " Married"
                      : ""}
                  </p>
                </li>
                <li>
                  <span>Family Location</span>
                  <p>
                    :
                    {userFamilyInfo &&
                    userFamilyInfo.family_location_same_as_mine === 1
                      ? `Same as ${
                          userInfo && userInfo.gender === "female"
                            ? "bride"
                            : "groom"
                        }'s Location`
                      : userFamilyInfo?.city && userFamilyInfo?.state
                      ? `${userFamilyInfo?.city}, ${userFamilyInfo?.state}`
                      : userFamilyInfo?.city ||
                        userFamilyInfo?.state ||
                        "Not specified"}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="personal-info-body">
            <form onSubmit={infoForm.handleSubmit}>
              <div className="personal-info-edit">
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Family Value : <small className="validate-tag"> *</small>
                    </label>
                    <ul className="list-sec d-flex align-items-center">
                      {Object.keys(FAMILY_VALUES_OPTIONS).map((item, i) => {
                        return (
                          <li key={i}>
                            <div className="form-check d-flex align-items-start">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={"family_values"}
                                value={FAMILY_VALUES_OPTIONS[item]}
                                id={"family_values" + i}
                                checked={
                                  infoForm.values.family_values ===
                                  FAMILY_VALUES_OPTIONS[item]
                                }
                                onChange={infoForm.handleChange}
                              />
                              <label
                                className="form-check-label ms-2"
                                htmlFor={"family_values" + i}
                              >
                                {prepareLabel(FAMILY_VALUES_OPTIONS[item])}
                              </label>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <CustomError
                    name="family_values"
                    form={infoForm}
                    className="error-ml"
                  />
                </div>
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Family Type : <small className="validate-tag"> *</small>
                    </label>
                    <ul className="list-sec d-flex align-items-center">
                      {Object.keys(FAMILY_TYPES_OPTIONS).map((item, i) => {
                        return (
                          <li key={i}>
                            <div className="form-check d-flex align-items-start">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={"family_type"}
                                value={FAMILY_TYPES_OPTIONS[item]}
                                id={"family_type" + i}
                                checked={
                                  infoForm.values.family_type ===
                                  FAMILY_TYPES_OPTIONS[item]
                                }
                                onChange={infoForm.handleChange}
                              />
                              <label
                                className="form-check-label ms-2"
                                htmlFor={"family_type" + i}
                              >
                                {prepareLabel(FAMILY_TYPES_OPTIONS[item])}
                              </label>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <CustomError
                    name="family_type"
                    form={infoForm}
                    className="error-ml"
                  />
                </div>
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Family Status : <small className="validate-tag"> *</small>
                    </label>
                    <ul className="list-sec d-flex align-items-center">
                      {Object.keys(FAMILY_STATUS_OPTIONS).map((item, i) => {
                        return (
                          <li key={i}>
                            <div className="form-check d-flex align-items-start">
                              <input
                                className="form-check-input"
                                type="radio"
                                checked={
                                  infoForm.values.family_status ===
                                  FAMILY_STATUS_OPTIONS[item]
                                }
                                onChange={infoForm.handleChange}
                                name="family_status"
                                value={FAMILY_STATUS_OPTIONS[item]}
                                id={"family_status" + i}
                              />
                              <label
                                className="form-check-label ms-2"
                                htmlFor={"family_status" + i}
                              >
                                {prepareLabel(FAMILY_STATUS_OPTIONS[item])}
                              </label>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <CustomError
                    name="family_status"
                    form={infoForm}
                    className="error-ml"
                  />
                </div>
                <div className="row in-block-field mb-3">
                  <div className="form-group  col-md-5">
                    <label className="">Father's Occupation </label>
                    <select
                      className="form-select"
                      name="father_occupation"
                      onChange={infoForm.handleChange}
                      value={infoForm?.values?.father_occupation}
                    >
                      <option value="">Occupation</option>
                      {occupations.map((m) => (
                        <option key={m.id} value={m.id}>
                          {m.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group  col-md-5">
                    <label className="">Mother's Occupation</label>
                    <select
                      className="form-select"
                      name="mother_occupation"
                      onChange={infoForm.handleChange}
                      value={infoForm?.values?.mother_occupation}
                    >
                      <option value="">Occupation</option>
                      {occupations.map((m) => (
                        <option key={m.id} value={m.id}>
                          {m.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row in-block-field mb-3">
                  <div className="form-group  col-md-2">
                    <label className="">No. of Brothers </label>
                    <select
                      className="form-select"
                      name="number_of_brothers"
                      onChange={infoForm.handleChange}
                      value={infoForm?.values?.number_of_brothers}
                    >
                      <option>-select-</option>
                      {Array.from({ length: 10 }, (_, index) => (
                        <option
                          key={index + 1}
                          value={String(index + 1).padStart(2, "0")}
                        >
                          {String(index + 1).padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group  col-md-3">
                    <label className="">Brothers Married</label>
                    <select
                      className="form-select"
                      name="number_of_brothers_married"
                      onChange={infoForm.handleChange}
                      value={infoForm?.values?.number_of_brothers_married}
                    >
                      <option>-select-</option>
                      {Array.from({ length: 10 }, (_, index) => (
                        <option
                          key={index + 1}
                          value={String(index + 1).padStart(2, "0")}
                        >
                          {String(index + 1).padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group  col-md-2">
                    <label className="">No. of Sisters</label>
                    <select
                      className="form-select"
                      name="number_of_sisters"
                      onChange={infoForm.handleChange}
                      value={infoForm?.values?.number_of_sisters}
                    >
                      <option>-select-</option>
                      {Array.from({ length: 10 }, (_, index) => (
                        <option
                          key={index + 1}
                          value={String(index + 1).padStart(2, "0")}
                        >
                          {String(index + 1).padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group  col-md-3">
                    <label className="">Sisters Married</label>
                    <select
                      className="form-select"
                      name="number_of_sisters_married"
                      onChange={infoForm.handleChange}
                      value={infoForm?.values?.number_of_sisters_married}
                    >
                      <option>-select-</option>
                      {Array.from({ length: 10 }, (_, index) => (
                        <option
                          key={index + 1}
                          value={String(index + 1).padStart(2, "0")}
                        >
                          {String(index + 1).padStart(2, "0")}
                        </option>
                      ))}
                    </select>

                  </div>
                    <CustomError
                        name="number_of_sisters_married"
                        form={infoForm}
                      />
                    
                    <CustomError
                        name="number_of_brothers_married"
                        form={infoForm}
                      />
                </div>
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">Family Location:</label>
                    <ul className="list-sec d-flex">
                      <li>
                        <div className="form-check d-flex align-items-center">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="family_location_same_as_mine_1"
                            name="family_location_same_as_mine"
                            value={1}
                            checked={
                              infoForm.values.family_location_same_as_mine == 1
                            }
                            onChange={infoForm.handleChange}
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor="flexCheckDefault"
                          >
                            Same as my Location
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check d-flex align-items-center">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="family_location_same_as_mine_2"
                            name="family_location_same_as_mine"
                            checked={
                              infoForm.values.family_location_same_as_mine == 0
                            }
                            onChange={infoForm.handleChange}
                            value={0}
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor="flexCheckDefault"
                          >
                            Different Location
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {infoForm.values.family_location_same_as_mine == 0 ? (
                  <div className="row in-block-field mb-3">
                    <div className="form-group  col-md-3">
                      <label className="">Family Country</label>
                      <select
                        className="form-select"
                        name="family_location_country"
                        onChange={handelCountryChange}
                        value={infoForm?.values?.family_location_country}
                      >
                        <option value="">Country</option>
                        {countries.map((m) => (
                          <option key={m.id} value={m.id}>
                            {m.name}
                          </option>
                        ))}
                      </select>

                      <CustomError
                        name="family_location_country"
                        form={infoForm}
                        className="error-ml"
                      />
                    </div>
                    <div className="form-group  col-md-4">
                      <label className="">Family State</label>
                      <select
                        className="form-select"
                        name="family_location_state"
                        onChange={handelStateChange}
                        value={infoForm?.values?.family_location_state}
                      >
                        <option value="" disabled>
                          State
                        </option>
                        {states.map((m) => (
                          <option key={m.id} value={m.id}>
                            {m.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group  col-md-3">
                      <label className="">Family City</label>
                      <select
                        className="form-select"
                        name="family_location_city"
                        onChange={infoForm.handleChange}
                        value={infoForm?.values?.family_location_city}
                      >
                        <option value="" disabled>
                          City
                        </option>
                        {Cities.map((m) => (
                          <option key={m.id} value={m.id}>
                            {m.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : null}
                <div className="personal-info-btn mt-3 text-end">
                  <button className="btn-sm" type="submit">
                    Save
                  </button>
                  <button
                    className="btn-sm btn-outline ms-2"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default EditFamilyInfo;
