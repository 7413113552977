import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getLocalKey } from '../../../utils/commonfunction';

const PublicRoute = ({ component: Component }) => {
    const getAdminkey = getLocalKey("admin_aws_auth") ? JSON.parse(getLocalKey("admin_aws_auth")) : {};

    useEffect(() => {
        Promise.all([import('rsuite/dist/rsuite.min.css'),
        import('bootstrap/dist/css/bootstrap.min.css'),
        import("../../../assets/admin/css/skins.css"),
        import("../../../assets/admin/css/icon-list.css"),
        import("../../../assets/admin/plugins/select2/css/select2.min.css"),
        import("../../../assets/admin/css/style.css"),
        import("../../../assets/admin/css/dark-style.css"),
        import("../../../assets/admin/css/colors/default.css"),
        import("../../../assets/admin/plugins/multipleselect/multiple-select.css"),
        import("../../../assets/admin/css/sidemenu/sidemenu.css"),
        import("../../../assets/admin/plugins/summernote/summernote-bs4.css"),
        import("../../../assets/admin/plugins/fileuploads/css/fileupload.css"),
        import("../../../assets/admin/plugins/fancyuploder/fancy_fileupload.css")]).then(() => {
            // dispatch(setGlobalLoader(false));
        })
    }, []);


    return (
        <>
            {(function(){
                if(Object.keys(getAdminkey).length == 0){
                    return Component;
                }else{
                    return <Navigate to="/admin/dashboard" />;
                }
            })()}
        </>
    )

}

export default PublicRoute;