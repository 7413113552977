import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { formateDate, handleServerValidations, TrimText } from '../../../../../utils/commonfunction';
import AppBreadCrumbs from '../../../common/breadcrumbs';
import CustomPagination from '../../../common/customPagination';
import GlobalLoader from '../../../shared/globalLoader';
import { PageDelete, PagesList } from '../../../services/cms.services';
import Swal from 'sweetalert2';
import { SWAL_SETTINGS } from '../../../../../utils/Constants';
import $ from "jquery";
import Sorting from '../../../common/sorting';

function CmsPagesList() {

    const navigate = useNavigate();
    const tableRef = useRef(null);
    const params = useParams();
    const [datalength, setDatalength] = useState("");
    const [dynamicColumn, setDynamicColumn] = useState("");
    const [list, setList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [globalsearch, setGlobalSearch] = useState("");
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({});
    const [serialno, setSerialNo] = useState("");
    const [page, setPage] = useState(params.pgno);
    const [itemperpage, setItemPerPage] = useState(10);
    const [statsupdate, setStatusUpdate] = useState("false");
    const [defaultSorting, setDefaultSorting] = useState(true);

    const breadcrumbs = [{title:"Dashboard", url:"/admin/dashboard"},{title:"Pages", url:""}];

    useEffect(() => {
        if(tableRef?.current){
            let rows =  tableRef?.current?.childNodes[0];
            let colCount = rows?.childNodes.length;
            setDynamicColumn(colCount);
        }
    },[tableRef?.current]);

    useEffect(() => {
        setLoader(true);
        let formData = new FormData();
        formData.append("page", params.pgno);
        formData.append("per_page", itemperpage);
        formData.append("paginate", true);
        formData.append("sort", JSON.stringify(sorting));
        formData.append("search", JSON.stringify(search));
        formData.append("global_search", globalsearch)
        PagesList(formData).then((response) => {
            if(response?.success){
                setList(response?.data?.list);
                setSerialNo(response?.data?.offset);
                setDatalength(response?.data?.total_records);
                setLoader(false);
            }
        }).catch((err) => {
            console.log("err",err);
            setLoader(false);
        })
    },[sorting, search,itemperpage,params.pgno, page,statsupdate,globalsearch]);

    // const viewFunction = (row) => {
    //     navigate(`/admin/cms/pages/view/${row?.id}`)
    // }

    const editFunction = (row) => {
        navigate(`/admin/cms/pages/edit/${row?.id}`)
    }

    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }

    const resetFilter = (e) => {
        e.preventDefault();
        setGlobalSearch("");
        prepareSearch();
        setSearch({});
        // $("#defaultstatus")[0].selectedIndex = 0;
      };

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }



    function Deletefunction(data) {
        Swal.fire({
          customClass: "swal-wide",
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#403fad",
          cancelButtonColor: "#f1388b",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            PageDelete(data)
              .then((response) => {
                setStatusUpdate(!statsupdate);
                if (response.success) {
                  Swal.fire({
                    icon: "success",
                    text: response.message,
                    ...SWAL_SETTINGS,
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                  });
                }
              })
              .catch((error) => {
                console.log("deleteError");
              });
          }
        });
      }

  return (
    <>
            <AppBreadCrumbs data={breadcrumbs} />
            {/* table section */}
            <div className="animation_fade">
                <div className="card custom-card overflow-hidden">
                    <div className="card-body">
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <h6 className="main-content-label">CMS PAGES</h6>
                            <div className='d-flex align-items-center'>
                                <div className='form-group mb-0 me-3'>
                                    <div className="form-group mb-0 rangepicker_container filter_design">
                                        <i className="fa fa-search calender_icon"></i>
                                        <input type="search" className="form-control" value={globalsearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                    </div>
                                </div>
                                {/* <div className="me-3">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("createdAt", e) }} resetdate={resetdate} />
                                </div> */}
                                {/* <div className="me-3">
                                    <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                </div> */}
                                <button type="reset" value='Reset'  onClick={resetFilter} className="btn btn-warning float-right mr-2">Reset Filter</button>
                                <button className="btn ripple btn-main-primary signbtn" onClick={() => navigate(`/admin/cms/pages/add`)}>Add New</button>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table id="example-input" className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                <thead ref={tableRef}>
                                    <tr>
                                        <th style={{width:'50px'}}>
                                            <div>
                                                <span>S.no</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Title</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="title" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Slug</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="slug" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="sorting_column"> 
                                                <span>Description</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="description" />
                                            </div>
                                        </th>

                                        <th className="created_head">
                                            <div className='sorting_column'>
                                                <span>Created Date</span>
                                                <Sorting sort={sorting} defaultSorting={defaultSorting} handleSort={handleSort} column="created_at" />
                                            </div>
                                        </th>
                                        {/* <th className='status_head'>Status</th> */}
                                        <th className='action_head'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={dynamicColumn}>
                                                <GlobalLoader height={'10vh'} background={'#fff'} textColor={'#504fb3'}/>
                                            </td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => {
                                                return <tr key={i} >
                                                    <td>{serialno + i + 1}</td>
                                                    <td>{row?.title ? TrimText(row.title,20) : "N/A"}</td>
                                                    <td>{row?.slug ? row.slug : "N/A"}</td>
                                                    <td><div dangerouslySetInnerHTML={{__html: row?.description ? TrimText(row?.description) : "N/A"}}></div></td>
                                                    <td>{row?.created_at ? formateDate(row.created_at) : "N/A"}</td>
                                                    {/* <td>
                                                        {row.status === 1 ? 
                                                            <button className="btn ripple btn-main-primary signbtn" onClick={() => { ChangeStatus(row?._id, 0) }}>Active</button>
                                                        : 
                                                            <button className="btn ripple btn-secondary" onClick={() => { ChangeStatus(row?._id, 1) }}>Inactive </button>
                                                        }
                                                    </td> */}
                                                    <td>
                                                        <div className="d-flex">
                                                            {/* <button className="btn ripple btn-main-primary signbtn " onClick={() => { viewFunction(row) }}>View</button> */}
                                                            <button className="btn ripple btn-success mlAction ms-1" onClick={() => { editFunction(row) }}>Edit</button>
                                                            <button className="btn ripple btn-secondary mlAction ms-1" onClick={()=>{ Deletefunction(row?.id);}}>Delete</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }) : <tr><td colSpan={dynamicColumn} className="text-center">No records</td></tr>}</>}
                                </tbody>
                            </table>
                        </div>
                        <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {datalength ? datalength : "0"}</b></div>
                        {datalength && datalength > 0 ?
                            <CustomPagination datalength={datalength} itemperpage={itemperpage} setPage={setPage} currentPage={page} pageRoute={[{ name: "PageList", path: "/admin/cms/pages/list" }]} /> : ""}
                    </div>
                    <div>
                    </div>
                </div>
            </div>
            {/* table section end */}
        </>
  )
}

export default CmsPagesList