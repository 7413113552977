import { MONTHS } from "./Constants";
import { cognitoLogOut } from "./amazon_utils2";

export const capitalizeFirstLetter = (str) => {
    return str ? str?.charAt(0)?.toUpperCase() + str?.slice(1) :'';
};

export const encodeValue = (value) => {
    return btoa(value);
};

export const decodeValue = (value) => {
    return atob(value);
};

export const setLocalKey = (key, value) => {
    if (process.env.REACT_APP_PRODUCTION === "true") {
        localStorage.setItem(encodeValue(key), encodeValue(value));
    } else {
        localStorage.setItem(key, value);
    }
};

export const getLocalKey = (key) => {
    if (process.env.REACT_APP_PRODUCTION === "true") {
        if (localStorage.getItem(encodeValue(key))) {
            return decodeValue(localStorage.getItem(encodeValue(key)));
        }
    } else {
        if (localStorage.getItem(key)) {
            return localStorage.getItem(key);
        }
    }
};

export const removeLocalKey = (key) => {
    if (process.env.REACT_APP_PRODUCTION === "true") {
        localStorage.removeItem(encodeValue(key));
    } else {
        localStorage.removeItem(key);
    }
};

export const setSessionKey = (key, value) => {
    if (process.env.REACT_APP_PRODUCTION === "true") {
        sessionStorage.setItem(encodeValue(key), encodeValue(value));
    } else {
        sessionStorage.setItem(key, value);
    }
};

export const getSessionKey = (key) => {
    if (process.env.REACT_APP_PRODUCTION === "true") {
        if (sessionStorage.getItem(encodeValue(key))) {
            return decodeValue(sessionStorage.getItem(encodeValue(key)));
        }
    } else {
        if (sessionStorage.getItem(key)) {
            return sessionStorage.getItem(key);
        }
    }
};

export const removeSessionKey = (key) => {
    if (process.env.REACT_APP_PRODUCTION === "true") {
        sessionStorage.removeItem(encodeValue(key));
    } else {
        sessionStorage.removeItem(key);
    }
};

export const getUser = function () {
    if (getLocalKey("user_token")) {
        return getLocalKey("user_token");
    } else {
        return {};
    }
};

export const getAdmin = function () {
    if (getLocalKey("admin")) {
        return JSON.parse(getLocalKey("admin"));
    } else {
        return {};
    }
};

export const logOutAdmin = () => {
    removeLocalKey("admin_aws_auth");
    if (getLocalKey("admin_aws_auth")) {
        window.location.href = "/";
    } else {
        window.location.href = "/admin/login";
    }
};

export const logOutUser = () => {
    cognitoLogOut().then(()=>{
        removeLocalKey("token");
        removeLocalKey("USER_DATA");
        removeLocalKey("user_token");
        removeSessionKey("USER_DATA");
            window.location.href = "/";
    }).catch((err)=>{
        console.error("Error while logout ",err);
    });
};

export const getQuery = (obj) => {
    const keys = Object.keys(obj);
    if(keys && keys.length){
        return '?'+(keys.map(key => `${key}=${obj[key]}`).join('&'));
    }else{
        return '';
    }
};

export const removeSpecialCharacter = (text) => {
    return text ? text?.replace(/-/g, " ")?.replace(/_/g, " "): '';
};

export const prepareLabel = (text) => {
    return capitalizeFirstLetter(removeSpecialCharacter(text));
};

export const handleServerValidations = (response) => {
    let message = response.message;
    if (Array.isArray(response.error)) {
        if (response.error[0] && response.error[0].msg) {
        message = response.error[0].msg;
        } else if (response.error[0] && response.error[0].message) {
        message = response.error[0].message;
        }
    }
    return message;
};

export const formateDate = (dateString, showTime = true) => {
    let newStrDate = "";
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        return "NA";
    }
    newStrDate =
        MONTHS[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
    if (showTime) {
        const hour =
        date.getHours() === 0
            ? 12
            : date.getHours() > 12
            ? date.getHours() - 12
            : date.getHours();
        const min =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        const secs =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        const ampm = date.getHours() < 12 ? "AM" : "PM";
        newStrDate += ` ${hour}:${min}:${secs} ${ampm}`;
    }
    return newStrDate;
};

export const showStatus = (status) => {
    let result = null;
    switch (status) {
        case 0:
            result = `<span className="badge badge-danger" href="#">Inactive</span>`;
            break;
        case 1:
            result = `<span className="badge badge-primary" href="#">Active</span>`;
            break;
        default:
            result =  `<span className="badge badge-danger" href="#">Unknown</span>`;
    }
    return result;
}

export const showBoolean = (status) => {
    let result = null;
    switch (status) {
        case 0:
            result = `<span className="badge badge-danger" href="#">False</span>`;
            break;
        case 1:
            result = `<span className="badge badge-primary" href="#">True</span>`;
            break;
        default:
            result =  `<span className="badge badge-danger" href="#">Unknown</span>`;
    }
    return result;
}

export const TrimText = (value, size = 40) => {
    return value !== undefined && value.length > size
        ? value.slice(0, size) + "..."
        : value;
};  

export const globalLoader = (action) => {
    const loader = document.getElementById("main-loader");
    if (loader) {
        let customStyle = action ? "flex" : "none";
        loader.style.display = customStyle;
    }
};

export function convertHeightToFeetAndInches(type,height) {
    var totalInches = 0;
    var cms = 0;
    let feet = 0
    let inches = 0
    if(type=='feet'){
      feet = Math.floor(height);
      inches = Math.round((height - feet) * 12);
      totalInches = feet * 12 + inches;
      cms = totalInches * 2.54;
      return inches!=0 ? `${feet} Ft ${inches} In / ${cms} Cms`: `${feet} Ft / ${cms} Cms`;
    }
    else{
      totalInches = height
      cms = totalInches * 2.54; 
      feet = Math.floor(totalInches / 12);
      let remainingInches = totalInches % 12;
      return remainingInches != 0 ? `${feet} Ft ${remainingInches} In / ${cms} Cms` : `${feet} Ft / ${cms} Cms`;
    }
}

export function convertCmToFeetAndInches(cm) {
    const inches = cm / 2.54;
    const feet = Math.floor(inches / 12);
    const remainingInches = Math.round(inches % 12);

    return `${feet} ft ${remainingInches} in/ ${cm} Cms`;
}

export const hideAllErrors = (formik) => {
    const touchedFields = {};
    Object.keys(formik.values).forEach(field => {
        touchedFields[field] = false;
    });
    formik.setTouched(touchedFields);
};

export const greet = () => {
    const now = new Date();
    const hour = now.getHours();

    if (hour >= 5 && hour < 12) {
        return "Good Morning!";
    } else if (hour >= 12 && hour < 18) {
        return "Good Afternoon!";
    } else {
        return "Good Evening!";
    }
}
