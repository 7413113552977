import React, { useState, useEffect } from "react";
import { getProfile,updateProfile } from "../../services/user.service";
import * as Yup from "yup";
import CustomError from "../../shared/CustomError";
import { useFormik } from "formik";
import { failedResponse, successResponse } from "../../../../utils/toastr";

const EditPersonalInfo = ({ userInfo,onUserInfoChange }) => {
  const [isInfoEditing, setIsInfoEditing] = useState(false);
  const toggleInfoEdit = () => {
    setIsInfoEditing(!isInfoEditing);
    if(!isInfoEditing){
      infoForm.resetForm()
    }
  };
  const handleCancel = () => {
    toggleInfoEdit();
    infoForm.resetForm()
  };


  const infoValidations = Yup.object().shape({
    about_me: Yup.string().required("Description is required"),
  });

  const infoForm = useFormik({
    initialValues: {
      about_me: userInfo && userInfo.about_me ? userInfo.about_me : "",
    },
    validationSchema: infoValidations,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const updateUserResponse = await updateProfile({'about_me':values.about_me,type:'about_me'});
        if(updateUserResponse.success){
          toggleInfoEdit();
          onUserInfoChange();
          successResponse(updateUserResponse.message)
        }
        else{
          failedResponse("Something Went Wrong");
        }
      } catch (err) {
        console.log("Error", err);
      }
    },
  });

  return (
    <>
      <div
        className={`personal-info-content border-sec mb-4 about${isInfoEditing ? "open" : ""
          }`}
      >
        <div className="personal-info-head d-flex align-items-center justify-content-between mb-3">
          <h3>
            A few words about{" "}
            {userInfo && userInfo.profile_for === "myself"
              ? "Myself"
              : "My " + (userInfo && userInfo.profile_for)}
          </h3>
          <a className="btn-sm" onClick={toggleInfoEdit}>
            <i className="ri-pencil-fill"></i>
            {isInfoEditing ? "Close" : "Edit"}
          </a>
        </div>
        <div className="content-wrapper">
          {isInfoEditing ? (
            <div className="personal-info-edit">
              <form onSubmit={infoForm.handleSubmit}>
                <div className="form-group">
                  <textarea
                    className="form-control min-h"
                    placeholder="Enter Description"
                    type="text"
                    name="about_me"
                    onChange={infoForm.handleChange}
                    value={infoForm.values.about_me}
                  ></textarea>

                  <CustomError name="about_me" form={infoForm} />
                  <div className="personal-info-btn mt-3 text-end">
                    <button className="btn-sm" type="submit">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn-sm btn-outline ms-2"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="personal-info-body">
              <p>{userInfo && userInfo.about_me}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditPersonalInfo;
