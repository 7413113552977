import React, { useState, useEffect } from "react";
import { getQueries } from "../../services/global.service";
import { setInterest, getInterest } from "../../services/user.service";
import { successResponse, failedResponse } from "../../../../utils/toastr";
const EditHobbies = () => {
  const [isInfoEditing, setIsInfoEditing] = useState(false);
  const [activeTab, setActiveTab] = useState("hobbie");
  const [hobbiesOptions, setHobbiesOptions] = useState([]);
  const [musicOptions, setMusicOptions] = useState([]);
  const [readingOptions, setReadingOptions] = useState([]);
  const [movieOptions, setMoviesOptions] = useState([]);
  const [sportsOptions, setSportsOptions] = useState([]);
  const [foodOptions, setFoodsOptions] = useState([]);
  const [languageOptions, setLanguagesOptions] = useState([]);

  async function fetchUserInterests() {
    await getInterest()
      .then((response) => {
        if (response.success) {
          const convertedData = {
            hobbies: response.data["hobbie"]
              ? response.data["hobbie"]["id"]
              : [],
            music: response.data["music"] ? response.data["music"]["id"] : [],
            reading: response.data["reading"]
              ? response.data["reading"]["id"]
              : [],
            movie: response.data["movie"] ? response.data["movie"]["id"] : [],
            sports: response.data["sports-fitness"]
              ? response.data["sports-fitness"]["id"]
              : [],
            food: response.data["food"] ? response.data["food"]["id"] : [],
            language: response.data["spoken-language"]
              ? response.data["spoken-language"]["id"]
              : [],
          };
          setSelectedInterest(convertedData);
        }
      })
      .catch((err) => {
        console.log("error in fetching user intrests", err);
      });
  }
  const handelTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    /**Getting religion options */
    getQueries({ type: "hobbie" })
      .then((response) => {
        if (response.success) {
          setHobbiesOptions(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });

    getQueries({ type: "music" })
      .then((response) => {
        if (response.success) {
          setMusicOptions(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });

    /**Getting getGotras options */
    getQueries({ type: "reading" })
      .then((response) => {
        if (response.success) {
          setReadingOptions(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });

    /**Getting getDosh options */
    getQueries({ type: "movie" })
      .then((response) => {
        if (response.success) {
          setMoviesOptions(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });

    getQueries({ type: "sports-fitness" })
      .then((response) => {
        if (response.success) {
          setSportsOptions(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });

    /**Getting getDosh options */
    getQueries({ type: "food" })
      .then((response) => {
        if (response.success) {
          setFoodsOptions(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });

    getQueries({ type: "spoken-language" })
      .then((response) => {
        if (response.success) {
          setLanguagesOptions(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });
    fetchUserInterests();
  }, []);

  const [selectedInterest, setSelectedInterest] = useState({
    hobbies: [],
    music: [],
    reading: [],
    movie: [],
    sports: [],
    food: [],
    language: [],
  });

  const handleToggle = (type, id) => {
    if (selectedInterest[type].includes(id)) {
      selectedInterest[type].splice(selectedInterest[type].indexOf(id), 1);
    } else {
      selectedInterest[type].push(id);
    }
    const button = document.getElementById(`toggleButton-${id}`);
    if (button) {
      const isActive = selectedInterest[type].includes(id);
      if (isActive) {
        button.classList.add("active");
      } else {
        button.classList.remove("active");
      }
    }
    console.log(selectedInterest[type].includes(id));
  };

  const updateInterests = () => {
    try {
      setInterest(selectedInterest)
        .then((response) => {
          if (response.success) {
            successResponse(response.message);
          } else {
            failedResponse("Something Went Wrong");
          }
        })
        .catch((err) => {
          console.log("Server error ", err);
        });
    } catch (err) {
      console.log("error ", err);
    }
  };
  return (
    <>
      <div className="wraper-inner">
        <section className="profile-edit-add-hobbies ">
          <div className="add-hobbies-head">
            <div className="container">
              <ul className="add-hobbies-list d-flex">
                <li className={activeTab === "hobbie" ? "active" : ""}>
                  <a
                    href="#hobbies-interests"
                    onClick={() => handelTabClick("hobbie")}
                  >
                    Hobbies & Interests
                  </a>
                </li>
                <li className={activeTab === "music" ? "active" : ""}>
                  <a href="#Music" onClick={() => handelTabClick("music")}>
                    Music
                  </a>
                </li>
                <li className={activeTab === "reading" ? "active" : ""}>
                  <a href="#Reading" onClick={() => handelTabClick("reading")}>
                    Reading
                  </a>
                </li>
                <li className={activeTab === "movie" ? "active" : ""}>
                  <a href="#movie-tv" onClick={() => handelTabClick("movie")}>
                    Movie and TV shows
                  </a>
                </li>
                <li className={activeTab === "sports" ? "active" : ""}>
                  <a
                    href="#sports-fitness"
                    onClick={() => handelTabClick("sports")}
                  >
                    Sports and Fitness
                  </a>
                </li>
                <li className={activeTab === "food" ? "active" : ""}>
                  <a href="#food" onClick={() => handelTabClick("food")}>
                    Food
                  </a>
                </li>
                <li className={activeTab === "language" ? "active" : ""}>
                  <a
                    href="#spoken-languages"
                    onClick={() => handelTabClick("language")}
                  >
                    Spoken languages
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="container">
            <div className="add-hobbies-body similer-box-shadow mt-4">
              <div className="scroll-box-profile">
                <div id="hobbies-interests" className="mb-4">
                  <h4 className="inner-title mb-4">
                    Choose your Hobbies & Interests
                  </h4>
                  <ul className="add-hobbies-content-list">
                    {hobbiesOptions.map((hobby) => (
                      <li id={`toggleButton-${hobby?.id}`} key={hobby?.id}>
                        <label className="hobby-label">
                          <input
                            type="checkbox"
                            value={hobby?.id}
                            onChange={() => handleToggle("hobbies", hobby?.id)}
                          />
                          {hobby.name}
                          <span className="close-btn">X</span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
                <div id="Music" className="mb-4">
                  <h4 className="inner-title mb-4">
                    Choose the music genres you listen to
                  </h4>
                  <ul className="add-hobbies-content-list">
                    <ul className="add-hobbies-content-list">
                      {musicOptions.map((music) => (
                        <li key={music?.id}  id={`toggleButton-${music?.id}`}>
                          <label className="hobby-label">
                            <input
                              type="checkbox"
                              value={music?.id}
                              onChange={() => handleToggle("music", music?.id)}
                            />
                            {music.name}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </ul>
                </div>
                <div id="Reading" className="mb-4">
                  <h4 className="inner-title mb-4">
                    Choose the type of books you read
                  </h4>
                  <ul className="add-hobbies-content-list">
                    {readingOptions.map((book) => (
                      <li key={book?.id}  id={`toggleButton-${book?.id}`}>
                        <label className="hobby-label">
                          <input
                            type="checkbox"
                            value={book?.id}
                            onChange={() => handleToggle("reading", book?.id)}
                          />
                          {book.name}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
                <div id="movie-tv" className="mb-4">
                  <h4 className="inner-title mb-4">
                    Choose the type of movies you watch
                  </h4>
                  <ul className="add-hobbies-content-list ">
                    {movieOptions.map((movie) => (
                      <li key={movie?.id} id={`toggleButton-${movie?.id}`}>
                        <label className="hobby-label">
                          <input
                            type="checkbox"
                            value={movie?.id}
                            onChange={() => handleToggle("movie", movie?.id)}
                          />
                          {movie.name}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
                <div id="sports-fitness" className="mb-4">
                  <h4 className="inner-title mb-4">
                    Select Sports/ Fitness Activities/ Games
                  </h4>
                  <ul className="add-hobbies-content-list">
                    {sportsOptions.map((sport) => (
                      <li key={sport?.id} id={`toggleButton-${sport?.id}`}>
                        <label className="hobby-label">
                          <input
                            type="checkbox"
                            value={sport?.id}
                            onChange={() => handleToggle("sports", sport?.id)}
                          />
                          {sport.name}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
                <div id="food" className="mb-4">
                  <h4 className="inner-title mb-4">
                    Choose your favourite cuisine
                  </h4>
                  <ul className="add-hobbies-content-list">
                    {foodOptions.map((food) => (
                      <li key={food?.id} id={`toggleButton-${food?.id}`}>
                        <label className="hobby-label">
                          <input
                            type="checkbox"
                            value={food?.id}
                            onChange={() => handleToggle("food", food?.id)}
                          />
                          {food.name}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
                <div id="spoken-languages" className="mb-4">
                  <h4 className="inner-title mb-4">
                    Choose the languages you know
                  </h4>
                  <ul className="add-hobbies-content-list ">
                    {languageOptions.map((language) => (
                      <li key={language?.id} id={`toggleButton-${language?.id}`}>
                        <label className="hobby-label">
                          <input
                            type="checkbox"
                            value={language?.id}
                            onChange={() =>
                              handleToggle("language", language?.id)
                            }
                          />
                          {language.name}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <hr />
              <div className="btn-group-box mt-3 text-end">
                <button className=" btn-sm" onClick={updateInterests}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EditHobbies;
