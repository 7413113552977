import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsMobile from '../aws-exports';
import { setLocalKey } from './commonfunction';
import { USER_ROLES } from './Constants';

/**Setting pool credentials */
Amplify.configure(awsMobile);

export const cognitoSignup = (data) => {
  return new Promise((resolve, reject) => {
    Auth.signUp({
      username:data.phone,
      password:data.password,
      attributes: {
        email:data.email,
        phone_number:data.phone,
      },
    }).then((result)=>{
      resolve(result);
    }).catch((err)=>{
      reject(err);
    });
  });
};
export const confirmSignUp = (data) => {
  return new Promise((resolve, reject) => {
    Auth.confirmSignUp(data.username, data.otp).then((result)=>{
      resolve(result);
    }).catch((err)=>{
      reject(err);
    });
  });
};
export const resendConfirmSignUpCode = (username) => {
  return new Promise((resolve, reject) => {
    Auth.resendSignUp(username).then((result)=>{
      resolve(result);
    }).catch((err)=>{
      reject(err);
    });
  });
};
export const signIn = (username,password) => {
  return new Promise((resolve, reject) => {
    Auth.signIn(username,password).then((result)=>{
      setLocalKey('user_type',USER_ROLES.USER);
      resolve(result);
    }).catch((err)=>{
      reject(err);
    });
  });
};
export const getLoggedInUser = () => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser().then((result)=>{
      resolve(result);
    }).catch((err)=>{
      reject(err);
    });
  });
};
export const getCodeForVerifyPhone = (loggedInUser) => {
  return new Promise((resolve, reject) => {
    Auth.verifyUserAttribute(loggedInUser,'phone_number').then((result)=>{
      resolve(result);
    }).catch((err)=>{
      reject(err);
    });
  });
};
export const verifyPhone = (cognitoUser, otp) => {
  return new Promise((resolve, reject) => {
    cognitoUser.verifyAttribute('phone_number', otp, {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (error) => {
        reject(error);
      },
    });
  });
};
export const forgetPassword = (username) => {
  return new Promise((resolve, reject) => {
    Auth.forgotPassword(username).then((result)=>{
      resolve(result);
    }).catch(err=>{
      reject(err);
    });
  });
};
export const resetPassword = (username, code, newPassword) => {
   return Auth.forgotPasswordSubmit(username, code, newPassword);
};
export const cognitoLogOut = () => {
  return Auth.signOut();
};