import { useFormik } from 'formik';
import React, { useState } from 'react';
import { searchDetails } from '../../services/search.service';
import * as Yup from 'yup';
import CustomError from '../../shared/CustomError';
import { capitalizeFirstLetter, formateDate, prepareLabel } from '../../../../utils/commonfunction';
import GlobalLoader from '../../../admin/shared/globalLoader';
import { useNavigate } from 'react-router-dom';

const ViewSearchList = () => {
    const [showdefault, setShowDefault] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const viewProfileForm = useFormik({
        initialValues: {
            matrimony_id: ''
        },
        validationSchema: Yup.object().shape({
            matrimony_id: Yup.string().required('This field is required'),
        }),
        onSubmit: (values) => {
            setLoading(true);
            searchDetails(values?.matrimony_id)
                .then(data => {
                    setShowDefault(data && data.data ? data.data : []);
                })
                .catch(error => {
                    console.log("error=====>", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    });

    const handleViewProfileClick = () => {
        navigate(`/search-view/details/${viewProfileForm.values?.matrimony_id}`);
    };

    return (
        <>
            <div className="tab-pane" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex={0}>
                <form onSubmit={viewProfileForm.handleSubmit}>
                    <div className="label-input-field-box d-flex align-items-center pb-5">
                        <div className="label-input-field">
                            <label className="label-up">Enter Matrimony ID</label>
                            <input
                                type="text"
                                name="matrimony_id"
                                placeholder="Eg: H123456"
                                className="form-control"
                                value={viewProfileForm?.values?.matrimony_id}
                                onChange={viewProfileForm?.handleChange}
                            />
                            <CustomError name={'matrimony_id'} form={viewProfileForm} />
                        </div>
                        <button className="btn-sm ms-3" type='submit' onClick={handleViewProfileClick}>
                            View Profile
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default ViewSearchList;

