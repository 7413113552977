import axiosInstance from "../../../utils/axios";

const path = "admin/cms"


export const PagesList = async (data) => {
    return await axiosInstance().post(`${path}`,data);
}

export const AddPages = async (data) => {
    return await axiosInstance().post(`${path}/add`,data);
}

export const EditPages = async (id, data) => {
    return await axiosInstance().patch(`${path}/edit/${id}`,data);
}

export const PageDetails = async (id) => {
    return await axiosInstance().get(`${path}/details/${id}`);
}
export const PageDelete = async (id) => {
    return await axiosInstance().delete(`${path}/${id}`);
}

// export const CmsPagesDetails = async (id) => {
//     return await axiosInstance().get(`${path}/details?id=${id}`);
// }

export const EmailTemplateList = async (data) => {
    return await axiosInstance().post(`${path}/email-templates`,data);
}

export const AddEmailTemplates = async (data) => {
    return await axiosInstance().post(`${path}/email-templates/add`,data);
}

export const EditEmailTemplates = async (id, data) => {
    return await axiosInstance().patch(`${path}/email-templates/edit/${id}`,data);
}

export const EmailTemplatesDetails = async (id) => {
    return await axiosInstance().get(`${path}/email-templates/details/${id}`);
}
export const EmailTemplatesDelete = async (id) => {
    return await axiosInstance().delete(`${path}/email-templates/${id}`);
}