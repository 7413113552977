import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { searchDetails } from '../../services/search.service';
import GlobalLoader from '../../shared/globalLoader';
import { capitalizeFirstLetter, formateDate, prepareLabel } from '../../../../utils/commonfunction';
import profileUser from "../../../../assets/user/images/profil-user-2.png"
import chatUser from "../../../../assets/user/images/chat-user.png"
import verified1 from "../../../../assets/user/images/verified-1.svg"
import verified2 from "../../../../assets/user/images/verified-2.svg"
import verified3 from "../../../../assets/user/images/verified-3.svg"
import verified4 from "../../../../assets/user/images/verified-4.svg"
import verified5 from "../../../../assets/user/images/verified-5.svg"
import detailIcon1 from "../../../../assets/user/images/detail-icon-1.svg"
import userAvatar from "../../../../assets/user/images/user-avtar.svg"
import matchingIcon from "../../../../assets/user/images/matching-icon.svg"
import unMatchingIcon from "../../../../assets/user/images/unmatching-icon.svg"
import homeImg from "../../../../assets/user/images/home-img.svg"
import weddingBazzar from "../../../../assets/user/images/wedding_bazaar.png"
import successStoryPicture from "../../../../assets/user/images/success-story-picture.png"
import photoVeriefied from "../../../../assets/user/images/Photo-Verified.png"
import userIcon1 from "../../../../assets/user/images/user-icon-1.svg"
import userIcon2 from "../../../../assets/user/images/user-icon-2.svg"
import userIcon3 from "../../../../assets/user/images/user-icon-3.svg"
import userIcon4 from "../../../../assets/user/images/user-icon-4.svg"
import premiumMember from "../../../../assets/user/images/Premium-member.png"
import idVerified from "../../../../assets/user/images/ID-verified.png"

const ProfileDetails = () => {
    const { id } = useParams();
    const [profileData, setProfileData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        searchDetails(id)

            .then(data => {
                setProfileData(data && data.data ? data.data : []);
                console.log("anii", data.data);
            })
            .catch(error => {
                console.log("error=====>", error);
            });
    }, [id]);

    return (
        <>
            <div className="tab-pane" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex={0}>

                {loading ? (
                    <GlobalLoader height={'10vh'} background={'#fff'} textColor={'#504fb3'} />
                ) : profileData && Object.keys(profileData).length > 0 ? (
                    <>
                        <div className='container-fluid w-50 mt-5'>
                            <div className='row'>
                                <div className="col-lg-12 form-group">
                                    <div className="wraper-inner">
                                        <section className="user-profiles-details padding-space">
                                            <div className="container">
                                                {/* <div className="title-head d-flex justify-content-between align-items-center mb-3">
                                                    <h3 className="inner-title d-flex"><i className="ri-arrow-left-line me-3"></i> All Matches 1/4806</h3>
                                                    <div className="pre-next-action d-flex">
                                                        <button className="pre-btn">
                                                            <i className="fas fa-angle-double-left me-0 ms-2"></i> <span>  Previous </span><img src={chatUser} /></button>
                                                        <button className="pre-btn ms-3"><img src={chatUser} />
                                                            <span>  Next </span> <i className="fas fa-angle-double-right me-2 ms-0"></i></button>
                                                    </div>
                                                </div> */}
                                                <div className="single-user-profiles d-flex mb-4 similer-box-shadow">
                                                    <div className="single-user-profiles-left">
                                                        <div className="owl-carousel owl-theme single-user-slider">
                                                            <div className="item">
                                                                <figure>
                                                                    <span className="verified-tag"><img src={photoVeriefied} />Photo Verified</span>
                                                                    <img src={profileUser} />
                                                                    <span className="image-info"> 1/1 &nbsp;<i className="fa-solid fa-images"></i></span>
                                                                </figure>
                                                            </div>
                                                            <div className="item">
                                                                <figure>
                                                                    <span className="verified-tag"><img src={photoVeriefied} />Photo Verified</span>
                                                                    <img src={profileUser} />
                                                                    <span className="image-info"> 1/1 &nbsp;<i className="fa-solid fa-images"></i></span>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <figcaption className="single-user-profiles-right">
                                                        <div className="user-info-head d-flex justify-content-between align-items-start">
                                                            <div className="user-info-left">
                                                                <div className="profile-title-info">
                                                                    <div className="verified-tag-box d-flex mb-2">
                                                                        <p className="id-verified-tag me-2"><img className="me-2" src={idVerified} />ID verified</p>
                                                                        <p className="Premium-tag"><img className="me-2" src={premiumMember} />Premium Member</p>
                                                                    </div>
                                                                    <h3>{profileData && profileData?.name ? capitalizeFirstLetter(profileData.name) : "N/A"}</h3>
                                                                    <span>H10265933 | Last seen an hour ago </span>
                                                                </div>
                                                                <ul className="user-info-list">
                                                                    <li><img src={userIcon1} />{profileData && profileData?.age ? profileData.age : "N/A"}, 5'6"</li>
                                                                    <li><img src={userIcon2} />Baniya</li>
                                                                    <li><img src={userIcon3} />{profileData && profileData?.highest_education ? profileData.highest_education : "N/A"}</li>
                                                                    <li><img src={userIcon4} />Ghaziabad, Uttar Pradesh</li>
                                                                </ul>
                                                            </div>
                                                            <div className="user-info-right d-flex align-items-center">
                                                                <span className="tag-info"> <i className="ri-star-line"></i>Shortlist </span>
                                                                <span className="tag-info ms-2"> <i className="ri-phone-fill"></i> Call  </span>
                                                                <div className="dropdown more-drop ms-2">
                                                                    <button className=" dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className="ri-more-2-fill"></i>
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        <li><a className="dropdown-item" href="#">Delete</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="mt-5" />
                                                        <div className="profile-intersted mt-3">
                                                            <h4>Interested in him?</h4>
                                                            <h5>Connect Now</h5>
                                                            <div>
                                                                <a className="btn-sm btn-outline decline-btn me-2 grey-border"><i className="ri-close-line"></i> Don't Show </a>
                                                                <a className="btn-sm accept-btn min-w"><i className="ri-check-line"></i> Like him </a>
                                                            </div>
                                                        </div>
                                                    </figcaption>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="similer-box-shadow">
                                                            <div className="mb-3 About-user-info mb-5">
                                                                <h3 className="inner-title mb-2">About Shashank Agarwal</h3>
                                                                <p>{profileData && profileData?.about_me ? capitalizeFirstLetter(profileData.about_me) : "N/A"}</p>
                                                            </div>
                                                            <div className="profile-verification mb-5">
                                                                <h3 className="inner-title mb-2">Profile verification - 3/5  </h3>
                                                                <ul className="Profile-verification-list d-flex">
                                                                    <li><img src={verified1} />
                                                                        <span>Mobile verified</span>
                                                                    </li>
                                                                    <li><img src={verified2} />
                                                                        <span>Govt. ID verified</span>
                                                                    </li>
                                                                    <li><img src={verified3} />
                                                                        <span>Photo verified</span>
                                                                    </li>
                                                                    <li><img src={verified4} />
                                                                        <span>Education not verified</span>
                                                                    </li>
                                                                    <li><img src={verified5} />
                                                                        <span>Income not verified</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="basic-details mb-5">
                                                                <h3 className="inner-title mb-2">His Basic Details</h3>
                                                                <ul className="info-details-list ">
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Age</span></figure>
                                                                        <figcaption>
                                                                            <p>29 Years and 11 months</p>
                                                                        </figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Physique</span></figure>
                                                                        <figcaption>
                                                                            <ul className="more-info-list d-flex align-items-center">
                                                                                <li>68 Kg </li>
                                                                                <li> 5'6" </li>
                                                                                <li>Slim </li>
                                                                                <li>Normal</li>
                                                                            </ul>
                                                                        </figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Spoken Languages</span></figure>
                                                                        <figcaption>
                                                                            <p>{profileData && profileData?.mother_tongue ? profileData.mother_tongue : "N/A"}</p>
                                                                        </figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Eating Habits</span></figure>
                                                                        <figcaption>
                                                                            <p>Vegetarian</p>
                                                                        </figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Smoking Habits</span></figure>
                                                                        <figcaption>
                                                                            <p>Doesn't Smoke</p>
                                                                        </figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Drinking Habits</span></figure>
                                                                        <figcaption>
                                                                            <p>Doesn't Drink</p>
                                                                        </figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Profile Created By</span></figure>
                                                                        <figcaption>
                                                                            <p>{profileData && profileData?.profile_for ? capitalizeFirstLetter(profileData.profile_for) : "N/A"}</p>
                                                                        </figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Marital Status</span></figure>
                                                                        <figcaption>
                                                                            <p>{profileData && profileData?.marital_status ? prepareLabel(profileData.marital_status) : "N/A"}</p>
                                                                        </figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Lives In</span> <br /><span className="verified-tag-info ms-4"><img src={photoVeriefied} />Location  Verified</span></figure>
                                                                        <figcaption>
                                                                            <p>Ghaziabad, Uttar Pradesh</p>
                                                                        </figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Citizenship</span></figure>
                                                                        <figcaption>
                                                                            <p>Indian Citizen</p>
                                                                        </figcaption>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="Religious-details mb-5">
                                                                <h3 className="inner-title mb-2">His Religious Details</h3>
                                                                <ul className="info-details-list ">
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Religion</span></figure>
                                                                        <figcaption>
                                                                            <p>Hindu</p>
                                                                        </figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Caste/Subcaste</span></figure>
                                                                        <figcaption>
                                                                            <p>Baniya, Agarwal</p>
                                                                        </figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Spoken Languages</span></figure>
                                                                        <figcaption><a className="text-link"> Request Gothram </a></figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Dosha(m)</span></figure>
                                                                        <figcaption>
                                                                            <p>No Dosham</p>
                                                                        </figcaption>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="lock-box-info d-flex mb-5">
                                                                <span className="lock-icon"><i className="ri-lock-line"></i></span>
                                                                <div className="lock-box-info-left">
                                                                    <ul className="lock-box-info-list">
                                                                        <li className="d-flex">
                                                                            <div className="lock-box-content d-flex">
                                                                                <figure>
                                                                                    <img src={detailIcon1} />
                                                                                    <span>Date Of Birth</span>
                                                                                </figure>
                                                                                <p className="blur-text">00/00/0000</p>
                                                                            </div>
                                                                            <div className="lock-box-content d-flex">
                                                                                <figure>
                                                                                    <span>Time Of Birth</span>
                                                                                </figure>
                                                                                <p className="blur-text">00000000</p>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                    <ul className="info-details-list ">
                                                                        <li>
                                                                            <figure><img src={detailIcon1} /><span>Star/Raasi</span></figure>
                                                                            <figcaption>
                                                                                <p>Star is <b className="blur-text">star</b> Raasi is <b className="blur-text">raasi</b></p>
                                                                            </figcaption>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="lock-box-info-right">
                                                                    <span className="mb-1 upgrade-text"><i className="ri-lock-line"></i> Upgrade to view horoscope details</span>
                                                                    <a className="btn-sm"> Become a paid member </a>
                                                                </div>
                                                            </div>
                                                            <div className="Professional-details mb-5">
                                                                <h3 className="inner-title mb-2">His Professional Details</h3>
                                                                <ul className="info-details-list ">
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Employment</span></figure>
                                                                        <figcaption>
                                                                            <p>Works in Private Sector</p>
                                                                        </figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Income</span></figure>
                                                                        <figcaption>
                                                                            <p>{profileData && profileData?.annual_income ? profileData.annual_income : "N/A"}</p>
                                                                        </figcaption>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="lock-box-info d-flex mb-5">
                                                                <span className="lock-icon"><i className="ri-lock-line"></i></span>
                                                                <div className="lock-box-info-left">
                                                                    <ul className="info-details-list ">
                                                                        <li>
                                                                            <figure><img src={detailIcon1} /><span>Education</span></figure>
                                                                            <figcaption>
                                                                                <p>B.Tech. from paid_member_only </p>
                                                                            </figcaption>
                                                                        </li>
                                                                        <li>
                                                                            <figure><img src={detailIcon1} /><span>Occupation</span></figure>
                                                                            <figcaption>
                                                                                <p>Software Professional in paid_member_only </p>
                                                                            </figcaption>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="lock-box-info-right">
                                                                    <span className="mb-1 upgrade-text"><i className="ri-lock-line"></i> Upgrade to view college & company info</span>
                                                                    <a className="btn-sm"> Become a paid member </a>
                                                                </div>
                                                            </div>
                                                            <div className="about-his-family mb-5">
                                                                <h3 className="inner-title mb-2">About His Family</h3>
                                                                <ul className="info-details-list ">
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Family Type</span></figure>
                                                                        <figcaption>
                                                                            <p>Nuclear upper middle class family with moderate values</p>
                                                                        </figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Parents</span></figure>
                                                                        <figcaption>
                                                                            <p>Father is Employed, Mother is a Home Maker</p>
                                                                        </figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Ancestral Origin</span></figure>
                                                                        <figcaption>
                                                                            <p>{profileData && profileData?.ancestralOrigin ? profileData.ancestralOrigin : "N/A"}</p>
                                                                        </figcaption>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="about-Lifestyle mb-5">
                                                                <h3 className="inner-title mb-2">His Lifestyle</h3>
                                                                <ul className="info-details-list ">
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Hobbies</span></figure>
                                                                        <figcaption>
                                                                            <p>Travel</p>
                                                                        </figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Favourite Music Genre</span></figure>
                                                                        <figcaption>
                                                                            <p>Film Songs</p>
                                                                        </figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Favourite Books</span></figure>
                                                                        <figcaption>
                                                                            <p>Business / Professional</p>
                                                                        </figcaption>
                                                                    </li>
                                                                    <li>
                                                                        <figure><img src={detailIcon1} /><span>Favourite Movies and Shows</span></figure>
                                                                        <figcaption>
                                                                            <p>Drama</p>
                                                                        </figcaption>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="partner-preferences mb-5">
                                                                <h3 className="inner-title mb-4">Shashank Agarwal's Partner Preferences</h3>
                                                                <div className="matched-prefer d-flex">
                                                                    <figure><img src={userAvatar} /></figure>
                                                                    <h4>You match <b>16/20 of his preferences</b></h4>
                                                                    <figure><img src={userAvatar} /></figure>
                                                                </div>
                                                            </div>
                                                            <div className="partner-preferences-box mb-5">
                                                                <div className="partner-preferences-inner mb-5">
                                                                    <div className="partner-preferences-head d-flex  justify-content-between mb-3">
                                                                        <h4>His Basic Preferences</h4>
                                                                        <span>You match<img src={matchingIcon} /></span>
                                                                    </div>
                                                                    <ul className="partner-preferences-list">
                                                                        <li>
                                                                            <h5>Preferred Bride's Age</h5>
                                                                            <span>22-29 yrs</span>
                                                                            <p><img src={matchingIcon} /></p>
                                                                        </li>
                                                                        <li>
                                                                            <h5>Preferred Height</h5>
                                                                            <span>5'0" - 5'6"</span>
                                                                            <p><img src={unMatchingIcon} /></p>
                                                                        </li>
                                                                        <li>
                                                                            <h5>Preferred Marital Status</h5>
                                                                            <span>{profileData && profileData?.marital_status ? prepareLabel(profileData.marital_status) : "N/A"}</span>
                                                                            <p><img src={matchingIcon} /></p>
                                                                        </li>
                                                                        <li>
                                                                            <h5>Preferred Mother Tongue</h5>
                                                                            <span>Any</span>
                                                                            <p><img src={matchingIcon} /></p>
                                                                        </li>
                                                                        <li>
                                                                            <h5>Preferred Physical Status</h5>
                                                                            <span>Normal</span>
                                                                            <p><img src={matchingIcon} /></p>
                                                                        </li>
                                                                        <li>
                                                                            <h5>Preferred Eating Habits</h5>
                                                                            <span>Vegetarian, Eggetarian</span>
                                                                            <p><img src={matchingIcon} /></p>
                                                                        </li>
                                                                        <li>
                                                                            <h5>Preferred Smoking Habits</h5>
                                                                            <span>Doesn't Matter</span>
                                                                            <p><img src={matchingIcon} /></p>
                                                                        </li>
                                                                        <li>
                                                                            <h5>Preferred Drinking Habits</h5>
                                                                            <span>Doesn't Matter</span>
                                                                            <p><img src={matchingIcon} /></p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="partner-preferences-inner mb-5">
                                                                    <div className="partner-preferences-head d-flex  justify-content-between mb-3">
                                                                        <h4>His Religious Preferences</h4>
                                                                    </div>
                                                                    <ul className="partner-preferences-list">
                                                                        <li>
                                                                            <h5>Preferred Religion</h5>
                                                                            <span>Hindu</span>
                                                                            <p><img src={matchingIcon} /></p>
                                                                        </li>
                                                                        <li>
                                                                            <h5>Preferred Caste</h5>
                                                                            <span>Agarwal, Baniya - Bania, Gupta, Maheshwari, Vaish</span>
                                                                            <p><img src={unMatchingIcon} /></p>
                                                                        </li>
                                                                        <li>
                                                                            <h5>Preferred Subcaste</h5>
                                                                            <span>Any</span>
                                                                            <p><img src={matchingIcon} /></p>
                                                                        </li>
                                                                        <li>
                                                                            <h5>Preferred Star</h5>
                                                                            <span>Any</span>
                                                                            <p><img src={matchingIcon} /></p>
                                                                        </li>
                                                                        <li>
                                                                            <h5>Preferred Dosham</h5>
                                                                            <span>Doesn't Matter</span>
                                                                            <p><img src={matchingIcon} /></p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="partner-preferences-inner mb-5">
                                                                    <div className="partner-preferences-head d-flex  justify-content-between mb-3">
                                                                        <h4>His Professional Preferences</h4>
                                                                    </div>
                                                                    <ul className="partner-preferences-list">
                                                                        <li>
                                                                            <h5>Preferred Education</h5>
                                                                            <span>Any</span>
                                                                            <p><img src={matchingIcon} /></p>
                                                                        </li>
                                                                        <li>
                                                                            <h5>Preferred Employment Type</h5>
                                                                            <span>Any</span>
                                                                            <p><img src={unMatchingIcon} /></p>
                                                                        </li>
                                                                        <li>
                                                                            <h5>Preferred Occupation</h5>
                                                                            <span>Any</span>
                                                                            <p><img src={matchingIcon} /></p>
                                                                        </li>
                                                                        <li>
                                                                            <h5>Preferred Annual Income</h5>
                                                                            <span>Any</span>
                                                                            <p><img src={matchingIcon} /></p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="partner-preferences-inner">
                                                                    <div className="partner-preferences-head d-flex  justify-content-between mb-3">
                                                                        <h4>His Location Preferences</h4>
                                                                    </div>
                                                                    <ul className="partner-preferences-list">
                                                                        <li>
                                                                            <h5>Preferred Country</h5>
                                                                            <span>India</span>
                                                                            <p><img src={matchingIcon} /></p>
                                                                        </li>
                                                                        <li>
                                                                            <h5>Preferred Residing State</h5>
                                                                            <span>Chandigarh, Chhattisgarh, Delhi / NCR, Uttar Pradesh</span>
                                                                            <p><img src={unMatchingIcon} /></p>
                                                                        </li>
                                                                        <li>
                                                                            <h5>Preferred Residing City</h5>
                                                                            <span>Any</span>
                                                                            <p><img src={unMatchingIcon} /></p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="view-braches advertise-border-box text-center">
                                                            <div className="view-braches-head text-center mb-4">
                                                                <h4>140+ matrimony branches across India</h4>
                                                                <span>To serve you better</span>
                                                            </div>
                                                            <figure className="mb-4"><img src={homeImg} /></figure>
                                                            <span>Visit any of our 140+ matrimony Branches</span>
                                                            <a href="" className="btn-sm mt-3"> View Branches </a>
                                                        </div>
                                                        <div className="wedding-bazaar p-0 advertise-border-box text-center mb-3">
                                                            <figure><img src={weddingBazzar} /></figure>
                                                        </div>
                                                        <div className="success-stories advertise-border-box text-center">
                                                            <div className="view-braches-head text-center mb-4">
                                                                <h4>140+ matrimony branches across India</h4>
                                                                <span>To serve you better</span>
                                                            </div>
                                                            <figure className="mb-4"><img src={successStoryPicture} /></figure>
                                                            <span>Niranjan Kumar &amp; Neha Kumari</span>
                                                            <a href="" className="btn-sm mt-3">  Read Their Story </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
};

export default ProfileDetails;