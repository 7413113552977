import React from 'react'
import { Outlet, Link, useLocation } from 'react-router-dom'

const SearchFiles = () => {
    const loc = useLocation();

    return (
        <>
            <div className="wraper-inner">
                <section className="search-profile padding-space">
                    <div className="container">
                        <div className="tabs-card similer-box-shadow p-0 search-profile-tabs">
                            <div className="tabs-card-head">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <Link
                                            className={"nav-link linkactive " + (loc.pathname == "/search-files/search" ? " active" : "")}
                                            aria-current="page"
                                            to={"/search-files/search"}
                                        >
                                            By Criteria
                                        </Link>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <Link
                                            className={"nav-link linkactive " + (loc.pathname == "/search-files/search-view" ? " active" : "")}
                                            aria-current="page"
                                            to={"/search-files/search-view"}
                                        >
                                            By Profile Id
                                        </Link>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <Link
                                            className={"nav-link linkactive " + (loc.pathname == "/search-files/search-list" ? " active" : "")}
                                            aria-current="page"
                                            to={"/search-files/search-list"}
                                        >
                                            Saved Search
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <Outlet />
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default SearchFiles;