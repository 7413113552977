import React, { useState } from 'react';
import { CKEditor } from 'ckeditor4-react';
import toHtml from 'html-react-parser'
import { getAdmin } from '../../../utils/commonfunction';

function CustomCkEditor(props) {

    let uniqueId = Math.floor(Math.random() * 1000000);;
    const [editorContent, setEditorContent] = useState(props.value);

    const handleChange = (event) => {
        const data = event.editor.getData();
        setEditorContent(data);
        props.setFieldValue(props.fieldname, data);
    };

  return (
    <CKEditor
      id="ifr"
      onBeforeLoad={(CKEDITOR) => {
        let alreadyExist = CKEDITOR.plugins.get(`cstButton${uniqueId}`);
        if (!alreadyExist) {
          CKEDITOR.plugins.add(`cstButton${uniqueId}`, {
            icons: `cstButton${uniqueId}`,
            init: function (editor) {
              editor.ui.addButton(`CstButton${uniqueId}`, {
                label: "Audio/Video",
                command: `cstButton${uniqueId}`,
                toolbar: "insert, 30",
                name: `cstbutton${uniqueId}`,
                icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaLO2_EFFI-eVhUBi6BS6XKE54viRbADMBb-xvh41zS7Pzk5Ts-fkAHrwIHdfZ2_vYE7g&usqp=CAU",
              });
            },
          });
        }
        return (CKEDITOR.disableAutoInline = true);
      }}
      onBeforeDestroy={(CKEDITOR) => {
        if (CKEDITOR?.editor?.commands[`cstButton${uniqueId}`]) {
          CKEDITOR.editor.commands[`cstButton${uniqueId}`].disable();
        }
      }}
      initData={editorContent ? toHtml(editorContent) : ""}
      // data={editorContent}
      onChange={(event, editor) => {
        handleChange(event, editor);
      }}
      config={{
        filebrowserUploadUrl: `${process.env.REACT_APP_API_URL}/admin/gallery/upload-ck-image`,
        allowedContent: true,
        fileTools_requestHeaders: {
          Authorization: `Bearer ${getAdmin().token}`,
        },
        extraPlugins: `cstButton${uniqueId}`,
      }}
    />
  )
}

export default CustomCkEditor