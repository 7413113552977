import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { globalLoader } from '../../../utils/commonfunction';
import * as dashboardServices from '../services/dashboard.services';

const AdminDashboard = () => {
	const [showDashboardCount, setShowDashboardCount] = useState();

	useEffect(() => {
		dashboardServices.Counts().then((response) => {
			if(response.success){
				setShowDashboardCount(response?.data);
			}
		}).catch((error) => {
			console.log("error",error)
		})
	},[])

	return (
		<>
			<div className="row row-sm animation_fade">
				<div className="col-sm-12 col-lg-12 col-xl-12">
					<div className="row row-sm">
						<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
							<div className="card custom-card">
								<div className="card-body">
									<Link to={`/admin/user-management/users/list/1`}>
										<div className="card-item">
											<div className="card-item-icon card-icon cp ">
												<svg xmlns="http://www.w3.org/2000/svg" height="24" className="bi bi-people-fill" viewBox="0 0 16 16">
													<path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
												</svg>
											</div>
											<div className="card-item-title mb-2">
												<h3 className="main-content-label tx-13 font-weight-bold mb-1">USERS</h3>
											</div>
											<div className="card-item-body">
												<div className="card-item-stat">
													<h4 className="font-weight-bold">{showDashboardCount && showDashboardCount.users ? showDashboardCount.users : "0"}</h4>
												</div>
											</div>
										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default AdminDashboard;
