import React, { useEffect, useRef } from 'react';
import trustedImg from '../../../assets/user/images/trusted-img-3.webp';
import { getSessionKey, prepareLabel, setSessionKey } from '../../../utils/commonfunction';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CustomError from '../shared/CustomError';
import { useNavigate } from 'react-router-dom';
import { DISABILITY_OPTIONS, FAMILY_STATUS_OPTIONS, FAMILY_TYPES_OPTIONS, FAMILY_VALUES_OPTIONS, HEIGHT_OPTIONS, HEIGHT_UNITS_OPTIONS, MARITAL_STATUS_OPTIONS } from '../../../utils/Constants';

export default function SignupStep3() {
    const signupFormRef = useRef();
    const navigate = useNavigate();
    useEffect(() => {
        document.querySelector('body').classList.add('bg-lignt-grey-shade');
        return () => {
            document.querySelector('body').classList.remove('bg-lignt-grey-shade');
        };
    }, []);
    useEffect(() => {
        /**Checking that user is coming from prev step */
        const prevStep = getSessionKey('REGISTRATION_COMPLETED_STEPS');
        if (prevStep && prevStep === "2") {
            /** Everything is fine */
        } else {
            /**Go back to initial step */
            navigate('/');
        }
    }, [navigate]);
    let prevStepData = JSON.parse(getSessionKey('REGISTRATION_FORM_DATA'));

    const signupValidations = Yup.object().shape({
        maritalStatus: Yup.string().required('Marital status is required'),
        familyStatus: Yup.string().required('Family status is required'),
        familyType: Yup.string().required('Family type is required'),
        familyValues: Yup.string().required('Family values is required'),
        anyDisability: Yup.string().required('This field is required'),
    });
    const signupForm = useFormik({
        initialValues: {
            maritalStatus: '',
            believeInSecondChance: false,
            height: '',
            familyStatus: '',
            familyType: '',
            familyValues: '',
            anyDisability: '',
        },
        validationSchema: signupValidations,
        onSubmit: values => {
            try {
                /**Retrieving previous step's data */
                const prevStepData = JSON.parse(getSessionKey('REGISTRATION_FORM_DATA'));
                const allData = { ...prevStepData, ...values };
                /**Saving data to localStorage */
                setSessionKey('REGISTRATION_COMPLETED_STEPS', '3');
                setSessionKey('REGISTRATION_FORM_DATA', JSON.stringify(allData));
                /**Sending to next step */
                navigate('/signup-step-4');
            } catch (err) {
                console.log("Error", err);
            }
        },
    });
    
    useEffect(() => {
        signupFormRef.current = signupForm;
    }, [signupForm]);

    return (
        <div className="wraper-inner">
            <section className="accounts-page padding-space">
                <div className="container">
                    <span className="tag-line">
                        Great! You have completed <b>60%</b>
                    </span>
                    <div className="signup-box-main">
                        <div className="row">
                            <div className="col-md-4 pe-0">
                                <div className="signup-box-l">
                                    <figure>
                                        <img src={trustedImg} alt="trusted-img-3.webp" />
                                    </figure>
                                    <figcaption>
                                        <p>
                                            Thousands of Success Stories! {prevStepData?.profileFor == "myself" ? "your" : "your "+prevStepData?.profileFor+"'s"} could be next.
                                        </p>
                                    </figcaption>
                                </div>
                            </div>
                            <div className="col-md-8 ps-0">
                                <div className="signup-box-r">
                                    <h3>Personal details get {prevStepData?.profileFor == "myself" ? "your" : "your "+prevStepData?.profileFor+"'s"} the right matches</h3>
                                    <div className="singup-form">
                                        <form onSubmit={signupForm.handleSubmit}>
                                            <div className="form-group mb-3 ">
                                                <div className="inline-field">
                                                    <label className="form-label">Marital Status</label>
                                                    <div>
                                                        <div className="cutom-checked d-flex">
                                                            {
                                                                Object.keys(MARITAL_STATUS_OPTIONS).map((item, i) => {
                                                                    return (<div className="form-check" key={i}>
                                                                        <input
                                                                            className="form-check-input cp"
                                                                            type="radio"
                                                                            name={'maritalStatus'} onChange={signupForm.handleChange}
                                                                            value={MARITAL_STATUS_OPTIONS[item]}
                                                                            id={"maritalStatus" + i}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor={"maritalStatus" + i}
                                                                        >
                                                                            {prepareLabel(MARITAL_STATUS_OPTIONS[item])}
                                                                        </label>
                                                                    </div>)
                                                                })
                                                            }
                                                        </div>
                                                        <CustomError name='maritalStatus' form={signupForm} />
                                                    </div>
                                                </div>
                                                <div className="addon-min mb-3">
                                                    <div className="form-check mt-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            defaultValue={false}
                                                            onChange={(e) => { signupForm.setFieldValue('believeInSecondChance', e.target.checked) }} checked={signupForm?.values?.believeInSecondChance}
                                                            name='believeInSecondChance'
                                                            id="believeInSecondChance"
                                                        />
                                                        <label
                                                            className="form-check-label cp"
                                                            htmlFor="believeInSecondChance"
                                                        >
                                                            Choose to believe in second chances
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="inline-field ">
                                                    <label className="form-label">Height</label>
                                                    <select className="form-select b-border-input" name='height' onChange={signupForm?.handleChange} value={signupForm?.values?.height}>
                                                        <option value={' '}>Feet / Inches</option>
                                                        {
                                                            HEIGHT_OPTIONS.map((item, index) => {
                                                                return (<option key={index} value={item?.value}>{item?.label}</option>)
                                                            })
                                                        }
                                                    </select>
                                                    <div className="option-text">(Optional)</div>
                                                </div>
                                            </div>
                                            {/* {
                                                signupForm.values.heightUnit ?
                                                    <div className="form-group mb-3">
                                                        <div className="inline-field ">
                                                            <label className="form-label">Height</label>
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    name="height"
                                                                    placeholder=""
                                                                    className="form-control b-border-input"
                                                                    onChange={signupForm.handleChange} value={signupForm?.values?.height}
                                                                />
                                                                <CustomError name='height' form={signupForm} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            } */}
                                            <div className="form-group mb-3 ">
                                                <div className="inline-field ">
                                                    <label className="form-label">Family Status</label>
                                                    <div>
                                                        <div className="cutom-checked d-flex">
                                                            {
                                                                Object.keys(FAMILY_STATUS_OPTIONS).map((item, i) => {
                                                                    return (<div className="form-check" key={i}>
                                                                        <input
                                                                            className="form-check-input cp"
                                                                            type="radio"
                                                                            name={'familyStatus'} onChange={signupForm.handleChange}
                                                                            value={FAMILY_STATUS_OPTIONS[item]}
                                                                            id={"familyStatus" + i}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor={"familyStatus" + i}
                                                                        >
                                                                            {prepareLabel(FAMILY_STATUS_OPTIONS[item])}
                                                                        </label>
                                                                    </div>)
                                                                })
                                                            }
                                                        </div>
                                                        <CustomError name='familyStatus' form={signupForm} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 ">
                                                <div className="inline-field ">
                                                    <label className="form-label">Family Type</label>
                                                    <div>
                                                        <div className="cutom-checked d-flex">
                                                            {
                                                                Object.keys(FAMILY_TYPES_OPTIONS).map((item, i) => {
                                                                    return (<div className="form-check" key={i}>
                                                                        <input
                                                                            className="form-check-input cp"
                                                                            type="radio"
                                                                            name={'familyType'} onChange={signupForm.handleChange}
                                                                            value={FAMILY_TYPES_OPTIONS[item]}
                                                                            id={"familyType" + i}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor={"familyType" + i}
                                                                        >
                                                                            {prepareLabel(FAMILY_TYPES_OPTIONS[item])}
                                                                        </label>
                                                                    </div>)
                                                                })
                                                            }
                                                        </div>
                                                        <CustomError name='familyType' form={signupForm} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group  mb-3 ">
                                                <div className="inline-field ">
                                                    <label className="form-label">Family Values</label>
                                                    <div>
                                                        <div className="cutom-checked d-flex">
                                                            {
                                                                Object.keys(FAMILY_VALUES_OPTIONS).map((item, i) => {
                                                                    return (<div className="form-check" key={i}>
                                                                        <input
                                                                            className="form-check-input cp"
                                                                            type="radio"
                                                                            name={'familyValues'} onChange={signupForm.handleChange}
                                                                            value={FAMILY_VALUES_OPTIONS[item]}
                                                                            id={"familyValues" + i}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor={"familyValues" + i}
                                                                        >
                                                                            {prepareLabel(FAMILY_VALUES_OPTIONS[item])}
                                                                        </label>
                                                                    </div>)
                                                                })
                                                            }
                                                        </div>
                                                        <CustomError name='familyValues' form={signupForm} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group  mb-3 ">
                                                <div className="inline-field">
                                                    <label className="form-label">Any Disability</label>
                                                    <div>
                                                        <div className="cutom-checked d-flex">
                                                            {
                                                                Object.keys(DISABILITY_OPTIONS).map((item, i) => {
                                                                    return (<div className="form-check" key={i}>
                                                                        <input
                                                                            className="form-check-input cp"
                                                                            type="radio"
                                                                            name={'anyDisability'} onChange={signupForm.handleChange}
                                                                            value={DISABILITY_OPTIONS[item]}
                                                                            id={"anyDisability" + i}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor={"anyDisability" + i}
                                                                        >
                                                                            {prepareLabel(DISABILITY_OPTIONS[item])}
                                                                        </label>
                                                                    </div>)
                                                                })
                                                            }
                                                        </div>
                                                        <CustomError name='anyDisability' form={signupForm} />
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn btn-primary min-w mt-4" type='submit'>
                                                Continue
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-text text-center mt-4 mb-4">
                        <p className="">Copyright © 2023. All rights reserved.</p>
                    </div>
                </div>
            </section>
        </div>
    )
}