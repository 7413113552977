import React, { useEffect, useState } from 'react'
import { useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import { handleServerValidations, prepareLabel } from '../../../../utils/commonfunction';
import { SWAL_SETTINGS } from '../../../../utils/Constants';
import GlobalLoader from '../../../admin/shared/globalLoader';
import { searchDelete, searchList } from '../../services/search.service';

function SearchList() {

    const navigate = useNavigate();
    const [statsupdate, setStatusUpdate] = useState("false");
    const [showList, setShowList] = useState([]);
    const [isLoading,setLoading] = useState(false);

    const editFunction = (item) => {
        navigate(`/search-files/search?search_id=${item?.id}`)
    }

    useEffect(() => {
        const formData = new FormData();
        searchList(formData)
            .then((data) => {
                setShowList(data && data.data && data.data.list ? data.data.list : []);
                setTimeout(() => {
                }, 100)
                setLoading(true);
            })
            .catch((error) => {
                console.log("error ====> ", error);
            });

    }, [statsupdate]);

    function Deletefunction(data) {
        var modal = document.getElementById('delete_search');
        modal.style.display = 'block';
        document.getElementById('delete_close_modal_search').addEventListener('click', function() {
          modal.style.display = 'none';
        });
        document.getElementById('proceedButton').addEventListener('click', function() {
          searchDelete(data)
            .then((response) => {
              setStatusUpdate(!statsupdate);
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  text: response.message,
                  ...SWAL_SETTINGS,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  text: handleServerValidations(response),
                  ...SWAL_SETTINGS,
                });
              }
              modal.style.display = 'none';
            })
            .catch((error) => {
              console.log("deleteError");
              modal.style.display = 'none';
            });
        });
      }

      const cancelFunction = () => {
        document.getElementById('delete_close_modal_search').click();
      }
      

    return (
        <>
            <div
                className="tab-pane"
                id="contact-tab-pane"
                role="tabpanel"
                aria-labelledby="contact-tab"
                tabIndex={0}
            >
                <h3 className="inner-title mb-4">Your Saved Searches</h3>
                <div className='saved-search-list'>
                  {showList && showList.map((item, index) => (
                      <div className='saved-search-item' key={index}>
                        <div className='saved-search-left'>
                          <p className="m-0">{prepareLabel(item?.title)}</p>
                        </div>
                        <div className="saved-search-right">
                          <button className="btn" onClick={()=>{ editFunction(item)}}><i class="ri-pencil-fill"></i></button>
                          <button className="btn" onClick={()=>{ Deletefunction(item.id)}}><i class="ri-delete-bin-6-fill"></i></button>
                        </div>
                      </div>
                  ))}
                </div>
            </div>
            <div className="modal common-modal xs-modal pure-modal" id="delete_search" tabIndex={-1} aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-head">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" id='delete_close_modal_search' aria-label="Close" />
                        </div>
                        <div className="modal-body">
                                <div className="form-group mb-3">
                                    <label className="form-label">Delete Save Search</label>
                                    <label className="form-label">Are you sure you want to delete your saved search</label>
                                </div>
                                <button id="proceedButton" className="btn-sm">Proceed</button>
                                <button className="btn-sm ms-2" onClick={() => cancelFunction()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    
}

export default SearchList