export const USER_ROLES = {
    USER: "user",
    SUPER_ADMIN: "super_admin"
};
export const PROFILE_FOR_OPTIONS = {
    MYSELF: 'myself',
    DAUGHTER: 'daughter',
    SON: 'son',
    SISTER: 'sister',
    BROTHER: 'brother',
    RELATIVE: 'relative',
    FRIEND: 'friend'
};
export const DOSH_QUESTION_OPTIONS = {
    NO: 'no',
    YES: 'yes',
    DONT_KNOW: 'dont_know'
};
export const MARITAL_STATUS_OPTIONS = {
    NEVER_MARRIED: 'never_married',
    WIDOWED: 'widowed',
    DIVORCED: 'divorced',
    AWAITING_DIVORCED: 'awaiting_divorced'
};
export const FAMILY_STATUS_OPTIONS = {
    MIDDLE_CLASS: 'middle_class',
    UPPER_MIDDLE_CLASS: 'upper_middle_class',
    RICH: 'rich',
    AFFLUENT: 'affluent'
};
export const FAMILY_TYPES_OPTIONS = {
    JOINT: 'joint',
    NUCLEAR: 'nuclear'
};
export const FAMILY_VALUES_OPTIONS = {
    ORTHODOX: 'orthodox',
    TRADITIONAL: 'traditional',
    MODERATE: 'moderate',
    LIBERAL: 'liberal',
};
export const DISABILITY_OPTIONS = {
    NONE: 'none',
    PHYSICALLY_CHALLENGED: 'physically_challenged'
};
export const HEIGHT_UNITS_OPTIONS = {
    FEET: 'feet',
    INCHES: 'inches'
};
export const HEIGHT_OPTIONS = [
    { label: "4'6\"", value: '137' },
    { label: "4'7\"", value: '140' },
    { label: "4'8\"", value: '142' },
    { label: "4'9\"", value: '145' },
    { label: "4'10\"", value: '147' },
    { label: "4'11\"", value: '150' },
    { label: "5", value: '152' },
    { label: "5'1\"", value: '155' },
    { label: "5'2\"", value: '157' },
    { label: "5'3\"", value: '160' },
    { label: "5'4\"", value: '162' },
    { label: "5'5\"", value: '165' },
    { label: "5'6\"", value: '168' },
    { label: "5'7\"", value: '170' },
    { label: "5'8\"", value: '173' },
    { label: "5'9\"", value: '175' },
    { label: "5'10\"", value: '178' },
    { label: "5'11\"", value: '180' },
    { label: "6", value: '183' },
    { label: "6'1\"", value: '185' },
    { label: "6'2\"", value: '188' },
    { label: "6'3\"", value: '190' },
    { label: "6'4\"", value: '193' },
    { label: "6'5\"", value: '195' },
    { label: "6'6\"", value: '198' },
    { label: "6'7\"", value: '200' },
    { label: "6'8\"", value: '203' },
    { label: "6'9\"", value: '206' },
    { label: "6'10\"", value: '208' },
    { label: "6'11\"", value: '211' },
    { label: "7", value: '213' },
    { label: "7'1\"", value: '216' },
    { label: "7'2\"", value: '218' },
    { label: "7'3\"", value: '221' },
    { label: "7'4\"", value: '223' },
    { label: "7'5\"", value: '226' },
    { label: "7'6\"", value: '229' },
    { label: "7'7\"", value: '231' },
    { label: "7'8\"", value: '234' },
    { label: "7'9\"", value: '236' },
    { label: "7'10\"", value: '239' },
    { label: "7'11\"", value: '241' },
]

export const AGE_OPTIONS = Array.from({ length: 50 }, (_, index) => ({
    label: (index + 21).toString(),
    value: (index + 21).toString(),
}));

export const HIGHEST_EDUCATION_OPTIONS = [
    { label: '10th', value: '10th' },
    { label: '12th', value: '12th' },
    { label: 'Diploma', value: 'diploma' },
    { label: 'Graduate', value: 'graduate' },
    { label: 'Post graduate', value: 'post_graduate' },
    { label: 'Masters', value: 'Masters' },
];
export const EMPLOYED_IN_OPTIONS = [
    { label: 'Government/PSU', value: 'government-psu' },
    { label: 'Private', value: 'private' },
    { label: 'Business', value: 'business' },
    { label: 'Defence', value: 'defence' },
    { label: 'Self Employed', value: 'self-employed' },
    { label: 'Not working', value: 'not-working' },
];
export const OCCUPATION_OPTIONS = [
    { label: 'Farmer', value: 'farmer' },
    { label: 'Engineer', value: 'engineer' },
    { label: 'Lawyer', value: 'lawyer' },
    { label: 'Doctor', value: 'doctor' },
    { label: 'Professor', value: 'professor' },
];
export const CURRENCIES_OPTIONS = [
    { label: 'INR', value: 'INR' },
];
export const ANNUAL_INCOME_OPTIONS = [
    { label: '100000', value: '100000' },
    { label: '500000', value: '500000' },
    { label: '800000', value: '800000' },
    { label: '1000000', value: '1000000' },
    { label: '2000000', value: '2000000' },
    { label: 'Greater than 20 Lakh', value: 'Greater than 20 Lakh' },
];
export const ANCESTRAL_ORIGIN_OPTIONS = [
    { label: '100000', value: '100000' },
    { label: '500000', value: '500000' },
    { label: '800000', value: '800000' },
    { label: '1000000', value: '1000000' },
    { label: '2000000', value: '2000000' },
];

export const SWAL_SETTINGS = {
    customClass: 'swal-wide',
    position: 'top-right',
    showConfirmButton: false,
    timer: 7000,
    toast: true,
    showClass: {
        popup: 'animate__animated animate__bounceInRight'
    },
    hideClass: {
        popup: 'animate__animated animate__bounceOutRight'
    },
    showCloseButton: true,
    // timerProgressBar: true,
};

/* For AWS Configuration */

export const superAdminPoolData = {
    UserPoolId: process.env.REACT_APP_AWS_ADMIN_USER_POOL_ID,
    ClientId: process.env.REACT_APP_AWS_ADMIN_USER_POOL_CLIENT_ID
}

export const IdentityPoolId = process.env.REACT_APP_USER_IDENTITY_POOL_ID;
export const AwsRegion = process.env.REACT_APP_AWS_REGION;

/* For AWS Configuration */

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const MOBILE_NUMBER_REGEX = /^[0-9]+$/;
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
export const ATLEAST_ONE_SMALL_REGEX = /[a-z]/g
export const ATLEAST_ONE_CAPITAL_REGEX = /[A-Z]/g
export const ATLEAST_ONE_NUMBER_REGEX = /[0-9]/g;
export const ATLEAST_ONE_SPECIAL_CHARACTER_REGEX = /[^\w\s]/;
export const PASSWORD_MIN_LENGTH = 8;


export const DRINKING_OPTIONS = {
    No: 'No',
    Yes: 'Yes',
    occasionally: 'Occasionally'
};



export const SMOKING_OPTIONS = {
    No: 'No',
    Yes: 'Yes',
    occasionally: 'Occasionally'
};



export const EATING_OPTIONS = {
    veg: 'Vegetarian',
    non_veg: 'Non Vegetarian',
    average: 'Eggetarian',
};


export const BODY_OPTIONS = {
    slim: 'Slim',
    athetic: 'Athetic',
    average: 'Average',
    heavy: 'Heavy'
};

export const RECENTLY_ACTIVE_PROFILE = [
    { label: 'today', value: 'today' },
    { label: 'last_3_days', value: 'last_3_days' },
    { label: 'one_week', value: 'one_week' },
    { label: 'one_month', value: 'one_month' }
]

export const PHYSICAL_STATUS = {
    awaiting_divorced: 'awaiting_divorced',
    normal: 'normal'
};

export const DOSH_OPTIONS = {
    NO: 'no',
    YES: 'yes',
    DONT_KNOW: 'dont_know',
    not_specified: 'not_specified'
}

export const SHOW_PROFILE = [
    {
        heading: "Shortlisted",
        sub_heading: "Profiles you have shortlisted"
    },
    {
        heading: "Ignored",
        sub_heading: "Profiles you have ignored"
    },
    {
        heading: "Viewed",
        sub_heading: "Profiles you have already viewed"
    },
]
export const PROFILE_CREATED_BY = [
    { label: 'Friend', value: 'friend' },
    { label: 'Others', value: 'others' },
    { label: 'Parents', value: 'parents' },
    { label: 'Relative', value: 'relative' },
    { label: 'Self', value: 'self' },
    { label: 'Sibling', value: 'sibling' },
]

export const MARITAL_STATUS = [
    { label: 'Never_Married', value: 'never_married' },
    { label: 'Widowed', value: 'widowed' },
    { label: 'Divorced', value: 'divorced' },
    { label: 'Awaiting_divorced', value: 'awaiting_divorced' },
]

export const PHYSICAL_STATUS_OPTIONS = [

    { label: 'Awaiting_divorced', value: 'awaiting_divorced' },
    { label: 'Normal', value: 'normal' }
]

export const DOSH_VALUE = [
 {label: 'No', value:'no'},
 {label: 'Yes',value:'yes'},
 {label: 'Dont_know',value: 'dont_know'},
 {label: 'Not_specified',value:'not_specified'}
]

export const EATING_VALUE = [
  {label:'Vegetarian', value:'vegetarian'},
  {label:'Non_vegetarian',value: 'non_vegetarian'},
 {label: 'Eggetarian',value:'eggetarian'},

]

export const DRINKING_VALUE = [
   {label: 'No',value: 'No'},
   {label: 'Yes',value: 'Yes'},
   {label: 'occasionally',value:'Occasionally'}
]

export const SMOKING_VALUE = [
    {label: 'No',value: 'No'},
    {label: 'Yes',value: 'Yes'},
    {label: 'occasionally',value:'Occasionally'}

]

export const FAMILY_STATUS = [
    {label: 'Middle_Class',value: 'middle_class'},
    {label: 'Upper_Middle_Class',value: 'upper_middle_class'},
    {label: 'Rich',value: 'rich'},
    {label: 'Affluent',value: 'affluent'},
]

export const FAMILY_TYPES = [
    {label: 'Joint',value: 'joint'},
    {label: 'Nuclear',value: 'nuclear'},
]

export const FAMILY_VALUES = [
    {label: 'Orthodox',value: 'orthodox'},
    {label: 'Traditional',value: 'traditional'},
    {label: 'Moderate',value: 'moderate'},
    {label: 'Liberal',value: 'liberal'},
]