import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import CustomError from '../../../../user/shared/CustomError';
import AppBreadCrumbs from '../../../common/breadcrumbs';
import CustomCkEditor from '../../../common/customCkEditor';
import { AddEmailTemplates} from '../../../services/cms.services';
import {SWAL_SETTINGS} from '../../../../../utils/Constants';
import Swal from 'sweetalert2';

function AddEmailTemplatesPage() {

    const navigate = useNavigate();

    const breadcrumbs = [{title:"Dashboard", url:"/admin/dashboard"},{title:"Email Templates", url:"/admin/cms/email-templates/list/1"},{title:"Add", url:""}]
    
    const addValidations = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        slug: Yup.string().required("Slug is required"),
        subject: Yup.string().required("Subject is required"),
        description: Yup.string().required("Description is required"),
    })

    const addForm = useFormik({
        initialValues:{
            title:"",
            slug:"",
            subject:"",
            description:""
        },
        validationSchema: addValidations,
        onSubmit:(values) => {
            let formData = new FormData();
            formData.append('title',values?.title);
            formData.append('slug',values?.slug);
            formData.append('subject',values?.subject);
            formData.append('description',values?.description);
            AddEmailTemplates(formData).then((response) => {
                if(response?.success){
                    Swal.fire({
                        icon: 'success',
                        text: response.message,
                        ...SWAL_SETTINGS
                    })
                    navigate("/admin/cms/email-templates/list/1");
                }
            }).catch((error) => {
                console.log("error",error)
            })
        }
    })

  return (
    <>
        <AppBreadCrumbs data={breadcrumbs} />
        <form onSubmit={addForm.handleSubmit}>
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div>
                                <h6 className="main-content-label mb-3">Add Email Template</h6>
                            </div>
                            <div className="row row-sm">
                                <div className='col-lg-6 form-group'>
                                    <label htmlFor='title' className='text-left d-flex'>Title:<span className="requirestar">*</span>
                                    </label>
                                    <input name='title' id="title" type='text' onChange={addForm.handleChange} onBlur={addForm.handleBlur} value={addForm.values.title} className='form-control' />
                                    <CustomError className={'text-danger'} name='title' form={addForm} />
                                </div>
                                <div className='col-lg-6 form-group'>
                                    <label htmlFor='slug' className='text-left d-flex'>Slug:<span className="requirestar">*</span></label>
                                    <input name='slug' id="slug" type='text' onChange={addForm.handleChange} onBlur={addForm.handleBlur} value={addForm.values.slug} className='form-control' />
                                    <CustomError className={'text-danger'} name='slug' form={addForm} />
                                </div>
                                <div className='col-lg-12 form-group'>
                                    <label htmlFor='subject' className='text-left d-flex'>Subject:<span className="requirestar">*</span>
                                    </label>
                                    <input name='subject' id="subject" type='text' onChange={addForm.handleChange} onBlur={addForm.handleBlur} value={addForm.values.subject} className='form-control' />
                                    <CustomError className={'text-danger'} name='subject' form={addForm} />
                                </div>
                                <div className='col-lg-12 form-group'>
                                    <label htmlFor='subject' className='text-left d-flex'>Description:<span className="requirestar">*</span></label>
                                    <CustomCkEditor
                                        fieldname={"description"}
                                        setFieldValue={addForm.setFieldValue}
                                        value={addForm.values.description}
                                        setFieldTouched={addForm.setFieldTouched}
                                    />
                                    <CustomError className={'text-danger'} name='description' form={addForm} />
                                </div>
                                <div className="">
                                    <button className="btn btn-main-primary signbtn mr-2" type="submit" disabled={addForm.submitted ? true : null}>
                                        <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                        Submit
                                    </button>
                                    <button className="btn ripple btn-secondary" type='button' disabled={addForm.submitted ? true : null} onClick={() => navigate(-1)}>
                                        <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </>
  )
}

export default AddEmailTemplatesPage;