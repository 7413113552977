import React from 'react';
import { Navigate } from 'react-router-dom';
import { getLocalKey } from '../../../utils/commonfunction';

const UserPublicRoute = ({ component: Component }) => {
    const getuserToken = getLocalKey("token") ? JSON.parse(getLocalKey("token")) : {}
    return (
        <>
            {(function(){
                if(!Object.keys(getuserToken).length){
                    return Component;
                }else{
                    return <Navigate to="/dashboard" />;
                }
            })()}
        </>
    )
}

export default UserPublicRoute;

