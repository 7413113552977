import axios from "axios";
import { getLocalKey, getUser, logOutAdmin, logOutUser, setSessionKey } from '../utils/commonfunction';
import { USER_ROLES } from "./Constants";

const Axios = (history = null) => {
  const baseURL = process.env.REACT_APP_API_URL
  let headers = { };
  
  try{
    if(getLocalKey("token")){
      let userToken = JSON.parse(getLocalKey("token"));
      headers.Authorization = `Bearer ${userToken?.jwtToken}`;
    }
  }
  catch(err){
    console.log(err);
  }

  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers
  });

  axiosInstance.interceptors.response.use((response) =>
    new Promise((resolve, reject) => {
      resolve(response.data);
    }), (error) => {
      // console.error("erroree -----> ", error);
      if (error && error.response && error.response.status && error.response.status === 401) {
        const userType = getLocalKey('user_type');
        if(userType === USER_ROLES.SUPER_ADMIN){
          setSessionKey("session_expired", "true");
          localStorage.setItem("landingpage", window.location.pathname);
          localStorage.setItem("landingpagesearch", window.location.search);
          logOutAdmin();
        }else{
          logOutUser();
        }
      }
    }
  );

  return axiosInstance;
};

export default Axios;