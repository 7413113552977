import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AppBreadCrumbs from '../../../common/breadcrumbs';
import CustomError from '../../../../user/shared/CustomError';
import CustomCkEditor from '../../../common/customCkEditor';
import { useNavigate, useParams } from 'react-router-dom';
import { EditPages, PageDetails } from '../../../services/cms.services';
import { SWAL_SETTINGS } from '../../../../../utils/Constants';
import Swal from 'sweetalert2';
import GlobalLoader from '../../../shared/globalLoader';

function EditCmsPage() {
    
    const navigate = useNavigate();
    const params = useParams();
    const [showDefault, setShowDefault] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        PageDetails(params.id).then((response) => {
            if(response?.success){
                setShowDefault(response?.data);
                setTimeout(() => {
                    setDataLoaded(true);
                },100)
            }
        }).catch((err) => {
            console.log("err",err)
        })
    },[params.id]);

    const breadcrumbs = [{title:"Dashboard", url:"/admin/dashboard"},{title:"Pages", url:"/admin/cms/pages/list/1"},{title:"Edit",url:""}]

    const editFormValidation = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        slug: Yup.string().required("Slug is required"),
        description: Yup.string().required("Description is required")
    })

    const editForm = useFormik({
        enableReinitialize: true,
        initialValues:{
            title: showDefault && showDefault?.title ? showDefault.title:  '',
            slug: showDefault && showDefault?.slug ? showDefault.slug: '',
            description: showDefault && showDefault?.description ? showDefault.description: ''
        },
        validationSchema: editFormValidation,
        onSubmit: (values) => {
            let formData = new FormData();
            formData.append('title',values?.title);
            formData.append('slug',values?.slug);
            formData.append('description',values?.description);

            EditPages(params.id, formData).then((response) => {
                if(response?.success){
                    Swal.fire({
                        icon: 'success',
                        text: response.message,
                        ...SWAL_SETTINGS
                    })
                    navigate("/admin/cms/pages/list/1");
                }
            }).catch((error) => {
                console.log("error",error)
            })
        }
    })

  return (
    <>
        <AppBreadCrumbs data={breadcrumbs} />
        {dataLoaded ? (
            <form onSubmit={editForm.handleSubmit}>
                <div className="row row-sm">
                    <div className="col-lg-12 col-md-12 animation_fade">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-3">Edit Cms</h6>
                                </div>
                                <div className="row row-sm">
                                    <div className='col-lg-6 form-group'>
                                        <label htmlFor='title' className='text-left d-flex'>Title:<span className="requirestar">*</span>
                                        </label>
                                        <input name='title' id="title" type='text' onChange={editForm.handleChange} onBlur={editForm.handleBlur} value={editForm.values.title} className='form-control' />
                                        <CustomError className={'text-danger'} name='title' form={editForm} />
                                    </div>
                                    <div className='col-lg-6 form-group'>
                                        <label htmlFor='slug' className='text-left d-flex'>Slug:<span className="requirestar">*</span></label>
                                        <input name='slug' id="slug" type='text' onChange={editForm.handleChange} onBlur={editForm.handleBlur} value={editForm.values.slug} className='form-control' />
                                        <CustomError className={'text-danger'} name='slug' form={editForm} />
                                    </div>
                                    <div className='col-lg-12 form-group'>
                                        <label htmlFor='subject' className='text-left d-flex'>Description:<span className="requirestar">*</span></label>
                                        <CustomCkEditor
                                            fieldname={"description"}
                                            setFieldValue={editForm.setFieldValue}
                                            value={editForm.values.description}
                                            setFieldTouched={editForm.setFieldTouched}
                                        />
                                        <CustomError className={'text-danger'} name='description' form={editForm} />
                                    </div>
                                    <div className="">
                                        <button className="btn btn-main-primary signbtn mr-2" type="submit" >
                                            <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                            Submit
                                        </button>
                                        <button className="btn ripple btn-secondary" type='button' onClick={() => navigate(-1)}>
                                            <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        ) : (
            <GlobalLoader height={'10vh'} background={'#fff'} textColor={'#504fb3'}/>
        )}
    </>
  )
}

export default EditCmsPage;