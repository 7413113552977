import axiosInstance from "../../../utils/axios";

const path = "admin/portal-design"

export const PortalDesignUpdate = async (values) => {
    return await axiosInstance().post(`${path}/update`, values);
}

export const PortalDesignDetails = async () => {
    return await axiosInstance().get(`${path}/details`);
}
export const PortalDesignResetSetting = async () => {
    return await axiosInstance().post(`${path}/reset-settings`);
}