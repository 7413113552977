export const BASE ="http://172.16.11.93:8000/web-services/api/v1"
export const BASE_API = BASE;

export const MOBILE_REGEX = /^[0-9]{10}$/;

export const PASSWORDS_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%^&*?])[A-Za-z\d#$@!%^&*?]{8,}$/g;

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z ]{2,}))$/;

export const NOSPACE_REGEX = /^(?!\s+$).*/;

export const NOSPACE = "Spaces are not allowed";

export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/gif", "video/mp4", "video/x-ms-wmv", "video/quicktime", "video/webm", "application/pdf", "text/plain", "application/msword", "application/vnd.ms-excel", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.openxmlformats-officedocument.presentationml.presentation"];

export const MULTIPLESPACE = /  +/g;

export const CARD_REGEX = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/g;

export const NODIGITREGEX = /^([^0-9]*)$/

export const DECIMALPATTERN = /^[0-9]+(\.[0-9]{1,2})?$/;

export const Months = [
    { name: 'Jan', days: 31,value:1 },
    { name: 'Feb', days: 28 ,value:2},
    { name: 'Mar', days: 31,value:3},
    { name: 'Apr', days: 30,value:4 },
    { name: 'May', days: 31,value:5 },
    { name: 'Jun', days: 30,value:6 },
    { name: 'Jul', days: 31,value:7 },
    { name: 'Aug', days: 31,value:8 },
    { name: 'Sept', days: 30 ,value:9},
    { name: 'Oct', days: 31 ,value:10},
    { name: 'Nov', days: 30,value:11 },
    { name: 'Dec', days: 31,value:12 },
  ];
