import React from 'react';
import { Navigate } from 'react-router-dom';
import { getLocalKey} from '../../../utils/commonfunction';

const PrivateRoute = ({ component: Component, route }) => {
    const adminData = getLocalKey("admin_aws_auth") ? JSON.parse(getLocalKey("admin_aws_auth")) : {};
    return (
        <>
            {(function(){
                if(Object.keys(adminData).length){
                    return Component;
                }else{
                    return <Navigate to="/admin/login" />;
                }
            })()}
        </>
    )
}

export default PrivateRoute;