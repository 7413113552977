import React from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import logoImg from '../../../assets/user/images/logo.png';
import CustomError from '../../user/shared/CustomError';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { superAdminPoolData } from '../../../utils/Constants';

let userPool = new CognitoUserPool(superAdminPoolData);

function AdminForgotPassword() {

    const formValidation = Yup.object().shape({
        email: Yup.string().email('Please enter a valid email').required("Email is required"),
    })

    const forgotPasswordForm = useFormik({
        initialValues:{
            email: ""
        },
        validationSchema:formValidation,
        onSubmit:(values) => {
            console.log("values",values);

            let userData = {
                Username: values?.email,
                Pool: userPool
            }

            const cognitoUser = new CognitoUser(userData);

            cognitoUser.forgotPassword({
                onSuccess: (data) => {
                    console.log('Forgot password request successful', data);
                },

                onFailure: (err) => {
                    console.log("Error", err)
                }
            })
        }
    })

  return (
    <div className='page main-signin-wrapper'>
        <div className='innerbody'>
            <div className="row signpages text-center">
                <div className="col-md-12">
                    <div className="card">
                        <div className="row row-sm">
                            <div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
                                <div className="mt-5 pt-0 p-0 pos-absolute">
                                    <img src={logoImg} className="ht-100 mb-4 mt-2" alt="logo" style={{height:"65px"}}/>
                                    <div className="clearfix"></div>
                                    <h5 className="mt-2 text-white">Reset Your Password</h5>
                                    <span className="tx-white-6 tx-14 mb-5 mt-xl-0">Signup to create, discover and connect with the global community</span>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
                                <div className="container-fluid">
                                    <div className="row row-sm">
                                        <div className="card-body mt-2 mb-2">
                                            {/* <img src="../assets/img/brand/logo.png" className=" d-lg-none header-brand-img text-left float-left mb-4" alt="logo" /> */}
                                            <div className="clearfix"></div>
                                            <h5 className="text-left mb-2">Forgot Password</h5>
                                            <p className="mb-4 text-muted tx-13 ml-0 text-left">OTP will be sent to your Registered Email</p>
                                            <form onSubmit={forgotPasswordForm.handleSubmit}>
                                                <div className="form-group text-left">
                                                    <label>Email</label>
                                                    <input className={"form-control"}
                                                        placeholder="Enter your email"
                                                        type="email"
                                                        name="email"
                                                        onChange={forgotPasswordForm.handleChange}
                                                        onBlur={forgotPasswordForm.handleBlur}
                                                        value={forgotPasswordForm.values.email}
                                                        autoComplete="off" 
                                                    />
                                                    <CustomError className={"text-danger"} name={"email"} form={forgotPasswordForm}/>
                                                </div>
                                                <button className={"btn ripple btn-main-primary btn-block signbtn"} type="submit">Request Email</button>
                                                {/* {loader === true ?
                                                    <div className="spinner-border text-primary mt-2" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> : ""} */}
                                            </form>
                                            <div className="card-footer border-top-0 pl-0 mt-3 text-left ">
                                                <p className='mb-2'>Did you remembered your password?</p>
                                                <p className="mb-0">Try to <Link to="/admin/login">Login</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminForgotPassword;