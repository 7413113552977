// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.


const awsMobile = {
  aws_project_region: process.env.REACT_APP_AWS_REGION, 
  aws_cognito_identity_pool_id: process.env.REACT_APP_USER_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  aws_appsync_graphqlEndpoint: "https://vs7kuieogvh37i2xvgafxy5qge.appsync-api.eu-north-1.amazonaws.com/graphql",
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-7fktddpalradxmrfv4ojarutbm",
};

export default awsMobile;