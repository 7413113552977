import React from 'react';

const Subscription = () => {
    return (
        <div class="wraper-inner">
            <section class="Subscription padding-space">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7 m-auto">
                            <div class="Subscription-top ">
                                <h3 class="text-center">Pay now to contact matches</h3>
                                <ul class="contact-list">
                                    <li>Send <b>unlimited messages</b> & chat, view 50 verified mobile numbers</li>
                                    <li>Check compatibility with matches by viewing unlimited horoscopes</li>
                                    <li class="cross-li"><del>View and contact <b>ID verified matches with photos</b> from <b>exclusive Prime section</b></del></li>
                                    <li class="cross-li"><del><b>Priority customer service</b> helpline</del></li>
                                    <li class="cross-li"><del>Get <b>better visibility and responses</b> from matches with 1-month Profile Highlighter</del></li>
                                </ul>
                            </div>
                            <div class="choose-membership pt-1">
                                <div class="form-check-custom d-flex mt-5">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" value="" id="flexRadioDefault1" />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            <div class="form-check-content">
                                                <div class="form-check-content-head">
                                                    <h4>GOLD</h4>
                                                    <span>3 Months</span>
                                                </div>
                                                <p class="price-text">₹5,300</p>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="form-check best-selling-subs">
                                        <span class="best-selling-tag"><i class="ri-star-fill"></i> best selling <i class="ri-star-fill"></i></span>
                                        <input class="form-check-input" type="radio" value="" id="flexRadioDefault2" />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            <div class="form-check-content">
                                                <div class="form-check-content-head">
                                                    <h4>PRIME GOLD</h4>
                                                    <span>3 Months</span>
                                                </div>
                                                <p class="price-text">₹5,300</p>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" value="" id="flexRadioDefault3" />
                                        <label class="form-check-label" for="flexRadioDefault3">
                                            <div class="form-check-content">
                                                <div class="form-check-content-head">
                                                    <h4>PRIME PLATINUM</h4>
                                                    <span>3 Months</span>
                                                </div>
                                                <p class="price-text">₹5,300</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="Subscription-bottom">
                                    <span class="offer-valid"><b>Offer Valid Only Today!</b></span>
                                    <button class="gdnt-primary min-w">Pay Now ₹ 7,500</button>
                                    <a href="/" class="text-link d-flex align-items-center justify-content-center mt-2">View All Packages <i class="ri-arrow-right-s-line"></i> </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Subscription;