import React, {useLayoutEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import GlobalLoader from './globalLoader';

function FrontRoot() {
    const pathname = useLocation();
    const [isLoading,setIsLoading] = useState(true);
    useLayoutEffect(() => {
        window.scrollTo(0,0)
        setTimeout(() => {
          setIsLoading(false)
        }, 200);
    },[pathname])

  return (
      <>
        {isLoading ? <GlobalLoader/> : <Outlet/>}
      </>
  )
}

export default FrontRoot