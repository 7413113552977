import React, { useState, useEffect, useRef } from "react";
import { getProfile, updateBasicInfo } from "../../services/user.service";
import {
  capitalizeFirstLetter,
  prepareLabel,
  convertCmToFeetAndInches,
} from "../../../../utils/commonfunction";
import * as Yup from "yup";
import CustomError from "../../shared/CustomError";
import { useFormik } from "formik";
import { failedResponse, successResponse } from "../../../../utils/toastr";
import {
  PROFILE_FOR_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  DISABILITY_OPTIONS,
  BODY_OPTIONS,
  HEIGHT_OPTIONS,
  SMOKING_OPTIONS,
  EATING_OPTIONS,
} from "../../../../utils/Constants";
import { getQueries } from "../../services/global.service";
import { Months } from "../../shared/constant";
const EditBasicDetail = ({ userInfo, onUserInfoChange }) => {
  const [disableGender, setDisableGender] = useState(false);
  const [motherTongueOptions, setMotherTongueOptions] = useState([]);
  async function getMotherTongues() {
    getQueries({ type: "mother-tongue" })
      .then((response) => {
        if (response.success) {
          setMotherTongueOptions(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });
  }
  useEffect(() => {
    if (userInfo) {
      const dateOfBirth = new Date(userInfo.date_of_birth);
      const day = dateOfBirth.getDate() + 1;
      const month = dateOfBirth.getMonth() + 1;
      const year = dateOfBirth.getFullYear();
      setDay(day);
      setMonth(month);
      setYear(year);
      handleGenderSelection(userInfo.profile_for);
    }
    getMotherTongues();
  }, [userInfo]);

  const handleKgChange = (event) => {
    const kgValue = event.target.value;
    const lbsValue = Math.round(kgValue * 2.20462);
    // basicInfoForm.setFieldValue("weight_kg", Number(kgValue));
    basicInfoForm.setFieldValue("weight_lbs", lbsValue);
  };

  const handleLbsChange = (event) => {
    const lbsValue = event.target.value;
    const kgValue = Math.round((lbsValue) / 2.20462);
    basicInfoForm.setFieldValue("weight_kg", kgValue);
    basicInfoForm.setFieldValue("weight_lbs", lbsValue);
  };

  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const months = Months;
  // Calculate the birth year range based on the current year
  const currentYear = new Date().getFullYear();
  const minBirthYear = currentYear - 18;
  const years = Array.from({ length: 50 }, (_, i) => minBirthYear - i);

  const getDaysInMonth = (selectedMonth) => {
    const selectedMonthObject = months.find((m) => m.value === selectedMonth);
    return selectedMonthObject ? selectedMonthObject.days : 31;
  };

  // Update days when the month changes
  const handleMonthChange = (e) => {
    setMonth(e.target.value);
    setDay(""); // Reset day when the month changes
  };

  function setDob() {
    console.log(day, month, year);
    if (day && month && year) {
      const dob = new Date(`${month}-${day}-${year}`);
      const today = new Date();
      let age = today.getFullYear() - year;
      basicInfoForm.setFieldValue("date_of_birth", dob);
      basicInfoForm.setFieldValue("age", age);
      return dob;
    }
  }

  const [isInfoEditing, setIsInfoEditing] = useState(false);
  const toggleInfoEdit = () => {
    setIsInfoEditing(!isInfoEditing);
    if(!isInfoEditing){
      basicInfoForm.resetForm();
    }
  };

  const handleCancel = () => {
    toggleInfoEdit();
    basicInfoForm.resetForm();
  };

  const basicInfoValidations = Yup.object().shape({
    profile_for: Yup.string().required("Profile is required"),
    name: Yup.string().trim().required("Name is required"),
    gender: Yup.string().required("Gender is required"),
    body_type: Yup.string().required("Body type is required"),
    age: Yup.string(),
    date_of_birth: Yup.string(),
    physical_status: Yup.string().required("This field is required"),
    height: Yup.number().required("This field is required"),
    weight_kg: Yup.number().required("This field is required").notOneOf([0], 'This field is required'),
    weight_lbs: Yup.number().required("This field is required"),
    mother_tongue_id: Yup.string().required("Mother tongue is required"),
    marital_status: Yup.string().required("Marital status is required"),
    eating_habits: Yup.string(),
    smoking_habits: Yup.string(),
    drinking_habits: Yup.string(),
  });

  const basicInfoForm = useFormik({
    initialValues: {
      profile_for: (userInfo && userInfo.profile_for) || "",
      name: (userInfo && userInfo.name) || "",
      gender: (userInfo && userInfo.gender) || "",
      body_type: (userInfo && userInfo.body_type) || "",
      date_of_birth: "",
      age: (userInfo && userInfo.age) || "",
      physical_status: (userInfo && userInfo.physical_status) || "",
      height: (userInfo && userInfo.height) || "",
      weight_kg: (userInfo && userInfo.weight_kg) ? Math.round(userInfo.weight_kg): "",
      weight_lbs: (userInfo && userInfo.weight_lbs) ? Math.round(userInfo.weight_lbs): "",
      mother_tongue_id: (userInfo && userInfo.mother_tongue_id) || "",
      marital_status: (userInfo && userInfo.marital_status) || "",
      eating_habits: (userInfo && userInfo.eating_habits) || "",
      smoking_habits: (userInfo && userInfo.smoking_habits) || "",
      drinking_habits: (userInfo && userInfo.drinking_habits) || "",
    },
    validationSchema: basicInfoValidations,
    validateOnChange:true,
    validateOnBlur:true,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      // onSubmit: async (values) => {
      console.log("here", values);
      try {
        let dob = setDob();
        let age = new Date().getFullYear() - year;
        let data = { ...values, date_of_birth: dob, age: age };
        const updateUserResponse = await updateBasicInfo(data);
        if (updateUserResponse.success) {
          // fetchUserData();
          onUserInfoChange();
          toggleInfoEdit();
          successResponse(updateUserResponse.message)
        } else {
          failedResponse("Something Went Wrong");
        }
      } catch (err) {
        console.log("Error", err);
      }
    },
  });

  function handleGenderSelection(value) {
    if (value) {
      const maleOptions = [
        PROFILE_FOR_OPTIONS.BROTHER,
        PROFILE_FOR_OPTIONS.SON,
      ];
      const femaleOptions = [
        PROFILE_FOR_OPTIONS.DAUGHTER,
        PROFILE_FOR_OPTIONS.SISTER,
      ];
      if (maleOptions.indexOf(value) >= 0) {
        basicInfoForm.setFieldValue("gender", "male");
        setDisableGender(true);
      } else if (femaleOptions.indexOf(value) >= 0) {
        basicInfoForm.setFieldValue("gender", "female");
        setDisableGender(true);
      } else {
        basicInfoForm.setFieldValue("gender", "");
        setDisableGender(false);
      }
    } else {
      basicInfoForm.setFieldValue("gender", "");
    }
  }

  function handleGenderSelectionEvent(e) {
    const value = e.target.value;
    handleGenderSelection(value);
  }

  return (
    <>
      <div className="personal-info-head d-flex align-items-center justify-content-between mb-3">
        <h3>Basic Details</h3>
        <a className="btn-sm" onClick={toggleInfoEdit}>
          <i className="ri-pencil-fill"></i> {isInfoEditing ? "Close" : "Edit"}
        </a>
      </div>

      <div className="content-wrapper">
        {!isInfoEditing ? (
          <div className="personal-info-body">
            <div className="personal-info-list-box  d-flex">
              <ul className="personal-info-list">
                <li>
                  <span>Profile For</span>
                  <p>
                    :{" "}
                    {userInfo && userInfo.profile_for === "myself"
                      ? "Myself"
                      : "My " + (userInfo && userInfo.profile_for)}
                  </p>
                </li>
                <li>
                  <span>Gender</span>
                  <p className="caps">
                    : {(userInfo && userInfo.gender) || "Not Specified"}
                  </p>
                </li>
                <li>
                  <span>Body Type</span>
                  <p className="caps">
                    : {(userInfo && userInfo.body_type) || "Not Specified"}{" "}
                  </p>
                </li>
                <li>
                  <span>Physical Status</span>
                  <p className="caps">
                    :{" "}
                    {(userInfo && userInfo.physical_status) ? prepareLabel(userInfo.physical_status) : "Not Specified"}
                  </p>
                </li>
                <li>
                  <span>Weight</span>
                  <p>
                    :{" "}
                    {userInfo && userInfo.weight_kg
                      ? Math.round(userInfo.weight_kg) + " kg"
                      : userInfo && userInfo.weight_lbs
                      ? Math.round(userInfo.weight_lbs) + " lbs"
                      : "Not Specified"}
                  </p>
                </li>
                <li>
                  <span>Marital Status</span>
                  <p className="caps">
                    :{" "}
                    {(userInfo && prepareLabel(userInfo.marital_status)) ||
                      "Not Specified"}
                  </p>
                </li>
              </ul>
              <ul className="personal-info-list">
                <li>
                  <span>Name</span>
                  <p className="caps">
                    : {(userInfo && userInfo.name) || "Not Specified"}
                  </p>
                </li>
                <li>
                  <span>Age</span>
                  <p>: {userInfo && userInfo.age}</p>
                </li>
                <li>
                  {/* 4 Ft 6 In / 137 Cms */}
                  <span>Height</span>
                  <p>
                    :{" "}
                    {convertCmToFeetAndInches(
                      userInfo && userInfo.height
                    )}
                  </p>
                </li>
                <li>
                  <span>Mother Tongue</span>
                  <p>
                    : {(userInfo && userInfo.mother_tongue) || "Not Specified"}
                  </p>
                </li>
                <li>
                  <span>Eating Habits</span>
                  <p>
                    : {(userInfo && userInfo.eating_habits) || "Not Specified"}
                  </p>
                </li>
                <li>
                  <span>Smoking Habits</span>
                  <p>
                    : {(userInfo && userInfo.smoking_habits) || "Not Specified"}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="personal-info-body">
            <form onSubmit={basicInfoForm.handleSubmit}>
              <div className="personal-info-edit">
                {/* Profile For */}
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Profile created for :
                      <small className="validate-tag"> *</small>
                    </label>
                    <div className="froup-field ">
                      <select
                        className="form-select"
                        name="profile_for"
                        onChange={(e) => {
                          basicInfoForm.handleChange(e);
                          handleGenderSelectionEvent(e);
                        }}
                        value={basicInfoForm?.values?.profile_for}
                      >
                        <option value="">Select Profile</option>
                        {Object.keys(PROFILE_FOR_OPTIONS).map((key, i) => {
                          return (
                            <option key={i} value={PROFILE_FOR_OPTIONS[key]}>
                              {capitalizeFirstLetter(PROFILE_FOR_OPTIONS[key])}
                            </option>
                          );
                        })}
                      </select>
                      <CustomError name="profile_for" form={basicInfoForm} />
                    </div>
                  </div>
                </div>
                {/* Name */}
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Name : <small className="validate-tag"> *</small>
                    </label>
                    <div className="froup-field ">
                      <input
                        type="text"
                        name="name"
                        onChange={basicInfoForm.handleChange}
                        value={basicInfoForm?.values?.name}
                        placeholder="Enter Name"
                        className="form-control"
                      />
                      <CustomError name="name" form={basicInfoForm} />
                    </div>
                  </div>
                </div>
                {/* Gender */}
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Gender :<small className="validate-tag"> *</small>
                    </label>
                    <div className="froup-field ">
                      <select
                        className="form-select"
                        disabled={disableGender}
                        name="gender"
                        onChange={basicInfoForm.handleChange}
                        value={basicInfoForm?.values?.gender}
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                      <CustomError name="gender" form={basicInfoForm} />
                    </div>
                  </div>
                </div>
                {/* Age or date of birth */}
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Age / Date of birth :{" "}
                      <small className="validate-tag"> *</small>
                    </label>
                    <div className="froup-field d-flex align-items-center">
                      <input
                        type="text"
                        name="age"
                        disabled
                        onChange={basicInfoForm.handleChange}
                        value={basicInfoForm?.values?.age}
                        placeholder="Age"
                        className="form-control"
                      />
                      <span className="to-text ps-2 pe-2">yrs or</span>
                      <select
                        className="form-select ms-1 dob-select"
                        value={day || ""}
                        onChange={(e) => setDay(e.target.value)}
                      >
                        <option value="" disabled hidden>
                          Day
                        </option>
                        {Array.from(
                          { length: getDaysInMonth(month) },
                          (_, i) => i + 1
                        ).map((d) => (
                          <option key={d} value={d}>
                            {d}
                          </option>
                        ))}
                      </select>

                      <select
                        className="form-select ms-1 dob-select"
                        value={month || ""}
                        onChange={handleMonthChange}
                      >
                        <option value="" disabled hidden>
                          Month
                        </option>
                        {months.map((m) => (
                          <option key={m.value} value={m.value}>
                            {m.name}
                          </option>
                        ))}
                      </select>

                      <select
                        className="form-select ms-1 dob-select"
                        value={year || ""}
                        onChange={(e) => setYear(e.target.value)}
                      >
                        <option value="" disabled hidden>
                          Year
                        </option>
                        {years.map((y) => (
                          <option key={y} value={y}>
                            {y}
                          </option>
                        ))}
                      </select>

                      <CustomError name="age" form={basicInfoForm} />
                    </div>
                  </div>
                </div>
                {/* Height */}

                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Height : <small className="validate-tag"> *</small>
                    </label>
                    <div className="froup-field d-flex align-items-center">
                      <select
                        className="form-select "
                        name="height"
                        onChange={basicInfoForm?.handleChange}
                        value={basicInfoForm?.values?.height}
                      >
                        <option value={" "}>Feet / Inches</option>
                        {HEIGHT_OPTIONS.map((item, index) => {
                          return (
                            <option key={index} value={item?.value}>
                              {item?.label}
                            </option>
                          );
                        })}
                      </select>

                    </div>
                  </div>
                      <CustomError name="height" form={basicInfoForm} className='error-ml' />
                </div>

                {/* Weight */}
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Weight : <small className="validate-tag"> *</small>
                    </label>
                    <div className="froup-field d-flex align-items-center">
                      <select
                        className="form-select"
                        name="weight_kg"
                        onChange={(e) => {basicInfoForm.handleChange(e); handleKgChange(e)}}
                        defaultValue={basicInfoForm?.values.weight_kg}
                      >
                        <option value="">Kgs</option>
                        {Array.from(
                          { length: 71 },
                          (_, index) => 30 + index
                        ).map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <span className="to-text ps-2 pe-2">Kgs</span>
                      <select
                        className="form-select"
                        name="weight_lbs"
                        onChange={handleLbsChange}
                        value={basicInfoForm?.values?.weight_lbs}
                      >
                        <option value="">LBS</option>
                        {Array.from({ length: 71 }, (_, index) =>
                          Math.round((30 + index) * 2.20462)
                        ).map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <span className="to-text ps-2 pe-2">Lbs</span>
                    </div>

                  </div>
                    <CustomError name="weight_kg" form={basicInfoForm} className="error-ml"/>
                </div>

                {/* Marital status */}
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Marital Status :{" "}
                      <small className="validate-tag"> *</small>
                    </label>
                    <div className="froup-field d-flex align-items-center">
                      <select
                        className="form-select"
                        name="marital_status"
                        onChange={(e) => {
                          basicInfoForm.handleChange(e);
                        }}
                        value={basicInfoForm?.values?.marital_status}
                      >
                        <option value="">Select Marital Status</option>
                        {Object.keys(MARITAL_STATUS_OPTIONS).map((key, i) => {
                          return (
                            <option key={i} value={MARITAL_STATUS_OPTIONS[key]}>
                              {prepareLabel(MARITAL_STATUS_OPTIONS[key])}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                    <CustomError name="marital_status" form={basicInfoForm} className="error-ml"/>
                </div>
                {/* Body Type */}
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Body Type : <small className="validate-tag"> *</small>
                    </label>
                    <div className="froup-field ">
                      <ul className="list-sec d-flex align-items-center">
                        {Object.keys(BODY_OPTIONS).map((item, i) => {
                          return (
                            <li key={i}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  checked={
                                    basicInfoForm.values.body_type ==
                                    BODY_OPTIONS[item]
                                  }
                                  name={"body_type"}
                                  onChange={basicInfoForm.handleChange}
                                  value={BODY_OPTIONS[item]}
                                  id={"body_type" + i}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"body_type" + i}
                                >
                                  {/* {prepareLabel(BODY_OPTIONS[item])} */}
                                </label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                      <CustomError name="body_type" form={basicInfoForm} className='error-ml' />
                </div>
                {/* Physical status */}
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Physical Status :{" "}
                      <small className="validate-tag"> *</small>
                    </label>
                    <ul className="list-sec d-flex align-items-center">
                      {Object.keys(DISABILITY_OPTIONS).map((item, i) => {
                        return (
                          <li key={i}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                checked={
                                  basicInfoForm.values.physical_status ==
                                  DISABILITY_OPTIONS[item]
                                }
                                name={"physical_status"}
                                onChange={basicInfoForm.handleChange}
                                value={DISABILITY_OPTIONS[item]}
                                id={"physical_status" + i}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={"physical_status" + i}
                              >
                                {prepareLabel(DISABILITY_OPTIONS[item])}
                              </label>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                      <CustomError name="physical_status" form={basicInfoForm} className='error-ml' />
                </div>
                {/* Mother Tongue */}
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Mother Tongue : <small className="validate-tag"> *</small>
                    </label>
                    <div className="froup-field d-flex align-items-center">
                      <select
                        className="form-select b-border-input"
                        name={"mother_tongue_id"}
                        onChange={basicInfoForm.handleChange}
                        value={basicInfoForm?.values?.mother_tongue_id}
                      >
                        <option value={""}>Select</option>
                        {motherTongueOptions.map((motherTongue, i) => {
                          return (
                            <option key={i} value={motherTongue?.id}>
                              {motherTongue?.name}
                            </option>
                          );
                        })}
                      </select>
                      <CustomError name="mother_tongue_id" form={basicInfoForm} />
                    </div>
                  </div>
                </div>
                {/* Know to read */}
                {/* <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">Know to Read :</label>
                    <div className="form-check coustom-check d-flex align-items-center">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Hindi
                      </label>
                    </div>
                  </div>
                </div> */}

                {/* Eating Habits */}
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">Eating Habits:</label>
                    <ul className="list-sec d-flex">
                      {Object.keys(EATING_OPTIONS).map((item, i) => {
                        return (
                          <li key={i}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                checked={
                                  basicInfoForm.values.eating_habits ==
                                  EATING_OPTIONS[item]
                                }
                                name={"eating_habits"}
                                onChange={basicInfoForm.handleChange}
                                value={EATING_OPTIONS[item]}
                                id={"eating_habits" + i}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={"eating_habits" + i}
                              >
                                {prepareLabel(EATING_OPTIONS[item])}
                              </label>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                {/* Drinking Habits */}
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Drinking Habits :{" "}
                    </label>
                    <div className="froup-field">
                      <ul className="list-sec d-flex">
                        {Object.keys(SMOKING_OPTIONS).map((item, i) => {
                          return (
                            <li key={i}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  checked={
                                    basicInfoForm.values.drinking_habits ==
                                    SMOKING_OPTIONS[item]
                                  }
                                  name={"drinking_habits"}
                                  onChange={basicInfoForm.handleChange}
                                  value={SMOKING_OPTIONS[item]}
                                  id={"drinking_habits" + i}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"drinking_habits" + i}
                                >
                                  {prepareLabel(SMOKING_OPTIONS[item])}
                                </label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Smoking Habits */}
                <div className="form-group d-flex align-items-center">
                  <label className="inline-label-sm">Smoking Habits :</label>
                  <div className="froup-field">
                    <ul className="list-sec d-flex">
                      {Object.keys(SMOKING_OPTIONS).map((item, i) => {
                        return (
                          <li key={i}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                checked={
                                  basicInfoForm.values.smoking_habits ==
                                  SMOKING_OPTIONS[item]
                                }
                                name={"smoking_habits"}
                                onChange={basicInfoForm.handleChange}
                                value={SMOKING_OPTIONS[item]}
                                id={"smoking_habits" + i}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={"smoking_habits" + i}
                              >
                                {prepareLabel(SMOKING_OPTIONS[item])}
                              </label>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="personal-info-btn mt-3 text-end">
                  <button className="btn-sm" type="submit">
                    Save
                  </button>
                  <button className="btn-sm btn-outline ms-2" type="button" onClick={handleCancel}>Cancel</button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default EditBasicDetail;
