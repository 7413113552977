import React, { useState, useEffect } from "react";
import {
  getProfile,
  setFamilyAboutInfo,
  getFamilyInfo,
} from "../../services/user.service";
import * as Yup from "yup";
import CustomError from "../../shared/CustomError";
import { useFormik } from "formik";
import { failedResponse, successResponse } from "../../../../utils/toastr";

const EditAboutFamily = () => {
  const [isInfoEditing, setIsInfoEditing] = useState(false);
  const [userFamilyInfo, setUserFamilyInfo] = useState(null);

  async function fetchUserFamilyData() {
    await getFamilyInfo()
      .then((response) => {
        if (response.success) {
          setUserFamilyInfo(response.data ? response.data : null);
        }
      })
      .catch((err) => {
        console.log("error in fetching family info", err);
      });
  }
  useEffect(() => {
    fetchUserFamilyData();
  }, []);
  
  const toggleInfoEdit = () => {
    setIsInfoEditing(!isInfoEditing);
    if (!isInfoEditing) {
      infoForm.resetForm();
    }
  };
  const handleCancel = () => {
    toggleInfoEdit();
    infoForm.resetForm();
  };

  const infoValidations = Yup.object().shape({  about_my_family: Yup.string()
    .required("Description is required")
    .test('not-only-spaces', 'Description cannot consist of only spaces', (value) => {
      return /\S/.test(value);
    }),
  });

  const infoForm = useFormik({
    initialValues: {
      about_my_family:
        userFamilyInfo && userFamilyInfo.about_my_family
          ? userFamilyInfo.about_my_family
          : "",
    },
    validationSchema: infoValidations,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const updateUserResponse = await setFamilyAboutInfo({
          about_my_family: values.about_my_family,
        });
        if (updateUserResponse.success) {
          fetchUserFamilyData();
          toggleInfoEdit();
          successResponse(updateUserResponse.message);
        } else {
          failedResponse("Something Went Wrong");
        }
      } catch (err) {
        console.log("Error", err);
      }
    },
  });
  return (
    <>
      <div className="personal-info-head d-flex align-items-center justify-content-between mb-3">
        <h3>About My Family</h3>
        <a className="btn-sm" onClick={toggleInfoEdit}>
          <i className="ri-pencil-fill"></i> {isInfoEditing ? "Close" : "Edit"}
        </a>
      </div>

      <div className="content-wrapper">
        {!isInfoEditing ? (
          <div className="personal-info-body">
            <p>
              {userFamilyInfo && userFamilyInfo.about_my_family
                ? userFamilyInfo && userFamilyInfo.about_my_family
                : "Not Specified"}
            </p>
          </div>
        ) : (
          <div className="personal-info-body">
            <div className="personal-info-edit">
              <form onSubmit={infoForm.handleSubmit}>
                <div className="form-group">
                  <textarea
                    className="form-control min-h"
                    placeholder="Enter Description"
                    type="text"
                    name="about_my_family"
                    onChange={infoForm.handleChange}
                    value={infoForm.values.about_my_family}
                  ></textarea>

                  <CustomError name="about_my_family" form={infoForm} />
                  <small>
                    Use this space to talk about your parents, what do they do
                    and what are their backgrounds. What do your siblings do? Do
                    they live in the same city as your parents? Talk about
                    others in your extended family and so on.
                  </small>
                  <div className="personal-info-btn mt-3 text-end">
                    <button className="btn-sm" type="submit">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn-sm btn-outline ms-2"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditAboutFamily;
