import axiosInstance from "../../../utils/axios";
const path = "web/user"

export const getProfile = async (values) => {
    return await axiosInstance().get(`${path}/profile`, values);
}

export const updateProfile= async (values) => {
    return await axiosInstance().post(`${path}/update-profile`, values);
}

export const updateBasicInfo= async (values) => {
    return await axiosInstance().post(`${path}/update-basic-info`, values);
}

export const getLocation = async (data={}) => {
    return await axiosInstance().get(`${path}/get-location-info`);
}

export const setLocation = async (values) => {
    return await axiosInstance().post(`${path}/set-location-info`,values);
}

export const getProfessionalInfo = async (data={}) => {
    return await axiosInstance().get(`${path}/get-professional-info`);
}

export const setProfessionalInfo = async (values) => {
    return await axiosInstance().post(`${path}/set-professional-info`,values);
}


export const getReligionalInfo = async (data={}) => {
    return await axiosInstance().get(`${path}/get-religional-info`);
}

export const setReligionalInfo = async (values) => {
    return await axiosInstance().post(`${path}/set-religional-info`,values);
}


export const getFamilyInfo = async (data={}) => {
    return await axiosInstance().get(`${path}/get-family-info`);
}

export const setFamilyInfo = async (values) => {
    return await axiosInstance().post(`${path}/set-family-info`,values);
}


export const setFamilyAboutInfo = async (values) => {
    return await axiosInstance().post(`${path}/set-about-family-info`,values);
}

export const setInterest = async (values) => {
    return await axiosInstance().post(`${path}/set-interests`,values);
}

export const getInterest = async (values) => {
    return await axiosInstance().post(`${path}/get-interests`,values);
}

export const imageRecongnition = async (values) => {
    return await axiosInstance().post(`${path}/image-recongnition`,values);
}

export const getUserPhotos = async () => {
    return await axiosInstance().get(`${path}/get-user-photos`);
}

export const deleteUserPhotos = async (id) => {
    return await axiosInstance().delete(`${path}/delete-user-photos/${id}`);
}

export const makeMainPhoto = async (id) => {
    return await axiosInstance().patch(`${path}/set-profile-picture/${id}`);
}