import axiosInstance from "../../../utils/axios";
const path = "web/search"

export const searchSave = async (values) => {
    return await axiosInstance().post(`${path}/save`, values);
}

export const searchList = async (values) => {
    return await axiosInstance().post(`${path}/list`, values);
}

export const searchDetails = async (matrimony_id) => {
    return await axiosInstance().get(`${path}/view-profile?matrimony_id=${matrimony_id}`);
}

export const searchDelete = async (id) => {
    return await axiosInstance().delete(`${path}/${id}`);
}

export const searchEdit = async (id, data) => {
    return await axiosInstance().patch(`${path}/edit/${id}`,data);
}

export const Details = async (id) => {
    return await axiosInstance().get(`${path}/details/${id}`);
}