import React, { useState, useEffect, useRef } from "react";
import { getQueries, getRaasi } from "../../services/global.service";
import {
  capitalizeFirstLetter,
  prepareLabel,
} from "../../../../utils/commonfunction";
import * as Yup from "yup";
import CustomError from "../../shared/CustomError";
import { useFormik } from "formik";
import { failedResponse, successResponse } from "../../../../utils/toastr";
import {
  getReligionalInfo,
  setReligionalInfo,
  getProfile,
} from "../../services/user.service";
import {
  getCities,
  getCountries,
  getStates,
} from "../../services/global.service";
import { DOSH_QUESTION_OPTIONS } from "../../../../utils/Constants";
const EditReligionInfo = ({userInfo,userReligionInfo,onUserReligionInfoChange}) => {
  const infoFormRef = useRef();
  const [religionOptions, setReligionOptions] = useState([]);
  const [casteOptions, setCasteOptions] = useState([]);
  const [gotraOptions, setGotraOptions] = useState([]);
  const [doshOptions, setDoshOptions] = useState([]);
  const [raasiOptions, setRaasiOptions] = useState([]);
  const [starOptions, setStarOptions] = useState([]);
  const [isInfoEditing, setIsInfoEditing] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [Cities, setCities] = useState([]);
  const [selectedDosh, setSelectedDosh] = useState("");

  const [timeOfBirthState, setTimeOfBirthState] = useState({
    time_am_of_birth: "",
    time_hr_of_birth: "",
    time_mn_of_birth: "",
  });

  const renderFirstError = () => {
    if (infoForm.errors.time_am_of_birth) {
      return <CustomError name="time_am_of_birth" form={infoForm} className="error-ml" />;
    }
    if (infoForm.errors.time_hr_of_birth) {
      return <CustomError name="time_hr_of_birth" form={infoForm} className="error-ml" />;
    }
    if (infoForm.errors.time_mn_of_birth) {
      return <CustomError name="time_mn_of_birth" form={infoForm} className="error-ml" />;
    }
    return null; // No error found
  };

  useEffect(() => {
    if (userReligionInfo) {
      if (userReligionInfo.time_of_birth) {
      let tob = userReligionInfo.time_of_birth;
      let tobArray = tob.split(" ");
      let tobArray2 = tobArray[0].split(":");
      setTimeOfBirthState({
        time_am_of_birth: tobArray[1],
        time_hr_of_birth: tobArray2[0],
        time_mn_of_birth: tobArray2[1],
      });
    }
    setSelectedDosh(userReligionInfo.have_dosh);
    getCountriesData();
    getStatesData(userReligionInfo?.country_of_birth);
    getCitiesData(userReligionInfo?.state_of_birth);
    }
  }, [userReligionInfo,userInfo]);

  const toggleInfoEdit = () => {
    setIsInfoEditing(!isInfoEditing);
  };

  const handleCancel = () => {
    toggleInfoEdit();
    infoForm.resetForm()
  };
  async function getCountriesData() {
    getCountries()
      .then((response) => {
        if (response.success) {
          setCountries(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });
  }
  async function getCitiesData(id = 1) {
    getCities({ state_id: id })
      .then((response) => {
        if (response.success) {
          setCities(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });
  }
  async function getStatesData(id = 1) {
    getStates({ country_id: id })
      .then((response) => {
        if (response.success) {
          setStates(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });
  }
  useEffect(() => {
    /**Getting religion options */
    getQueries({ type: "religion" })
      .then((response) => {
        if (response.success) {
          setReligionOptions(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });

    getQueries({ type: "caste" })
      .then((response) => {
        if (response.success) {
          setCasteOptions(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });

    /**Getting getGotras options */
    getQueries({ type: "gotras" })
      .then((response) => {
        if (response.success) {
          setGotraOptions(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });

    /**Getting getDosh options */
    getQueries({ type: "dosha" })
      .then((response) => {
        if (response.success) {
          setDoshOptions(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });

    getQueries({ type: "star" })
      .then((response) => {
        if (response.success) {
          setStarOptions(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });

    getRaasi()
      .then((response) => {
        if (response.success) {
          setRaasiOptions(response.data);
        }
      })
      .catch((err) => {
        console.log("Server error ", err);
      });

  }, []);

  function handleDoshChange(e) {
    setSelectedDosh(e.target.value);
    infoForm.setFieldValue("have_dosh", e.target.value);
    if (e.target.value !== DOSH_QUESTION_OPTIONS.YES) {
      infoForm.setFieldValue("dosha_ids", []);
    }
  }
  function handleDoshSelection(e) {
    let values = [...infoForm.values.dosha_ids];
    const selectedValue = e.target.value;
    if (values.includes(selectedValue)) {
      values.splice(values.indexOf(selectedValue), 1);
    } else {
      values.push(selectedValue);
    }
    infoForm.setFieldValue("dosha_ids", values);
    console.log(infoForm.values.dosha_ids.includes(selectedValue));
  }


  const handedlCountryChange = (e) => {
    infoForm.setFieldValue('country_of_birth',e.target.value)
    getStatesData(e.target.value)
    infoForm.setFieldValue('state_of_birth','')
    infoForm.setFieldValue('city_of_birth','')
  };


  const handedlStateChange = (e) => {
    infoForm.setFieldValue('state_of_birth',e.target.value)
    getCitiesData(e.target.value)
    infoForm.setFieldValue('city_of_birth','')
  };
  const infoValidations = Yup.object().shape({
    religion_id: Yup.string().required("This field is required"),
    caste_id: Yup.string().required("This field is required"),
    subcaste: Yup.string().required("This field is required"),
    marry_same_other_community: Yup.string(),
    gothram: Yup.string(),
    star_id: Yup.string(),
    // raasi_lookup_id: Yup.string(),
    have_dosh: Yup.string().required("This field is required"),
    dosha_ids: Yup.array().test("dosh", "Please select dosh", (value) => {
      if (
        infoFormRef?.current?.values.have_dosh === DOSH_QUESTION_OPTIONS.YES
      ) {
        return Boolean(value.length);
      } else {
        return true;
      }
    }),
    time_hr_of_birth: Yup.string().required("This field is required"),
    time_mn_of_birth: Yup.string().required("This field is required"),
    time_am_of_birth: Yup.string().required("This field is required"),
    country_of_birth: Yup.string().required("This field is required"),
    state_of_birth: Yup.string().required("This field is required"),
    city_of_birth: Yup.string().required("This field is required"),
  });
  const infoForm = useFormik({
    initialValues: {
      religion_id: (userReligionInfo && userReligionInfo.religion_id) || "",
      caste_id: (userReligionInfo && userReligionInfo.caste_id) || "",
      subcaste: (userReligionInfo && userReligionInfo.subcaste) || "",
      marry_same_other_community:
        (userReligionInfo && userReligionInfo.marry_same_other_community) ||
        false,
      gothram: (userReligionInfo && userReligionInfo.gothram) || "",
      star_id: (userReligionInfo && userReligionInfo.star_id) || "",
      raasi_lookup_id:
        (userReligionInfo && userReligionInfo.raasi_lookup_id) || "",
      have_dosh: (userReligionInfo && userReligionInfo.have_dosh) || "",
      dosha_ids:(userReligionInfo &&userReligionInfo.dosha_ids)?  userReligionInfo.dosha_ids.split(',') : [],
      time_hr_of_birth:
        (timeOfBirthState && timeOfBirthState.time_hr_of_birth) || "",
      time_mn_of_birth:
        (timeOfBirthState && timeOfBirthState.time_mn_of_birth) || "",
      time_am_of_birth:
        (timeOfBirthState && timeOfBirthState.time_am_of_birth) || "",
      country_of_birth:
        (userReligionInfo && userReligionInfo.country_of_birth) || "",
      state_of_birth:
        (userReligionInfo && userReligionInfo.state_of_birth) || "",
      city_of_birth: (userReligionInfo && userReligionInfo.city_of_birth) || "",
    },
    validationSchema: infoValidations,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      try {
        let time_of_birth =
          values.time_hr_of_birth +
          ":" +
          values.time_mn_of_birth +
          " " +
          values.time_am_of_birth;
        let data = {
          religion_id: values.religion_id,
          caste_id: values.caste_id,
          subcaste: values.subcaste,
          marry_same_other_community: values.marry_same_other_community,
          gothram: values.gothram,
          star_id: values.star_id,
          raasi_lookup_id: values.raasi_lookup_id,
          have_dosh: values.have_dosh,
          dosha_ids: values.dosha_ids,
          country_of_birth: values.country_of_birth,
          state_of_birth: values.state_of_birth,
          city_of_birth: values.city_of_birth,
          time_of_birth: time_of_birth,
        };
        const updateResponse = await setReligionalInfo(data);
        if (updateResponse.success) {
          onUserReligionInfoChange();
          toggleInfoEdit();
          resetForm();
          successResponse(updateResponse.message);
        } else {
          failedResponse("Something Went Wrong");
        }
      } catch (err) {
        console.log("Error", err);
      }
    },
  });

  useEffect(() => {
    infoFormRef.current = infoForm;
  }, [infoForm]);
  return (
    <>
      <div className="personal-info-head d-flex align-items-center justify-content-between mb-3">
        <h3>Religion Information</h3>
        <a className="btn-sm" onClick={toggleInfoEdit}>
          <i className="ri-pencil-fill"></i> {isInfoEditing ? "Close" : "Edit"}
        </a>
      </div>

      <div className="content-wrapper">
        {!isInfoEditing ? (
          <div className="personal-info-body">
            <div className="personal-info-list-box  d-flex">
              <ul className="personal-info-list">
                <li>
                  <span>Religion</span>
                  <p>
                    :{" "}
                    {userReligionInfo && userReligionInfo.religion
                      ? userReligionInfo && userReligionInfo.religion
                      : "Not Specified"}
                  </p>
                </li>
                <li>
                  <span>Caste / Sub Caste</span>
                  <p>
                    :{" "}
                    {userReligionInfo
                      ? userReligionInfo.caste && userReligionInfo.subcaste
                        ? `${userReligionInfo.caste}- ${userReligionInfo.subcaste}`
                        : userReligionInfo.caste ||
                          userReligionInfo.subcaste ||
                          "Not specified"
                      : "Not specified"}
                  </p>
                </li>
                <li>
                  <span>Gothra(m)</span>
                  <p>
                    :{" "}
                    {userReligionInfo && userReligionInfo.gotra
                      ? userReligionInfo.gotra
                      : "Not Specified"}
                  </p>
                </li>
                <li>
                  <span>Star / Raasi</span>
                  <p>
                    :{" "}
                    {userReligionInfo && userReligionInfo.star
                      ? userReligionInfo.star
                      : "Not Specified"}
                  </p>
                </li>
                <li>
                  <span>Dosh </span>
                  <p className="caps">
                    :{" "}
                    {userReligionInfo && userReligionInfo.have_dosh == "0"
                      ? "No"
                      : (userReligionInfo && userReligionInfo.have_dosh) ? prepareLabel(userReligionInfo.have_dosh) :'Not Specified'}
                  </p>
                </li>
              </ul>
              <ul className="personal-info-list">
                <li>
                  <span>Time of Birth</span>
                  <p>
                    :{" "}
                    {userReligionInfo && userReligionInfo.time_of_birth
                      ? userReligionInfo.time_of_birth
                      : "Not Specified"}
                  </p>
                </li>
                <li>
                  <span>Place of Birth</span>
                  <p>
                    :{" "}
                    {userReligionInfo
                      ? userReligionInfo.city && userReligionInfo.state
                        ? `${userReligionInfo.city}, ${userReligionInfo.state}`
                        : userReligionInfo.city ||
                          userReligionInfo.state ||
                          "Not specified"
                      : "Not specified"}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <form onSubmit={infoForm.handleSubmit}>
            <div className="personal-info-edit">
              <div className="inline-input-box mb-3">
                <div className="form-group d-flex align-items-center">
                  <label className="inline-label-sm">
                    Religion :<small className="validate-tag"> *</small>
                  </label>
                  <div className="froup-field ">
                    <select
                      className="form-select"
                      name={"religion_id"}
                      onChange={infoForm.handleChange}
                      value={infoForm?.values?.religion_id}
                    >
                      <option value={""}>Select</option>
                      {religionOptions.map((item, i) => {
                        return (
                          <option key={i} value={item?.id}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <CustomError name="religion_id" form={infoForm} />
              </div>
              <div className="inline-input-box mb-3">
                <div className="form-group d-flex align-items-center">
                  <label className="inline-label-sm">
                    Caste :<small className="validate-tag"> *</small>
                  </label>
                  <div className="froup-field ">
                    <select
                      className="form-select"
                      name={"caste_id"}
                      onChange={infoForm.handleChange}
                      value={infoForm?.values?.caste_id}
                    >
                      <option value={""}>Select</option>
                      {casteOptions.map((item, i) => {
                        return (
                          <option key={i} value={item?.id}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <CustomError name="caste_id" form={infoForm} />
              </div>
              <div className="inline-input-box mb-4">
                <div className="form-group d-flex align-items-center mb-4">
                  <label className="inline-label-sm">
                    Sub Caste :<small className="validate-tag"> *</small>
                  </label>
                  <div className="froup-field ">
                    <input
                      type="text"
                      className="form-control"
                      name="subcaste"
                      onChange={infoForm.handleChange}
                      value={infoForm?.values?.subcaste}
                    />
                  </div>
                </div>
                  <CustomError name="subcaste" form={infoForm} className="error-ml" />
                </div>
              <div className="inline-input-box mb-4">
                <div className="form-check mt-2 d-flex align-items-center">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    defaultValue={false}
                    onChange={(e) => {
                      infoForm.setFieldValue(
                        "marry_same_other_community",
                        e.target.checked
                      );
                    }}
                    checked={infoForm?.values?.marry_same_other_community}
                    name="marry_same_other_community"
                    id="marry_same_other_community"
                  />
                  <label
                    className="form-check-label cp"
                    htmlFor="willingToMarryOtherCommunities"
                  >
                    Willing to marry from other communities also
                  </label>
                </div>
                {/* <ul className="list-sec">
                  <li>
                    <div className="form-check d-flex align-items-start">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault11"
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="flexRadioDefault11"
                      >
                        Willing to marry from the same community
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check d-flex align-items-start">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault11"
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="flexRadioDefault11"
                      >
                        Willing to marry from other communities also
                      </label>
                    </div>
                  </li>
                </ul> */}
              </div>
              <div className="inline-input-box mb-3">
                <div className="form-group d-flex align-items-center">
                  <label className="inline-label-sm">Gothra(m) :</label>
                  <div className="froup-field ">
                    <select
                      className="form-select"
                      name={"gothram"}
                      onChange={infoForm.handleChange}
                      value={infoForm?.values?.gothram}
                    >
                      <option value={""}>Select</option>
                      {gotraOptions.map((item, i) => {
                        return (
                          <option key={i} value={item?.id}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <CustomError name="gothram" form={infoForm} />
              </div>
              <div className="inline-input-box mb-3">
                <div className="form-group d-flex align-items-center">
                  <label className="inline-label-sm">Star Sign :</label>
                  <div className="froup-field ">
                    <select
                      className="form-select"
                      name={"star_id"}
                      onChange={infoForm.handleChange}
                      value={infoForm?.values?.star_id}
                    >
                      <option value={""}>Select</option>
                      {starOptions.map((item, i) => {
                        return (
                          <option key={i} value={item?.id}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <CustomError name="star_id" form={infoForm} />
              </div>
              <div className="inline-input-box mb-3">
                <div className="form-group d-flex align-items-center">
                  <label className="inline-label-sm">Raasi / Moon Sign :</label>
                  <div className="froup-field ">
                    <select
                      className="form-select"
                      name={"raasi_lookup_id"}
                      onChange={infoForm.handleChange}
                      value={infoForm?.values?.raasi_lookup_id}
                    >
                      <option value={""}>Select</option>
                      {starOptions.map((item, i) => {
                        return (
                          <option key={i} value={item?.id}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <CustomError name="raasi_lookup_id" form={infoForm} />
              </div>
              <div className="inline-input-box mb-3">
                <div className="form-group d-flex align-items-center">
                  <label className="inline-label-sm">
                    Have Dosh : <small className="validate-tag"> *</small>
                  </label>
                  <div className="froup-field ">
                    <ul className="list-sec d-flex align-items-center">
                      {Object.keys(DOSH_QUESTION_OPTIONS).map((item, i) => {
                        return (
                          
                          <li key={i}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="have_dosh"
                              onChange={handleDoshChange}
                              checked={
                                selectedDosh === DOSH_QUESTION_OPTIONS[item]
                              }
                              value={DOSH_QUESTION_OPTIONS[item]}
                              id={"doshNo" + i}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={"doshNo" + i}
                            >
                              {prepareLabel(DOSH_QUESTION_OPTIONS[item])}
                            </label>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              {infoForm?.values?.have_dosh &&
              infoForm?.values?.have_dosh === DOSH_QUESTION_OPTIONS.YES ? (
                <div className="inline-input-box mb-3">
                  <div className="form-group d-flex align-items-center">
                    <label className="inline-label-sm">
                      Dosh : <small className="validate-tag"> *</small>
                    </label>
                    <div className="froup-field ">
                      <ul className="check-option-list d-flex">
                        {doshOptions.map((item, i) => {
                          return (
                            <li key={i}>
                              <div className="form-check d-flex align-items-center">
                                <input
                                  className="form-check-input cp me-2"
                                  type="checkbox"
                                  name="dosha_ids"
                                  value={item?.id}
                                  checked={Boolean(
                                    infoForm.values.dosha_ids.includes(
                                      String(item?.id)
                                    )
                                  )}
                                  onChange={handleDoshSelection}
                                  id={"dosha_ids" + i}
                                />
                                <label
                                  className="form-check-label cp"
                                  htmlFor={"dosha_ids" + i}
                                >
                                  {item?.name}
                                </label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                      <CustomError name="dosha_ids" form={infoForm} />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="personal-info-head mb-4 mt-4 inner-personal-info">
                <h3>Horoscope Details</h3>
              </div>
              <div className="inline-input-box mb-3">
                <div className="form-group d-flex align-items-center">
                  <label className="inline-label-sm">
                    Time of Birth :<small className="validate-tag"> *</small>
                  </label>
                  <div className="froup-field d-flex align-items-center">
                    <select
                      className="form-select"
                      name="time_hr_of_birth"
                      onChange={infoForm.handleChange}
                      value={infoForm?.values?.time_hr_of_birth}
                    >
                      <option value="">-Hrs-</option>
                      {Array.from({ length: 12 }, (_, index) => (
                        <option
                          key={index + 1}
                          value={String(index + 1).padStart(2, "0")}
                        >
                          {String(index + 1).padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                    <select
                      className="form-select ms-2"
                      name="time_mn_of_birth"
                      onChange={infoForm.handleChange}
                      value={infoForm?.values?.time_mn_of_birth}
                    >
                      <option value="">-Min-</option>
                      {Array.from({ length: 60 }, (_, index) => (
                        <option
                          key={index + 1}
                          value={String(index + 1).padStart(2, "0")}
                        >
                          {String(index + 1).padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                    <select
                      className="form-select ms-2"
                      name="time_am_of_birth"
                      onChange={infoForm.handleChange}
                      value={infoForm?.values?.time_am_of_birth}
                    >
                      <option value="">--AM/PM--</option>
                      <option value="am">AM</option>
                      <option value="pm">PM</option>
                    </select>
                  </div>
                </div>
                {renderFirstError()}
              </div>

              <div className="inline-input-box mb-3">
                <div className="form-group d-flex align-items-start">
                  <label className="inline-label-sm">
                    Place of Birth : <small className="validate-tag"> *</small>
                  </label>
                  <div className="froup-field ">
                    <select
                      className="form-select mb-2"
                      name="country_of_birth"
                      onChange={handedlCountryChange}
                      value={infoForm?.values?.country_of_birth}
                    >
                      <option value="">Country</option>
                      {countries.map((m) => (
                        <option key={m.id} value={m.id}>
                          {m.name}
                        </option>
                      ))}
                    </select>
                    <CustomError name="country_of_birth" form={infoForm}  />
                    <select
                      className="form-select mb-2"
                      name="state_of_birth"
                      onChange={handedlStateChange}
                      value={infoForm?.values?.state_of_birth}
                    >
                      <option value="">State</option>
                      {states.map((m) => (
                        <option key={m.id} value={m.id}>
                          {m.name}
                        </option>
                      ))}
                    </select>
                    <CustomError name="state_of_birth" form={infoForm}  />
                    <select
                      className="form-select mb-2"
                      name="city_of_birth"
                      onChange={infoForm.handleChange}
                      value={infoForm?.values?.city_of_birth}
                    >
                      <option value="">City</option>
                      {Cities.map((m) => (
                        <option key={m.id} value={m.id}>
                          {m.name}
                        </option>
                      ))}
                    </select>
                    <CustomError name="city_of_birth" form={infoForm}  />
                  </div>
                </div>
                        {/* {renderFirstPlaceError()} */}
              </div>
              <div className="personal-info-btn mt-3 text-end">
                <button className="btn-sm" type="submit">
                  Save
                </button>
                <button
                  className="btn-sm btn-outline ms-2"
                  type="button"
                  onClick={toggleInfoEdit}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default EditReligionInfo;
