import React, { useEffect, useState } from 'react';
import trustedImg from '../../../assets/user/images/trusted-img-5.webp';
import { getSessionKey, globalLoader, prepareLabel, removeSessionKey, setLocalKey, setSessionKey } from '../../../utils/commonfunction';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CustomError from '../shared/CustomError';
import { ConfirmUser, signup } from '../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { successResponse, failedResponse } from '../../../utils/toastr';
import Swal from 'sweetalert2';
import { getLoggedInUser } from '../../../utils/amazon_utils2';

export default function SignupStep5() {
    const navigate = useNavigate();
    const [profileOwnerName, setProfileOwnerName] = useState('');
    useEffect(() => {
        document.querySelector('body').classList.add('bg-lignt-grey-shade');
        return () => {
            document.querySelector('body').classList.remove('bg-lignt-grey-shade');
        };
    }, []);
    useEffect(() => {
        /**Checking that user is coming from prev step */
        const prevStep = getSessionKey('REGISTRATION_COMPLETED_STEPS');
        try {
            const prevData = JSON.parse(getSessionKey('REGISTRATION_FORM_DATA'));
            if (prevData && prevData.profileFor) {
                setProfileOwnerName(prevData.profileFor);
            }
        } catch (err) {
            console.log("Error", err);
        }
        if (prevStep && prevStep === "4") {
            // Everything is fine 
        } else {
            // Go back to initial step 
            navigate('/');
        }
    }, [navigate]);

    const signupValidations = Yup.object().shape({
        aboutProfileOwner: Yup.string().trim().required('This field is required').min(50, "Please enter minimum 50 characters"),
        acceptedTerms: Yup.string().trim().required('Please accept the terms and conditions').test('acceptedTerms','Please accept the terms and conditions',(value)=>{
            if(value === "true" || value === true){
                return true;
            }else{
                return false;
            }
        })
    });
    const signupForm = useFormik({
        initialValues: {
            aboutProfileOwner: '',
            acceptedTerms: false,
        },
        validationSchema: signupValidations,
        onSubmit: values => {
            globalLoader(true);
            try {
                /**Retrieving previous step's data */
                const prevStepData = JSON.parse(getSessionKey('REGISTRATION_FORM_DATA'));
                let allData = { ...prevStepData, ...values };
                signup(allData).then((signRes) => {
                    if(signRes?.success){
                        setSessionKey('user_id', signRes.data.userId);
                        setSessionKey('phone_number', JSON.stringify(signRes.data.phoneNumber));
                        setSessionKey('validate_token', signRes.data.validateToken);
                        setSessionKey('password', allData.password);
                        setSessionKey('email', allData.email);
                        removeSessionKey('REGISTRATION_COMPLETED_STEPS');
                        removeSessionKey('REGISTRATION_FORM_DATA');
                        successResponse(signRes.message);
                        getLoggedInUser().then((loggedInUser)=>{
                            globalLoader(false);
                            setLocalKey("token", JSON.stringify({...loggedInUser.signInUserSession.accessToken}));
                            navigate("/dashboard");
                        });
                    }else{
                        failedResponse(signRes.message);
                        globalLoader(false);
                    }
                }).catch((err) => {
                    console.log("####Error", err);
                    failedResponse(err);
                    globalLoader(false);
                });
            } catch (err) {
                console.log("Error", err);
                failedResponse(err);
                globalLoader(false);
            }
        },
    });

    return (
        <div className="wraper-inner">
            <section className="accounts-page padding-space">
                <div className="container">
                    <span className="tag-line">
                        Great! You have completed <b>90%</b>
                    </span>
                    <div className="signup-box-main">
                        <div className="row">
                            <div className="col-md-4 pe-0">
                                <div className="signup-box-l">
                                    <figure>
                                        <img src={trustedImg} alt="trusted-img-5.webp" />
                                    </figure>
                                    <figcaption>
                                        <p>
                                            With thousands of profiles, this is the best place to find
                                            your {profileOwnerName} match faster.
                                        </p>
                                    </figcaption>
                                </div>
                            </div>
                            <div className="col-md-8 ps-0">
                                <div className="signup-box-r">
                                    <h3>Let's write something interesting about {profileOwnerName == "myself" ? "you" : "your" + " " + profileOwnerName} </h3>
                                    <div className="singup-form">
                                        <form onSubmit={signupForm.handleSubmit}>
                                            <div className="form-group  mb-3 ">
                                                <div className="inline-field align-items-start">
                                                    <label className="form-label">{profileOwnerName == "myself" ? "About you" : "About your "+ profileOwnerName}</label>
                                                    <div className="form-group">
                                                        <textarea
                                                            className="form-control min-textarea"
                                                            name='aboutProfileOwner' onChange={signupForm.handleChange} value={signupForm?.values?.aboutProfileOwner}
                                                        />
                                                        <CustomError name='aboutProfileOwner' form={signupForm} />
                                                    </div>
                                                    <div className="option-text">
                                                        Write a few words to get to know better
                                                        <a className="link-text d-block" >Help me to write</a>
                                                    </div>
                                                </div>
                                                <div className="addon-min">
                                                    {
                                                        signupForm?.values?.aboutProfileOwner && signupForm?.values?.aboutProfileOwner.trim().length ?
                                                            <span className="pt-2 pb-3 des-text">
                                                                <b>{signupForm?.values?.aboutProfileOwner.trim().length} </b>Characters Typed.
                                                            </span>
                                                            : null
                                                    }
                                                    <div className="form-check mt-2 ">
                                                        <input
                                                            className="form-check-input cp"
                                                            type="checkbox"
                                                            defaultValue={false}
                                                            onChange={(e) => { signupForm.setFieldValue('acceptedTerms', e.target.checked) }} checked={signupForm?.values?.acceptedTerms}
                                                            id="acceptedTerms"
                                                        />
                                                        <label
                                                            className="form-check-label cp"
                                                            htmlFor="acceptedTerms"
                                                        >
                                                            I hereby consent to share my personal data for the
                                                            purpose of matchmaking and its related services.
                                                        </label>
                                                    </div>
                                                    <CustomError name='acceptedTerms' form={signupForm} />
                                                </div>
                                            </div>
                                            <button className="btn btn-primary min-w mt-5" type='submit'>
                                                Complete
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-text text-center mt-4 mb-4">
                        <p className="">Copyright © 2023. All rights reserved.</p>
                    </div>
                </div>
            </section>
        </div>
    )
}