// // Example React component using the upload and download functions
import React, { useState } from 'react';
import axios from 'axios';
import { upload } from "../services/demo.service";
import { Storage } from 'aws-amplify';
import { uploadFileToS3 } from '../../../utils/amazon_utils';


const FileUploader = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (file) {
      uploadFile(file);
    }
  };

  // File upload
const uploadFile = async (file) => {
  const formData = new FormData(); 
  formData.append('file', file);


  await uploadFileToS3(file).then(res=>{

  }).catch(error=>{
    //console.log(error);
  });



  // const verificationResponse = await upload(formData);
  // if (verificationResponse.success) {
  //   //successResponse("Mobile Verification", "Verified Successfully");
  //   //navigate('/');
  // } else {
  //   //failedResponse('Error', verificationResponse.message);
  // }

  // // await axios.post('/upload', formData, {
  // //   headers: {
  // //     'Content-Type': 'multipart/form-data',
  // //   },
  // // });
};

// File download
const downloadFile = async (filename) => {
  const response = await axios.get(`/download/${filename}`, {
    responseType: 'blob',
  });

  // Handle the file download logic, e.g., display or save the file
};


  return (
    <>
     <div>
     <input type="file" onChange={handleFileChange} />
       <button onClick={handleUpload}>Upload File</button>
     </div>
    </>
  );
};

export default FileUploader;
