import axiosInstance from "../../../utils/axios";
const path = "web/auth"

export const signup = async (values) => {
    return await axiosInstance().post(`${path}/sign-up`, values);
}

export const otpVerification = async (values) => {
    return await axiosInstance().post(`${path}/verify-phone`, values);
}

export const login = async (values) => {
    return await axiosInstance().post(`${path}/login`, values);
}

export const sendEmailOtp = async (values) => {
    return await axiosInstance().post(`${path}/send-otp`, values);
}

export const verifyOtp = async (values) => {
    return await axiosInstance().post(`${path}/verify-email`, values);
}

export const verifyOtpForgetPassword = async (values) => {
    return await axiosInstance().post(`${path}/forget-password/verify-otp`, values);
}

export const completeProfile = async (values) => {
    return await axiosInstance().post(`${path}/complete-profile`, values);
}

// export const forgetPassword = async (values) => {
//     return await axiosInstance().post(`${path}/forget-password`, values);
// }


export const resetPassword = async (values) => {
    return await axiosInstance().post(`${path}/forget-password/reset-password`, values);
}

export const resendOtp = async (values) => {
    return await axiosInstance().post(`${path}/resend-otp`, values);
}

export const ConfirmUser = async (values) => {
    return await axiosInstance().post(`${path}/confirm-user`, values);
}

export const setUserToken = async (values) => {
    return await axiosInstance().post(`${path}/set-user-token`, values);
}

export const IsPhoneVerified = async (values) => {
    return await axiosInstance().post(`${path}/is-phone-verified`, values);
}

export const getUsername = async (values) => {
    return await axiosInstance().post(`${path}/username`, values);
}

