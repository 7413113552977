import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as $ from 'jquery';
import { getLocalKey, logOutAdmin } from '../../../utils/commonfunction';
import Swal from 'sweetalert2';


const Header = () => {

	const [userName, setUserName] = useState("");
	const [roleName, setRoleName] = useState("");
	const [profile, setProfile] = useState("");

	useEffect(() => {
		$(document).ready(function () {
			$('.showpop').click(function () {
				$('.main-profile-menu').addClass("show");
			})
			$('.fixed_bg').click(function () {
				$('.main-profile-menu').removeClass("show");
			})
		})
	}, [])

	useEffect(() => {
		let adminData = getLocalKey("admin");
		setRoleName(adminData?.name);
		setUserName(adminData?.role);
		setProfile(adminData?.main_profile_picture ? URL.createObjectURL(adminData?.main_profile_picture) : "https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png")
	},[])

	const logout = () => {
		Swal.fire({
			customClass: 'swal-wide',
			title: 'Are you sure?',
			text: "You want to logout!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#403fad',
			cancelButtonColor: '#f1388b',
			confirmButtonText: 'Yes'
		}).then((result) => {
			if (result.isConfirmed) {
				logOutAdmin();
			}
		})
	}

	const toggleSidebar = (e) => {
		e.preventDefault();
		if ($("body").hasClass("main-sidebar-hide")) {
			$("body").removeClass("main-sidebar-hide")
		} else {
			$("body").addClass("main-sidebar-hide")
		}
	}

	return (
		<>
			{/* <!-- Main Header--> */}
			<div className="main-header side-header">
				<div className="container-fluid">
					<div className="main-header-left">
					<span className="main-header-menu-icon e_none cp" id="mainSidebarToggle" onClick={toggleSidebar}><span></span></span>
					</div>
					<div className="main-header-center">
						<div className="responsive-logo">
							{/* <a href="index.html"><img src={logo} className="mobile-logo" alt="logo" /></a> */}
							{/* <a href="index.html"><img src="../assets/img/brand/Mask Group 1.png" className="mobile-logo" alt="logo" /></a>
							<a href="index.html"><img src="../assets/img/brand/Mask Group 1.png" className="mobile-logo-dark" alt="logo" /></a> */}
						</div>
					</div>
					<div className="main-header-right">
						<div className={`dropdown main-profile-menu d-flex align-items-center `}>
							{/* <a className="d-flex" href="javascript:void(0)">
								<span className="main-img-user" onClick={() => setShowProfile(!showprofile)}><img alt="avatar" src="../assets/img/brand/Mask Group 1@2x.png"/></span>
							</a> */}
							<button className="d-flex border-0 bg-white showpop">
								{/* <span className="main-img-user"><img alt="avatar" src={Logo} /></span> */}
								<span className="main-img-user"><img alt="avatar" src={profile} /></span>
							</button>
							<div className="dropdown-menu">
								<span className='fixed_bg'></span>
								<div className='position-relative headerdropdown' style={{ zIndex: "99999" }}>
									<div className="header-navheading">
										<h4 className="main-notification-title text-capitalize">{userName}</h4>
										<p className="main-notification-text">{roleName}</p>
									</div>
									<Link className="dropdown-item " to={`/admin/profile/edit/${"1"}`}>
										<i className="fe fe-edit"></i> Edit Profile
									</Link>
									<button className="dropdown-item " onClick={() => logout()}>
										<i className="fe fe-power"></i> Log Out
									</button>
								</div>
							</div>
						</div>
						<button className="navbar-toggler navresponsive-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-4"
							aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
							<i className="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
						</button>
						{/* <!-- Navresponsive closed --> */}
					</div>
				</div>
			</div>
			{/* <!-- End Main Header--> */}
		</>
	)
}

export default Header;