import React, { useEffect, useState } from "react";
import { getSessionKey,globalLoader,setLocalKey } from '../../../utils/commonfunction';
import { otpVerification } from "../services/auth.service";
import * as Yup from 'yup';
import CustomError from '../shared/CustomError';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { successResponse, failedResponse } from "../../../utils/toastr";
import { changePhoneNumber, signIn} from "../../../utils/amazon_utils";

export default function MobileVerify() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({ number: '', id: '', email: '', validate_token: "", password: ''});
  let newCognitoUser = null;

  useEffect(() => {
    const userPhone = JSON.parse(getSessionKey("phone_number"));
    const userId = getSessionKey("user_id");
    const userValidateToken = getSessionKey("validate_token");
    const password = getSessionKey("password");
    const email = getSessionKey("email");
    // const userEmail = getSessionKey('user_email');
    if (userPhone && userId && userValidateToken && password) {
      setUserInfo({
        number: userPhone,
        id: userId,
        validate_token: userValidateToken,
        email: email,
        password: password,
      });
    }
    // setTimeout(()=>{
    //   const cognitoSignInData = { email:userInfo?.email, password:userInfo?.password };
    //   console.log("cognitoSignInData",cognitoSignInData);
    //   signIn(cognitoSignInData).then(({cognitoUser, result}) => {
    //     newCognitoUser = cognitoUser;        
    //     console.log("signInResponse", result.accessToken);
    //     console.log(JSON.stringify(result?.accessToken));
    //     setLocalKey("token", JSON.stringify(result?.accessToken));
    //     newCognitoUser.getAttributeVerificationCode('phone_number', {
    //       onSuccess: function(result) {
    //         console.log('call result: ' + result);
    //       },
    //       onFailure: function(err) {
    //         alert(err.message || JSON.stringify(err));
    //       },
    //       inputVerificationCode: function() {
    //         console.log("inputVerificationCode executed");
    //         // var verificationCode = prompt('Please input verification code: ', '');
    //         // cognitoUser.verifyAttribute('phone_number', verificationCode, this);
    //       },
    //     });
    //     // globalLoader(false);
    //     // navigate("/dashboard");
    //   }).catch((err) => {
    //       console.log("####Sign In Error", err);
    //       failedResponse(err?.message);
    //       globalLoader(false);
    //   });
    // },1000);
  }, []);

  const otpValidations = Yup.object().shape({
    otp: Yup.string()
      .trim()
      .required("OTP is required")
      .matches(/^\d{6}$/, "OTP must be exactly 6 digits"), // Ensure OTP is exactly 6 digits
  });

  const otpForm = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: otpValidations,
    enableReinitialize: true, // Allow reinitialization when initialValues changes
    onSubmit: async (values) => {
      globalLoader(true);
      const mobileOtp = values.otp;
      console.log("newCognitoUser",newCognitoUser);
      let cognitoSignInData = { email:userInfo?.email, password:userInfo?.password }
      signIn(cognitoSignInData).then(({cognitoUser, result}) => {
        // newCognitoUser = cognitoUser;        
        console.log("signInResponse", result.accessToken);
        console.log(JSON.stringify(result?.accessToken));
        setLocalKey("token", JSON.stringify(result?.accessToken));
        cognitoUser.verifyAttribute('phone_number', mobileOtp, this);
        // newCognitoUser.getAttributeVerificationCode('phone_number', {
        //   onSuccess: function(result) {
        //     console.log('call result: ' + result);
        //   },
        //   onFailure: function(err) {
        //     alert(err.message || JSON.stringify(err));
        //   },
        //   inputVerificationCode: function() {
        //     console.log("inputVerificationCode executed");
        //     // var verificationCode = prompt('Please input verification code: ', '');
        //     // cognitoUser.verifyAttribute('phone_number', verificationCode, this);
        //   },
        // });
      });

      // try {
      //   const verificationResponse = await otpVerification({otp:values.otp, validation_token: userInfo.validate_token});
      //     if (verificationResponse.success) {
      //       const cognitoSignInData = { email:userInfo?.email, password:userInfo?.password };
      // signIn(cognitoSignInData).then((signInResponse) => {
      //   console.log(signInResponse.accessToken)
      //   console.log(JSON.stringify(signInResponse?.accessToken))
      //     setLocalKey("token", JSON.stringify(signInResponse?.accessToken));
      //     globalLoader(false);
      //     navigate("/dashboard");
      // }).catch((err) => {
      //     console.log("####Sign In Error", err);
      //     failedResponse(err?.message);
      //     globalLoader(false);
      // });
      //     } else {
      //       failedResponse(verificationResponse.message);
      //       globalLoader(false);
      //     }
      // } catch (err) {
      //   console.log("Error", err);
      //   globalLoader(false);
      //   failedResponse(err?.message);
      // }
    }
  });

  const phoneValidations = Yup.object().shape({
    phone: Yup.object().shape({
      number: Yup.number()
        .typeError("Invalid phone number")
        .required("Phone is required"),
    }),
  });

  const phoneForm = useFormik({
    initialValues: {
      phone: {
        code: userInfo?.number?.code ? userInfo?.number?.code : "+91",
        number: userInfo?.number?.number ? userInfo?.number?.number : "",
      },
    },
    validationSchema: phoneValidations,
    enableReinitialize: true, // Allow reinitialization when initialValues changes
    onSubmit: async (values) => {
      try {
        let data = {
          username: userInfo.email.toString(),
          phone: values.phone.code + values.phone.number,
        };
        changePhoneNumber(data)
          .then(async (response) => {
            successResponse("Success", "Phone number changed successfully");
            // navigate('/verify-mobile');
          })
          .catch((error) => {
            // Handle error
            failedResponse(error.errors);
          });
      } catch (err) {
        console.log("Error", err);
        failedResponse(err);
      }
    },
  });

  // function resendCode() {
  //   try {
  //     let data = {
  //       username: userInfo.email.toString(),
  //     };
  //     cognitoResendCode(data)
  //       .then(async (response) => {
  //         successResponse("Code sent to your mobile successfully");
  //       })
  //       .catch((error) => {
  //         failedResponse(error.errors);
  //       });
  //   } catch (err) {
  //     console.log("Error", err);
  //     failedResponse(err);
  //   }
  // }

  const handleSkip = () => {
    globalLoader(true);
    let cognitoSignInData = { email:userInfo?.email, password:userInfo?.password }
    signIn(cognitoSignInData).then((signInResponse) => {
      setLocalKey("token", JSON.stringify(signInResponse?.accessToken));
      navigate("/dashboard");
      globalLoader(false);
    }).catch((err) => {
      console.log("####Sign In Error", err);
      failedResponse(err?.message);
      globalLoader(false);
    });
  }

  return (
    <>
      <div className="wraper-inner">
        <section className="accounts-page padding-space">
          <div className="container">
            <div className="congratulations-content text-center mb-4">
              <p>
                {" "}
                <b>Congratulations!</b> You have successfully registered with
                MohanMatrimony.{" "}
              </p>
              <p>
                Your Matrimony ID is {/* <b>H12830069 </b> */}
                <b>{userInfo.id}</b>
              </p>
              {/* <div className="form-check d-flex align-items-center justify-content-center ">
              <input className="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault" />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Keep me logged in <span>(Recommended).</span> Stay logged in
                always with MohanMatrimony.com
              </label>
            </div> */}
            </div>
            <hr className="grey-line pb-4" />
            <div className="verify-box text-center">
              <h3>Verify Your Mobile Number</h3>
              <p>
                You will receive a 6-digit confirmation code via SMS to{" "}
                <b>{userInfo?.number?.code + userInfo?.number?.number}</b>
                {/* (<a href="/" data-bs-target="#change-number" data-bs-toggle="modal">edit</a>) */}
              </p>
            </div>
            <div className="Verify-input ">
              <form onSubmit={otpForm.handleSubmit}>
                <div className="form-group d-flex">
                  <input
                    type="text"
                    name="otp"
                    placeholder="Enter Code"
                    className="form-control"
                    onChange={otpForm.handleChange}
                    value={otpForm?.values?.otp}
                  />
                  <button className="btn s-btn" type="submit">
                    Verify
                  </button>
                </div>
                <CustomError name="otp" form={otpForm} className="text-start" />
              </form>
              {/* <span>
              Didn't receive code yet? <a onClick={() => resendCode()}>Resend code</a>
            </span> */}
            </div>
            <div className="text-btn ">
              <a
                onClick={() => handleSkip()}
                className="d-flex align-items-center"
              >
                Skip <i className="ri-arrow-right-s-line"></i>
              </a>
            </div>
          </div>
        </section>
      </div>

      <div
        className="modal fade common-modal xs-modal"
        id="change-number"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-head">
              <h1 className="modal-title">Change Phone Number</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={phoneForm.handleSubmit}>
                <div className="form-group mb-4 select-num">
                  <div className="d-flex">
                    <select
                      className="form-select me-3"
                      name="phone.code"
                      onChange={phoneForm.handleChange}
                      value={phoneForm?.values?.phone.code}
                    >
                      <option value="+91">+91</option>
                      <option value="+1">+1</option>
                    </select>
                    <div className="w-100">
                      <input
                        type="text"
                        name="phone.number"
                        onChange={phoneForm.handleChange}
                        value={phoneForm?.values?.phone.number}
                        placeholder="Enter Mobile Number"
                        className="form-control"
                      />
                      <CustomError
                        name="phone.number"
                        form={phoneForm}
                        className="d-block"
                      />
                    </div>
                  </div>
                </div>
                <button className="btn btn-primary w-100" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
