import React from 'react';
import Swal from 'sweetalert2';
import {  logOutUser } from '../../../utils/commonfunction';

const Settings = () => {
    const handleLogout = () => {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You want to logout!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ff9933',
            cancelButtonColor: '#1675e0',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                logOutUser();
            }
        })
    }
    return (<>
<div className="wraper-inner">
   <section className="profile-setting-page padding-space">
      <div className="container">
         <h3 className="title-page">Profile Settings</h3>
         <div className="profile-setting-tabs">
            <div className="side-tabs-box d-flex">
               <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button className="nav-link active" id="tab-01" data-bs-toggle="tab" data-bs-target="#tab-001" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Edit e-mail Address</button>
                  <button className="nav-link" id="tab-02" data-bs-toggle="tab" data-bs-target="#tab-002" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Change Password</button>
                  <button className="nav-link" id="tab-03" data-bs-toggle="tab" data-bs-target="#tab-003" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Alerts & Updates</button>
                  <button className="nav-link" id="tab-04" data-bs-toggle="tab" data-bs-target="#tab-004" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Call Preferences</button>
                  <button className="nav-link" id="tab-05" data-bs-toggle="tab" data-bs-target="#tab-005" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Privacy</button>
                  <button className="nav-link" id="tab-06" data-bs-toggle="tab" data-bs-target="#tab-006" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Profile Settings</button>
                  <button className="nav-link" id="tab-07" data-bs-toggle="tab" data-bs-target="#tab-007" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Deactivate Profile</button>
                  <button className="nav-link" id="tab-08" data-bs-toggle="tab" data-bs-target="#tab-008" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Delete Profile</button>
                  <button className="nav-link" id="tab-09" data-bs-toggle="tab" data-bs-target="#tab-009" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Ignored Profiles</button>
                  <button className="nav-link" id="tab-10" data-bs-toggle="tab" data-bs-target="#tab-0010" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Blocked Profiles</button>
                  <button className="nav-link" id="tab-11" onClick={() => handleLogout()}>Logout</button>
               </div>
               <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="tab-001" role="tabpanel" aria-labelledby="tab-01" tabindex="0">
                     <div className="email-info-tab">
                        <div className="side-tabs-head">
                           <h4 className="tab-in-title">Edit e-mail Address</h4>
                        </div>
                        <div className="side-tabs-body">
                           <div className="row">
                              <div className="col-md-8">
                                 <p className="mb-3">A valid e-mail id will be used to send you partner search mailers, member to member communication mailers and special offers.</p>
                                 <div className="form-group ">
                                    <div className="d-flex align-items-center tabs-btn">
                                        <input type="text" name="" placeholder="pooja1@gmail.com" className="form-control me-3"/>
                                       <button className="btn-sm">Save</button>
                                       <button className="btn-sm disble-btn ms-1">Reset</button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="tab-002" role="tabpanel" aria-labelledby="tab-02" tabindex="0">
                     <div className="change-password-tab">
                        <div className="side-tabs-head">
                           <h4 className="tab-in-title">Change Password</h4>
                        </div>
                        <div className="side-tabs-body">
                           <p className="mb-3">Your password must have a minimum of 6 characters. We recommend you choose an alphanumeric password. E.g.: Matri123</p>
                           <div className="row change-passowr-form">
                              <div className="form-group col-md-3">
                                 <label className="form-label">Enter Current Password</label>
                                 <input type="text" name="" placeholder="" className="form-control"/>
                              </div>
                              <div className="form-group col-md-3">
                                 <label className="form-label">Enter New Password</label>
                                 <input type="text" name="" placeholder="" className="form-control"/>
                              </div>
                              <div className="form-group col-md-4">
                                 <label className="form-label">Confirm Password</label>
                                 <input type="text" name="" placeholder="" className="form-control"/>
                              </div>
                              <div className="col-md-12 mt-3 text-end">
                                 <button className="btn-sm">Change Password</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="tab-003" role="tabpanel" aria-labelledby="tab-03" tabindex="0">
                     <div className="alerts-updates-tab">
                        <div className="side-tabs-head">
                           <h4 className="tab-in-title">Alerts & Updates</h4>
                        </div>
                        <div className="side-tabs-body">
                           <ul className="alerts-list">
                              <li>
                                 <a>
                                    <div className="alerts-titles">
                                       <h4>E-mail</h4>
                                       <p>Choose what updates you receive on your e-mail</p>
                                    </div>
                                    <span className="arrow-indi"><i className="ri-arrow-right-s-line"></i></span>
                                 </a>
                              </li>
                              <li>
                                 <a>
                                    <div className="alerts-titles">
                                       <h4>SMS</h4>
                                       <p>Choose what updates you get via SMS</p>
                                    </div>
                                    <span className="arrow-indi"><i className="ri-arrow-right-s-line"></i></span>
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="tab-004" role="tabpanel" aria-labelledby="tab-04" tabindex="0">
                     <div className="call-preferences-tab">
                        <div className="side-tabs-head">
                           <h4 className="tab-in-title">Call Preferences</h4>
                        </div>
                        <div className="side-tabs-body">
                           <p className="mb-3">Let us know when we can call you:</p>
                           <ul className="radio-list">
                              <li>
                                 <div className="form-check d-flex align-items-center">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                    <label className="form-check-label ps-2" htmlFor="flexRadioDefault1">
                                    Call when there are important updates/offers
                                    </label>
                                 </div>
                              </li>
                              <li>
                                 <div className="form-check d-flex align-items-center">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                    <label className="form-check-label ps-2" htmlFor="flexRadioDefault1">
                                    Call after 1 month
                                    </label>
                                 </div>
                              </li>
                              <li>
                                 <div className="form-check d-flex align-items-center">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                    <label className="form-check-label ps-2" htmlFor="flexRadioDefault1">
                                    Call after 3 months
                                    </label>
                                 </div>
                              </li>
                              <li>
                                 <div className="form-check d-flex align-items-center">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                    <label className="form-check-label ps-2" htmlFor="flexRadioDefault1">
                                    Call after 6 months
                                    </label>
                                 </div>
                              </li>
                              <li>
                                 <div className="form-check d-flex align-items-center">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                    <label className="form-check-label ps-2" htmlFor="flexRadioDefault1">
                                    Never
                                    </label>
                                 </div>
                              </li>
                           </ul>
                           <div className="tabs-btn text-end"><button className="btn-sm">Submit</button></div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="tab-005" role="tabpanel" aria-labelledby="tab-05" tabindex="0">
                     <div className="sub-tabs">
                        <div className="nav nav-tabs d-flex" id="nav-tab" role="tablist">
                           <button className="nav-link active" id="sub-tabs01" data-bs-toggle="tab" data-bs-target="#sub-tabs001" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Mobile Privacy</button>
                           <button className="nav-link" id="sub-tabs02" data-bs-toggle="tab" data-bs-target="#sub-tabs002" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Photo Privacy</button>
                           <button className="nav-link" id="sub-tabs03" data-bs-toggle="tab" data-bs-target="#sub-tabs003" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Horoscope Privacy</button>
                        </div>
                        <div className="tab-content pt-3" id="nav-tabContent">
                           <div className="tab-pane fade show active" id="sub-tabs001" role="tabpanel" aria-labelledby="sub-tabs01" tabindex="0">
                              <div className="mobile-privacy-info">
                                 <div className="accordion accordion-tabs" id="accordionExample">
                                    <div className="accordion-item">
                                       <h2 className="accordion-header">
                                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                             <div className="accordion-button-inner">
                                                <h4>Verified Contact</h4>
                                                <small>Change / Edit your mobile number and verify it.</small>
                                             </div>
                                          </button>
                                       </h2>
                                       <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                          <div className="accordion-body">
                                             <p>Given below are your contact details. In case, your mobile number has changed, please verify your mobile number to continue using our services. Profiles without verified mobile numbers cannot be part of our site anymore.</p>
                                             <p>BharatMatrimony is the world's only matrimony site to have 100% mobile verified profiles.</p>
                                             <div className="border-box-tabs">
                                                <div className="bg-title mb-3 bg-title-in">
                                                   <h4>Contact Details</h4>
                                                </div>
                                                <div className="border-box-body">
                                                   <div className="side-tabs-head">
                                                      <h4 className="tab-in-title">Contact Number</h4>
                                                   </div>
                                                   <div className="row">
                                                      <div className="col-md-8">
                                                         <div className="inline-sm-input-box mb-3">
                                                            <div className="form-group d-flex align-items-start">
                                                               <label className="inline-label">Country<small className="validate-tag">*</small></label>
                                                               <div className="froup-field d-flex align-items-center">
                                                                  <select className="form-select">
                                                                     <option>+91</option>
                                                                  </select>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="inline-sm-input-box mb-3">
                                                            <div className="form-group d-flex align-items-start">
                                                               <label className="inline-label">Primary Mobile Number<small className="validate-tag">*</small></label>
                                                               <div className="froup-field d-flex align-items-center">
                                                                  <input type="text" name="" placeholder="8290475917" className="form-control"/>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="inline-sm-input-box mb-3">
                                                            <div className="form-group d-flex align-items-start">
                                                               <label className="inline-label">Alternate Number</label>
                                                               <span className="d-flex text-content">Not Specified<a className="link-text ms-2">Edit</a></span>
                                                            </div>
                                                         </div>
                                                         <div className="min-w-sm ms-auto">
                                                            <button className="btn-sm">Save</button>
                                                            <button className="btn-sm disble-btn ms-1">Cancel</button>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="border-box-body">
                                                   <div className="side-tabs-head d-flex align-items-center justify-content-between">
                                                      <h4 className="tab-in-title">Contact Preferences</h4>
                                                      <a className="link-text ms-2">Edit</a>
                                                   </div>
                                                   <div className="row contact-preferences">
                                                      <div className="col-md-8">
                                                         <div className="inline-sm-input-box mb-3">
                                                            <div className="form-group d-flex align-items-start">
                                                               <label className="inline-label">Whom to contact</label>
                                                               <span className="d-flex text-content">Not Specified</span>
                                                            </div>
                                                         </div>
                                                         <div className="inline-sm-input-box mb-3">
                                                            <div className="form-group d-flex align-items-start">
                                                               <label className="inline-label">Contact person's name</label>
                                                               <span className="d-flex text-content">Not Specified</span>
                                                            </div>
                                                         </div>
                                                         <div className="inline-sm-input-box mb-3">
                                                            <div className="form-group d-flex align-items-start">
                                                               <label className="inline-label">Convenient time to call</label>
                                                               <span className="d-flex text-content">Comments</span>
                                                            </div>
                                                         </div>
                                                         <div className="inline-sm-input-box ">
                                                            <div className="form-group d-flex align-items-start">
                                                               <label className="inline-label">Alternate Number</label>
                                                               <span className="d-flex text-content">Not Specified</span>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <p className="mt-2">By verifying your mobile number, members can contact you through mobile.</p>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion-item">
                                       <h2 className="accordion-header">
                                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                             <div className="accordion-button-inner">
                                                <h4>Mobile Privacy</h4>
                                                <small>Control who can view your mobile number.</small>
                                             </div>
                                          </button>
                                       </h2>
                                       <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                          <div className="accordion-body">
                                             <div className="border-box-tabs p-3">
                                                <ul className="radio-list">
                                                   <li>
                                                      <div className="form-check d-flex align-items-start">
                                                         <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                                         <label className="form-check-label ps-2" htmlFor="flexRadioDefault1">
                                                            <div className="radio-title-mix-head">
                                                               <h4>Show mobile number only to paid members from my community</h4>
                                                               <small className=""><i className="ri-arrow-right-s-fill"></i>Paid members from your community can reach you directly or through SMS</small>
                                                            </div>
                                                         </label>
                                                      </div>
                                                   </li>
                                                   <li>
                                                      <div className="form-check d-flex align-items-start">
                                                         <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                                         <label className="form-check-label ps-2" htmlFor="flexRadioDefault1">
                                                            <div className="radio-title-mix-head">
                                                               <h4>Show mobile number only to paid members from my community</h4>
                                                               <small className=""><i className="ri-arrow-right-s-fill"></i>Paid members from your community can reach you directly or through SMS</small>
                                                            </div>
                                                         </label>
                                                      </div>
                                                   </li>
                                                   <li>
                                                      <div className="form-check d-flex align-items-center">
                                                         <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                                         <label className="form-check-label ps-2" htmlFor="flexRadioDefault1">
                                                            <div className="radio-title-mix-head">
                                                               <h4>Show mobile number only to paid members whom I had contacted / responded</h4>
                                                               <small className=""><i className="ri-arrow-right-s-fill"></i>Paid members whom you have contacted / responded can reach you directly or through SMS</small>
                                                            </div>
                                                         </label>
                                                      </div>
                                                   </li>
                                                   <li>
                                                      <div className="form-check d-flex align-items-center">
                                                         <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                                         <label className="form-check-label ps-2" htmlFor="flexRadioDefault1">
                                                            <div className="radio-title-mix-head">
                                                               <h4>Hide my mobile number</h4>
                                                               <small className=""><i className="ri-arrow-right-s-fill"></i> Your mobile number will be hidden, however you can receive SMS from other paid members</small>
                                                            </div>
                                                         </label>
                                                      </div>
                                                   </li>
                                                </ul>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion-item">
                                       <h2 className="accordion-header">
                                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                             <div className="accordion-button-inner">
                                                <h4>Mobile Alerts</h4>
                                                <small>Choose if you want to receive Notifications through SMS.</small>
                                             </div>
                                          </button>
                                       </h2>
                                       <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                          <div className="accordion-body">
                                             <p>You will get SMS notifications to 8290475917, when</p>
                                             <ul className="get-notify-list">
                                                <li>You receive a new message</li>
                                                <li>You get an interest</li>
                                                <li>You get a reply for your message</li>
                                                <li>Your interest gets accepted</li>
                                                <li>Member requests you to add Photo</li>
                                                <li>Member sends you a reminder for Interest</li>
                                             </ul>
                                             <p><b><small className="validate-tag">* </small> Members using our app will be notified through instant push notifications instead of SMS.</b></p>
                                             <hr className="dash-sep"/>
                                             <p>As a member, you have the benefit of receiving mobile alerts. We recommend you to keep your mobile alerts on all the time for instant notifications. You may choose to <a href="text-link">turn off </a> the alerts</p>
                                             <p><small className="validate-tag">*</small> Sent only to members registered with Indian Mobile number</p>
                                             <p><small className="validate-tag">*</small> To turn off App notifications go to BharatMatrimony App settings</p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="tab-pane fade" id="sub-tabs002" role="tabpanel" aria-labelledby="sub-tabs02" tabindex="0">
                              <div className="photo-privacy-tabs">
                                 <h4 className="tab-in-title mb-3">
                                 Your Photo Privacy has been set to <span className="heightlight-text">"Visible to All"</span>
                                 </h4>
                              </div>
                              <div className="border-box-tabs p-3">
                                 <ul className="radio-list">
                                    <li>
                                       <div className="form-check d-flex align-items-start">
                                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                          <label className="form-check-label ps-2" htmlFor="flexRadioDefault1">
                                             <div className="radio-title-mix-head">
                                                <h4>Visible to all 
                                                    <img className="better-response" src="images/recommend-betterresponse.png"/></h4>
                                             </div>
                                          </label>
                                       </div>
                                    </li>
                                    <li>
                                       <div className="form-check d-flex align-items-start">
                                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                          <label className="form-check-label ps-2" htmlFor="flexRadioDefault1">
                                             <div className="radio-title-mix-head">
                                                <h4> Visible to members you have contacted/accepted and to all paid members</h4>
                                             </div>
                                          </label>
                                       </div>
                                    </li>
                                    <li>
                                       <div className="form-check d-flex align-items-center">
                                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                          <label className="form-check-label ps-2" htmlFor="flexRadioDefault1">
                                             <div className="radio-title-mix-head">
                                                <h4>Visible to members of your choice</h4>
                                                <div className="ugredeinfo-text d-flex">
                                                   <small>When members request to view your photo,<br/>
                                                   you can decide whether to allow or not</small>
                                                   <b className="ugrede-text ms-3">Upgrade to use this feature</b>
                                                </div>
                                             </div>
                                          </label>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                           <div className="tab-pane fade" id="sub-tabs003" role="tabpanel" aria-labelledby="sub-tabs03" tabindex="0">
                              <div className="horoscope-privacy-tabs">
                                 <h4 className="tab-in-title mb-3">Your Photo Privacy has been set to <span className="heightlight-text">"Visible to All"</span></h4>
                                 <ul className="radio-list">
                                    <li>
                                       <div className="form-check d-flex align-items-start">
                                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                          <label className="form-check-label ps-2" htmlFor="flexRadioDefault1">
                                             <div className="radio-title-mix-head">
                                                <h4 className="d-flex">Visible to all 
                                                <img className="better-response" src="images/recommend-betterresponse.png"/></h4>
                                                <small className=""><i className="ri-arrow-right-s-fill"></i> Paid members can view unlimited number of horoscopes / Free members have limited access to horoscope.
                                                </small>
                                             </div>
                                          </label>
                                       </div>
                                    </li>
                                    <li>
                                       <div className="form-check d-flex align-items-start">
                                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                          <label className="form-check-label ps-2" htmlFor="flexRadioDefault1">
                                             <div className="radio-title-mix-head">
                                                <h4>Visible only to members whom I had contacted / responded.</h4>
                                             </div>
                                          </label>
                                       </div>
                                    </li>
                                    <li>
                                       <div className="form-check d-flex align-items-start">
                                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                          <label className="form-check-label ps-2" htmlFor="flexRadioDefault1">
                                             <div className="radio-title-mix-head">
                                                <h4> Protect with Horoscope Password</h4>
                                                <small>With this option you can</small>
                                                <small className=""><i className="ri-arrow-right-s-fill"></i>Share your Horoscope Password to a member of your choice.</small>
                                                <small className=""><i className="ri-arrow-right-s-fill"></i>Grant access to your Horoscope at the time of contacting.</small>
                                             </div>
                                          </label>
                                       </div>
                                    </li>
                                 </ul>
                                 <div className="tabs-btn mt-3"> <button className="btn-sm">Submit</button> </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="tab-006" role="tabpanel" aria-labelledby="tab-06" tabindex="0">
                     <div className="profile-setting-tabs">
                        <div className="side-tabs-head">
                           <h4 className="tab-in-title">Your Profile Privacy has been set as "Show my Profile to all including visitors"</h4>
                        </div>
                        <div className="side-tabs-body">
                           <ul className="radio-list">
                              <li>
                                 <div className="form-check d-flex align-items-start">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                    <label className="form-check-label ps-2" htmlFor="flexRadioDefault1">
                                       <div className="radio-title-mix-head">
                                          <h4>Show my Profile to all including visitors 
                                            <img className="better-response" src="images/recommend-icon.png"/></h4>
                                          <small className=""><i className="ri-arrow-right-s-fill"></i>This will help prospects share your profile with their family members, who are not registered members. *</small>
                                       </div>
                                    </label>
                                 </div>
                              </li>
                              <li>
                                 <div className="form-check d-flex align-items-start">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                    <label className="form-check-label ps-2" htmlFor="flexRadioDefault1">
                                       <div className="radio-title-mix-head">
                                          <h4>Show my Profile to registered members only.</h4>
                                       </div>
                                    </label>
                                 </div>
                              </li>
                           </ul>
                           <hr/>
                           <div className="form-check coustom-check d-flex align-items-center">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                              <label className="form-check-label" htmlFor="flexCheckDefault">
                              Let others know that I shortlisted their profile.
                              </label>
                           </div>
                           <div className="tabs-btn mt-3 text-end">
                              <button className="btn-sm">Submit</button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="tab-007" role="tabpanel" aria-labelledby="tab-07" tabindex="0">
                     <div className="deactivate-profile">
                        <div className="side-tabs-head">
                           <h4 className="tab-in-title">Deactivate Profile</h4>
                        </div>
                        <div className="side-tabs-body conten-box">
                           <p>You can temporarily deactivate your profile if you do not want to delete it. On deactivation your profile will be hidden from our members and you will not be able to contact any member until you activate.</p>
                           <p>Your profile status is currently <span className="heightlight-text">active.</span> If you would like to change your status, please select Deactivate Now.</p>
                           <p><b>Select the number of days / months you would like to keep your profile deactivated.</b></p>
                           <div className="form-group col-md-4 mb-3">
                              <select className="form-select">
                                 <option>Select Days</option>
                              </select>
                           </div>
                           <p className="grey-text-content">NOTE : Your profile will be activated after the selected time period elapses. E.G. If you select 15 days as the time period, your profile will be deactivated for 15 days and will be automatically activated on the 16th day. You will receive a mailer in this regard.</p>
                           <div className="tabs-btn"><button className="btn-sm">Deactivate Now</button></div>
                           <hr/>
                           <p className="grey-text-content">Note: Once you deactivate your profile you will not be able to contact any member either through Express Interest, Personalised Messages or Chat and your profile details will also not be visible to members.</p>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="tab-008" role="tabpanel" aria-labelledby="tab-08" tabindex="0">
                     <div className="deactivate-profile">
                        <div className="side-tabs-head">
                           <h4 className="tab-in-title">Delete Profile</h4>
                        </div>
                        <div className="side-tabs-body conten-box">
                           <p>Please choose a reason for profile deletion.</p>
                           <p>NOTE: If you delete your profile, it cannot be restored.</p>
                           <div className="button-radio-btn d-flex mt-3">
                              <div className="form-check">
                                 <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault11" />
                                 <label className="form-check-label" htmlFor="flexRadioDefault11">
                                 Marriage fixed
                                 </label>
                              </div>
                              <div className="form-check">
                                 <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault22"/>
                                 <label className="form-check-label" htmlFor="flexRadioDefault22">
                                 Married
                                 </label>
                              </div>
                              <div className="form-check">
                                 <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault33"/>
                                 <label className="form-check-label" htmlFor="flexRadioDefault33">
                                 Other reasons
                                 </label>
                              </div>
                           </div>
                           <div className="border-sec button-radio-content">
                              <div className="marriage fixed">
                                 <div className="sec-head mb-3">
                                    <h3>Congratulations!</h3>
                                    <p>We are happy that you found your life partner.</p>
                                 </div>
                                 <div className="sec-body">
                                    <h4 className="inner-sub-title mb-2">Select the source:</h4>
                                    <ul className="list-sec d-flex align-items-center">
                                       <li>
                                          <div className="form-check d-flex align-items-start">
                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault11" />
                                             <label className="form-check-label ms-2" htmlFor="flexRadioDefault11">
                                             Through BharatMatrimony
                                             </label>
                                          </div>
                                       </li>
                                       <li>
                                          <div className="form-check d-flex align-items-start">
                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault11" />
                                             <label className="form-check-label ms-2" htmlFor="flexRadioDefault11">
                                             Through other Matrimonial site
                                             </label>
                                          </div>
                                       </li>
                                       <li>
                                          <div className="form-check d-flex align-items-start">
                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault11" />
                                             <label className="form-check-label ms-2" htmlFor="flexRadioDefault11">
                                             Through other source
                                             </label>
                                          </div>
                                       </li>
                                    </ul>
                                    <div className="form-group col-md-4">
                                       <label className="form-label">Groom's name</label>
                                       <input type="text" name="" placeholder="" className="form-control"/>
                                    </div>
                                    <ul className="list-sec d-flex align-items-center mt-4 ">
                                       <li>
                                          <div className="form-check d-flex align-items-start">
                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault11" />
                                             <label className="form-check-label ms-2" htmlFor="flexRadioDefault11">
                                             Marriage Date 
                                             </label>
                                          </div>
                                       </li>
                                       <li>
                                          <div className="form-check d-flex align-items-start">
                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault11" />
                                             <label className="form-check-label ms-2" htmlFor="flexRadioDefault11">
                                             Date not yet fixed?
                                             </label>
                                          </div>
                                       </li>
                                    </ul>
                                    <div className="form-group col-md-4 mt-2">
                                       <input type="date" name="" placeholder="" className="form-control"/>
                                    </div>
                                    <div className="form-group col-md-4 mt-2">
                                       <select className="form-select ">
                                          <option>Getting married in</option>
                                       </select>
                                    </div>
                                    <div className="form-check d-flex align-items-center mt-3">
                                       <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                       <label className="form-check-label d-flex align-items-center" htmlFor="flexCheckDefault">
                                       <span  className="ms-3 me-2 gift-img">
                                        <img src="images/gift-icon-wedding.webp"/></span>I would like to receive a gift from Bharat Matrimony delivered to me in person at my wedding.
                                       </label>
                                    </div>
                                    <div className="form-group col-md-7 mt-3">
                                       <div className="form-group-head d-flex justify-content-between">
                                          <label className="form-label">  Your story</label>
                                          <small className="label-sub">Need help to write your story ?</small>
                                       </div>
                                       <textarea className="form-control" ></textarea>
                                    </div>
                                    <div className="tabs-btn  mt-3">
                                       <button className="btn-sm me-1">SuBmit</button>
                                       <button className="btn-sm btn-outline">Cancel</button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="tab-009" role="tabpanel" aria-labelledby="tab-09" tabindex="0">.9..</div>
                  <div className="tab-pane fade" id="tab-0010" role="tabpanel" aria-labelledby="tab-010" tabindex="0">.10..</div>
                  <div className="tab-pane fade" id="tab-0011" role="tabpanel" aria-labelledby="tab-011" tabindex="0">.11..</div>
               </div>
            </div>
         </div>
      </div>
</section>
</div>
    </>)
}

export default Settings;