
import React, { useState, useEffect } from "react";
import { getProfile, updateProfile } from "../../services/user.service";
import * as Yup from "yup";
import CustomError from "../../shared/CustomError";
import { useFormik } from "formik";
import { failedResponse, successResponse } from "../../../../utils/toastr";
import Icon1 from "../../../../assets/user/images/icon-1.svg";

const useDynamicForm = (type) => {
  const [formValidations, setFormValidations] = useState({});
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const handleFormValidations = (type) => {
      let infoValidations;
      if (type == "age") {
        infoValidations = Yup.object().shape({
          min_age: Yup.number().required("Min age is required"),
          max_age: Yup.number()
          .required("Max age is required")
          .test("is-greater-than-min", "Max age must be greater than min age", function (
            value
          ) {
            const minAge = this.parent.min_age; // Access min_age from the parent object
            return value >= minAge;
          }),
        });
      }
      if (type == "height") {
        infoValidations = Yup.object().shape({
          min_height: Yup.number().required("Min height is required"),
          max_height: Yup.number().required("Min height is required"),
        });
      }
      if (type == "marital_status") {
        infoValidations = Yup.object().shape({
          marital_status: Yup.string().required("marital_status is required"),
        });
      }
      if (type == "mother_tongue") {
        infoValidations = Yup.object().shape({
          mother_tongue: Yup.string().required("mother_tongue is required"),
        });
      }
      if (type == "eating_habit") {
        infoValidations = Yup.object().shape({
          eating_habit: Yup.string().required("eating_habit is required"),
        });
      }
      if (type == "drinking_habit") {
        infoValidations = Yup.object().shape({
          drinking_habit: Yup.string().required("drinking_habit is required"),
        });
      }
      if (type == "smoking_habit") {
        infoValidations = Yup.object().shape({
          smoking_habit: Yup.string().required("smoking_habit is required"),
        });
      }
      setFormValidations(infoValidations || {});
    };
    const handleInitialValues = (type) => {
      let initalValues;
      if (type == "age") {
        initalValues = {
          min_age: "",
          max_age: "",
        };
      }
      if (type == "height") {
        initalValues = {
          min_height: "",
          max_height: "",
        };
      }
      if (type == "marital_status") {
        initalValues = {
          marital_status: "",
        };
      }
      if (type == "mother_tongue") {
        initalValues = {
          mother_tongue: "",
        };
      }
      if (type == "eating_habit") {
        initalValues = {
          eating_habit: "",
        };
      }
      if (type == "drinking_habit") {
        initalValues = {
          drinking_habit: "",
        };
      }
      if (type == "smoking_habit") {
        initalValues = {
          smoking_habit: "",
        };
      }
      setFormValidations(initalValues || {});
    };

    handleFormValidations();
    handleInitialValues();
    console.log(type)
  }, [type]);

  return { formValidations, initialValues };
};
const EditBasicPreference = () => {
  const [infoFormType, setInfoFormType] = useState("");
  const { formValidations, initialValues } = useDynamicForm(infoFormType);

  const handleFormSubmission = async (type, values) => {
    try {
    } catch (err) {
      console.log("Error", err);
    }
  };

  const infoForm = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape(formValidations),
    enableReinitialize: true,
    onSubmit: async (values) => {
      await handleFormSubmission(infoFormType, values);
    },
  });

  const openEditModal = (type) => {
    setInfoFormType(type);
  };

  return (
    <>
      <div className="partner-preferences-content mb-5" id=" Basic-Preferences">
        <div className="partner-preferences-head d-flex align-items-center">
          <figure>
            <img src={Icon1} />
          </figure>
          <figcaption>
            <h5>Basic Preferences</h5>
          </figcaption>
        </div>
        <div className="partner-preferences-body">
          <ul className="partner-preferences-content">
            <li>
              <div className="partner-preferences-list-left">
                <h5>Groom's Age</h5>
                <span>25 - 32 years</span>
              </div>
              <a
                className="partner-preferences-edit"
                data-bs-target="#edit-detail"
                data-bs-toggle="modal"
                onClick={() => openEditModal("age")}
              >
                <i className="ri-pencil-line"></i>
              </a>
            </li>
            <li>
              <div className="partner-preferences-list-left">
                <h5>Height</h5>
                <span>4 Ft 6 In- 5 Ft 6 In/137 Cms- 168 Cms</span>
              </div>
              <a
                className="partner-preferences-edit"
                data-bs-target="#edit-detail"
                data-bs-toggle="modal"
                onClick={() => openEditModal("height")}
              >
                <i className="ri-pencil-line"></i>
              </a>
            </li>
            <li>
              <div className="partner-preferences-list-left">
                <h5>Marital Status</h5>
                <span>Never Married</span>
              </div>
              <a className="partner-preferences-edit">
                <i className="ri-pencil-line"></i>
              </a>
            </li>
            <li>
              <div className="partner-preferences-list-left">
                <h5>Mother Tongue</h5>
                <span>
                  Awadhi, Brij, Chatisgarhi, Dogri, Garhwali, Haryanvi,
                  Himachali ...
                  <a className="link-text">Read More</a>
                </span>
              </div>
              <a className="partner-preferences-edit">
                <i className="ri-pencil-line"></i>
              </a>
            </li>
            <li>
              <div className="partner-preferences-list-left">
                <h5>Eating Habits</h5>
                <span>Doesn't matter</span>
              </div>
              <a className="partner-preferences-edit">
                <i className="ri-pencil-line"></i>
              </a>
            </li>
            <li>
              <div className="partner-preferences-list-left">
                <h5>Drinking Habits</h5>
                <span>Doesn't matter</span>
              </div>
              <a className="partner-preferences-edit">
                <i className="ri-pencil-line"></i>
              </a>
            </li>
            <li>
              <div className="partner-preferences-list-left">
                <h5>Smoking Habits</h5>
                <span>Doesn't matter</span>
              </div>
              <a className="partner-preferences-edit">
                <i className="ri-pencil-line"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="modal fade common-modal xs-modal"
        data-bs-backdrop="static"
        id="edit-detail"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-head">
              <h1 className="modal-title">Update</h1>
              <button
                type="button"
                id="modal_close_reset"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            {console.log(infoForm)}
            <div className="modal-body">
              <form onSubmit={infoForm.handleSubmit}>
                {infoFormType === "age" && (
                  <>
                    <div className="form-group mb-3">
                      <label className="form-label">Min Age</label>
                      <input
                        type="text"
                        name="min_age"
                        placeholder=""
                        className="form-control"
                        onChange={infoForm.handleChange}
                        value={infoForm?.values?.min_age}
                      />
                      <CustomError name="min_age" form={infoForm} />
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label">Max Age</label>
                      <input
                        type="text"
                        name="max_age"
                        placeholder=""
                        className="form-control"
                        onChange={infoForm.handleChange}
                        value={infoForm?.values?.max_age}
                      />
                      <CustomError name="max_age" form={infoForm} />
                    </div>
                  </>
                )}
                {infoFormType === "height" && (
                  <>
                    <div className="form-group mb-3">
                      <label className="form-label">Min Height</label>
                      <input
                        type="text"
                        name="min_height"
                        placeholder=""
                        className="form-control"
                        onChange={infoForm.handleChange}
                        value={infoForm?.values?.min_height}
                      />
                      <CustomError name="min_height" form={infoForm} />
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label">Max Height</label>
                      <input
                        type="text"
                        name="max_height"
                        placeholder=""
                        className="form-control"
                        onChange={infoForm.handleChange}
                        value={infoForm?.values?.max_height}
                      />
                      <CustomError name="max_height" form={infoForm} />
                    </div>
                  </>
                )}
                <button className="btn btn-primary w-100" type="submit">
                  {" "}
                  Submit{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBasicPreference;
