import React, { useState, useEffect, useRef } from "react";
import { getLocation,setLocation,getProfile } from "../../services/user.service";
import { getCities,getCountries,getStates,getQueries} from "../../services/global.service";
import { capitalizeFirstLetter,prepareLabel } from "../../../../utils/commonfunction";
import * as Yup from "yup";
import CustomError from "../../shared/CustomError";
import { useFormik } from "formik";
import { failedResponse, successResponse } from "../../../../utils/toastr";

const EditLocationDetail = ({userInfo,userLocationInfo,onUserLocationInfoChange}) => {
  const [isInfoEditing, setIsInfoEditing] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [Cities, setCities] = useState([]);
  const [ancestralOrigin, setAncestralOrigin] = useState([]);

  async function getCountriesData() {
    getCountries().then((response)=>{
      if(response.success){
        setCountries(response.data)
      }
    }).catch((err)=>{
        console.log("Server error ", err);
    });
  }
  async function getCitiesData(id=1) {
    getCities({state_id:id}).then((response)=>{
      if(response.success){
        setCities(response.data)
      }
    }).catch((err)=>{
        console.log("Server error ", err);
    });
  }
  async function getStatesData(id=1) {
    getStates({country_id:id}).then((response)=>{
      if(response.success){
        setStates(response.data)
      }
    }).catch((err)=>{
        console.log("Server error ", err);
    });
  }

  async function getAncestralOriginData(id=1) {
    getQueries({type:'ancestral-origin'}).then((response)=>{
      if(response.success){
        setAncestralOrigin(response.data);
      }
    }).catch((err)=>{
        console.log("Server error ", err);
    });
  }
 
  useEffect(() => {
    getAncestralOriginData();
    getCountriesData();
    getStatesData(userLocationInfo?.country_id ? userLocationInfo?.country_id:1);
    getCitiesData(userLocationInfo?.state_id ? userLocationInfo?.country_id :1);
  }, [userLocationInfo,userInfo]);

  const toggleInfoEdit = () => {
    setIsInfoEditing(!isInfoEditing);
    if(isInfoEditing){
      infoForm.setFieldValue('country_id',(userLocationInfo && userLocationInfo.country_id) || "")
      infoForm.setFieldValue('state_id',(userLocationInfo && userLocationInfo.state_id) || "")
      infoForm.setFieldValue('city_id',(userLocationInfo && userLocationInfo.city_id) || "")
      infoForm.setFieldValue('citizenship_id',(userLocationInfo && userLocationInfo.citizenship_id) || "")
      infoForm.setFieldValue('ancestral_origin_id',(userLocationInfo && userLocationInfo.ancestral_origin_id) || "")
    }
  };
  const handleCancel = () => {
    toggleInfoEdit();
    infoForm.resetForm()
    
  };


  const infoValidations = Yup.object().shape({
    country_id: Yup.string().required("Country is required"),
    city_id: Yup.string().required("City is required"),
    state_id: Yup.string().required("State is required"),
    citizenship_id: Yup.string().required("Citizenship is required"),
    ancestral_origin_id: Yup.string().required("Ancestral origin is required"),
  });

  const infoForm = useFormik({
    initialValues: {
      country_id: userLocationInfo && userLocationInfo.country_id || '',
      city_id: userLocationInfo && userLocationInfo.city_id || '',
      state_id: userLocationInfo && userLocationInfo.state_id || '',
      citizenship_id: userLocationInfo && userLocationInfo.citizenship_id || '',
      ancestral_origin_id: (userLocationInfo && userLocationInfo.ancestral_origin_id) ? userLocationInfo.ancestral_origin_id :
      (userInfo && userInfo.ancestral_id)?userInfo.ancestral_id : '',
    },
    validationSchema: infoValidations,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const updateResponse = await setLocation(values);
        if(updateResponse.success){
          toggleInfoEdit();
          onUserLocationInfoChange();
          successResponse(updateResponse.message)
        }
        else{
          failedResponse("Something Went Wrong");
        }
      } catch (err) {
        console.log("Error", err);
      }
    },
  });

  const handedlCountryChange = (e) => {
    infoForm.setFieldValue('country_id',e.target.value)
    getStatesData(e.target.value)
    infoForm.setFieldValue('state_id','')
    infoForm.setFieldValue('city_id','')
  };


  const handedlStateChange = (e) => {
    infoForm.setFieldValue('state_id',e.target.value)
    getCitiesData(e.target.value)
    infoForm.setFieldValue('city_id','')
  };
  return (
    <>
      <div className="personal-info-head d-flex align-items-center justify-content-between mb-3">
        <h3>{userInfo && userInfo.gender === "female" ? "Bride": "Groom"}'s Location</h3>
        <a className="btn-sm" onClick={toggleInfoEdit}>
          <i className="ri-pencil-fill"></i> {isInfoEditing ? 'Close' : 'Edit'}
        </a>
      </div>

      <div className="content-wrapper">
        {!isInfoEditing ? (
          <div className="personal-info-body">
          <div className="personal-info-list-box  d-flex">
            <ul className="personal-info-list">
              <li>
                <span>Country</span>
                <p>: {userLocationInfo && userLocationInfo.country|| 'Not Specified'}</p>
              </li>
              <li>
                <span>State</span>
                <p>: {userLocationInfo && userLocationInfo.state|| 'Not Specified'}</p>
              </li>
              <li>
                <span>Ancestral Origin</span>
                <p>: {userLocationInfo && userLocationInfo.ancestralOrigin ?  userLocationInfo && userLocationInfo.ancestralOrigin :
                userInfo && userInfo.ancestralOrigin ? userInfo && userInfo.ancestralOrigin: 'Not Specified'}</p>
              </li>
            </ul>
            <ul className="personal-info-list">
              <li>
                <span>City</span>
                <p>: {userLocationInfo && userLocationInfo.city|| 'Not Specified'}</p>
              </li>
              <li>
                <span>Citizenship</span>
                <p>: {userLocationInfo && userLocationInfo.citizenship|| 'Not Specified'}</p>
              </li>
            </ul>
          </div>
        </div>
        ) : (
            <div className="personal-info-body">
            <form onSubmit={infoForm.handleSubmit}>
            <div className="personal-info-edit">
               <div className="row in-block-field mb-3">
                  <div className="form-group  col-md-4">
                     <label className="">Country Living in  :<small className="validate-tag"> *</small></label>
                     <select className="form-select" name="country_id"onChange={handedlCountryChange} value={infoForm?.values?.country_id}>
                     <option value="">Country</option>
                        {countries.map((m) => (
                          <option key={m.id} value={m.id}>{m.name}</option>
                        ))}
                      </select>

                      <CustomError name="country_id" form={infoForm} />
                  </div>
                  <div className="form-group  col-md-4">
                     <label className="">Citizenship   :<small className="validate-tag"> *</small></label>
                     <select className="form-select"name="citizenship_id"onChange={infoForm.handleChange} value={infoForm?.values?.citizenship_id} >
                     <option value="">Citizenship</option>
                     {countries.map((m) => (
                          <option key={m.id} value={m.id}>{m.name}</option>
                        ))}
                     </select>
                      <CustomError name="citizenship_id" form={infoForm} />
                  </div>
               </div>
               <div className="row in-block-field mb-3">
                  <div className="form-group  col-md-4">
                     <label className="">Residing State <small className="validate-tag"> *</small></label>
                     <select className="form-select" name="state_id"onChange={handedlStateChange} value={infoForm?.values?.state_id}>
                     <option value="">State</option>
                     {states.map((m) => (
                          <option key={m.id} value={m.id}>{m.name}</option>
                        ))}
                     </select>
                     <CustomError name="state_id" form={infoForm} />
                  </div>
                  <div className="form-group  col-md-4">
                     <label className="">Residing City / District  :<small className="validate-tag"> *</small></label>
                     <select className="form-select"name="city_id" onChange={infoForm.handleChange} value={infoForm?.values?.city_id}>
                     <option value="">City</option>
                     {Cities.map((m) => (
                          <option key={m.id} value={m.id}>{m.name}</option>
                        ))}
                     </select>
                      <CustomError name="city_id" form={infoForm} />
                  </div>
               </div>
               <div className="row in-block-field mb-3">
                  <div className="form-group  col-md-4">
                     <label className="">Ancestral Origin *   :<small className="validate-tag"> *</small></label>
                     <select className="form-select" name="ancestral_origin_id"onChange={infoForm.handleChange} value={infoForm?.values?.ancestral_origin_id}>
                     <option value="">Ancestral Origin</option>
                     {ancestralOrigin.map((m) => (
                          <option key={m.id} value={m.id}>{m.name}</option>
                        ))}
                     </select>
                     <CustomError name="ancestral_origin_id" form={infoForm} />
                  </div>
               </div>
               <div className="personal-info-btn mt-3 text-end"><button className="btn-sm" type="submit">Save</button>
                  <button className="btn-sm btn-outline ms-2" type="button" onClick={handleCancel}>Cancel</button>
               </div>
            </div>
            </form>
         </div>
        )}
      </div>
    </>
  );
};

export default EditLocationDetail;
