import React from 'react';
import limcaBookImg from '../../../assets/user/images/limca-book.webp';
import fbImg from '../../../assets/user/images/fb-icon.webp';
import twitterDropImg from '../../../assets/user/images/twitter-icon.webp';
import instagramImg from '../../../assets/user/images/instagrram-icon.webp';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer-box">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 ">
                                <div className="footer-content-box">
                                    <div className="footer-content">
                                        <h3>Help &amp; Support</h3>
                                        <ul className="footer-link">
                                            <li>
                                                <a href="/">24x7 Live help</a>
                                            </li>
                                            <li>
                                                <a href="/">Contact us</a>
                                            </li>
                                            <li>
                                                <a href="/">Feedback</a>
                                            </li>
                                            <li>
                                                <a href="/">FAQs</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="footer-content">
                                        <h3>Our other Services</h3>
                                        <ul className="footer-link">
                                            <li>
                                                <a href="/">EliteMatrimony.com</a>
                                            </li>
                                            <li>
                                                <a href="/">Muslim match App Store</a>
                                            </li>
                                            <li>
                                                <a href="/">Muslim match Play Store</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="footer-content">
                                        <h3>Our Wedding Services</h3>
                                        <ul className="footer-link">
                                            <li>
                                                <a href="/">Mandap.com </a>
                                            </li>
                                            <li>
                                                <a href="/">WeddingBazaar.com</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="footer-content-box">
                                    <div className="footer-content">
                                        <h3>Information</h3>
                                        <ul className="footer-link">
                                            <li>
                                                <a href="/">About Us </a>
                                            </li>
                                            <li>
                                                <a href="/">Awards</a>
                                            </li>
                                            <li>
                                                <a href="/">Milestones</a>
                                            </li>
                                            <li>
                                                <a href="/">Register Free</a>
                                            </li>
                                            <li>
                                                <a href="/">Partner Search</a>
                                            </li>
                                            <li>
                                                <a href="/">Member Login</a>
                                            </li>
                                            <li>
                                                <a href="/">Success stories</a>
                                            </li>
                                            <li>
                                                <a href="/">Payment Options</a>
                                            </li>
                                            <li>
                                                <a href="/">Careers</a>
                                            </li>
                                            <li>
                                                <a href="/">Media Room</a>
                                            </li>
                                            <li>
                                                <a href="/">TV Commercials</a>
                                            </li>
                                            <li>
                                                <a href="/">Advertise with us</a>
                                            </li>
                                            <li>
                                                <a href="/">Terms &amp; Conditions</a>
                                            </li>
                                            <li>
                                                <a href="/">Privacy Policy</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="footer-content-box">
                                    <div className="footer-content">
                                        <h3>Related Matrimony Services</h3>
                                        <ul className="footer-link">
                                            <li>
                                                <a href="/">HappyMarriages.com</a>
                                            </li>
                                            <li>
                                                <a href="/">Matrimonial Sites</a>
                                            </li>
                                            <li>
                                                <a href="/">Matrimonial Websites</a>
                                            </li>
                                            <li>
                                                <a href="/">Bharat Matrimony Branches</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-footer">
                            <div className="row">
                                <div className="col-md-7  bottom-f-l">
                                    <p>
                                        This website is strictly for matrimonial purpose only and not a
                                        dating website.
                                    </p>
                                    <p> Copyright © 2023. All rights reserved.</p>
                                </div>
                                <div className="col-md-5 bottom-f-r d-flex">
                                    <div className="Limca-book d-flex">
                                        <figure>
                                            <img src={limcaBookImg} alt='Book' />
                                        </figure>
                                        <figcaption>
                                            <p>The Limca Book of records Highest Number of Marriages</p>
                                        </figcaption>
                                    </div>
                                    <div className="follow-us">
                                        <h3>Follow Us on:</h3>
                                        <ul className="social-list d-flex">
                                            <li>
                                                <a href="/">
                                                    <img src={fbImg} alt='Facebook'/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/">
                                                    <img src={twitterDropImg} alt='Twitter' />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/">
                                                    <img src={instagramImg} alt='Instagram'/>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer