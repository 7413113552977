import React, { useEffect, useRef, useState } from 'react';
import trustedImg from '../../../assets/user/images/trusted-img-2.webp';
import { getSessionKey, prepareLabel, setSessionKey } from '../../../utils/commonfunction';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CustomError from '../shared/CustomError';
import { useNavigate } from 'react-router-dom';
import { getQueries  } from '../services/global.service';
import { DOSH_QUESTION_OPTIONS  } from '../../../utils/Constants';

export default function SignupStep2() {
    const navigate = useNavigate();
    const signupFormRef = useRef();
    const [casteOptions, setCasteOptions] = useState([]);
    const [gotraOptions, setGotraOptions] = useState([]);
    const [doshOptions, setDoshOptions] = useState([]);
    useEffect(() => {
        document.querySelector('body').classList.add('bg-lignt-grey-shade');
        return () => {
            document.querySelector('body').classList.remove('bg-lignt-grey-shade');
        };
    }, []);
    useEffect(() => {
        /**Checking that user is coming from prev step */
        const prevStep = getSessionKey('REGISTRATION_COMPLETED_STEPS');
        if(prevStep && prevStep === "1"){
            /** Everything is fine */
        }else{
            /**Go back to initial step */
            navigate('/');
        }
    }, [navigate]);
    useEffect(() => {
        /**Getting getCastes options */
        getQueries({type:'caste'}).then((response) => {
            if (response.success) {
                setCasteOptions(response.data);
            }
        }).catch((err) => {
            console.log("Server error ", err);
        });

        /**Getting getGotras options */
        getQueries({type:'gotras'}).then((response) => {
            if (response.success) {
                setGotraOptions(response.data);
            }
        }).catch((err) => {
            console.log("Server error ", err);
        });

        /**Getting getDosh options */
        getQueries({type:'dosha'}).then((response) => {
            if (response.success) {
                setDoshOptions(response.data);
            }
        }).catch((err) => {
            console.log("Server error ", err);
        });
    }, []);

    const signupValidations = Yup.object().shape({
        caste: Yup.string().required('Caste is required'),
        subCaste: Yup.string().trim().required('Sub caste is required'),
        doshReason: Yup.array().test('dosh', 'Please select dosh', value=>{
            if(signupFormRef?.current?.values.dosh === DOSH_QUESTION_OPTIONS.YES ){
                return Boolean(value.length);
            }else{
                return true;
            }
        }),
    });
    const signupForm = useFormik({
        initialValues: {
            caste: '',
            willingToMarryOtherCommunities: false,
            subCaste: '',
            gotra: '',
            dosh: '',
            doshReason: [],
        },
        validationSchema: signupValidations,
        onSubmit: values => {
            try {
                /**Retrieving previous step's data */
                const prevStepData = JSON.parse(getSessionKey('REGISTRATION_FORM_DATA'));
                const allData = { ...prevStepData, ...values };
                /**Saving data to localStorage */
                setSessionKey('REGISTRATION_COMPLETED_STEPS', '2');
                setSessionKey('REGISTRATION_FORM_DATA', JSON.stringify(allData));
                /**Sending to next step */
                navigate('/signup-step-3');
            } catch (err) {
                console.log("Error", err);
            }
        },
    });
    function handleDoshSelection(e){
        let values = [...signupForm.values.doshReason];
        if(e.target.checked){
            values.push(e.target.value);
        }else{
            values.splice(values.indexOf(e.target.value),1);
        }
        signupForm.setFieldValue('doshReason',values);
    }

    // useEffect(()=>{
    //     /**Getting getSubCastes options */
    //     getSubCastes({caste_id:signupForm.values.caste}).then((response) => {
    //         if (response.success) {
    //             setSubCasteOptions(response.data);
    //         }
    //     }).catch((err) => {
    //         console.log("Server error ", err);
    //     });
        
    // },[signupForm.values.caste]);

    useEffect(() => {
        signupFormRef.current = signupForm;
    }, [signupForm]);
    function handleDoshChange(e){
        signupForm.setFieldValue('dosh', e.target.value); 
        if(e.target.value!==DOSH_QUESTION_OPTIONS.YES){
            signupForm.setFieldValue('doshReason', []); 
        }
    };
    return (
        <div className="wraper-inner">
            <section className="accounts-page padding-space">
                <div className="container">
                    <span className="tag-line">
                        Great! You have completed <b>40%</b>
                    </span>
                    <div className="signup-box-main">
                        <div className="row">
                            <div className="col-md-4 pe-0">
                                <div className="signup-box-l">
                                    <figure>
                                        <img src={trustedImg} alt="trusted-img.webp" />
                                    </figure>
                                    <figcaption>
                                        <p>Every profile is mobile-verified to ensure credibility</p>
                                    </figcaption>
                                </div>
                            </div>
                            <div className="col-md-8 ps-0">
                                <div className="signup-box-r">
                                    <h3>Religion/Caste details help us find better matches</h3>
                                    <div className="singup-form">
                                        <form onSubmit={signupForm.handleSubmit}>
                                            <div className="form-group  mb-3">
                                                <div className="inline-field  align-items-start">
                                                    <label className="form-label pt-2">Caste</label>
                                                    <div className="addon-check">
                                                        <div>
                                                            <select className="form-select b-border-input" name={'caste'} 
                                                            onChange={signupForm.handleChange} value={signupForm?.values?.caste}>
                                                                <option value={''}>Select</option>
                                                                {
                                                                    casteOptions.map((item, i) => {
                                                                        return (<option key={i} value={item?.id}>{item?.name}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                            <CustomError name='caste' form={signupForm} />
                                                        </div>
                                                        <div className="form-check mt-2 d-flex align-items-center">
                                                            <input
                                                                className="form-check-input me-2"
                                                                type="checkbox"
                                                                defaultValue={false}
                                                                onChange={(e)=>{signupForm.setFieldValue('willingToMarryOtherCommunities',e.target.checked)}} checked={signupForm?.values?.willingToMarryOtherCommunities}
                                                                name='willingToMarryOtherCommunities'
                                                                id="willingToMarryOtherCommunities"
                                                            />
                                                            <label
                                                                className="form-check-label cp"
                                                                htmlFor="willingToMarryOtherCommunities"
                                                            >
                                                                Willing to marry from other communities also
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="inline-field ">
                                                    <label className="form-label">Subcaste</label>
                                                    <div>
                                                        {/* <select className="form-select b-border-input" name={'subCaste'} onChange={signupForm.handleChange} value={signupForm?.values?.subCaste}>
                                                            <option value={''}>Select</option>
                                                            {
                                                                SUB_CASTES.map((item, i) => {
                                                                    return (<option key={i} value={item?.label}>{item?.value}</option>)
                                                                })
                                                            }
                                                        </select> */}
                                                        <input
                                                            className="form-control b-border-input"
                                                            type="text"
                                                            name={'subCaste'} 
                                                            onChange={signupForm.handleChange}
                                                            value={signupForm?.values?.subCaste}
                                                        />
                                                        <CustomError name='subCaste' form={signupForm} />
                                                    </div>
                                                    {/* <div className="option-text">(Optional)</div> */}
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="inline-field ">
                                                    <label className="form-label">Gothra(m)</label>
                                                    <div>
                                                        <select className="form-select b-border-input" name={'gotra'} onChange={signupForm.handleChange} value={signupForm?.values?.gotra}>
                                                            <option value={''}>Select</option>
                                                            {
                                                                gotraOptions.map((item, i) => {
                                                                    return (<option key={i} value={item?.id}>{item?.name}</option>)
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="option-text">(Optional)</div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 ">
                                                <div className="inline-field ">
                                                    <label className="form-label">Dosh</label>
                                                    <div className="cutom-checked d-flex addon-box">
                                                        {
                                                            Object.keys(DOSH_QUESTION_OPTIONS).map((item, i)=>{
                                                                return (<div className="form-check" key={i}>
                                                                    <input
                                                                        className="form-check-input cp"
                                                                        type="radio"
                                                                        name={'dosh'} onChange={handleDoshChange} 
                                                                        value={DOSH_QUESTION_OPTIONS[item]}
                                                                        id={"doshNo"+i}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor={"doshNo"+i}
                                                                    >
                                                                        {prepareLabel(DOSH_QUESTION_OPTIONS[item])}
                                                                    </label>
                                                                </div>)
                                                            })
                                                        }
                                                        {/* <div className="form-check ">
                                                            <input
                                                                className="form-check-input cp"
                                                                type="radio"
                                                                name={'dosh'} onChange={signupForm.handleChange} value='yes'
                                                                id="doshYes"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="doshYes"
                                                            >
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div className="form-check ">
                                                            <input
                                                                className="form-check-input cp"
                                                                type="radio"
                                                                name={'dosh'} onChange={signupForm.handleChange} value='dont_know'
                                                                id="doshDontKnow"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="doshDontKnow"
                                                            >
                                                                Don't Know
                                                            </label>
                                                        </div> */}
                                                    </div>
                                                    <div className="option-text">(Optional)</div>
                                                </div>
                                            </div>
                                            {
                                                signupForm?.values?.dosh && signupForm?.values?.dosh === DOSH_QUESTION_OPTIONS.YES ?
                                                <div className="check-option addon-min">
                                                    <ul className="check-option-list d-flex">
                                                        {
                                                            doshOptions.map((item, i)=>{
                                                                return (
                                                                    <li key={i}>
                                                                        <div className="form-check d-flex align-items-center">
                                                                            <input
                                                                                className="form-check-input cp me-2"
                                                                                type="checkbox"
                                                                                name='doshReason'
                                                                                value={item?.id}
                                                                                onChange={handleDoshSelection}
                                                                                id={"doshReason"+i}
                                                                            />
                                                                            <label
                                                                                className="form-check-label cp"
                                                                                htmlFor={"doshReason"+i}
                                                                            >
                                                                                {item?.name}
                                                                            </label>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                    <CustomError name='doshReason' form={signupForm} />
                                                </div>
                                                :null
                                            }
                                            <button className="btn btn-primary min-w mt-5" type='submit'>
                                                Continue
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-text text-center mt-4 mb-4">
                        <p className="">Copyright © 2023. All rights reserved.</p>
                    </div>
                </div>
            </section>
        </div>

    )
}