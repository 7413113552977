import React, { useEffect, useRef, useState } from 'react';
import trustedImg from '../../../assets/user/images/trusted-img.webp';
import { getSessionKey, globalLoader, hideAllErrors, setSessionKey } from '../../../utils/commonfunction';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CustomError from '../shared/CustomError';
import { useNavigate } from 'react-router-dom';
import {getQueries, checkRegisteredEmail, checkRegisteredPhone } from '../services/global.service';
import { sendEmailOtp, verifyOtp } from '../services/auth.service';
import { Button, DatePicker, Modal } from 'rsuite';
import { failedResponse, successResponse } from '../../../utils/toastr';
import { ATLEAST_ONE_CAPITAL_REGEX, ATLEAST_ONE_NUMBER_REGEX, ATLEAST_ONE_SMALL_REGEX, ATLEAST_ONE_SPECIAL_CHARACTER_REGEX, PASSWORD_MIN_LENGTH } from '../../../utils/Constants';
import { cognitoSignup, confirmSignUp, getCodeForVerifyPhone, getLoggedInUser, resendConfirmSignUpCode, signIn, verifyPhone } from '../../../utils/amazon_utils2';

export default function SignupStep1() {
    const confirmUserModalHandler = useRef();
    const verifyPhoneModalHandler = useRef();
    const verifyPhoneModalFormCloseHandler = useRef();
    const navigate = useNavigate();
    const [religionOptions, setReligionOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [motherTongueOptions, setMotherTongueOptions] = useState([]);
    let prevStepData = JSON.parse(getSessionKey('REGISTRATION_FORM_DATA'));
    useEffect(() => {
        document.querySelector('body').classList.add('bg-lignt-grey-shade');
        return () => {
            document.querySelector('body').classList.remove('bg-lignt-grey-shade');
        };
    }, []);
    useEffect(() => {
        // verifyPhoneModalHandler.current.click()
        /**Checking that user is coming from prev step */
        const prevStep = getSessionKey('REGISTRATION_COMPLETED_STEPS');
        if(prevStep && prevStep === "0"){
            prevStepData =JSON.parse(getSessionKey('REGISTRATION_FORM_DATA'))
            /** Everything is fine */
        }else{
            /**Go back to initial step */
            navigate('/');
        }
        
    }, [navigate]);
    useEffect(() => {
        /**Getting religion options */
        getQueries({type:'religion'}).then((response)=>{
            if(response.success){
                setReligionOptions(response.data);
            }
        }).catch((err)=>{
            console.log("Server error ", err);
        });

        /**Getting mother-tongue options */
        getQueries({type:'mother-tongue'}).then((response)=>{
            if(response.success){
                setMotherTongueOptions(response.data);
            }
        }).catch((err)=>{
            console.log("Server error ", err);
        });
        // confirmUserModalHandler.current.click();
        // verifyPhoneModalHandler.current.click();
    }, []);

    const signupValidations = Yup.object().shape({
        dateOfBirth: Yup.string().required('Date of birth is required'),
        religion: Yup.string().required('Religion is required'),
        motherTongue: Yup.string().required('Mother tongue is required'),
        email: Yup.string().trim().required('Email is required').matches(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{3,})$/i,'Please enter a valid email'),
        phone: Yup.object().shape({
            number: Yup.number()
                .typeError('Invalid phone number')
                .required('Phone is required')
                .test(
                    'len',
                    'Phone number must be exactly 10 digits',
                    (val) => val && val.toString().length === 10
                ),
        }),
        password: Yup.string().required('Password is required')
        .matches(ATLEAST_ONE_SMALL_REGEX, "Password should have at least 1 lowercase letter")
        .matches(ATLEAST_ONE_CAPITAL_REGEX, "Password should have at least 1 capital letter")
        .matches(ATLEAST_ONE_NUMBER_REGEX, "Password should have at least 1 number")
        .matches(ATLEAST_ONE_SPECIAL_CHARACTER_REGEX, "Password should have at least 1 special character")
        .min(PASSWORD_MIN_LENGTH, "Password should be at least 8 characters long"),
    });

    const signupForm = useFormik({
        initialValues: {
            dateOfBirth: '',
            religion: '',
            motherTongue: '',
            email: '',
            phone: {
                code: '+91',
                number: ''
            },
            password: '',
        },
        validationSchema: signupValidations,
        onSubmit: async values => {
            try{
                /**Checking for email availability */
                globalLoader(true);
                checkRegisteredEmail({ email: values.email }).then((response) => {
                    if(response?.success){
                        /**Checking for phone availability */
                        const allData = { ...prevStepData, ...signupForm?.values };
                        setSessionKey('REGISTRATION_FORM_DATA', JSON.stringify(allData));
                        checkRegisteredPhone(values.phone).then(phoneCheckResponse => {
                            if (phoneCheckResponse.success) {
                            const cognitoSignupData = { name: allData.name, email: allData.email, phone: allData.phone.code.trim() + allData.phone.number.trim(), password: allData.password };
                            /**Saving data to localStorage */
                            console.log("allData",allData);
                            cognitoSignup(cognitoSignupData).then((cognitoResponse) => {
                                console.log("cognitoResponse",cognitoResponse);
                                let newData = { ...allData, cognito_user_id:cognitoResponse.userSub };
                                setSessionKey('REGISTRATION_FORM_DATA', JSON.stringify(newData));
                                confirmUserModalHandler.current.click();
                                globalLoader(false);
                            }).catch((error) => {
                                failedResponse(error?.message);
                                globalLoader(false);
                            })
                            } else {
                                globalLoader(false);
                                failedResponse(phoneCheckResponse.message);
                            }
                        }).catch((err) => {
                            globalLoader(false);
                            failedResponse(err.message);
                        });
                    } else {
                        globalLoader(false);
                        failedResponse(response.message);
                    }
                }).catch((err) => {
                    globalLoader(false);
                    failedResponse(err.message);
                })
            }catch(err){
                failedResponse(err.message);
            }
        },
    });

    const confirmUserOTPForm = useFormik({
        initialValues: {
            otp: ''
        },
        validationSchema: Yup.object().shape({
            otp: Yup.string().trim().required('Please enter OTP').min(6, 'Invalid OTP').max(6, 'Invalid OTP').matches(/^[0-9]+$/, 'Invalid OTP'),
        }),
        onSubmit: async (values, {resetForm}) => {
            globalLoader(true);
            try {
                let allData = JSON.parse(getSessionKey('REGISTRATION_FORM_DATA'));
                let confirmData = {
                    username: allData.phone.code+allData.phone.number.trim(),
                    otp:values.otp
                };
                confirmSignUp(confirmData).then(()=>{
                    successResponse("Email verified successfully");
                    /** Login user */
                    signIn(confirmData.username, allData.password).then(()=>{
                        /**Getting current logged-in user */
                        getLoggedInUser().then((user)=>{
                            /**Sending OTP on user's phone number */
                            getCodeForVerifyPhone(user).then(()=>{
                                verifyPhoneModalHandler.current.click();
                                globalLoader(false);
                            }).catch((err)=>{
                                globalLoader(false);
                                failedResponse(err?.message);
                            });
                        }).catch((err)=>{
                            globalLoader(false);
                            failedResponse(err?.message);
                        });
                    }).catch((err)=>{
                        globalLoader(false);
                        failedResponse(err?.message);
                    });
                }).catch(err=>{
                    globalLoader(false);
                    failedResponse(err?.message);
                });
            } catch (err) {
                console.log("Error", err);
                failedResponse(err?.message);
                globalLoader(false);
            }
        }
    });

    const verifyPhoneModalForm = useFormik({
        initialValues: {
            otp: ''
        },
        validationSchema: Yup.object().shape({
            otp: Yup.string().trim().required('Please enter OTP').min(6, 'Invalid OTP').max(6, 'Invalid OTP').matches(/^[0-9]+$/, 'Invalid OTP'),
        }),
        onSubmit: async (values, {resetForm}) => {
            globalLoader(true);
            try {
                getLoggedInUser().then((user)=>{
                    verifyPhone(user, values.otp).then((result)=>{
                        successResponse("Phone verified successfully");
                        globalLoader(false);
                        if(verifyPhoneModalFormCloseHandler && verifyPhoneModalFormCloseHandler.current){
                            verifyPhoneModalFormCloseHandler.current.click();
                        }
                        setTimeout(() => {
                            /**Sending user to step 2 */
                            setSessionKey('REGISTRATION_COMPLETED_STEPS', '1');
                            navigate('/signup-step-2');
                        }, 100);
                    }).catch(err=>{
                        console.log("Error", err);
                        globalLoader(false);
                    })
                })
            } catch (err) {
                console.log("Error", err);
                failedResponse(err?.message);
                globalLoader(false);
            }
        }
    });

    function disabledDate(date) {
        const eighteenYearsAgo = new Date();
        eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
        /** Disable dates that are greater than or equal to eighteen years back */
        return date >= eighteenYearsAgo;
    };
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    function handleResendCode(type) {
        if(type === "confirmSignup"){
            let allData = JSON.parse(getSessionKey('REGISTRATION_FORM_DATA'));
            const userName = allData.phone.code+allData.phone.number.trim();
            resendConfirmSignUpCode(userName).then(()=>{
                successResponse("OTP re-sent successfully");
            }).catch((err)=>{
                failedResponse(err.message);
            });
        }else if(type === "phoneVerification"){
            getLoggedInUser().then((user)=>{
                /**Sending OTP on user's phone number */
                getCodeForVerifyPhone(user).then(()=>{
                    successResponse("OTP re-sent successfully");
                }).catch((err)=>{
                    failedResponse(err?.message);
                });
            }).catch((err)=>{
                globalLoader(false);
                failedResponse(err?.message);
            });
        }
    };

    function handleSkip(){
        if(verifyPhoneModalFormCloseHandler && verifyPhoneModalFormCloseHandler.current){
            verifyPhoneModalFormCloseHandler.current.click();
        }
        setTimeout(() => {
            /**Sending user to step 2 */
            setSessionKey('REGISTRATION_COMPLETED_STEPS', '1');
            navigate('/signup-step-2');
        }, 100);
    }

    return (
        <>
            <div className="wraper-inner">
                <section className="accounts-page padding-space">
                    <div className="container">
                        <span className="tag-line">
                            Great! You have completed <b>20%</b>
                        </span>
                        <div className="signup-box-main">
                            <div className="row">
                                <div className="col-md-4 pe-0">
                                    <div className="signup-box-l">
                                        <figure>
                                            <img src={trustedImg} alt="trusted-img.webp" />
                                        </figure>
                                        <figcaption>
                                            <p>Trusted by thousands of Indians across the world.</p>
                                        </figcaption>
                                    </div>
                                </div>
                                <div className="col-md-8 ps-0">
                                    <div className="signup-box-r">
                                        <h3>Please provide us with {prevStepData?.profileFor === "myself" ? "your" : "your " + prevStepData?.profileFor + "'s"} basic details</h3>
                                        <div className="singup-form">
                                            <form onSubmit={signupForm.handleSubmit}>
                                                <div className="form-group mb-3">
                                                    <div className="inline-field ">
                                                        <label className="form-label">Date Of Birth</label>
                                                        <div className="date-box date datepicker">
                                                            <DatePicker className="form-control b-border-input sign-up-datepicker"
                                                                caretAs={() => null} oneTap={true} cleanable={false} placeholder='DD / MM / YYYY' format="dd/MM/yyyy"
                                                                shouldDisableDate={disabledDate}
                                                                calendarDefaultDate={eighteenYearsAgo}
                                                                onChange={(value) => {
                                                                    signupForm.setFieldValue('dateOfBirth', value);
                                                                }} />
                                                            <CustomError name='dateOfBirth' form={signupForm} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <div className="inline-field ">
                                                        <label className="form-label">Religion</label>
                                                        <div>
                                                            <select className="form-select b-border-input" name={'religion'} onChange={signupForm.handleChange} value={signupForm?.values?.religion}>
                                                                <option value={''}>Select</option>
                                                                {
                                                                    religionOptions.map((religion, i) => {
                                                                        return (<option key={i} value={religion?.id}>{religion?.name}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                            <CustomError name='religion' form={signupForm} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <div className="inline-field ">
                                                        <label className="form-label">Mother Tongue</label>
                                                        <div>
                                                            <select className="form-select b-border-input" name={'motherTongue'} onChange={signupForm.handleChange} value={signupForm?.values?.motherTongue}>
                                                                <option value={''}>Select</option>
                                                                {
                                                                    motherTongueOptions.map((motherTongue, i) => {
                                                                        return (<option key={i} value={motherTongue?.id}>{motherTongue?.name}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                            <CustomError name='motherTongue' form={signupForm} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <div className="inline-field ">
                                                        <label className="form-label">Email ID</label>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                name="email"
                                                                placeholder=""
                                                                className="form-control b-border-input"
                                                                autoComplete="off"
                                                                onChange={signupForm.handleChange}
                                                                value={signupForm?.values?.email}
                                                            />
                                                            <CustomError name='email' form={signupForm} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <div className="inline-field ">
                                                        <label className="form-label">Phone</label>
                                                        <div className='d-flex' style={{ width: "300px" }}>
                                                            <select className="form-select b-border-input me-3" style={{ minWidth: "78px", width: "78px" }}
                                                                name='phone.code' onChange={signupForm.handleChange} value={signupForm?.values?.phone.code}>
                                                                <option value="+91">+91</option>
                                                                <option value="+1">+1</option>
                                                            </select>
                                                            <div style={{ flex: '4' }}>
                                                                <input
                                                                    type="text"
                                                                    name='phone.number' onChange={signupForm.handleChange} value={signupForm?.values?.phone.number}
                                                                    className="form-control b-border-input w-100"
                                                                />
                                                                <CustomError name='phone.number' form={signupForm} className="d-block" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <div className="inline-field ">
                                                        <label className="form-label">Password</label>
                                                        <div>
                                                            <input
                                                                type="password"
                                                                name="password"
                                                                placeholder=""
                                                                className="form-control b-border-input"
                                                                onChange={signupForm.handleChange} value={signupForm?.values?.password}
                                                            />
                                                            <CustomError name='password' form={signupForm} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className="btn btn-primary min-w mt-5" type='submit'>
                                                    Continue
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-text text-center mt-4 mb-4">
                            <p className="">Copyright © 2023. All rights reserved.</p>
                        </div>
                    </div>
                </section>
            </div>
            {/* <<<<<<< confirm-user-modal >>>>>> */}
            <button className="d-none" data-bs-toggle="modal" data-bs-target="#confirm-user-modal" ref={confirmUserModalHandler}></button>
            <div className="modal fade common-modal xs-modal" data-bs-backdrop="static" id="confirm-user-modal" tabIndex={-1} aria-labelledby="confirm-user-modal"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-head">
                            <h1 className="modal-title">Email verification</h1>
                            <button type="button" onClick={() => { confirmUserOTPForm.resetForm(); hideAllErrors(confirmUserOTPForm); }} id='close_verify_otp' className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <form onSubmit={confirmUserOTPForm.handleSubmit}>
                                <div className="form-group mb-3">
                                    <label className="form-label" htmlFor='otp-input-confirmUserOTPForm'>Enter OTP</label>
                                    <input type="text" name="otp" id='otp-input-confirmUserOTPForm' placeholder="" className="form-control"
                                        onChange={confirmUserOTPForm.handleChange} value={confirmUserOTPForm?.values?.otp} />
                                    <CustomError name='otp' form={confirmUserOTPForm} />
                                </div>
                                <button className="btn btn-primary w-100" type='submit'>Submit</button>
                            </form>
                            <ul className="login-info-link">
                                <li>
                                    <a onClick={()=>{handleResendCode("confirmSignup")}}>Resend Code</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <<<<<<< confirm-user-modal ends >>>>>> */}

            {/* <<<<<<< verify-phone-modal >>>>>> */}
            <button className="d-none" data-bs-toggle="modal" data-bs-target="#verify-phone-modal" ref={verifyPhoneModalHandler}></button>
            <div className="modal fade common-modal xs-modal" data-bs-backdrop="static" id="verify-phone-modal" tabIndex={-1} aria-labelledby="verify-phone-modal"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-head">
                            <h1 className="modal-title">Phone number verification</h1>
                            <button type="button" ref={verifyPhoneModalFormCloseHandler} onClick={() => { verifyPhoneModalForm.resetForm(); hideAllErrors(verifyPhoneModalForm); }} id='close_verify_otp' className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <form onSubmit={verifyPhoneModalForm.handleSubmit}>
                                <div className="form-group mb-3">
                                    <label className="form-label" htmlFor='otp-input-verifyPhoneModal'>Enter OTP</label>
                                    <input type="text" name="otp" id='otp-input-verifyPhoneModal' placeholder="" className="form-control"
                                        onChange={verifyPhoneModalForm.handleChange} value={verifyPhoneModalForm?.values?.otp} />
                                    <CustomError name='otp' form={verifyPhoneModalForm} />
                                </div>
                                <button className="btn btn-primary w-100" type='submit'>Submit</button>
                            </form>
                            <ul className="login-info-link">
                                <li>
                                    <a onClick={()=>{handleResendCode("phoneVerification")}}>Resend Code</a>
                                    <a onClick={handleSkip}>Skip</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <<<<<<< verify-phone-modal ends >>>>>> */}
        </>
    )
}