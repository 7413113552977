import React, { useLayoutEffect, useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import { Outlet } from 'react-router-dom';
import { getLocalKey, getSessionKey, globalLoader } from '../../../utils/commonfunction';


const UserLayout = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    // useLayoutEffect(() => {
    //     globalLoader(true);
    //     Promise.all([
    //         import('rsuite/dist/rsuite.min.css'),
    //         import('../../../assets/user/fonts/remixicon.css'),
    //         import('../../../assets/user/css/owl.carousel.min.css'),
    //         import("../../../assets/user/css/all.css"),
    //         import("../../../assets/user/css/tabler-icons.css"),
    //         import("../../../assets/user/css/animation.css"),
    //         import("../../../assets/user/css/style.css")]).then(() => {
    //         globalLoader(false);
    //     })
    // }, []);

    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}

export default UserLayout;